export const conditionalOptions = [
  { label: "Yes", code: true },
  { label: "No", code: false }
];
export const availabilityOptions = [
  { label: "Full Time", code: "Full Time" },
  { label: "Part Time", code: "Part Time" }
];
export const contractOptions = [
  { label: "Permanent", code: "permanent_work" },
  { label: "Temporary", code: "temporary_work" }
];
export const conditionalOptionWithNotSelected = [
  { label: "Yes", code: true },
  { label: "No", code: false },
  { label: "Not Selected", code: 'null' }
];
export const activeOptions = [
  { label: "Active", code: true },
  { label: "Deactivated", code: false },
  { label: "Not Selected", code: "null" },
];
export const jobStatusOptions = [
  { label: "Draft Jobs", code: "un-published" },
  { label: "Published Jobs", code: "published" }
];
export const publishedOptions = [
  { label: "Draft", code: 27 },
  { label: "Published", code: 29 },
  { label: "Inactive", code: 30 },
];
export const jobAvailabilityOptions = [
  { label: "Full Time", code: "full_time" },
  { label: "Part Time", code: "part_time" }
];