import candidate from "@/services/candidate";
import job from "@/services/job";
import location from "@/services/location";
import speciality from "@/services/speciality";
import qualification from "@/services/qualification";
import genderService from "@/services/gender";
import filter from "@/services/filter";
import hospital from "@/services/hospital";
import router from "@/router/index";

// TODO:: update the error messages in all export functions
// TODO:: Refactor this to use a common function to populate the data
// TODO:: Replace let with const where ever neccessary
// TODO:: update the function names with generate prifix generate a new function in schema
export const licenseAuthPqr = ({ commit }, payload) => {
  return filter
    .licenseAuthPqr(payload)
    .then(res => {
      let { data } = res;
      return data;
    })
    .catch(err => {
      console.log("error in licenseAuthPqr", err);
      return err;
    });
};

export const genericGetHospitals = ({ commit, getters }) => {
  let { getCustomerId, getCandidateId } = getters;
  if (!getCustomerId) return
  const query = ""
  return job
    .getOrganizationDetails({ customer_uid: getCustomerId, query })
    .then(async res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.organisation.name,
        code: val.organisation_id
      }));
      commit("SET_GENERIC_HOSPITALS", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching organization id", err);
      return err;
    });
};

export const genericGetOrganisations = ({ commit }) => {
  return filter
    .organisationFilter()
    .then(res => {
      const { data } = res;
      const mappedData = data.map(v => ({ code: v?.organisation_id, label: v?.name }))
      commit("SET_GENERIC_ORGANISATIONS",mappedData)
      return res;
    })
    .catch(err => {
      console.log("Error while Fetch Organisation List", err);
      return err;
    })
}

export const getTitles = ({ commit }) => {
  return candidate
    .getTitles()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.comments,
        code: val.location_id
      }));
      commit("SET_GENERIC_TITLES", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching titles", err);
      return err;
    });
};

export const pqrHeaderCountries = ({ commit }) => {
  return filter
    .pqrHeaderCountries()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.country_name,
        code: val.country_id
      }));
      commit("SET_GENERIC_PQR_HEADER_COUNTRIES", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching titles", err);
      return err;
    });
};

export const pqrHeaderLicensingAuthorities = ({ commit }, query) => {
  return filter
    .pqrHeaderLicensingAuthorities(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.filter(({ licensing }) => licensing === true)
        .map(val => ({
          label: val.issue_authority,
          code: val.issue_authority_id
        }));
      commit("SET_GENERIC_PQR_HEADER_LICENSING_AUTHORITIES", mappedData);
      return mappedData;
    })
    .catch(err => {
      // commit("SET_GENERIC_PQR_HEADER_LICENSING_AUTHORITIES", []);
      console.log("error fetching titles", err);
      return err;
    });
};

export const genericGetLocations = ({ commit }) => {
  return candidate
    .getLocations()
    .then(res => {
      let { data } = res;
      const mappedData = data.filter(({ location_id }) => location_id === 1).map(val => ({
        label: val.location,
        code: val.location_id
      }));
      commit("SET_GENERIC_LOCATIONS", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching locations", err);
      return err;
    });
};
export const getUAELocations = ({ commit }) => {
  return candidate
    .getUAELocations()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_UAE_LOCATIONS", data);
      return res;
    })
    .catch(err => {
      console.log("error fetching getUAELocations", err);
      return err;
    });
};
export const getHospitalName = ({ commit }) => {
  return hospital
    .getHospitalList()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_HOSPITAL_LIST", data);
      return res;
    })
    .catch(err => {
      console.log("error fetching locations", err);
      return err;
    });
};
export const genericGender = ({ commit }) => {
  return genderService
    .gender()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.gender,
        code: val.gender_id
      }));
      // commit("SET_GENERIC_GENDER", mappedData.concat({ label: "Not specified", code: 'null' }));
      commit("SET_GENERIC_GENDER", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in gender", err);
      return err;
    });
};
export const licensingAuthority = ({ commit }) => {
  return filter
    .licensingAuthority()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.comments,
        code: val.location_id
      }));
      commit("SET_GENERIC_LICENSING_AUTHORITY", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericCandidateLevel = ({ commit }) => {
  let query = `country_id__in=null`;
  return candidate
    .candidateLevel(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_level,
        code: val.level_id,
        typeId: val.candidate_type_id
      }));
      commit("SET_GENERIC_CANDIDATE_LEVEL", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateLevel", err);
      return err;
    });
};

export const candidateLevelCountryBased = ({ commit }) => {
  let query = `country_id__in=181`;
  return candidate
    .candidateLevel(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_level,
        code: val.level_id,
        typeId: val.candidate_type_id
      }));
      commit("SET_CANDIDATE_LEVEL_COUNTRY_BASED", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateLevel", err);
      return err;
    });
};

export const pqrLicensingLevel = ({ commit }, query) => {
  return candidate
    .candidateLevel(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_level,
        code: val.level_id,
        typeId: val.candidate_type_id
      }));
      commit("SET_GENERIC_PQR_HEADER_LICENSING_LEVEL", mappedData);
      return mappedData;
    })
    .catch(err => {

      console.log("error fetching levels", err);
      return err;
    });
};

export const recruitingCandidateLevel = ({ commit }) => {
  return candidate
    .recruitingCandidateLevel()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_RECRUITING_CANDIDATE_LEVEL", data);
      return res;
    })
    .catch(err => {
      console.log("error in recruitingCandidateLevel", err);
      return err;
    });
};

export const genericSubSpeciality = ({ commit }) => {
  return speciality
    .subSpeciality()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(({ speciality_reference, country_code, speciality_name, sub_speciality }) => ({
        label: `${country_code ? `${country_code} | ` : ''}${speciality_name} | ${sub_speciality}`,
        code: speciality_reference,
        speciality,
        sub_speciality,
        country_code
      }));
      commit("SET_GENERIC_SUB_SPECIALITY", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in skill", err);
      return err;
    });
};

export const getGenericSpecialitySearchBasedOnId = ({ commit }, searchQuery = "specialitySearch") => {
  let { query } = router.history.current;
  let queryKey = 'speciality_references';
  let queryData = query[searchQuery];
  if (queryData) {
    queryData = queryData.replace(/]|\[/g, "");
    return filter.specialitySearch(`${queryKey}=${queryData}`).then(res => {
      let { data } = res;
      const mappedData = data.map(({ speciality_reference, country_code, speciality, sub_speciality, sub_speciality_id, group_by, speciality_id, speciality_reference_group }) => ({
        label: `${country_code ? `${country_code} | ` : ''}${speciality} | ${sub_speciality}`,
        code: speciality_reference,
        speciality,
        sub_speciality,
        country_code,
        group_by,
        id: speciality_reference,
        speciality_id,
        sub_speciality_id,
        speciality_reference_group
      }));
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", mappedData);
      return res;
    }).catch(err => {
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", []);
      console.log("error in genericCountrySpecialitySubspeciality", err);
      return err;
    });
  }
}

export const genericSpecialitySearch = ({ commit }, searchQuery) => {
  return filter.specialitySearch(searchQuery).then(res => {
    let { data } = res;
    const mappedData = data.map(({ speciality_reference, country_code, speciality, sub_speciality, sub_speciality_id, speciality_id, speciality_reference_group, group_by }) => ({
      label: `${country_code ? `${country_code} | ` : ''}${speciality} | ${sub_speciality}`,
      code: speciality_reference,
      speciality,
      sub_speciality,
      country_code,
      group_by,
      sub_speciality_id,
      speciality_id,
      id: speciality_reference,
      speciality_reference_group,
    }));
    commit("SET_GENERIC_SPECIALITY_SEARCH", mappedData);
    return res;
  }).catch(err => {
    commit("SET_GENERIC_SPECIALITY_SEARCH", []);
    console.log("error in genericCountrySpecialitySubspeciality", err);
    return err;
  });
}

export const getGenericSpecialitySearchBasedOnCode = ({ commit }, payload) => {
  let { query } = router.history.current;
  let queryKey = 'speciality_references';
  let queryData = payload;
  if (payload.value.length) {
    if (typeof payload?.value[0] === "object")
      queryData = payload?.value.map(({ code }) => code).join()
    else
      queryData = payload?.value.join();
    return filter.specialitySearch(`${queryKey}=${queryData}`).then(res => {
      let { data } = res;
      const mappedData = data.map(({ speciality_reference, country_code, speciality, sub_speciality, sub_speciality_id, speciality_id, speciality_reference_group }) => {
        const splitArr = speciality_reference.replace(/[()]/g, '').split(",");
        const label = splitArr[0] === "0" ? sub_speciality : speciality
        const r = {
          label: label,
          code: speciality_reference,
          speciality,
          sub_speciality,
          country_code,
          sub_speciality_id,
          speciality_id,
          id: speciality_reference,
          speciality_reference_group
        }
        return r;
      }) || [];
      commit("UPDATE_SELECTED_FILTER", { name: "specialitySearch", value: mappedData });
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", mappedData);
      return res;
    }).catch(err => {
      commit("UPDATE_SELECTED_FILTER", { name: "specialitySearch", value: [] });
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", []);
      console.log("error in genericCountrySpecialitySubspeciality", err);
      return err;
    });
  } else {
    commit("UPDATE_SELECTED_FILTER", { name: "specialitySearch", value: [] });
  }
}

export const genericCandidateType = ({ commit }) => {
  return candidate
    .candidateType()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_type,
        code: val.candidate_type_id
      }));
      commit("SET_GENERIC_CANDIDATE_TYPE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const jobStatus = ({ commit }) => {
  return job
    .jobStatus()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_JOB_STATUS", data);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
// export const locationLanguage = ({ commit }) => {
//   return location
//     .locationLanguage()
//     .then(res => {
//       let { data } = res;
//       commit("SET_GENERIC_LOCATION_LANGUAGE", data);
//       return res;
//     })
//     .catch(err => {
//       console.log("error in candidateType", err);
//       return err;
//     });
// };
export const genericLocationLanguage = ({ commit }) => {
  return location
    .locationLanguage()
    .then(res => {
      let { data } = res;

      const mappedData = data.map(val => ({
        label: val.language,
        code: val.language_id
      }));
      commit("SET_GENERIC_LOCATION_LANGUAGE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericLocationRegion = ({ commit }) => {
  return location
    .locationRegion()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.region_name,
        code: val.region_id
      }));
      commit("SET_GENERIC_LOCATION_REGION", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericLocationCountryInfo = ({ commit }) => {
  return location
    .locationCountryInfo()
    .then(res => {
      let { data } = res;
      const nationalityInfo = data.map(val => ({
        label: val.nationality,
        code: val.country_id
      }))
      const birthPlacesInfo = data.map(val => ({
        label: val.country_name,
        code: val.country_id
      }))
      const preferredLocationsInfo = data.map(val => ({
        label: val.country_name,
        code: val.country_id,
        typeId: val.region_id
      }));
      commit("SET_GENERIC_LOCATION_COUNTRY_INFO", data);
      commit("SET_GENERIC_BIRTH_PLACE", birthPlacesInfo);
      commit("SET_GENERIC_NATIONALITY_INFO", nationalityInfo);
      commit("SET_GENERIC_PREFERRED_LOCATIONS", preferredLocationsInfo);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericGetSpeciality = ({ commit }) => {
  return speciality
    .speciality()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.speciality,
        code: val.speciality_id,
        typeId: val.candidate_type_id
      }));
      commit("SET_GENERIC_SPECIALITY", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const skill = ({ commit }) => {
  return speciality
    .skill()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_SKILL", data);
      return res;
    })
    .catch(err => {
      console.log("error in skill", err);
      return err;
    });
};
// export const specialInterest = ({ commit }) => {
//   return speciality
//     .specialInterest()
//     .then(res => {
//       let { data } = res;
//       commit("SET_GENERIC_SPECIAL_INTEREST", data);
//       return res;
//     })
//     .catch(err => {
//       console.log("error in skill", err);
//       return err;
//     });
// };
export const getQualification = ({ commit }) => {
  return qualification
    .qualification()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_QUALIFICATION", data);
      return res;
    })
    .catch(err => {
      console.log("error in skill", err);
      return err;
    });
};

export const fetchFavouriteFilter = ({ commit, getters }, page_name) => {
  const { getUserId: userId } = getters;
  commit("RESET_FAVOURITE_FILTER");
  return filter
    .getFavouriteFilter({ userId, page_name })
    .then(res => {
      let { data } = res;
      commit("SET_FAVOURITE_FILTER", data);
      return res;
    })
    .catch(err => {
      console.log("error in filter", err);
      return err;
    });
}