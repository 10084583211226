import customer from '@/services/customer';
import tabFilter from '@/services/tabFilter';
import _ from 'lodash'
import Vue from 'vue'
import job from '../services/job';
import dashboard from '@/services/dashboard';
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
const getDefaultState = () => {
  return {
    getScratchPads: [],
    getScratchPad: null,
    getTabTotalCounts: [],
    getTabStatusTotalCounts: [],
    getActionDetailsByRecruiter: [],
    getJobStatusTabTotalCount: [],
  };
}

const state = getDefaultState()


const getters = {
  getScratchPads: state => _.orderBy(state.getScratchPads, ['date_added'], ['desc']),
  getScratchPad: state => state.getScratchPad,
  getTabTotalCounts: state => state.getTabTotalCounts,
  getTabStatusTotalCounts: state => state.getTabStatusTotalCounts,
  getActionDetailsByRecruiter: state => state.getActionDetailsByRecruiter,
  getJobStatusTabTotalCount: state => state.getJobStatusTabTotalCount
};

const mutations = {
  ["SET_SCRATCH_PADS"](state, payload) {
    state.getScratchPads = payload;
  },
  ["SET_SCRATCH_PAD"](state, payload) {
    state.getScratchPad = payload;
  },
  ["addScratchPads"](state, payload) {
    state.getScratchPads.push(payload)
  },
  ["updateScratchPads"](state, payload) {
    if (state.getScratchPads) {
      const i = _.findIndex(state.getScratchPads, {
        id: payload.id
      })
      Vue.set(state.getScratchPads, i, payload)
    }
  },
  ["deleteScratchPad"](state, id) {
    const i = _.findIndex(state.getScratchPads, { id })
    if (i > -1) state.getScratchPads.splice(i, 1);
  },
  ["SET_TAB_TOTAL_COUNT"](state, payload) {
    state.getTabTotalCounts = payload;
  },
  ["SET_STATUS_TOTAL_COUNT"](state, payload) {
    state.getTabStatusTotalCounts = payload;
  },
  ["SET_RECRUITER_ACTION_DETAILS"](state, payload) {
    state.getActionDetailsByRecruiter = payload;
  },
  ["SET_JOB_STATUS_TOTAL_COUNT"](state, payload) {
    state.getJobStatusTabTotalCount = payload;
  },
};

const actions = {
  setScratchPad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    commit('SET_SCRATCH_PAD', payload)
  },
  getScratchPadItems: ({ state, commit, rootState, dispatch, getters }) => {
    const {
      getCustomerId,
      getUserId
    } = getters;
    return customer
      .getScratchPadItems(getCustomerId, getUserId)
      .then(async res => {
        commit("SET_SCRATCH_PADS", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  postScratchPad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    return customer
      .postScratchPad(payload.customer_uid, payload.user_id, payload)
      .then(async res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Item saved successfully'
        })
        commit('addScratchPads', res.data)
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  putScratchPad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    console.log(payload)
    return customer
      .putScratchPad(payload.customer_uid, payload.user_id, payload)
      .then(async res => {
        commit('updateScratchPads', res.data)
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Item updated successfully'
        })
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  deleteScratchpad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    return customer
      .deleteScratchpad(payload.customer_uid, payload.user_id, payload)
      .then(async res => {
        commit('deleteScratchPad', payload.id)
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Item deleted successfully'
        })
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  jobCandidateTabTotalCount: ({ state, commit, getters }, customer_id) => {
    let { getIsChildUser, getOrgIDFromAccessToken: organisation_id } = getters;
    let queryPayload = {}
    if (customer_id)
      queryPayload = { ...queryPayload, customer_id };
    if (organisation_id )
      queryPayload = { ...queryPayload, organisation_id }
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return tabFilter.jobCandidateTabTotalCount(queryString, getIsChildUser).then((res) => {
      let tabCounts = [];
      let statusCounts = [];
      _.forEach(res.data, (tabDetail) => {
        let tabCount = {
          displayName: tabDetail?.tab_.tab_name,
          name: tabDetail?.tab_.tab_name.split(" ").join("").toLowerCase(),
          id: 1,
          count: tabDetail?.count,
          value: tabDetail?.count,
          urlToJobStatus: `tab=${tabDetail?.tab_id}&tabFilter=2`
        }
        tabCounts.push(tabCount);
        _.forEach(tabDetail?.filters, status => {
          let statusCount = {
            displayName: status?.filter_.filter_name,
            name: status?.filter_.filter_name.split(" ").join("").toLowerCase(),
            id: status?.filter_?.id,
            count: status?.count,
            value: status?.count,
            urlToJobStatus: `tab=${tabDetail?.tab_id}&tabFilter=${status?.filter_?.id}`
          }
          statusCounts.push(statusCount);
        });

      });
      commit("SET_TAB_TOTAL_COUNT", tabCounts);
      commit("SET_STATUS_TOTAL_COUNT", statusCounts);
      return true;
    }).catch((err) => {
      console.log('error while fetching tab total count: ' + err);
      return err;
    });
  },

  fetchJobStatusTabTotalCount: ({ state, commit, getters }, customer_id) => {
    const { getOrgIDFromAccessToken } = getters;
    let query = getOrgIDFromAccessToken  ? `organisation_uid=${getOrgIDFromAccessToken}` : '';
    return tabFilter.getJobStatusTabTotalCount({ customer_id, query }).then((res) => {
      commit("SET_JOB_STATUS_TOTAL_COUNT", res.data);
    }).catch((err) => {
      console.log('error while fetching tab total count: ' + err);
      return err;
    });
  },

  getRecruiterActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id } = getters
    return job.getActionsForRecruiter({ user_id })
      .then(res => {
        const { data } = res
        commit("SET_RECRUITER_ACTION_DETAILS", data)
      })
      .catch(err => {
        console.log("Error in fetching action details", err)
        return err
      })
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
