import jobBoard from "@/services/jobBoard";
import router from "@/router/index";
import { isObject, Role } from "@/helpers/helper";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";

const state = {
  jobBoard: {},
  isLoading: false,
  selectedJobBoard: {},
  error: undefined,
  jobBoardPagination: {
    limit: 10,
    skip: 0,
    noMoreDataFromJobBoard: false
  },
  jobCount: 0,
  filterCount: 0,
  applicationStatus: null,
  selectedJobBoardAdvert: [],
  selectedJobBenefitsAndAdvertDesc: [],
  lastFetchedJobAdvert: 0,
  jobAdvertisements: [],
};

// create getters for all state variables
const getters = {
  isJobBoardFetching: () => state.isLoading,
  getJobBoard: (state, getters, rootState) => {
    return Object.keys(state.jobBoard).map((val) => state.jobBoard[val]);
  },
  getSelectedJobBoard: (state, getters, rootState) => state.selectedJobBoard,
  getSelectedJobBoardAdvert: (state, getters, rootState) => state.selectedJobBoardAdvert,
  getSelectedJobBenefitsAndAdvertDesc: (state) => state.selectedJobBenefitsAndAdvertDesc,
  getApplicationStatus: (state, getters, rootState) => state.applicationStatus,
  getJobBoardPaginationSkip: (state, getters, rootState) =>
    state.jobBoardPagination.skip,
  getJobBoardPaginationLimit: (state, getters, rootState) =>
    state.jobBoardPagination.limit,
  noMoreDataFromJobBoard: (state, getters, rootState) =>
    state.jobBoardPagination.noMoreDataFromJobBoard,
  getJobCount: (state, getters, rootState) => state.jobCount,
  getFilterCount: (state) => state.filterCount,
  jobAdvertisements: (state) => state.jobAdvertisements || [],
};

// actions
const actions = {

  // selectedJobBoard: (
  //   { state, commit, rootState, dispatch, getters },
  //   payload = {}
  // ) => {

  // },
  jobBoardDetailAction: async (
    { state, commit, rootState, dispatch, getters },
    { job_uuid, path }
  ) => {
    dispatch("showLoader");
    const { getCandidateId: candidate_id, getCustomerId: customer_id, getRoleScopes } = getters;
    return jobBoard
      .getJobDetail({
        customer_id,
        candidate_id,
        role: getRoleScopes.length ? getRoleScopes[0] : '',
        job_uuid,
        path
      })
      .then(res => {
        let { data } = res;
        const { getCandidateId } = getters;
        dispatch("fetchApplicationStatus", {
          job_uuid,
          candidate_uid: getCandidateId
        });
        if (Array.isArray(data)) {
          commit("SELECTED_JOBBOARD", { ...data[0] });
        } else {
          commit("SELECTED_JOBBOARD", { ...data });
        }
      })
      .catch(err => {
        console.error("error in get Job detail", err);
        return err;
      })
      .finally(() => {
        dispatch("hideLoader");
      });
  },
  jobBoardAction: (
    { state, commit, rootState, dispatch, getters },
    payload = {}
  ) => {
    commit("JOBBOARD");
    if (!payload.pagination) {
      commit("SET_JOB_BOARD_PAGINATION", {
        skip: 0,
        noMoreDataFromJobBoard: false
      });
    }
    let { page, query, orderBy } = payload;
    const {
      getCustomerId,
      getJobBoardPaginationSkip,
      getJobBoardPaginationLimit,
      getCandidateId,
      getRoleScopes,
      getRole,
      getOrgIDFromAccessToken,
      // candidateProfile,
    } = getters;
    const customer_id = getCustomerId;
    const { customerAdmin, systemCandidate, customerCandidate, customerRecruiter } = Role;
    let payloadVar = payload;

    if (customerAdmin === getRole || customerRecruiter === getRole) {
      dispatch("facilityJobCount", payload);
      if (getOrgIDFromAccessToken) {
        query = `organisation_uid=${getOrgIDFromAccessToken}&${query}`;
      }
    } else if ([systemCandidate, customerCandidate].includes(getRole)) {
      // const { organisation_id } = candidateProfile;
      dispatch('candidateJobCount', payload);
      // if (organisation_id) {
      //   query = `organisation_uid=${organisation_id}&${query}`;
      // }
    } else {
      dispatch("jobCount", payload);
    }
    commit("SET_JOB_LIST_FILTER", payload);
    commit("LAST_FETCHED_JOB_ADVERT", null)
    // if(!getJobCount){
    //   dispatch("jobCount");
    // }
    const { currentRoute: { fullPath } } = router
    if (fullPath.search("preferredType") != -1) {
      query = fullPath.search("saved") != -1 ? "preferred_type=saved"
        : fullPath.search("favourite") != -1 ? "preferred_type=favourite"
          : "applied=true";
    }
    query = `order_by=${orderBy ? '' : '-'}coalesce__last_modified_on__created_on&${query}`
    return jobBoard
      .getJobBoard({
        role: getRoleScopes.length ? getRoleScopes[0] : '',
        customer_id,
        skip: getJobBoardPaginationSkip,
        limit: getJobBoardPaginationLimit,
        page,
        query,
        candidate_id: getCandidateId,
        organisation_id: getOrgIDFromAccessToken,
      })
      .then(res => {
        try {
          let { data } = res;
          if (data.length < getJobBoardPaginationLimit) {
            commit("SET_JOB_BOARD_PAGINATION", {
              noMoreDataFromJobBoard: true
            });
          } else {
            commit("SET_JOB_BOARD_PAGINATION", {
              skip: getJobBoardPaginationSkip + getJobBoardPaginationLimit
            });
          }
          if (payloadVar && payloadVar.pagination) {
            commit("JOBBOARD_SUCCESS", { payload: data, pagination: payloadVar.pagination });
          } else {
            commit("JOBBOARD_SUCCESS", { payload: data });
            let queryJobId = parseInt(router.history.current.query.job_id) ? parseInt(router.history.current.query.job_id) : (data.length ? data[0].job_id : null);
            let selectedJobDetails = data.find(
              e => e.job_id === queryJobId
            );
            if (!selectedJobDetails) {
              selectedJobDetails = data[0]
            }
            if (selectedJobDetails) {
              dispatch("fetchJobBenefitsAdvertDescByJobId", {
                job_uuid: selectedJobDetails?.job_uuid,
                customer_id: selectedJobDetails?.customer_uid,
              });
            }
            commit("SELECTED_JOBBOARD", selectedJobDetails);
          }
        } catch (error) {
          console.error("error in jobBoard", error);
          commit("JOBBOARD_ERROR", error);
        }
      })
      .catch(err => {
        console.error("error in jobBoard", err);
        commit("JOBBOARD_ERROR", err);
        return err;
      });
  },
  modifyPublishJob: async ({ commit, dispatch, getters }, payload) => {
    const { getCustomerId, } = getters;
    const { organisation_uid, ...rest } = payload
    return jobBoard
      .updateJob(rest, organisation_uid, getCustomerId)
      .then(async res => {
        const { data } = res;
        const job_id = rest.job_id;
        commit("SELECTED_JOBBOARD", data);
        commit('UPDATE_JOB_BOARD_LIST_BY_JOB_ID', data)
        commit("SELECTED_JOBBOARD_ADVERT", data)
        dispatch("showToast", { class: 'bg-success text-white', message: rest.published ? 'Published successfully' : 'Updated successfully' });
        let result = Object.assign({}, { ...state.jobBoard, [`job_${[job_id]}`]: data })
      })
      .catch(err => {
        console.log(err, 'err')
        let msg = "Error while saving";
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      });
  },
  fetchApplicationStatus: ({ commit, dispatch, getters }, payload) => {
    const { getRoleScopes } = getters;
    let checkOnly = ["system_candidate", "customer_candidate"];
    if (payload.candidate_uid && checkOnly.includes(getRoleScopes[0])) {
      // dispatch("showLoader");
      return jobBoard
        .fetchApplicationStatus(payload)
        .then(res => {
          let { data } = res;
          commit('SET_APPLICATION_STATUS', data[0])
        })
        .catch(err => {
          console.error("error in fetchApplicationStatus", err);
          return err;
        })
      // .finally(() => {
      //   dispatch("hideLoader");
      // });
    }
  },

  selectedJobBoard: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    try {
      const { getCandidateId, getJobBoard } = getters;
      if (getJobBoard && getJobBoard.length) {
        const selectedJobBoard = getJobBoard.filter(
          e => e.job_uuid === payload
        )[0];
        if (selectedJobBoard && selectedJobBoard.length) {
          dispatch("fetchApplicationStatus", {
            job_uuid: selectedJobBoard && selectedJobBoard.job_uuid,
            candidate_uid: getCandidateId
          })
        }
        if (selectedJobBoard) {
          dispatch("fetchJobBenefitsAdvertDescByJobId", {
            job_uuid: selectedJobBoard?.job_uuid,
            customer_id: selectedJobBoard?.customer_uid,
          });
        }
        commit("SELECTED_JOBBOARD", selectedJobBoard);
      }
    } catch (e) {
      console.error("error occured in action selectedJobBoard ", e)
    }
  },
  applyJob: async (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { job_id, job_uuid } = payload;
    const { getCandidateId, candidateProfile: { candidate_uuid } } = getters;
    return jobBoard
      .applyJob(getCandidateId, job_id, { comments: "Sample" })
      .then(async res => {
        let { data } = res;
        commit("SET_APPLICATION_STATUS", data);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Applied for the job successfully!"
        });
        await dispatch("updateCandidateJobListByJobID", job_uuid);
        return data;
      })
      .catch(err => {
        console.error("error in applying", err);
        return err;
      });
  },

  withdrawJob: async (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { getCandidateId } = getters;
    const { job_id, job_uuid } = payload
    return jobBoard
      .withdrawJob(getCandidateId, job_id)
      .then(async res => {
        let { data } = res;
        commit("SET_APPLICATION_STATUS", "");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Successfully withdrawn from this job application!"
        });
        await dispatch("updateCandidateJobListByJobID", job_uuid);
        return data;
      })
      .catch(err => {
        console.error("error in applying", err);
        return err;
      });
  },
  resetApplicationStatus({ commit }) {
    commit("SET_APPLICATION_STATUS", "");
  },

  facilityJobCount: ({ commit, getters }, { query }) => {
    const { getCustomerId, getOrgIDFromAccessToken: org_id } = getters;
    query = query || "";
    return jobBoard
      .getJobsCountFacility(query, getCustomerId, org_id)
      .then(res => {
        let { data } = res;
        if (!query) {
          commit("JOB_COUNT", data);
        }
        commit("FILTER_COUNT", query ? data : 0);
        return res;
      })
      .catch(err => {
        console.log("error in getting job count", err);
        return err;
      });
  },

  jobCount: ({ commit, getters, dispatch }, { query }) => {
    const { getJobCount, getCandidateId, getCustomerId, getRoleScopes } = getters
    query = query || "";
    let role = getRoleScopes.length ? getRoleScopes[0] : ''
    return jobBoard.getJobsCount({
      query, candidate_id: getCandidateId, customer_id: getCustomerId, role
    })
      .then(res => {
        let { data } = res;
        if (!query) {
          commit("JOB_COUNT", data);
        }
        commit("FILTER_COUNT", query ? data : 0);
        if (!getJobCount) {
          query = ""
          return jobBoard.getJobsCount({ query, role }).then(res => {
            commit("JOB_COUNT", res.data);
          })
        }
        return res;
      })
      .catch(err => {
        console.error("error in getting job count", err);
        return err;
      });
  },
  fetchJobBenefitsAdvertDescByJobId: ({ commit, dispatch, getters }, payload) => {
    const { getRoleScopes } = getters
    if (state.lastFetchedJobAdvert == payload.job_uuid) return
    commit("LAST_FETCHED_JOB_ADVERT", payload.job_uuid)
    commit("SELECTED_JOBBOARD_ADVERT", []);
    dispatch("showLoader");
    let role = getRoleScopes[0] || null;
    if (getRoleScopes[0] && (role == Role.customerAdmin || role == Role.systemAdmin
      || role == Role.customerRecruiter || role == Role.systemRecruiter)) {
      return jobBoard
        .fetchJobBenefitsAdvertDescByJobId(payload).then(res => {
          let { data } = res;
          commit("SELECTED_JOBBOARD_ADVERT", data.advert_description);
          commit("SELECTED_JOB_BENEFITS_AND_ADVERT", data)
          return res
        })
        .catch(err => {
          console.error("error in fetching candidate page job details", err);
          return err;
        })
        .finally(res => dispatch("hideLoader"))
    } else {
      return jobBoard
        .fetchPublicAdvertDescForJobId(payload)
        .then(async res => {
          let { data } = res;
          commit("SELECTED_JOBBOARD_ADVERT", data[0]);
          return res
        })
        .catch(err => {
          console.error("error in fetching advert description", err);
          return err;
        })
        .finally(res => dispatch("hideLoader"))
    }
  },
  addPreferredJob: ({ getters, dispatch }, payload) => {
    const { job_id, job_uuid, preferred_type } = payload;
    dispatch('showToast', { message: `Adding into ${preferred_type} jobs...` });
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    return jobBoard
      .addPreferredJob({ candidate_id, customer_id, payload })
      .then(async res => {
        let { data } = res;
        dispatch("clearAllToast");
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: `Job Added into ${preferred_type} list successfully`,
        });
        await dispatch("updateCandidateJobListByJobID", job_uuid);
        return data;
      })
      .catch(err => {
        dispatch("clearAllToast");
        let msg = `Error while Adding into ${preferred_type} job list`;
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        console.error(msg, err);
        return err;
      });
  },
  deletePreferredJob: ({ getters, dispatch }, payload) => {
    const { preferred_id, job_id, job_uuid, preferred_type } = payload;
    dispatch('showToast', { message: `Deleting from ${preferred_type} jobs...` });
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    return jobBoard
      .deletePreferredJob({ candidate_id, customer_id, preferred_id })
      .then(async res => {
        let { data } = res;
        dispatch("clearAllToast");
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: `Removed Job from ${preferred_type} list successfully`,
        });
        await dispatch("updateCandidateJobListByJobID", job_uuid);
        return data;
      })
      .catch(err => {
        dispatch("clearAllToast");
        let msg = `Error while ${preferred_type} job`
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        console.error(msg, err);
        return err;
      });
  },
  updateCandidateJobListByJobID: ({ getters, dispatch, commit }, job_uuid) => {
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    return jobBoard
      .getJobDetail({ job_uuid, candidate_id, customer_id })
      .then(res => {
        let { data } = res;
        let jobDetails;
        if (Array.isArray(data)) {
          jobDetails = data[0];
        } else {
          jobDetails = data;
        }
        commit('UPDATE_JOB_BOARD_LIST_BY_JOB_ID', jobDetails)
        commit("SELECTED_JOBBOARD", jobDetails);
        commit("UPDATE_FAV_ARRAY", jobDetails);
        return data;
      })
      .catch(err => {
        let msg = "Error while Fetch job by ID";
        console.error(msg, err);
        return err;
      });
  },
  candidateJobCount: ({ commit, getters }, { query }) => {
    const { getCustomerId: customer_id, getCandidateId: candidate_id, candidateProfile, getJobCount } = getters;
    let initQuery = query || "";
    let serviceFunc = "getJobsCountCandidate"
    const { currentRoute: { fullPath } } = router
    if (fullPath.search("preferredType") != -1 && getJobCount) {
      initQuery = fullPath.search("favourite") != -1 ? "preferred_type=favourite" : "preferred_type=applied";
      serviceFunc = "getPreferredJobCount"
      if (fullPath.search("applied") != -1) {
        serviceFunc = "candidateAppliedJobsCount"
      }
    }
    query = initQuery;
    // const { organisation_id } = candidateProfile
    // if (organisation_id) {
    //   query += `&organisation_uid=${organisation_id}`;
    // }
    return jobBoard[serviceFunc]({ query, customer_id, candidate_id })
      .then(res => {
        let { data } = res;
        if (!initQuery) {
          commit("JOB_COUNT", data)
        }
        commit("FILTER_COUNT", initQuery ? data : 0);
        return res;
      })
      .catch(err => {
        console.log("error in getting job count", err);
        return err;
      });
  },
  fetchJobAdvertisement({ dispatch, commit }) {
    return jobBoard.getJobAdvertisements()
      .then(res => {
        const { data } = res;
        commit("SET_JOB_ADVERTISEMENTS", data);
        return data;
      })
      .catch(err => {
        const message = "Error while Fetching Job Advertisements";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
  },
  uploadAdvert({ dispatch, commit }, payload) {
    const { link, file, fileName } = payload;
    let queryPayload = { customer_id: 2, organisation_id: 1 };
    if (link)
      queryPayload = { ...queryPayload, link }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    const document = new FormData();
    document.append("file", file, fileName);
    dispatch("showToast", { message: 'Uploading...' })
    return jobBoard.uploadAdvert({ queryString, document })
      .then(res => {
        const { data } = res;
        dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded Successfully!' })
        return data;
      })
      .catch(err => {
        const message = "Error While Adding Advert";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
  },
  updateAdvert({ dispatch }, payload) {
    const { link, file, fileName, job_advertisement_id, is_active } = payload;
    let queryPayload = { customer_id: 2, organisation_id: 1 };
    if (link)
      queryPayload = { ...queryPayload, link }
    if (is_active != null)
      queryPayload = { ...queryPayload, is_active }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    dispatch("showToast", { message: 'Updating...' })
    let document = new FormData();
    if (fileName) {
      document.append("file_name", file, fileName);
    }
    return jobBoard.updateAdvert({ job_advertisement_id, queryString, document, fileName })
      .then(res => {
        const { data } = res;
        dispatch("showToast", { class: 'bg-success text-white', message: 'Updated Successfully!' })
        return data;
      })
      .catch(err => {
        const message = "Error While Updating Advert";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
  },
  deleteJobAdvert({ dispatch }, payload) {
    return jobBoard.deleteJobAdvert(payload)
      .then(res => {
        const { data } = res;
        dispatch("showToast", { class: 'bg-success text-white', message: 'Deleted Successfully!' })
        return data;
      })
      .catch(err => {
        const message = "Error While Delete Advert";
        dispatch('showToast', { class: 'bg-danger text-white', message });
        console.error(message, err);
        return err;
      })
  }
};

// mutations
const mutations = {
  ["JOBBOARD_ERROR"](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  ["SET_JOB_BOARD_PAGINATION"](state, data) {
    state.jobBoardPagination = { ...state.jobBoardPagination, ...data };
  },
  ["JOBBOARD_SUCCESS"](state, { payload, pagination }) {
    let result = {}
    if (isObject(payload)) {
      result = payload;
    } else {
      payload.forEach(item => {
        result[`job_${item.job_id}`] = item;
      });
    }
    if (pagination) {
      state.jobBoard = { ...state.jobBoard, ...result };
    } else {
      state.jobBoard = { ...result };
    }
    state.isLoading = false;
  },
  ["JOBBOARD"](state) {
    state.isLoading = true;
  },
  ["SELECTED_JOBBOARD"](state, payload) {
    state.selectedJobBoard = payload;
  },
  ["SELECTED_JOBBOARD_ADVERT"](state, payload) {
    state.selectedJobBoardAdvert = payload;
  },
  ["SELECTED_JOB_BENEFITS_AND_ADVERT"](state, payload) {
    state.selectedJobBenefitsAndAdvertDesc = payload
  },
  ["LAST_FETCHED_JOB_ADVERT"](state, payload) {
    state.lastFetchedJobAdvert = payload;
  },
  ["SET_APPLICATION_STATUS"](state, payload) {
    state.applicationStatus = payload
  },
  ["JOB_COUNT"](state, payload) {
    state.jobCount = payload;
  },
  ["FILTER_COUNT"](state, payload) {
    state.filterCount = payload;
  },
  ['SET_JOB_LIST_FILTER'](state, payload) {
    state.jobListFilter = payload
  },
  ['UPDATE_JOB_BOARD_LIST_BY_JOB_ID'](state, payload) {
    let job_id = `job_${[payload.job_id]}`
    state.jobBoard[`${job_id}`] = payload
  },
  ['UPDATE_JOB_BOARD_BY_REMOVING_JOB'](state, job_id) {
    job_id = `job_${[job_id]}`
    let job_board = state.jobBoard;
    state.jobBoard = [];
    delete job_board[job_id];
    state.jobBoard = job_board;
    state.selectedJobBoard = Object.keys(state.jobBoard).length ? state.jobBoard[Object.keys(state.jobBoard)[0]] : [];
    state.jobCount -= 1;
    if (state.filterCount) state.filterCount -= 1;
  },
  ['SET_JOB_ADVERTISEMENTS'](state, payload) {
    state.jobAdvertisements = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
