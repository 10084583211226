import axios from "axios";
import { BASE_URL } from "@/service_urls";

const configurableFields = {
  getCustomFieldsByOrganisationId: (organisation_id) => axios.get(`${BASE_URL}/configurable/${organisation_id}`),
  updateCustomFieldsByConfigurableId: (configurable_id, payload) => axios.put(`${BASE_URL}/configurable/${configurable_id}`, payload),
  createCustomFields: (payload) => axios.post(`${BASE_URL}/configurable/`, payload),
  getCustomFieldsForRegistration: (customer_uid, organisation_id) => {
    let url = `${BASE_URL}/configurable/registration/get/by/id?customer_uid=${customer_uid}`
    if (organisation_id) {
      url = `${BASE_URL}/configurable/registration/get/by/id?customer_uid=${customer_uid}&organisation_id=${organisation_id}`
    }
    return axios.get(`${url}`);
  },
  updateCustomFieldsByCandidateTypeForRegistration: (payload) => axios.put(`${BASE_URL}/configurable/registration/`, payload),
}

export default configurableFields;