import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const qualification = {
  qualification: () => axios.get(`${BASE_URL}/qualification/?order_by=qualification`),
  filteredQualification: ({ country_id, candidate_type_id, query }) => {
    let url = `${BASE_URL}/qualification/filtered/?country_id=${country_id}&candidate_type_id=${candidate_type_id}`;
    url = query ? `${url}&${query}` : url;
    return axios.get(url)
  },
  issueAuthority: () => axios.get(`${BASE_URL}/qualification/issue_authority/`),
  getQualificationsPQR: (candidate_type_id, level_id) => axios.get(`${BASE_URL}/qualification/pqr/?candidate_type_id=${candidate_type_id}&level_id=${level_id}`),
  getQualificationsPQRType: (candidate_type_id, level_id, country_id, qualification_type_id) => axios.get(`${BASE_URL}/qualification/pqr/?candidate_type_id=${candidate_type_id}&level_id=${level_id}&country_id=${country_id}&qualification_type_id=${qualification_type_id}`),
  getQualificationRegions: () => axios.get(`${BASE_URL}/location/region/`),
  getAllQualificationCountries: () => axios.get(`${BASE_URL}/location/region_country/`),
  filteredIssueAuthority: ({ qualification_id }) => axios.get(`${BASE_URL}/issue_authority_qualification/issue_authorities/${qualification_id}`),
  getQualificationCountries: (region_id__in) => axios.get(`${BASE_URL}/location/region_country/?region_id__in=${region_id__in}`)
}

export default qualification