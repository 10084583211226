import login from "@/services/login";
import router from "../router/index";

const state = {
    previous_page_url: null,
}

const getters = {
    getPreviousPageURL: (state) => state.previous_page_url
}

const actions = {
    checkOldPassword({ getters, dispatch, commit }, { username, oldPassword }) {
        let payload = new FormData();
        payload.append("username", username);
        payload.append("password", oldPassword);
        return login
            .loginAccessToken(payload)
            .then(res => {
                return res;
            })
            .catch(err => {
                console.error("error in login", err);
                if(err?.response?.status === 400) {
                    dispatch("showToast", {
                      class: "bg-danger text-white",
                      message: err.response.data.detail})
                  }
                return err;
            })
    },
    changePassword({ getters, dispatch, commit }, payload) {
        const { new_password, username } = payload;
        return login
            .changePassword({ new_password, username })
            .then(res => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Password reset successfully!"
                });
                dispatch("logout")
                return res;
            })
            .catch(err => {
                console.log('Error While Reset Password', err);
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: err.response.data.detail
                });
                return err;
            })
    }
}

const mutations = {
    ["SET_PREVIOUS_PAGE_URL"](state, payload) {
        state.previous_page_url = payload;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};