import candidate from "@/services/candidate";
import tabFilters from "../services/tabFilter";
import moment from "moment";
import { getFilterQueryStringWithoutArray, getCustomerId } from "@/helpers/helper.js";

const getDefaultState = () => {
  return {
    scrollTop: false,
    uploaded: false,
    toast: false,
    toastMessage: null,
    toastClass: null,
    toastHide: null,
    candidateProfile: null,
    documentType: [],
    getUploadedDocument: [],
    uploadResponse: 0,
    getSkills: [],
    getCandidateLanguage: [],
    getNonMedicalQual: [],
    getCandidateNonMedicalQuals: [],
    getCandidateQualification: [],
    getCandidateSpecialInterest: [],
    getCandidatePQRLevels: [],
    candidatePQRLevel: null,
    isFetchingPqr: false,
    candidatePreferredRegionList: [],
    candidatePreferredCountryList: [],
    showErrorMsgModal: {},
    multiFormEdited: {
      specializationInformation: false,
      personalInformation: false,
      splInterest: false,
      licenseInformation: false,
      qualification: false,
      langInfo: false,
      NonMedicalInfos: false,
      showError: false,
      fieldValidateError: false,
      preferredDeptInformation: false,
    },
    candidateJobPagination: {
      limit: 6,
      skip: 0,
      noMoreJobs: false
    },
    jobListForCandidateID: [],
    jobListForCandidateIDCount: 0,
    isFetchingJobsForCandidateID: false,
    jobDetailsForCandidateID: null,
    lastUploadedDocs: null,
    getCandidateJobPageInProcessStatus: [],
    getCandidateJobPageInProcessList: [],
    candidateJobInProcessPagination: {
      limit: 4,
      skip: 0,
      noMoreCandidates: false,
    },
    isFetchingCandidateJobInProcessList: false,
    preferredDepartments: [],
    block: 0,
  };
}

const state = getDefaultState()


const getters = {
  scrollTop: state => state.scrollTop,
  uploaded: state => state.uploaded,
  toast: state => state.toast,
  toastMessage: state => state.toastMessage,
  toastClass: state => state.toastClass,
  toastHide: state => state.toastHide,
  candidateProfile: state => state.candidateProfile,
  getUploadedDocument: state => state.getUploadedDocument,
  documentType: state => {
    const itemOrder = [1, 2, 12, 5, 11, 13, 9, 16, 15]
    return state.documentType && state.documentType.length
      ? state.documentType.map(val => ({
        label: val.comments,
        code: val.document_type_id,
        allowed_extensions: val.allowed_extensions
      })).sort((a, b) => itemOrder.indexOf(a.code) - itemOrder.indexOf(b.code))
      : []
  },
  documentTypeExcPhoto: state => {
    const itemOrder = [1, 12, 5, 11, 13, 9, 16, 15]
    return state.documentType && state.documentType.length
      ? state.documentType.map(val => ({
        label: val.comments,
        code: val.document_type_id,
        allowed_extensions: val.allowed_extensions
      })).filter(({ code }) => code != 2).sort((a, b) => itemOrder.indexOf(a.code) - itemOrder.indexOf(b.code))
      : []
  },
  uploadResponse: state => state.uploadResponse,
  getSkills: state => state.getSkills,
  getCandidateLanguage: state => state.getCandidateLanguage,
  getNonMedicalQual: (state) => {
    return state.getNonMedicalQual ? state.getNonMedicalQual
      .map(val => ({
        code: val.non_medical_qual_type?.id,
        label: val.non_medical_qual_type?.type_name + " - " + val.name,
        non_medical_qual_id: val.id,
        qual_type_id: val.non_medical_qual_type?.id,
      }))
      : []
  },
  getCandidateNonMedicalQuals: state => state.getCandidateNonMedicalQuals,
  getCandidateQualification: state => state.getCandidateQualification,
  getCandidateSpecialInterest: state => state.getCandidateSpecialInterest,
  getCandidatePQRLevels: state => state.getCandidatePQRLevels,
  getCandidatePQRLevel: state => state.candidatePQRLevel,
  isFetchingPqr: (state, getters, rootState) => state.isFetchingPqr,
  PreferredCountryFilterbyRegion: (state, getters, rootState) => ({ regions }) => {
    let { getRegionCountry } = getters;
    return getRegionCountry.length &&
      getRegionCountry.filter((val) => regions.includes(val.region_id))
        .map(val => {
          return {
            label: val.country.country_name,
            code: val.country.country_id,
            region_id: val.country.region_id
          }
        }) || [];
  },
  getCandidatePreferredRegionList: (state, getters, rootState) => {
    return state.candidatePreferredRegionList && state.candidatePreferredRegionList.map(val => ({
      label: val.region.region_name,
      code: val.region.region_id,
      preferred_region_id: val.candidate_region_id
    })) || [];
  },
  getCandidatePreferredCountryList: (state, getters, rootState) => {
    return state.candidatePreferredCountryList && state.candidatePreferredCountryList.map(val => ({
      label: val.country.country_name,
      code: val.country.country_id,
      preferred_country_id: val.candidate_preferred_country_id,
      region_id: val.country.region_id,
      country_code: val.country.country_code,
    })) || [];
  },
  showErrorMsgModal: (state, commit, getters) => {
    return state.showErrorMsgModal;
  },
  getMultiFormEdited: state => state.multiFormEdited,
  getResumeDocument: state => state.getUploadedDocument.length &&
    state.getUploadedDocument
      .map(
        ({
          document_type_id,
          uuid_link_to_doc,
          document_name,
          document_type,
          created_on,
          document_ext,
          candidate_document_uuid,
          comments,
        }) => {
          return {
            document_type_id,
            link: uuid_link_to_doc,
            lable: document_type.comments,
            name: document_name,
            updatedDate: moment(created_on).format("DD-MM-YYYY"),
            document_ext: document_ext.document_ext,
            document_id: candidate_document_uuid,
            comments: comments,
          };
        }
      )
      .find(({ document_type_id }) => document_type_id === 1) || [],
  getJobListForCandidateID: state => state.jobListForCandidateID,
  getJobListForCandidateIDCount: state => state.jobListForCandidateIDCount,
  noMoreJobs: state => state.candidateJobPagination.noMoreJobs,
  getCandidateJobPagination: state => state.candidateJobPagination,
  isFetchingJobsForCandidateID: state => state.isFetchingJobsForCandidateID,
  getJobDetailsForCandidateID: (state) => state.jobDetailsForCandidateID,
  lastUploadedDocs: (state) => state.lastUploadedDocs,
  getCandidateJobPageInProcessStatus: (state) => state.getCandidateJobPageInProcessStatus,
  getCandidateJobPageInProcessList: (state) => state.getCandidateJobPageInProcessList,
  getCandidateJobInProcessPagination: (state) => state.candidateJobInProcessPagination,
  isFetchingCandidateJobInProcessList: (state) => state.isFetchingCandidateJobInProcessList,
  preferredDepartments: state => state.preferredDepartments,
};

const mutations = {
  ["SET_SCROLLTOP"](state) {
    state.scrollTop = true;
    setTimeout(() => {
      state.scrollTop = false;
    }, 500);
  },
  ['RESET_CANDIDATE'](state, data) {
    Object.assign(state, getDefaultState())
  },
  ["SET_UPLOADED"](state) {
    state.uploaded = true;
    setTimeout(() => {
      state.uploaded = false;
    }, 500);
  },
  ["SET_TOAST"](state, payload) {
    state.toast = payload;
  },
  ["SET_TOAST_MESSAGE"](state, payload) {
    state.toastMessage = payload;
  },
  ["SET_TOAST_CLASS"](state, payload) {
    state.toastClass = payload;
  },
  ["SET_TOAST_HIDE"](state, timeout) {
    state.toastHide = timeout;
    setTimeout(() => {
      state.toast = false;
      state.toastMessage = null;
      state.toastClass = null;
      state.toastHide = null;
    }, timeout);
  },
  ["SET_CANDIDATE"](state, payload) {
    state.candidateProfile = payload;
  },
  ["SET_DOCUMENT_TYPE"](state, payload) {
    state.documentType = payload;
  },
  ["SET_FETCHING"](state, data) {
    state.isFetchingFilter = data;
  },
  ["SET_UPLOADED_DOCUMENT"](state, data) {
    state.getUploadedDocument = data;
  },
  ["SET_WAIT_RESPONSE"](state) {
    state.uploadResponse += 1;
  },
  ["SET_CLOSE_RESPONSE"](state) {
    state.uploadResponse -= 1;
  },
  ["SET_SKILLS"](state, data) {
    state.getSkills = data;
  },
  ["SET_CANDIDATE_LANGUAGE"](state, data) {
    state.getCandidateLanguage = data;
  },
  ["SET_NON_MEDICAL_QUALIFICATION"](state, data) {
    state.getNonMedicalQual = data;
  },
  ["CANDIDATE_NON_MEDICAL_QUALIFICATIONS"](state, data) {
    state.getCandidateNonMedicalQuals = data;
  },
  ["SET_CANDIDATE_QUALIFICATION"](state, data) {
    state.getCandidateQualification = data;
  },
  ["SET_CANDIDATE_SPECIALINTEREST"](state, data) {
    state.getCandidateSpecialInterest = data;
  },
  ["SET_CANDIDATE_PQRLEVELS"](state, data) {
    state.getCandidatePQRLevels = data;
  },
  ["SET_CANDIDATE_PQRLEVEL"](state, data) {
    state.candidatePQRLevel = data;
  },
  ["RESET_CANDIDATE_PQRLEVEL"](state, data) {
    state.candidatePQRLevel = null;
  },
  ["SET_IS_FETCHING_PQR"](state, data) {
    state.isFetchingPqr = data;
  },
  ["RESET_CANDIDATE_PQRLEVELS"](state, data) {
    state.getCandidatePQRLevels = [];
  },
  ["SET_CANDIDATE_PREFERRED_COUNTRY_LIST"](state, data) {
    state.candidatePreferredCountryList = data;
  },
  ["SET_CANDIDATE_PREFERRED_REGION_LIST"](state, data) {
    state.candidatePreferredRegionList = data;
  },
  ["RESET_CANDIDATE_PREFERRED_COUNTRY_LIST"](state, data) {
    state.candidatePreferredCountryList = [];
  },
  ["RESET_CANDIDATE_PREFERRED_REGION_LIST"](state, data) {
    state.candidatePreferredRegionList = [];
  },
  ["RESET_UPLOADED_DOCUMENT"](state, data) {
    state.getUploadedDocument = [];
  },
  ["SHOW_ERROR_MSG_MODAL"](state, data) {
    state.showErrorMsgModal = {
      modalTitle: data.modalTitle,
      modalColor: data.modalColor,
      modalContent: data.modalContent,
      isShowPopup: data.isShowPopup
    };
  },
  ["SET_MULTI_FORM_EDITED"](state, data) {
    state.multiFormEdited = data;
  },
  ["RESET_MULTI_FORM_EDITED"](state) {
    state.multiFormEdited = {
      specializationInformation: false,
      personalInformation: false,
      splInterest: false,
      licenseInformation: false,
      qualification: false,
      langInfo: false,
      showError: false,
      fieldValidateError: false,
      preferredDeptInformation: false,
    };
  },
  ['SET_CANDIDATE_JOB_PAGINATION'](state, data) {
    state.candidateJobPagination = { ...state.candidateJobPagination, ...data };
  },
  ['SET_JOB_LIST_FOR_CANDIDATE_ID'](state, payload) {
    state.jobListForCandidateID = payload;
  },
  ['SET_JOB_LIST_FOR_CANDIDATE_ID_COUNT'](state, payload) {
    state.jobListForCandidateIDCount = payload;
  },
  ['RESET_JOB_LIST_FOR_CANDIDATE_ID'](state, data) {
    state.jobListForCandidateID = [];
  },
  ['IS_FETCHING_JOBS_FOR_CANDIDATE_ID'](state, data) {
    state.isFetchingJobsForCandidateID = data;
  },
  ['SET_JOB_DETAILS_FOR_CANDIDATE_ID'](state, payload) {
    state.jobDetailsForCandidateID = payload;
  },
  ['SET_LAST_UPLOADED_DOCS'](state, payload) {
    state.lastUploadedDocs = payload
  },
  ["SET_CANDIDATE_JOB_PAGE_INPROCESS_STATUS"](state, payload) {
    state.getCandidateJobPageInProcessStatus = payload
  },
  ["SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST"](state, payload) {
    state.getCandidateJobPageInProcessList = payload
  },
  ["IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST"](state, payload) {
    state.isFetchingCandidateJobInProcessList = payload
  },
  ["SET_CANDIDATE_JOB_INPROCESS_PAGINATION"](state, payload) {
    state.candidateJobInProcessPagination = {
      ...state.candidateJobInProcessPagination,
      ...payload
    }
  },
  ['SET_PREFERRED_DEPARTMENTS'](state, payload) {
    state.preferredDepartments = payload;
  }
};

const actions = {
  scrollTop: ({ commit }) => {
    commit("SET_SCROLLTOP");
  },
  resetCandidate({ state, commit }, payload) {
    commit("RESET_CANDIDATE")
  },
  getUserMe: ({ commit, getters, dispatch }, { customer }) => {
    let { getRoleScopes } = getters;
    return candidate
      .getUserMe(customer)
      .then(res => {
        let { data } = res;
        if (data) {
          commit("SET_CANDIDATE", data);
          if (getRoleScopes.length && ["system_candidate", "customer_candidate"].includes(getRoleScopes[0])) {
            dispatch("fetchMyUploadedDocument");
          }
          return res;
        }
      })
      .catch(err => {
        return err;
      });
  },
  getCandidate: ({ commit }, payload) => {
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Loading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .getCandidate(payload.customer, payload.id)
      .then(res => {
        let { data } = res;
        if (data && data.candidate_uid) {
          commit("SET_TOAST_MESSAGE", "Loaded");
          commit("SET_TOAST_CLASS", "bg-success text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_CANDIDATE", data);
          return res;
        }
      })
      .catch(err => {
        console.log("Error fetching candidate", err);
        commit("SET_TOAST_MESSAGE", "Error fetching candidate");
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  createCandidate: ({ commit }, payload) => {
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.create(payload.customer_id, payload).then(async res => {
      commit("SET_TOAST_MESSAGE", "Success");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      commit("SET_CANDIDATE", res.data);
    })
      .catch(err => {
        let msg = "Error while saving";
        if (err.message.indexOf("409") > 0) {
          msg = "A user with same email already exists";
        } else if (err.response.status == 406 && err?.response?.data?.detail.includes("already registered")) {
          msg = "Requested Candidate EMail already registered";
        }
        console.log("error creating candidate", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateCandidate: ({ commit, getters }, payload) => {
    const { customer_uid, data } = payload;
    const { getCandidates } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .update(customer_uid, data)
      .then(res => {
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
        commit("SET_CANDIDATE", res.data);
        if (getCandidates.length) {
          const CandidateIndex = getCandidates.findIndex(e => e.candidate_uid === data.candidate_uid);
          const CandidateInfo = res.data
          if (CandidateIndex >= 0) {
            commit("REPLACE_CANDIDATE_BY_INDEX", { CandidateIndex, CandidateInfo })
          }
        }
        return res.data
      })
      .catch(err => {
        let msg = "Error while saving";
        if (err.message.indexOf("409") > 0) {
          msg = "User already exists";
        }
        console.log("error updating candidate", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  async uploadResume({ commit, dispatch }, payload) {
    const { customer_uid, candidate_uuid, user_id, file, document_type } = payload
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    if (candidate_uuid && file) {
      const document = new FormData();
      document.append("file", file, file.name);
      document.append("document_type_id", document_type);
      await candidate
        .document(customer_uid, candidate_uuid, user_id, document)
        .then(res => {
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", "Uploaded");
          commit("SET_TOAST_CLASS", "bg-success text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_LAST_UPLOADED_DOCS", res.data)
          return res;
        })
        .catch(err => {
          let msg = "Error while uploading resume";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          return err;
        }).finally(() => {

        });
    }
  },

  async uploadPhoto({ commit, dispatch }, payload) {
    const { customer_uid, candidate_uuid, user_id, file, document_type, fileName, blobData } = payload
    if (candidate_uuid && (blobData || file)) {
      dispatch("showToast", { message: 'Uploading...' })
      if (blobData) {
        let formData = new FormData();
        formData.append("file", blobData, fileName);
        formData.append("document_type_id", document_type);
        return candidate.document(customer_uid, candidate_uuid, user_id, formData)
          .then(res => {
            commit("SET_UPLOADED", true);
            commit("SET_LAST_UPLOADED_DOCS", res.data)
            dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
            return res;
          })
          .catch(err => {
            commit("SET_UPLOADED", true);
            let msg = "Error while uploading photo";
            dispatch("showToast", { class: 'bg-danger text-white', message: msg })
            return err;
          })
      } else if (file) {
        const { src } = file
        const type = src.split(';')[0].split(':')[1]
        await file.getCroppedCanvas().toBlob(function (blob) {
          let formData = new FormData();
          formData.append("file", blob, fileName);
          formData.append("document_type_id", document_type);
          return candidate.document(customer_uid, candidate_uuid, user_id, formData)
            .then(res => {
              commit("SET_UPLOADED", true);
              commit("SET_LAST_UPLOADED_DOCS", res.data)
              dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
              return res;
            })
            .catch(err => {
              commit("SET_UPLOADED", true);
              let msg = "Error while uploading photo";
              dispatch("showToast", { class: 'bg-danger text-white', message: msg })
              return err;
            })
        }, type);
      }
    }
    return
  },

  initFetchOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      documentType,
      getLocationRegion
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!documentType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchDocumentType")];
    }
    if (!getLocationRegion.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationRegion")];
    }
  },
  fetchDocumentType({ commit, getters }) {
    return candidate
      .getDocumentType()
      .then(async res => {
        commit("SET_DOCUMENT_TYPE", res.data);
      })
      .catch(err => {
        console.log("error fetching document type", err);
        return err;
      });
  },
  fetchUploadedDocument({ commit, dispatch, getters }) {
    commit("RESET_UPLOADED_DOCUMENT")
    const { candidateProfile: { customer_uid, candidate_uuid } } = getters;
    return candidate
      .getUploadedDocument(customer_uid, candidate_uuid)
      .then(async res => {
        commit("SET_UPLOADED_DOCUMENT", res.data);
      })
      .catch(err => {
        console.log("error fetching document type", err);
        return err;
      })
      .finally(() => {

      });
  },
  commitCandidateInfo({ commit, getters }, payload) {
    commit("SET_CANDIDATE", payload);
    commit("SET_IS_FETCHING_CANDIDATE_DETAILS", true);
    return payload;
  },
  updateDocument({ commit, dispatch, getters }, payload) {
    const { candidateProfile: { customer_uid, candidate_uuid } } = getters;
    const { candidate_document_id, comments } = payload
    return candidate.updateDocument(customer_uid, candidate_uuid, candidate_document_id, comments).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Document updated successfully' });
        dispatch("fetchUploadedDocument")
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while updating document' });
        }
        console.log("error in updating document", err);
        return err;
      });
  },
  deleteDocument({ commit, dispatch, getters }, candidate_document_id) {
    const { candidateProfile: { customer_uid, candidate_uuid } } = getters;
    return candidate.deleteDocument(customer_uid, candidate_uuid, candidate_document_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Document deleted successfully' });
        dispatch("fetchUploadedDocument")
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting document' });
        }
        console.log("error in deleting document", err);
        return err;
      });
  },
  async multipleFileUpload({ commit, dispatch }, payload) {
    const { file, document_type, customer_uid, candidate_uuid, user_id } = payload;
    commit("SET_WAIT_RESPONSE")
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading Documents...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    if (candidate_uuid && user_id && file) {
      const document = new FormData();
      document.append("file", file, file.name);
      document.append("document_type_id", document_type);
      await candidate
        .document(customer_uid, candidate_uuid, user_id, document)
        .then(res => {
          commit("SET_TOAST_MESSAGE", "Uploaded");
          commit("SET_TOAST_CLASS", "bg-success text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_CLOSE_RESPONSE")
          commit("SET_LAST_UPLOADED_DOCS", res.data)
          return res;
        })
        .catch(err => {
          let msg = "Error while uploading resume";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          commit("SET_CLOSE_RESPONSE")
          return err;
        }).finally(() => {

        });
    }
  },
  fetchMyUploadedDocument({ commit, dispatch, getters }) {
    const { getCustomerId, candidateProfile: { candidate_uuid } } = getters;
    return candidate
      .getUploadedDocument(getCustomerId, candidate_uuid)
      .then(async res => {
        commit("SET_UPLOADED_DOCUMENT", res.data);
      })
      .catch(err => {
        console.log("error fetching document type", err);
        return err;
      })
      .finally(() => {

      });
  },
  fetchSkills({ commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .getSkills(customer_uid, candidate_uid)
      .then(async res => {
        commit("SET_SKILLS", res.data);
      })
      .catch(err => {
        console.log("error fetching skills", err);
        return err;
      })
      .finally(() => {

      });
  },
  uploadSkill: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createSkill(customer_uid, candidate_uid, payload).then(async res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Skill";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  fetchCandidateLanguage({ commit, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .getCandidateLanguage(customer_uid, candidate_uid)
      .then(async res => {
        commit("SET_CANDIDATE_LANGUAGE", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate language", err);
        return err;
      })
      .finally(() => {

      });
  },
  fetchNonMedicalQualNames({ commit, getters }) {
    const { getCustomerId } = getters;
    return candidate
      .getNonMedicalQualNames(getCustomerId)
      .then(async res => {
        commit("SET_NON_MEDICAL_QUALIFICATION", res.data);
      })
      .catch(err => {
        console.log("error fetching non-medical qualifications", err);
        return err;
      })
  },
  fetchCandidateNonMedicalQuals({ commit, getters }, payload) {
    let candidate_uid
    let customer_uid
    if (payload) {
      candidate_uid = payload.candidate_uid
      customer_uid = payload.customer_uid
    } else {
      candidate_uid = getters.candidateProfile.candidate_uid;
      customer_uid = getters.candidateProfile.customer_uid;
    }
    return candidate
      .getCandidateNonMedicalQual(customer_uid, candidate_uid)
      .then(async res => {
        commit("CANDIDATE_NON_MEDICAL_QUALIFICATIONS", res.data);
      })
      .catch(err => {
        console.log("error fetching non-medical qualifications", err);
        return err;
      })
  },
  uploadCandidateNonMedicalInfo: ({ commit, getters, dispatch }, payload) => {
    const { candidateProfile: { customer_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createCandidateNonMedicalInfo(customer_uid, payload).then(async res => {
      dispatch("fetchCandidateNonMedicalQuals");
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        if (err?.response?.status === 422 && err?.response?.data?.detail === "expired") {
          let msg = "Can't be Uploaded!";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 2000);
          return err;
        } else {
          let msg = "Error while uploading";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          return err;
        }
      }).finally(() => {

      });
  },
  uploadCandidateLanguage: ({ commit, getters, dispatch }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createCandidateLanguage(customer_uid, candidate_uid, payload).then(async res => {
      dispatch("fetchCandidateLanguage");
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading candidate language";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  deleteNonMedicalInfo({ commit, dispatch, getters }, candidate_info_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteNonMedicalInfo(customer_uid, candidate_uid, candidate_info_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Qualification deleted successfully' });
        dispatch("fetchCandidateNonMedicalQuals");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting language' });
        }
        console.log("error in deleting Qualification", err);
        return err;
      });
  },
  deleteCandidateLanguage({ commit, dispatch, getters }, candidate_language_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteLanguage(customer_uid, candidate_uid, candidate_language_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Language deleted successfully' });
        dispatch("fetchCandidateLanguage");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting language' });
        }
        console.log("error in deleting language", err);
        return err;
      });
  },
  deleteCandidateSkills({ commit, dispatch, getters }, candidate_skill_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteSkills(customer_uid, candidate_uid, candidate_skill_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-danger text-white', message: 'Skill deleted successfully' });
        dispatch("fetchSkills");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting Skill' });
        }
        console.log("error in deleting Skill", err);
        return err;
      });
  },
  deleteCandidateQualification({ commit, dispatch, getters }, candidate_qualification_uuid) {
    const { candidateProfile: { customer_uid, candidate_uid, candidate_uuid } } = getters;
    return candidate.deleteQualification(customer_uid, candidate_uuid, candidate_qualification_uuid).then(async res => {
      if (res.status == 200) {
        commit("SET_UPLOADED", true);
        dispatch("showToast", { class: 'bg-success text-white', message: 'Qualification deleted successfully' });
        dispatch("fetchPQRLevels");
        dispatch("fetchQualification");
        dispatch("fetchUploadedDocument");
      }
      return res
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting qualification' });
        }
        console.log("error in deleting qualification", err);
        return err;
      });
  },
  fetchQualification({ commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uuid } } = getters;
    return candidate
      .getCandidateQualification(customer_uid, candidate_uuid)
      .then(async res => {
        commit("SET_CANDIDATE_QUALIFICATION", res.data);
      })
      .catch(err => {
        console.log("error fetching qualification", err);
        return err;
      })
      .finally(() => {

      });
  },
  uploadQualification: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uuid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createQualification(customer_uid, candidate_uuid, payload).then(async res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Qualification";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  deleteCandidateSpecialInterest({ commit, dispatch, getters }, candidate_speciality_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteSpecialInterest(customer_uid, candidate_uid, candidate_speciality_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Special interest deleted successfully' });
        dispatch("fetchspecialInterest");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting special interest' });
        }
        console.log("error in deleting special interest", err);
        return err;
      });
  },
  deleteSplInterestWithoutToast({ commit, dispatch, getters }, candidate_speciality_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deleteSpecialInterest(customer_uid, candidate_uid, candidate_speciality_id).then(async res => {
      if (res.status == 200) {
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting special interest' });
        }
        console.log("error in deleting special interest", err);
        return err;
      });
  },
  fetchspecialInterest({ commit, dispatch, getters }, payload) {
    let candidate_uid
    let customer_uid
    if (payload) {
      candidate_uid = payload.candidate_uid
      customer_uid = payload.customer_uid
    } else {
      candidate_uid = getters.candidateProfile.candidate_uid;
      customer_uid = getters.candidateProfile.customer_uid;
    }
    return candidate
      .getCandidateSpecialInterest(customer_uid, candidate_uid)
      .then(async res => {
        commit("SET_CANDIDATE_SPECIALINTEREST", res.data);
      })
      .catch(err => {
        console.log("error fetching special interest", err);
        return err;
      })
      .finally(() => {

      });
  },
  uploadSpecialInterest: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.createSpecialInterest(customer_uid, candidate_uid, payload).then(async res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Special interest";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      }).finally(() => {

      });
  },
  fetchPQRLevels({ commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("RESET_CANDIDATE_PQRLEVELS");
    return candidate
      .getCandidatePQRLevels(customer_uid, candidate_uid)
      .then(async res => {
        commit("SET_CANDIDATE_PQRLEVELS", res.data);
      })
      .catch(err => {
        console.log("error fetching PQR levels", err);
        return err;
      })
      .finally(() => {

      });
  },
  initCandidateDetailsFetch({ commit, dispatch, getters }) {
    dispatch("fetchCandidateNonMedicalQuals");
    dispatch("fetchCandidateLanguage");
    dispatch("fetchUploadedDocument");
    // dispatch("fetchspecialInterest");
    // dispatch("fetchQualification");
    // dispatch("fetchSkills");
    // dispatch("fetchLicense");
    // dispatch("fetchPQRLevels");
    // dispatch("fetchCandidatePreferredRegionList");
    // dispatch("fetchCandidatePreferredCountryList");
    // dispatch("locationLanguage");
    // dispatch("specialInterest");
    // dispatch("fetchQualificationRegions");
    // dispatch("fetchAllQualificationCountries");
    commit("RESET_CANDIDATE_PQRLEVEL");
    commit("RESET_JOB_LIST_FOR_CANDIDATE_ID");
    commit("RESET_JOB_CANDIDATE_COUNT");
    return
  },
  checkPQRLevel({ commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_IS_FETCHING_PQR", true);
    return candidate
      .checkPQRLevel({ customerId: customer_uid, candidateUid: candidate_uid })
      .then(res => {
        commit("SET_CANDIDATE_PQRLEVEL", res.data);
        return res.data;
      })
      .catch(err => {
        commit("SET_IS_FETCHING_PQR", false);
        console.log("error fetching PQR levels", err);
        return err;
      })
  },
  updateNonMedicalInfo: ({ getters, commit, dispatch }, { candidate_non_medical_qual_id, payload }) => {
    const { candidateProfile: { customer_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateNonMedicalInfo(customer_uid, candidate_non_medical_qual_id, payload)
      .then(async res => {

        dispatch("fetchCandidateNonMedicalQuals");
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
        return res
      })
      .catch(err => {
        if (err?.response?.status === 422 && err?.response?.data?.detail === "expired") {
          let msg = "Can't be Saved!";
          commit("SET_UPLOADED", true);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 2000);
          return err;
        } else {
          let msg = "Error while saving";
          console.log("error updating Language", err);
          commit("SET_TOAST_MESSAGE", msg);
          commit("SET_TOAST_CLASS", "bg-danger text-white");
          commit("SET_TOAST_HIDE", 3000);
          return err;
        }
      });
  },
  updateLanguage: ({ getters, commit, dispatch }, { candidate_lang_id, langPayload }) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateLanguagesSpoken(customer_uid, candidate_uid, candidate_lang_id, langPayload)
      .then(async res => {
        dispatch("fetchCandidateLanguage");
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating Language", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateSplInterest: ({ getters, commit }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateSplInterest(customer_uid, candidate_uid, payload)
      .then(async res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating special interest", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateSkill: ({ getters, commit }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateSkill(customer_uid, candidate_uid, payload)
      .then(async res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating skill", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  updateQualification: ({ getters, commit }, { candidate_qualification_uuid, qualificationPayload }) => {
    const { candidateProfile: { customer_uid, candidate_uuid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updateCandidateQualification(customer_uid, candidate_uuid, candidate_qualification_uuid, qualificationPayload)
      .then(async res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating qualification", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  fetchCandidatePreferredRegionList: ({ commit, dispatch, getters }) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_CANDIDATE_PREFERRED_REGION_LIST");
    return candidate
      .getCandidatePreferredRegionList({ customerId: customer_uid, candidateUid: candidate_uid })
      .then(res => {
        commit("SET_CANDIDATE_PREFERRED_REGION_LIST", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate preferred region list", err);
        return err;
      })
  },
  fetchCandidatePreferredCountryList: ({ commit, dispatch, getters }) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("RESET_CANDIDATE_PREFERRED_COUNTRY_LIST");
    return candidate
      .getCandidatePreferredCountryList({ customerId: customer_uid, candidateUid: candidate_uid })
      .then(res => {
        commit("SET_CANDIDATE_PREFERRED_COUNTRY_LIST", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate preferred country list", err);
        return err;
      })
  },
  createPreferredRegion: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .createCandidatePreferredRegions({ customerId: customer_uid, candidateUid: candidate_uid, payload })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while create preferred Region";
        return err;
      })
  },
  createPreferredCountry: ({ commit, getters }, payload) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .createCandidatePreferredCountry({ customerId: customer_uid, candidateUid: candidate_uid, payload })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while create preferred country";
        return err;
      })
  },
  deletePreferredRegion: ({ commit, getters }, preferred_region_id) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .deleteCandidatePreferredRegion({ customerId: customer_uid, candidateUid: candidate_uid, preferred_region_id })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while delete preferred country";
        return err;
      })
  },
  deletePreferredCountry: ({ commit, getters }, preferred_country_id) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .deleteCandidatePreferredCountry({ customerId: customer_uid, candidateUid: candidate_uid, preferred_country_id })
      .then(res => {
        return res;
      })
      .catch(err => {
        let msg = "Error while delete preferred region";
        return err;
      })
  },
  initFetchBasicInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      titles,
      getGender,
      getLocationCountryInfo,
      getNationalityCountryInfo,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!titles.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getTitles")];
    }
    if (!getGender.length) {
      appendFilterAction = [...appendFilterAction, dispatch("gender")];
    }
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getNationalityCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("nationalityCountryInfo")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchContactInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationCountryInfo,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchProfessionalInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getCandidateType,
      getSpeciality,
      getSubSpeciality,
      getCandidateLevel,
      nurseRegistrationType,
      candidateProfile: { customer_uid }
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getCandidateType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateType")];
    }
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    if (!getCandidateLevel.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    }
    if (!nurseRegistrationType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchNurseRegistrationType", { customer_id: customer_uid })];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchAvailabilityInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationRegion,
      getRegionCountry,
      getCandidatePreferredRegionList,
      getCandidatePreferredCountryList,
      getCurrency
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationRegion.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationRegion")];
    }
    if (!getRegionCountry.length) {
      appendFilterAction = [...appendFilterAction, dispatch("regionCountry")];
    }
    if (!getCandidatePreferredRegionList.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidatePreferredRegionList")]
    }
    if (!getCandidatePreferredCountryList.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCandidatePreferredCountryList")]
    }
    if (!getCurrency.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCurrency")]
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchLicenseInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationCountryInfo,
      getIssueAuthority
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFetchQualificationInfoOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getLocationCountryInfo,
      getIssueAuthority
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  hideErrorMsgModal({ commit, dispatch }) {
    let data = {
      modalTitle: null,
      modalColor: null,
      modalContent: null,
      isShowPopup: false
    }
    commit("SHOW_ERROR_MSG_MODAL", data)
  },
  initFetchSplInterestOptions({ state, commit, rootState, dispatch, getters }) {
    const { getSpecialInterest } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getSpecialInterest.length) {
      appendFilterAction = [...appendFilterAction, dispatch("specialInterest")];
    }
    Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
    })
  },
  initFetchLanguageOptions({ state, commit, rootState, dispatch, getters }) {
    const { getLocationLanguage, getLanguageProficiency } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationLanguage.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationLanguage")];
    }
    if (!getLanguageProficiency.length) {
      appendFilterAction = [...appendFilterAction, dispatch("languageProficiency")];
    }
    Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
    })
  },
  multiFormEdited({ commit, getters }, payload) {
    const { getMultiFormEdited } = getters;
    let data = getMultiFormEdited;
    for (const [key, value] of Object.entries(payload)) {
      data[key] = value;
    }
    commit('SET_MULTI_FORM_EDITED', data)
  },
  fetchJobListForCandidateID({ state, commit, dispatch, getters }, data) {
    dispatch("showLoader");
    const { pagination, isFavourite } = data;
    if (!pagination) {
      commit("SET_CANDIDATE_JOB_PAGINATION", {
        skip: 0,
        noMoreJobs: false
      });
      commit("RESET_JOB_LIST_FOR_CANDIDATE_ID");
      dispatch("initJobCandidateCount");
      if (isFavourite)
        dispatch("fetchFavouriteCount");
      else
        dispatch("fetchTabFilterCandidateCount");
    }
    const {
      candidateProfile: { candidate_uid },
      getCandidateJobPagination: { limit, skip },
      getJobListForCandidateID,
      getDisplayStatusSubStatus: statusSubStatus,
      getChildOrgWithCustomerQuery
    } = getters;
    let queryPayload = { skip, limit };
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    queryString += `&${getChildOrgWithCustomerQuery}`
    return candidate
      .jobListForCandidateID({ candidate_uid, statusSubStatus, queryString })
      .then(res => {
        let { data } = res;
        if (!pagination)
          commit('SET_JOB_LIST_FOR_CANDIDATE_ID', data);
        else
          commit('SET_JOB_LIST_FOR_CANDIDATE_ID', getJobListForCandidateID.concat(data));
        if (data.length < limit) {
          commit("SET_CANDIDATE_JOB_PAGINATION", {
            noMoreJobs: true
          });
        } else {
          commit("SET_CANDIDATE_JOB_PAGINATION", {
            skip: skip + limit,
            noMoreJobs: false
          });
        }
      })
      .catch(err => {
        console.log('Error while Candidate Job Fetch', err);
      })
      .finally(res => {
        dispatch("hideLoader");
        commit("IS_FETCHING_JOBS_FOR_CANDIDATE_ID", false)
      })
  },
  triggerCandidateJobMatch({ state, commit, dispatch, getters }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .candidateJobMatch({ customer_uid, candidate_uid })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log('Error trigger JobCandidateMatch', err)
        return err
      })
  },
  getCandidateIdByMail({ state, commit, dispatch, getters }, email) {
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Verifying Spouse E-Mail...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.getCandidateIDByMail(email)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        return err
      })
      .finally(res => commit("SET_TOAST_HIDE", 3000))
  },
  initUpdateJobStatus({ state, commit, dispatch, getters }, payloadData) {
    const {
      payload: {
        status_id,
        sub_status_id,
        filter_id,
        action_id,
        tab_id,
        current_status,
        current_sub_status,
      },
      job_id,
      candidate_uid,
    } = payloadData;
    let queryPayload = { user_action: 'update' };
    if (sub_status_id)
      queryPayload['sub_status_id'] = sub_status_id;
    if (job_id)
      queryPayload['job_id'] = job_id;
    if (candidate_uid)
      queryPayload['candidate_uid'] = candidate_uid;
    if (current_status)
      queryPayload['current_status'] = current_status;
    if (current_sub_status)
      queryPayload['current_sub_status'] = current_sub_status;
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    let finalPayload = { tab_id, filter_id, action_id, queryString }
    return candidate
      .getUpdateStatusSubStatus(finalPayload)
      .then(async res => {
        const { data } = res;
        const updateStatusSubStatus = data && data.length && data[0] || null;
        await dispatch("updateCandidateJobStatus", { payloadData, updateStatusSubStatus })
        return res;
      })
      .catch(err => {
        console.log("Error while get Update status and substatus", err)
        return err;
      })
  },
  updateCandidateJobStatus({ state, commit, dispatch, getters }, payload) {
    const { payloadData: { job_id, candidate_uid, payload: { reason_id, comments } }, updateStatusSubStatus } = payload;
    dispatch("clearAllToast");
    let queryPayload = {};
    if (reason_id)
      queryPayload['reason_id'] = reason_id;
    if (comments)
      queryPayload['comments'] = comments;
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    return candidate
      .updateJobCandidateStatus({ job_id, candidate_uid, payload: updateStatusSubStatus, queryString })
      .then(res => {
        const { data } = res;
        dispatch("fetchJobListForCandidateID", { pagination: false });
        commit("SET_JOB_DETAILS_FOR_CANDIDATE_ID", res.data);
        let msg = `Job moved into ${data?.status?.display_text}`;
        if (data?.sub_status?.display_text)
          msg = `Job moved into  ${data?.status?.display_text} - ${data?.sub_status?.display_text}`;
        commit("SET_UPDATE_TOAST_VISIBLE", { isShow: true, msg, cssClass: 'bg-success text-white' });
        return res.data;
      })
      .catch(err => {
        const msg = "Error while Updating Job Status"
        commit("SET_UPDATE_TOAST_VISIBLE", { isShow: true, msg, cssClass: "bg-danger text-white" });
        console.log(msg, err);
        return err
      })
  },
  fetchCandidateJobPageInProcessStatus({ commit, dispatch }) {
    commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST", [])
    commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", true)
    dispatch("showLoader")
    return tabFilters.getDisplayStatusSubStatusByTab({ tab_id: 3 }).then(firstRes => {
      return tabFilters.getDisplayStatusSubStatusByTab({ tab_id: 4 }).then(secondRes => {
        let status = firstRes.data.concat(secondRes.data)
        commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_STATUS", status)
      })
    }).catch(err => {
      const msg = "Error while getting candidate job in process list"
      console.log(msg, err);
    }).finally(res => {
      dispatch("hideLoader")
      return res
    })
  },
  fetchCandidateJobPageInProcessList({ commit, getters }, payload) {
    const { getCandidateJobPageInProcessStatus, getCandidateJobPageInProcessList, getCandidateJobInProcessPagination, getChildOrgWithCustomerQuery } = getters;
    const { job_id, pagination } = payload;
    if (!pagination) {
      commit("SET_CANDIDATE_JOB_INPROCESS_PAGINATION", {
        skip: 0,
        noMoreCandidates: false,
      })
    }
    let skip = payload.pagination ? getCandidateJobInProcessPagination.skip : 0;
    let limit = getCandidateJobInProcessPagination.limit;
    commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", true)
    return tabFilters.fetchJobCandidatesByStatusID({
      skip: skip,
      limit: limit,
      queryString: `job_id=${job_id}&${getChildOrgWithCustomerQuery}`,
      formPayload: getCandidateJobPageInProcessStatus
    }).then(res => {
      let { data } = res;
      if (data.length < limit) {
        commit("SET_CANDIDATE_JOB_INPROCESS_PAGINATION", {
          noMoreCandidates: true
        });
      } else {
        commit("SET_CANDIDATE_JOB_INPROCESS_PAGINATION", {
          skip: skip + limit,
          noMoreCandidates: false
        });
      }
      if (payload.pagination) {
        commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST", getCandidateJobPageInProcessList.concat(data));
      } else {
        commit("SET_CANDIDATE_JOB_PAGE_INPROCESS_LIST", data);
      }
    }).catch(err => {
      const msg = "Error while getting candidate job in process list"
      console.log(msg, err);
    }).finally(res => {
      commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", false)
      return res
    })
  },
  resetLastUploadedDocs({ commit }) {
    commit("SET_LAST_UPLOADED_DOCS", null)
  },
  getCandidateFrontSheet: ({ dispatch }, candidate_id) => {
    dispatch("showLoader");
    return candidate.getCandidate(getCustomerId(), candidate_id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log('error fetching candidate', err);
      }).finally(res => {
        dispatch("hideLoader");
        return res
      })
  },
  fetchPreferredDepartments: ({ dispatch, commit, getters }) => {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate
      .getPreferredDepartments(customer_uid, candidate_uid)
      .then(res => {
        commit("SET_PREFERRED_DEPARTMENTS", res.data);
        return res.data;
      })
      .catch(err => {
        console.log("error fetching Preferred Departments", err);
        return err;
      })
  },
  addPreferredDepartment({ dispatch, commit, getters }, payload) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Uploading...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate.addPreferredDepartment(customer_uid, candidate_uid, payload).then(res => {
      commit("SET_UPLOADED", true);
      commit("SET_TOAST_MESSAGE", "Uploaded");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch(err => {
        let msg = "Error while uploading Preferred Department";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  updatePreferredDepartment({ dispatch, commit, getters }, { candidate_preferred_departments_id, payload }) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return candidate
      .updatePreferredDepartment({ customer_uid, candidate_uid, candidate_preferred_departments_id, payload })
      .then(res => {
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", "Saved");
        commit("SET_TOAST_CLASS", "bg-success text-white");
        commit("SET_TOAST_HIDE", 3000);
        return res
      })
      .catch(err => {
        let msg = "Error while saving";
        console.log("error updating Preferred Department", err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      });
  },
  deletePreferredDepartment({ dispatch, commit, getters }, candidate_preferred_departments_id) {
    const { candidateProfile: { customer_uid, candidate_uid } } = getters;
    return candidate.deletePreferredDepartment({ customer_uid, candidate_uid, candidate_preferred_departments_id })
      .then(res => {
        if (res.status == 200) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Preferred Department deleted successfully' });
          dispatch("fetchPreferredDepartments")
        }
      })
      .catch(err => {
        let msg = "Error while Delete Preferred Department";
        console.log(msg, err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  initFetchSpecializationOptions({ getters, dispatch, commit }) {
    const { getSpeciality, getSubSpeciality } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
    })
  },
  checkLicenseValidation({ getters, dispatch, commit }, payload) {
    return candidate.checkLicenseValidation(payload)
      .then(res => {
        const { data } = res;
        return data;
      })
      .catch(err => {
        let msg = "Error while Licence Validation";
        console.log(msg, err);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  }
};

export default { state, getters, mutations, actions };
