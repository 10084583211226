import configurableFields from "@/services/configurableFields";

const getDefaultState = () => {
  return {
    configurableFields: {},
    configurable_id: null,
    registrationConfigurableFields: null,
    fieldByCandidateType: {},
  };
};

const state = getDefaultState();

const getters = {
  getConfigurableFields: (state) => state.configurableFields,
  getConfigurableId: (state) => state.configurable_id,
  getAllRegistrationConfigurableFields: (state) =>
    state.registrationConfigurableFields,
  getRegistrationConfigurableFieldByCandidateType: (state) => (
    candidate_type_id
  ) => {
    return state.registrationConfigurableFields &&
      state.registrationConfigurableFields.length
      ? state.registrationConfigurableFields
          .filter((registrationConfigurableField) => {
            return (
              registrationConfigurableField.candidate_type_id ===
              candidate_type_id
            );
          })
          .map((val) => {
            return val ? val.configuration : [];
          })
      : null;
  },
  getFieldByCandidateType: (state) => state.fieldByCandidateType,
};

const mutations = {
  ["SET_CONFIGURABLE_FIELDS"](state, payload) {
    state.configurableFields = { ...state.configurableFields, ...payload };
  },
  ["SET_CONFIGURABLE_ID"](state, configurable_id) {
    state.configurable_id = configurable_id;
  },
  ["SET_CONFIGURABLE_FIELDS_FOR_REGISTRATION"](state, payload) {
    state.registrationConfigurableFields = payload;
  },
  ["UPDATE_CANDIDATE_TYPE_FIELDS"](state, payload) {
    state.fieldByCandidateType = payload;
  },
};

const actions = {
  getConfigurableFieldsByOrganisationId(
    { state, commit, dispatch },
    organisation_id
  ) {
    dispatch("showLoader");
    dispatch("showToast", {
      class: "bg-success text-white",
      message: "Fetching Configurable fields",
    });
    return configurableFields
      .getCustomFieldsByOrganisationId(organisation_id)
      .then((res) => {
        let { data } = res;
        commit("SET_CONFIGURABLE_FIELDS", data[0].display_fields);
        commit("SET_CONFIGURABLE_ID", data[0].configurable_id);
        dispatch("hideLoader");
      })
      .catch((err) => {
        dispatch("hideLoader");
      });
  },
  updateConfigurableFields({ state, commit, dispatch }, configurable_id) {
    let payload = { display_fields: state.configurableFields };
    return configurableFields
      .updateCustomFieldsByConfigurableId(configurable_id, payload)
      .then((res) => {
        let { data } = res;
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Configurable fields Updated",
        });
      })
      .catch((err) => {});
  },
  createConfigurableFields({ state, commit, dispatch }, payload) {
    payload = { ...payload, ...{ display_fields: state.configurableFields } };
    return configurableFields
      .createCustomFields(payload)
      .then((res) => {
        let { data } = res;
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Configurable fields created",
        });
      })
      .catch((err) => {});
  },
  getConfigurableFieldsForRegistration({ state, commit, dispatch }, payload) {
    let { customer_uid, organisation_id } = payload;
    dispatch("showLoader");
    dispatch("showToast", {
      class: "bg-success text-white",
      message: "Fetching..",
    });
    return configurableFields
      .getCustomFieldsForRegistration(customer_uid, organisation_id)
      .then((res) => {
        let { data } = res;
        data?.forEach((fields) => {
          fields.keys = Object.keys(fields.configuration);
        });
        commit("SET_CONFIGURABLE_FIELDS_FOR_REGISTRATION", data);
        dispatch("hideLoader");
      })
      .catch((err) => {
        dispatch("hideLoader");
      });
  },
  changeConfigurableFieldByCandidateType(
    { getters, commit },
    candidate_type_id
  ) {
    let { getRegistrationConfigurableFieldByCandidateType } = getters;
    let customFields = getRegistrationConfigurableFieldByCandidateType(
      candidate_type_id
    );
    commit("UPDATE_CANDIDATE_TYPE_FIELDS", customFields);
    return true;
  },
  updateRegistrationFieldsForCandidateTypes({ getters, dispatch }) {
    let { getAllRegistrationConfigurableFields } = getters;
    return configurableFields
      .updateCustomFieldsByCandidateTypeForRegistration(
        getAllRegistrationConfigurableFields
      )
      .then((res) => {
        let { data } = res;
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Registration Configurable fields Updated",
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err?.response);
        let msg = err?.response?.data?.detail;
        msg = msg ? msg : "Error while updating!";
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: msg,
        });
      });
  },
};

export default { state, getters, mutations, actions };
