import communication from "@/services/communication";
import { getLocalOrgDetail, Role } from "@/helpers/helper.js";
import { BASE_URL } from "@/service_urls";
import moment from "moment";
import router from "../router/index";

const getDefaultState = () => {
  return {
    getEmailConversation: [],
    getMessageConversation: [],
    getCandidateEmailMessages: [],
    getRecruiterEmailMessages: [],
    getNotificationMessagesCount: 0,
    getEmailMessagesCount: 0,
    fullPathEmailBoxBackUrl: [],
    previousCount: 0,
    getEmailPagination: {
      limit: 15,
      skip: 0,
      noMoreDataFromEmailList: false,
    },
    getCandidateEmailPagination: {
      limit: 15,
      skip: 0,
      noMoreDataFromEmailList: false,
    },
    sse: null,
    getUserEmailAddress: [],
    getCandidateEmailAddress: [],
    getWindowWidth: window.innerWidth,
  };
};

const state = getDefaultState();

const getters = {
  sse: (state) => state.sse,
  getEmailConversation: (state) => state.getEmailConversation,
  messageCount: (state) => state.messageCount,
  getMessageConversation: (state) => state.getMessageConversation,
  noMoreDataFromEmailList: (state) =>
    state.getEmailPagination.noMoreDataFromEmailList,
  getEmailPaginationSkip: (state) => state.getEmailPagination.skip,
  getEmailPaginationLimit: (state) => state.getEmailPagination.limit,
  getCandidateEmailPaginationSkip: (state) =>
    state.getCandidateEmailPagination.skip,
  getCandidateEmailPaginationLimit: (state) =>
    state.getCandidateEmailPagination.limit,
  getNotificationMessagesCount: (state) => state.getNotificationMessagesCount,
  getEmailMessagesCount: (state) => state.getEmailMessagesCount,
  getCandidateEmailMessages: (state) =>
    state.getCandidateEmailMessages && state.getCandidateEmailMessages
      ? state.getCandidateEmailMessages.map((val) => ({
        sender: val.sender,
        subject: val.subject,
        load_datetime: moment(val.load_datetime).format(
          "DD MMM YYYY hh:mm A"
        ),
        email_id: val.email,
        direction: val.direction,
        message_id: val.message_id,
        body: val.body,
        read: val.read,
        job_id: val.job_id,
        customer_user_id: val.customer_user_id,
        sender_name: val.sender_name,
        candidate_uid: val.candidate_uid,
        attachment: val.email_attachment,
      }))
      : [],
  getRecruiterEmailMessages: (state) =>
    state.getRecruiterEmailMessages
      ? state.getRecruiterEmailMessages.map((val) => ({
        sender: val.sender,
        subject: val.subject,
        load_datetime: moment(val.load_datetime).format(
          "DD MMM YYYY hh:mm A"
        ),
        email_id: val.email,
        direction: val.direction,
        message_id: val.message_id,
        body: val.body,
        read: val.read,
        job_id: val.job_id,
        customer_user_id: val.customer_user_id,
        sender_name: val.sender_name,
        candidate_uid: val.candidate_uid,
        attachment: val.email_attachment,
      }))
      : [],
  emailBoxBackUrl: (state, getters, rootState) => {
    let { getRoleScopes } = getters;
    let emailBoxBackUrl = {
      admin: "/dashboard",
      facility: "/recruiter-dashboard",
    };
    let backURL = state.fullPathEmailBoxBackUrl;
    if (!backURL.length) {
      if (getRoleScopes[0] === Role.systemAdmin) {
        return emailBoxBackUrl.admin;
      } else {
        return emailBoxBackUrl.facility;
      }
    } else {
      return backURL[backURL.length - 1];
    }
  },
  getUserEmailAddress: (state) => state.getUserEmailAddress,
  getCandidateEmailAddress: (state) => state.getCandidateEmailAddress,
  getWindowWidth: (state) => state.getWindowWidth,
};

const mutations = {
  ["SET_CANDIDATE_EMAIL_MESSAGES"](state, payload) {
    state.getCandidateEmailMessages = payload;
  },
  ["SET_EMAIL_CONVERSATION"](state, payload) {
    state.getEmailConversation = payload;
  },
  ["SET_MESSAGE_CONVERSATION"](state, payload) {
    state.getMessageConversation = payload;
  },
  ["SET_NOTIFICATION_EMAIL_MESSAGES_COUNT"](state, data) {
    state.getNotificationMessagesCount = data;
  },
  ["SET_EMAIL_MESSAGES_COUNT"](state, data) {
    state.getEmailMessagesCount = data;
  },
  ["SET_RECRUITER_EMAIL_MESSAGES"](state, payload) {
    state.getRecruiterEmailMessages = payload;
  },
  ["SET_EMAIL_MESSAGE_BACK_URL"](state, url) {
    state.fullPathEmailBoxBackUrl = [...state.fullPathEmailBoxBackUrl, url];
  },
  ["SET_SSE"](state, data) {
    state.sse = data;
  },
  ["RESET_EMAIL_MESSAGE_BACK_URL"](state) {
    state.fullPathEmailBoxBackUrl = [];
  },
  ["REMOVE_EMAIL_MESSAGE_BACK_URL"](state, url) {
    let _index = _.findLastIndex(
      state.fullPathEmailBoxBackUrl,
      (e) => e === url
    );
    if (_index > -1) state.fullPathEmailBoxBackUrl.splice(_index, 1);
  },
  ["SET_USER_EMAIL_ADDRESS"](state, payload) {
    state.getUserEmailAddress = payload;
  },
  ["SET_CANDIDATE_EMAIL_ADDRESS"](state, payload) {
    state.getCandidateEmailAddress = payload;
  },
  ["SET_WINDOW_WIDTH"](state, data) {
    state.getWindowWidth = window.innerWidth;
  },
};

const actions = {
  getEmailConversation: ({ commit, getters }, payload) => {
    let order = "-message_id";
    return communication
      .getEmailConversation({payload, order})
      .then((res) => {
        commit("SET_EMAIL_CONVERSATION", res.data);
      })
      .catch((err) => {
        console.log("Error fetching Email communication", err);
        return err;
      });
  },

  fetchCandidateEmailMessages: ({ commit, getters }, payload) => {
    const {
      getCandidateEmailPaginationSkip,
      getCandidateEmailPaginationLimit,
    } = getters;
    let skip = getCandidateEmailPaginationSkip;
    let limit = getCandidateEmailPaginationLimit;
    let order = "-message_id";
    return communication
      .getCandidateEmailMessages({ payload, skip, limit, order })
      .then((res) => {
        commit("SET_CANDIDATE_EMAIL_MESSAGES", res.data);
      })
      .catch((err) => {
        console.log("Error fetching Email communication", err);
        return err;
      });
  },

  fetchNewNotificationEmail: ({ commit, getters, dispatch }) => {
    const { getUserFullName, sse } = getters;
    if (sse?.readyState == 0 || sse?.readyState == 1) return;
    const evtSource = new EventSource(
      "/subapi/communication/email/notification"
    );
    commit("SET_SSE", evtSource);
    evtSource.addEventListener(getUserFullName, function (event) {
      let data = JSON.parse(event.data);
      let user = localStorage.getItem("userData");
      if (!user) return;
      commit(
        "SET_NOTIFICATION_EMAIL_MESSAGES_COUNT",
        state.getNotificationMessagesCount + data.length
      );
      dispatch("showToast", {
        class: "bg-success text-white",
        message: "New Email Received!",
      });
      if (router.currentRoute.hash == "#notification") {
        dispatch("fetchRecruiterEmailMessages", {
          sender: getUserFullName,
          direction: "I",
          read: "false",
        });
        dispatch("fetchEmailMessagesCount", {
          sender: getUserFullName,
          direction: "I",
          read: "false",
        });
      } else if (router.currentRoute.hash == "#inbox") {
        dispatch("fetchRecruiterEmailMessages", {
          sender: getUserFullName,
          direction: "I",
        });
        dispatch("fetchEmailMessagesCount", {
          sender: getUserFullName,
          direction: "I",
        });
      }
    });
  },

  fetchUnreadEmailMessages: ({ commit, getters, dispatch }) => {
    const { getUserFullName:sender } = getters;
    const payload = { sender, direction:"I", read:false }
    return communication
      .getEmailMessagesAndCount(payload)
      .then((res) => {
        let { data } = res;
        commit("SET_NOTIFICATION_EMAIL_MESSAGES_COUNT", data);
      })
      .catch((err) => {
        console.log("Error Fetching Unread Notification Message", err);
        return err;
      });
  },

  fetchEmailMessagesCount: (
    { commit, getters,dispatch },
    payload
  ) => {
    return communication
      .getEmailMessagesAndCount(payload)
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_MESSAGES_COUNT", data);
        dispatch("fetchUnreadEmailMessages")
      })
      .catch((err) => {
        console.log("Error Fetching Notification Count", err);
        return err;
      });
  },

  fetchSearchTermEmailMessagesCount: (
    { commit, getters },
    { read, direction, searchTerm }
  ) => {
    let query;
    if (read && direction) query = `read=${read}&direction=${direction}`;
    else if (direction) query = `direction=${direction}`;

    return communication
      .getSearchTermEmailMessagesCount(query, searchTerm)
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_MESSAGES_COUNT", data);
      })
      .catch((err) => {
        console.log("Error Fetching Notification Count", err);
        return err;
      });
  },

  fetchSearchTermEmailMessages: (
    { commit, getters },
    { searchTerm, ...payload }
  ) => {
    const { getEmailPaginationSkip, getEmailPaginationLimit } = getters;
    let skip = getEmailPaginationSkip;
    let limit = getEmailPaginationLimit;

    let query;
    if (read && direction) query = `read=${read}&direction=${direction}`;
    else if (direction) query = `direction=${direction}`;

    return communication
      .getSearchTermEmailMessages({payload, skip, limit, searchTerm})
      .then((res) => {
        let { data } = res;
        commit("SET_RECRUITER_EMAIL_MESSAGES", data);
      })
      .catch((err) => {
        console.log("Error Fetching Notification Count", err);
        return err;
      });
  },

  fetchRecruiterEmailMessages: (
    { commit, getters },
    payload
  ) => {
    const { getEmailPaginationSkip, getEmailPaginationLimit } = getters;
    let skip = getEmailPaginationSkip;
    let limit = getEmailPaginationLimit;

    return communication
      .getRecruiterEmailMessages({payload, skip, limit})
      .then((res) => {
        let { data } = res;
        commit("SET_RECRUITER_EMAIL_MESSAGES", data);
      })
      .catch((err) => {
        console.log("Error fetching Email Messages", err);
        return err;
      });
  },
  sendOutboundEmail: (
    { commit, getters, dispatch },
    {
      sender,
      sender_name,
      customer_user_id,
      email_to,
      subject,
      message,
      job_id,
      candidate_uid,
      files,
    }
  ) => {
    const { org_detail } = getters.getUser;
    const { location } = getters.getOrgLocation;
    let base_url = BASE_URL.split("/api/v1")[0];
    let formData = new FormData();
    formData.append("sender", sender);
    formData.append("sender_name", sender_name);
    formData.append("customer_user_id", customer_user_id);
    formData.append("email_to", email_to);
    formData.append("subject", subject);
    formData.append("message_content", message);

    if (org_detail) formData.append("org_name", org_detail.org_name);
    if (location) formData.append("org_location", location);
    if (job_id) formData.append("job_id", job_id);
    if (candidate_uid) formData.append("candidate_uid", candidate_uid);

    if (files.length != 0) {
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append("file", file);
      }
    }
    return communication
      .sendOutboundEmail(base_url, formData)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email sent successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to send email",
        });
        console.log("Error sending Email", err);
        return err.response.status;
      });
  },

  downloadAttachment: ({ commit, getters }, { attachment_id }) => {
    return communication
      .downloadAttachment(attachment_id)
      .then((res) => {
        let { request } = res;
        let data_url = request.responseURL;
        return data_url;
      })
      .catch((err) => {
        return err;
      });
  },

  getMessageConversation: (
    { commit, getters },
    { sender, receiver, media }
  ) => {
    return communication
      .getMessageConversation(sender, receiver, media)
      .then((res) => {
        commit("SET_MESSAGE_CONVERSATION", res.data);
      })
      .catch((err) => {
        console.log("Error fetching Message communication", err);
        return err;
      });
  },

  sendOutboundWhatsapp: (
    { commit, getters, dispatch },
    { sender, whatsapp_to, message }
  ) => {
    return communication
      .sendOutboundWhatsapp(sender, whatsapp_to, message)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Message sent successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to send message",
        });
        console.log("Error sending Message", err);
        return err.response.status;
      });
  },

  sendOutboundMessage: (
    { commit, getters, dispatch },
    { sender, message_to, message }
  ) => {
    return communication
      .sendOutboundMessage(sender, message_to, message)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Message sent successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to send message",
        });
        console.log("Error sending Message", err);
        return err.response.status;
      });
  },

  deleteEmailConversation: ({ dispatch }, { deleteId }) => {
    return communication
      .deleteEmailConversation(deleteId)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email deleted successfully",
        });
        return res;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Failed to delete email",
        });
        console.log("Error delete conversation", err);
        return err;
      });
  },

  fetchAllCustomerContactUsersEmail: ({ commit, getters }, contact) => {
    const { getCustomerId, getOrgIDFromAccessToken, getRole } = getters;
    const org_id = getOrgIDFromAccessToken ? getOrgIDFromAccessToken : null;
    return communication
      .getAllCustomerContactUserEmail(
        getCustomerId,
        org_id,
        getRole,
        Role,
        contact
      )
      .then((res) => {
        let { data } = res;
        commit("SET_USER_EMAIL_ADDRESS", data.reverse());
      })
      .catch((err) => {
        console.log("Error Fetching User Email", err);
        return err;
      });
  },

  fetchAllUserEmailSeacrhTerm: ({ commit, getters }, { searchTerm }) => {
    const { getCustomerId, getOrgIDFromAccessToken } = getters;
    let skip = 0;
    let limit = 15;
    const org_id = null;
    let searchTerms = searchTerm ? `&searchTerm=${searchTerm}` : ``;
    return communication
      .getAllUserEmailSeacrhTerm(
        getCustomerId,
        org_id,
        skip,
        limit,
        searchTerms
      )
      .then((res) => {
        let { data } = res;
        commit("SET_CANDIDATE_EMAIL_ADDRESS", data);
      })
      .catch((err) => {
        console.log("Error Fetching User Email", err);
        return err;
      });
  },
  markAsReadUnread: ({ commit, getters,dispatch }, payload) => {
    const { read } = payload
    return communication.markAsReadUnRead(payload)
      .then(res => {
        const { data } = res;
        const message = `Changed as ${read ? 'Read' : 'UnRead'}`
        dispatch("showToast", {
          class: "bg-success text-white",
          message
        });
        return data
      })
      .catch(err => {
        console.log("Error While Update mail as read_unread", err);
        return err;
      })
  }
};

export default { state, getters, mutations, actions };
