import router from "../router/index";
import dynamicOrgUrl from "../services/dynamicOrgUrl"
import { getLocalOrgDetail, IS_DOH_THEME } from "@/helpers/helper"

const state = {
    orgDetail: {},
    dynamicOrgStaticFiles: [],
    isAcronymBasedLogin: false,
    getLoggedAcronym: JSON.parse(localStorage.getItem("orgDetail"))?.path_acronym || "",
};
const getters = {
    isAcronymBasedLogin: state => state.isAcronymBasedLogin,
    getOrgDetail: state => state.orgDetail,
    getDynamicOrgStaticFiles: state => state.dynamicOrgStaticFiles,
    getImages: state => {
        let orgFiles = state.dynamicOrgStaticFiles;
        if (IS_DOH_THEME) {
            return {
                header_logo: '/img/white-logo-doh.svg',
                customer_logo: '/img/doh_logo_new.png',
                banner: '/img/new_landing_page.png'
            }
        } else if (state.isAcronymBasedLogin) {
            return {
                header_logo: orgFiles?.header_logo_url,
                customer_logo: orgFiles?.customer_logo_url,
                banner: orgFiles?.banner_path_url
            }
        }
        return {
            header_logo: '/img/talentfind_logo2.png',
            customer_logo: '/img/talentfind_logo1.png',
            banner: '/img/new_home_bg.png'
        }
    },
    getLoggedAcronym: (state) => state.getLoggedAcronym,
};
const actions = {
    fetchOrgDetailsByAcronym({ state, commit, getters, dispatch }) {
        commit("SET_ACRONYM_BASED_LOGIN", true)
        dispatch("showLoader");
        const { getOrgDetail } = getters;
        let { params: { org_name: pid }, name } = router.currentRoute
        let acronym = pid;
        name = name || "";
        if (!(
            name.includes("Login")
            || name.includes("UnSubscribe")
            || name.includes("Register")
            || name.includes("Forgot")
        )) {
            acronym = getLocalOrgDetail()?.path_acronym
        }
        if (acronym == getOrgDetail?.path_acronym && acronym) {
            dispatch("hideLoader");
            return;
        }
        localStorage.removeItem("orgDetail");
        if (!acronym) {
            commit("RESET_DYNAMIC_ORG_DETAIL");
            commit("RESET_DYNAMIC_ORG_STATIC_FILES");
            commit("SET_ACRONYM_BASED_LOGIN", false);
            commit("SET_LOGGED_ACRONYM", "")
            dispatch("hideLoader");
            return;
        }
        return dynamicOrgUrl.getOrgDetailsByAcronym(acronym).then((res) => {
            const { data } = res;
            if (!data) {
                dispatch("showToast", {
                    class: 'bg-danger text-white',
                    message: 'No Organization found! redirected to system login!'
                });
                commit("SET_ACRONYM_BASED_LOGIN", false)
                commit("RESET_DYNAMIC_ORG_STATIC_FILES");
                commit("RESET_DYNAMIC_ORG_DETAIL");
                commit("SET_LOGGED_ACRONYM", "")
                router.push("/")
            } else {
                localStorage.setItem("orgDetail", JSON.stringify(data));
                commit("SET_DYNAMIC_ORG_DETAIL", data);
                commit("SET_LOGGED_ACRONYM", data?.path_acronym)
                dispatch("fetchOrgFilesByAcronym", acronym)
            }
        }).catch(err => {
            console.log("error while fetching org data", err)
        }).finally(res => {
            dispatch("hideLoader");
            return res;
        })
    },
    fetchOrgFilesByAcronym({ commit, getters, dispatch }, acronym) {
        dispatch("showLoader");
        const { getOrgDetail } = getters;
        return dynamicOrgUrl.getOrgFilesByAcronym(acronym).then(res => {
            const { data } = res;
            commit("SET_DYNAMIC_ORG_STATIC_FILES", data);
            let orgFiles = { ...getOrgDetail, orgFiles: data }
            localStorage.setItem("orgDetail", JSON.stringify(orgFiles));
        }).catch(err => {
            console.log("error while fetching org files", err)
        }).finally(res => {
            dispatch("hideLoader");
            return res;
        })
    },
    isEmailBelongsToAcronym({ dispatch }, email) {
        const { params: { org_name: pid } } = router.currentRoute
        let acronym = pid || "";
        const email_encoded = encodeURIComponent(email)
        return dynamicOrgUrl.isEmailBelongsToAcronym(email_encoded, acronym).then(res => {
            return res;
        }).catch(err => {
            if (err?.response?.status === 400) {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: err.response.data.detail
                })
            }
            console.log("error while fetching email belongings", err);
            return err;
        })
    }
};
const mutations = {
    ["SET_DYNAMIC_ORG_DETAIL"](state, payload) {
        state.orgDetail = payload;
    },
    ["RESET_DYNAMIC_ORG_DETAIL"](state) {
        state.orgDetail = "";
    },
    ["SET_DYNAMIC_ORG_STATIC_FILES"](state, payload) {
        state.dynamicOrgStaticFiles = payload;
    },
    ["RESET_DYNAMIC_ORG_STATIC_FILES"](state) {
        state.dynamicOrgStaticFiles = [];
    },
    ["SET_ACRONYM_BASED_LOGIN"](state, payload) {
        state.isAcronymBasedLogin = payload
    },
    ["SET_LOGGED_ACRONYM"](state, payload) {
        state.getLoggedAcronym = payload
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
