import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const login = {
  loginAccessToken: (payload) => axios.post(`${BASE_URL}/login/access-token`, payload),
  forgotPassword: (email) => axios.post(`${BASE_URL}/password-recovery/${email}`),
  resetPassword: (payload) => axios.post(`${BASE_URL}/reset-password/`, payload),
  unsubscribe: (email) => axios.get(`${BASE_URL}/candidate/unsubscribe/?email=${email}`),
  refreshtoken: (refresh_token) => axios.post(`${BASE_URL}/login/refresh-token/?refresh_token=${refresh_token}`),
  changePassword: ({ username, new_password }) => axios.put(`${BASE_URL}/change-password/${username}/new_password/${new_password}`),
  getAutoGenPassword: () => axios.get(`${BASE_URL}/new-password`),
  organisationAccessToken: (organisation_id) => axios.post(`${BASE_URL}/login/${organisation_id}/access-token`),
}

export default login