import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const tabFilters = {
    getJobCandidateTabs: () => axios.get(`${BASE_URL}/jobcandidateprocess/tabs`),
    jobCandidateCount: ({ tab_id, queryString }) => axios.get(`${BASE_URL}/jobcandidate/match/tab/${tab_id}/count?${queryString}`),
    getTabFilters: ({ tab_id }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filters?user_action=display`),
    getTabSubFilters: ({ tab_id, filter_id }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/sub_filters?user_action=display`),
    getDisplayStatusSubStatus: ({ tab_id, filter_id, queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/get_display_status?${queryString}`),
    tabFilterCandidateCount: ({ tab_id, filter_id, queryString }) =>
        axios.get(`${BASE_URL}/jobcandidate/match/tab/${tab_id}/filter/${filter_id}/count?${queryString}`),
    favouriteCount: ({ payload, queryString }) => axios.post(`${BASE_URL}/jobcandidate/match/count?${queryString}`, payload),
    jobCandidateTabTotalCount: (queryString) => axios.get(`${BASE_URL}/jobcandidate/match/total_count?${queryString}`),
    fetchJobCandidatesByStatusID: ({ skip, limit, queryString, formPayload }) =>
        axios.post(`${BASE_URL}/jobcandidate/match/candidates?skip=${skip}&limit=${limit}&${queryString}`, formPayload),
    getDisplayStatusSubStatusByTab: ({ tab_id }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/get_display_status`),
    getJobStatusTabTotalCount: ({ customer_id, query }) => axios.get(`${BASE_URL}/customer/${customer_id}/job/job_status_count?${query}`),
}
export default tabFilters;