<template>
  <div class="pl-2">
    <CModal color="primary" :show.sync="isShowModal" :closeOnBackdrop="false">
      <template #header>
        <h5>Switch Role</h5>
        <CButtonClose @click="modalCallBack(false)" />
      </template>

      <template #footer>
        <CButton color="secondary" @click="modalCallBack('Cancel', payload)"
          >Cancel</CButton
        >
        <CButton
          color="primary"
          v-if="impersonation"
          @click="modalCallBack('SystemAdmin', payload)"
          >Switch to System Admin
        </CButton>
        <CButton
          form="getFacilityList"
          color="primary"
          v-if="!impersonation"
          type="Submit"
          >Switch to Facility Admin
        </CButton>
      </template>

      <div v-if="impersonation">
        <CRow class="content-center text-black">
          Are you sure to switch back to System Admin ?
        </CRow>
      </div>
      <div v-if="!impersonation">
        <ValidationObserver ref="getFacilityList" v-slot="{ handleSubmit }">
          <form id="getFacilityList" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="row mb-3">
              <label class="required col-lg-4 text-black">
                Select Facility
              </label>
              <div class="col-lg-8">
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <Select
                    name="customer_data"
                    :value="customer_data"
                    @input="handleChangeSelect"
                    :options="
                      options && options['getFacilityList']
                        ? options['getFacilityList']
                        : []
                    "
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import Select from "@/components/reusable/Fields/Select";

export default {
  extends: Modal,
  name: "SwitchAccountModal",
  props: ["impersonation"],
  components: {
    Select,
  },
  data() {
    return {
      customer_data: "",
      payload: {},
    };
  },
  computed: {
    ...mapGetters(["getFacilityList"]),
    options() {
      return {
        getFacilityList:
          this.getFacilityList.map((val) => ({
            label: val?.organisation?.name,
            customer_id: val.customer_uid,
            organisation_id: val.organisation_id,
          })) || [],
      };
    },
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup === true) {
        this.customer_data = "";
      }
    },
  },
  methods: {
    handleChangeSelect(name, value) {
      this.customer_data = value;
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.getFacilityList.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.modalCallBack("CustomerAdmin", this.payload);
    },
  },
};
</script>
