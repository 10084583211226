import job from '@/services/job';
import jobBoard from '@/services/jobBoard';
import qualification from '@/services/qualification';
import customer from '@/services/customer';
import router from '../router/index';
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
import tabFilters from "@/services/tabFilter";
import candidate from '@/services/candidate';
import _ from "lodash";
import contactUser from '@/services/contactUser';

const getDefaultState = () => {
  return {
    jobsProfile: {},
    organizationId: '',
    jobId: null,
    jobById: [],
    getAllJobs: [],
    getCandidateFavJobs: [],
    getCandidateAppliedJobs: [],
    getJobTotalCount: 0,
    getFavoJobsCount: 0,
    getAppliedJobsCount: 0,
    qualifications: [],
    benefits: [],
    qualificationCountries: [],
    qualificationRegions: [],
    organisationRegions: [],
    jobLevels: [],
    orgJobDetails: [],
    jobCandidatePagination: {
      limit: 6,
      skip: 0,
      noMoreCandidates: false
    },
    candidateListForJobID: [],
    sub_status_options: [],
    qualificationCountriesByRegion: [],
    tabReasons: [],
    candidateDetailsForJobID: null,
    contactMethods: [],
    fieldsInAdvert: [],
    sectionDisplayNames: [],
    sectionTemplates: {},
    jobComments: [],
    shortListInProgressJobs: [],
    shortListInProgressJobPagination: {
      limit: 6,
      skip: 0,
      noMoreJobs: false
    },
    shortListInProgressStatusSubStatus: [],
    contactUserForSelectedJob: [],
    jobCommonComments: [],
    enumLabelData: {},
    orgContacts: [],
    getCandidateWithdrawJobs: [],
    getWithdrawJobsCount: 0,
  };
};

const state = getDefaultState();

const getters = {
  jobsProfile: (state) => state.jobsProfile,
  organizationId: (state) =>
    state.organizationId && state.organizationId.length
      ? state.organizationId.map((val) => ({
        label: val.organisation.name,
        code: val.organisation_id,
        organisation: val.organisation,
        customer_uid: val.customer_uid,
        customer_organisation_location: val.customer_organisation_location
      }))
      : [],
  jobId: (state) => state.jobId,
  jobById: (state) => state.jobById,
  getAllJobs: (state) => state.getAllJobs,
  getCandidateFavJobs: (state) => state.getCandidateFavJobs,
  getCandidateAppliedJobs: (state) => state.getCandidateAppliedJobs,
  getJobTotalCount: (state) => state.getJobTotalCount,
  getAppliedJobsCount: (state) => state.getAppliedJobsCount,
  getFavoJobsCount: (state) => state.getFavoJobsCount,
  getQualifications: (state) => state.qualifications,
  getOrgJobBenefits: (state) => state.benefits,
  getQualificationCountries: (state) => state.qualificationCountries,
  getQualificationRegions: (state) => state.qualificationRegions,
  getOrganisationRegions: (state) => {
    return state.organisationRegions?.length ? state.organisationRegions.map(({ region }) => ({
      code: region?.region_id,
      label: region?.region_name,
    })) : state.qualificationRegions.map((region) => ({
      code: region?.region_id,
      label: region?.region_name,
    }))
  },
  getJobLevels: (state) => state.jobLevels,
  getOrgJobDetails: (state) => state.orgJobDetails,
  getQualificationCountryByRegion: (state, getters, rootState) => {
    let data = state.qualificationCountriesByRegion.length ? state.qualificationCountriesByRegion.map(val => {
      return {
        label: val.country.country_name,
        code: val.country_id
      }
    }) : []
    return [{ label: "All", code: 0 }, ...data]
  },
  getRegionNameForSelectedId: (state, getters, rootState) => (regions) => {
    let { getQualificationRegions } = getters
    let data = getQualificationRegions.length && regions ? getQualificationRegions.filter(val => {
      return regions.includes(val.region_id)
    }).map(val => {
      return {
        label: val.region_name,
        code: val.region_id
      }
    }) : []
    return data.length ? data : [{ label: "All", code: 0 }]
  },
  getCountryNameForSelectedId: (state, getters, rootState) => (regions) => {
    let { getQualificationCountries } = getters
    let data = getQualificationCountries.length && regions ? getQualificationCountries.filter(val => {
      return regions.includes(val.country_id)
    }).map(val => {
      return {
        label: val.country.country_name,
        code: val.country_id,
        region_id: val.region_id,
      }
    }) : []
    return data.length ? data : [{ label: "All", code: 0 }]
  },
  getLevelForSelectedId: (state, getters, rootState) => (level_ids) => {
    let { getCandidateLevel } = getters
    return getCandidateLevel.length && level_ids ? getCandidateLevel.filter(val => {
      return level_ids.includes(val.level_id)
    }).map(val => {
      return {
        label: val.candidate_level,
        code: val.level_id
      }
    }) : []
  },
  getLanguageForSelectedId: (state, getters, rootState) => (language_ids) => {
    let { getLocationLanguage } = getters
    return getLocationLanguage.length && language_ids ? getLocationLanguage.filter(val => {
      return language_ids.includes(val.language_id)
    }).map(val => {
      return {
        label: val.language,
        code: val.language_id
      }
    }) : []
  },
  getSpecialInterestForSelectedId: (state, getters, rootState) => (special_interest_ids) => {
    let { getSpecialInterest } = getters
    return getSpecialInterest.length && special_interest_ids ? getSpecialInterest.filter(val => {
      return special_interest_ids.includes(val.special_interest_id)
    }).map(val => {
      return {
        label: val.special_interest,
        code: val.special_interest_id
      }
    }) : []
  },
  getCurrencyForSelectedID: (state, getters, rootState) => (currency_id) => {
    let { getCurrency } = getters
    return getCurrency?.length && currency_id ? getCurrency.filter(val => val.code == currency_id) : []
  },
  getAdLocationForSelectedID: (state, getters, rootState) => (location_ids) => {
    let ad_loc = [
      {
        code: "job_board",
        label: "Job Board",
      },
      {
        code: "social_media",
        label: "Social Media",
      },
    ];
    return location_ids ? ad_loc.filter(val => {
      return location_ids.includes(val.code)
    }) : []
  },
  getCandidateListForJobID: (state, getters, rootState) => state.candidateListForJobID,
  getJobCandidatePagination: (state, getters, rootState) =>
    state.jobCandidatePagination,
  noMoreCandidates: (state, getters, rootState) =>
    state.jobCandidatePagination.noMoreCandidates,
  getSubStatusOptions: (state, getters, rootState) => state.sub_status_options && state.sub_status_options.length ? state.sub_status_options.map(({ display_sub_status_ }) => { return { code: display_sub_status_?.status_id, label: display_sub_status_?.display_text } }) : [],
  getTabReasons: (state) => state.tabReasons.length ? state.tabReasons.map(({ reason_ }) => { return { code: reason_?.reason_id, label: reason_?.reason } }) : [],
  getCandidateDetailsForID: (state) => state.candidateDetailsForJobID,
  getContactMethods: (state) => state.contactMethods.map(val => { return { label: val.toUpperCase(), code: val } }) || [],
  getFieldsInAdvert: (state) => {
    return state.fieldsInAdvert?.length ? state.fieldsInAdvert.map(val => {
      return {
        code: val,
        label: val.split('_').map(function (val) {
          return val[0].toUpperCase() + val.substr(1);
        }).join(' ')
      }
    }) : []
  },
  getSectionDisplayNames: (state) => state.sectionDisplayNames,
  getSectionByDisplayNames: (state) => state.sectionTemplates,
  getJobComments: (state) => state.jobComments,
  getShortListInProgressJobPagination: (state, getters, rootState) =>
    state.shortListInProgressJobPagination,
  noMoreShortListInProgressJobs: (state, getters, rootState) =>
    state.shortListInProgressJobPagination.noMoreJobs,
  getShortListInProgressJobs: state => state.shortListInProgressJobs || [],
  getDisplayStatusShortListInProgressJob: state => state.shortListInProgressStatusSubStatus,
  getContactUserByTypeForJob: state => ({ customer_user_type_id }) => {
    let returnData = [];
    if (state.contactUserForSelectedJob?.length && customer_user_type_id) {
      state.contactUserForSelectedJob.forEach((val) => {
        let data = val?.customer_users?.filter(fil => {
          return fil.customer_user_type_id == customer_user_type_id
        }).map(fil => {
          return {
            label: val.contact?.name,
            code: fil.id
          }
        })
        if (data?.length) returnData.push(data[0])
      })
    }
    return returnData
  },
  getJobCommonComments: state => state.jobCommonComments,
  getLocationByOrganisation: state => (organisation_id) => {
    let data = state.organizationId?.length && organisation_id
      ? state.organizationId
        .filter(
          (val) => val.organisation_id == organisation_id
        )
        .map((val) => val?.customer_organisation_location) : [];
    if (data?.length) {
      data = data[0].map(val => ({
        label: val?.location?.location,
        code: val?.location_id,
        country_id: val?.location?.country_id
      }))
    }
    return data || [];

  },
  getOrgContacts: state => state.orgContacts?.map(val => {
    return {
      label: val?.contact?.name,
      code: val?.customer_organisation_contact_id
    }
  }) || [],
  getOrgContactForSelectedId: (state, getters, rootState) => (payload) => {
    let { customer_organisation_contact_id, contact_id } = payload
    return state?.orgContacts?.filter(val =>
      val.customer_organisation_contact_id == customer_organisation_contact_id || val.contact_id == contact_id)
      ?.map(val => {
        return {
          label: val?.contact?.name,
          code: val?.customer_organisation_contact_id
        }
      })[0] || [];
  },
  getWithdrawJobsCount: (state) => state.getWithdrawJobsCount,
  getCandidateWithdrawJobs: (state) => state.getCandidateWithdrawJobs,
};

const mutations = {
  ['SET_CREATE_JOBS'](state, payload) {
    state.jobsProfile = payload;
  },
  ['SET_ORGANIZATION_ID'](state, payload) {
    state.organizationId = payload;
  },
  ['SET_JOB_BY_ID'](state, payload) {
    state.jobById = payload;
  },
  ['SET_JOB_ID'](state, payload) {
    state.jobId = payload;
  },
  ['SET_UPDATE_JOBS'](state, payload) {
    state.jobsProfile = payload;
  },
  ['SET_All_JOBS'](state, payload) {
    state.getAllJobs = payload;
  },
  ['SET_CANDIDATE_FAV_JOBS'](state, payload) {
    state.getCandidateFavJobs = payload;
  },
  ['SET_CANDIDATE_APPLIED_JOBS'](state, payload) {
    state.getCandidateAppliedJobs = payload;
  },
  ['APPLIED_JOB_COUNT'](state, payload) {
    state.getAppliedJobsCount = payload
  },
  ['FAVO_JOB_COUNT'](state, payload) {
    state.getFavoJobsCount = payload
  },
  ['UPDATE_FAV_ARRAY'](state, payload) {
    let favJobs = state.getCandidateFavJobs || []
    let appliedJobs = state.getCandidateAppliedJobs || []
    state.getCandidateFavJobs = null;
    for (let i = 0; i < favJobs.length; i++) {
      if (favJobs[i].job_id == payload.job_id) favJobs[i] = payload
    }
    state.getCandidateFavJobs = favJobs;
    state.getCandidateAppliedJobs = null;
    for (let i = 0; i < appliedJobs.length; i++) {
      if (appliedJobs[i].job_id == payload.job_id) appliedJobs[i] = payload
    }
    state.getCandidateAppliedJobs = appliedJobs;
  },
  ['SET_JOB_TOTAL_COUNT'](state, payload) {
    state.getJobTotalCount = payload;
  },
  ['SET_QUALIFICATIONS_PQR'](state, payload) {
    state.qualifications = payload;
  },
  ['SET_BENEFITS'](state, payload) {
    state.benefits = payload;
  },
  ["SET_QUALIFICATION_COUNTRIES"](state, payload) {
    state.qualificationCountries = payload;
  },
  ["SET_QUALIFICATION_COUNTRIES_BY_REGION"](state, payload) {
    state.qualificationCountriesByRegion = payload;
  },
  ["SET_QUALIFICATION_REGIONS"](state, payload) {
    state.qualificationRegions = payload;
  },
  ["SET_ORGANISATION_REGIONS"](state, payload) {
    state.organisationRegions = payload
  },
  ['SET_JOB_LEVELS'](state, payload) {
    state.jobLevels = payload;
  },
  ['SET_ORG_JOB_DETAILS'](state, payload) {
    state.orgJobDetails = payload;
  },
  ['SET_CANDIDATE_LIST_FOR_JOB_ID'](state, payload) {
    state.candidateListForJobID = payload;
  },
  ['SET_JOB_CANDIDATE_PAGINATION'](state, data) {
    state.jobCandidatePagination = { ...state.jobCandidatePagination, ...data }
  },
  ['SET_SUB_STATUS_OPTIONS'](state, data) {
    state.sub_status_options = data;
  },
  ['RESET_CANDIDATE_LIST_FOR_JOB_ID'](state, data) {
    state.candidateListForJobID = [];
  },
  ['SET_REASONS_OPTIONS'](state, payload) {
    state.tabReasons = payload;
  },
  ['RESET_REASONS_OPTIONS'](state) {
    state.tabReasons = [];
  },
  ['RESET_SUB_STATUS_OPTIONS'](state) {
    state.sub_status_options = [];
  },
  ['SET_CANDIDATE_DETAILS_FOR_JOB_ID'](state, payload) {
    state.candidateDetailsForJobID = payload;
  },
  ['RESET_CANDIDATE_DETAILS_FOR_JOB_ID'](state) {
    state.candidateDetailsForJobID = null
  },
  ['SET_CONTACT_METHODS'](state, payload) {
    state.contactMethods = payload;
  },
  ['SET_FIELDS_IN_ADVERT'](state, payload) {
    state.fieldsInAdvert = payload;
  },
  ['SET_SECTION_DISPLAY_NAMES'](state, payload) {
    state.sectionDisplayNames = payload;
  },
  ['SET_SECTION_TEMPLATE_BY_DISPLAY_NAMES'](state, payload) {
    const { display_name, data } = payload;
    state.sectionTemplates[`${display_name}`] = data;
  },
  ['SET_JOB_COMMENTS'](state, payload) {
    state.jobComments = payload;
  },
  ['RESET_JOB_COMMENTS'](state) {
    state.jobComments = [];
  },
  ["SET_SHORTLIST_INPROGRESS_JOBS"](state, payload) {
    state.shortListInProgressJobs = payload;
  },
  ["RESET_SHORTLIST_INPROGRESS_JOBS"](state) {
    state.shortListInProgressJobs = [];
  },
  ['SET_SHORTLIST_INPROGRESS_JOB_PAGINATION'](state, data) {
    state.shortListInProgressJobPagination = { ...state.shortListInProgressJobPagination, ...data }
  },
  ['SET_SHORLIST_INPROGRESS_DISPLAY_STATUS_SUB_STATUS'](state, payload) {
    state.shortListInProgressStatusSubStatus = payload;
  },
  ['SET_CONTACT_USERS_FOR_SELECTED_JOB'](state, payload) {
    state.contactUserForSelectedJob = payload;
  },
  ['JOB_COMMENTS'](state, payload) {
    state.jobCommonComments = payload;
  },
  ['SET_ENUM_LABELS_DATA'](state, payload) {
    let { enum_name, data } = payload;
    state.enumLabelData[enum_name] = data;
  },
  ['SET_ORGANISATION_CONTACTS'](state, payload) {
    state.orgContacts = payload
  },
  ['SET_CANDIDATE_WITHDRAW_JOBS'](state, payload) {
    state.getCandidateWithdrawJobs = payload;
  },
  ['WITHDRAW_JOB_COUNT'](state, payload) {
    state.getWithdrawJobsCount = payload
  },
};

const actions = {
  fetchAllJobs: ({ state, commit, rootState, dispatch, getters }) => {
    return job
      .getAllJobs()
      .then(async (res) => {
        commit('SET_All_JOBS', res.data);
      })
      .catch((err) => {
        console.log('error fetching organization id', err);
        return err;
      });
  },
  fetchJobCommonComments: ({ commit }, job_uuid) => {
    return job
      .getJobComments(job_uuid)
      .then((res) => {
        commit('JOB_COMMENTS', res.data)
        return res.data
      })
      .catch((err) => {
        console.log('error fetching Matched jobs', err);
        return err;
      });
  },
  postJobComments: ({ dispatch, getters }, payload) => {
    let { job_id, job_uuid, comments } = payload;
    return job
      .createJobComment(job_uuid, { comments: comments })
      .then((res) => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Comment Added successfully'
        });
        return res;
      })
      .catch((err) => {
        console.log('error fetching Matched jobs', err);
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Error While Adding'
        });
        return err;
      });
  },
  deleteJobComment: ({ dispatch }, payload) => {
    const { job_id, job_uuid, comment_id } = payload;
    return job
      .deleteJobComment(job_uuid, comment_id)
      .then((res) => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Comment Deleted successfully'
        });
        return res;
      })
      .catch((err) => {
        console.log('error fetching Matched jobs', err);
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Error While Deleting'
        });
        return err;
      });
  },
  fetchCandidatePreferredJobs: ({ commit, getters }, preferred_type) => {
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    let query = `preferred_type=${preferred_type}`
    return job
      .getCandidateAppliedOrFavouriteJobs({ customer_id, candidate_id, query })
      .then((res) => {
        if (preferred_type === "favourite") {
          commit('SET_CANDIDATE_FAV_JOBS', res.data)
        }
        return res.data
      })
      .catch((err) => {
        console.log('error fetching preferred jobs', err);
        return err;
      });
  },
  fetchCandidateAppliedJobs: ({ commit, getters }) => {
    let query = "applied=true"
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    return job
      .getCandidateAppliedOrFavouriteJobs({ customer_id, candidate_id, query })
      .then((res) => {
        commit('SET_CANDIDATE_APPLIED_JOBS', res.data);
        return res.data
      })
      .catch((err) => {
        console.log('error fetching preferred jobs', err);
        return err;
      });
  },
  fetchAppliedJobCount: ({ commit, getters }) => {
    const { getCandidateId } = getters
    return jobBoard
      .candidateAppliedJobsCount({ candidate_id: getCandidateId })
      .then(res => {
        commit("APPLIED_JOB_COUNT", res.data)
        return res.data
      })
      .catch(err => {
        console.error("error in getting job count", err);
        return err;
      })
  },
  fetchFavoJobCount: ({ commit, getters }) => {
    const { getCandidateId, getCustomerId } = getters
    let query = "preferred_type=favourite&applied=false"
    return jobBoard
      .getPreferredJobCount({ query, candidate_id: getCandidateId, customer_id: getCustomerId })
      .then(res => {
        commit("FAVO_JOB_COUNT", res.data)
        return res.data
      })
      .catch(err => {
        console.error("error in getting job count", err);
        return err;
      });
  },
  fetchMatchedJobCount: ({ commit, getters }) => {
    const { getCandidateId } = getters
    return jobBoard
      .getMatchedJobCount({ candidate_id: getCandidateId })
      .then(res => {
        commit("MATCHED_JOB_COUNT", res.data)
        return res.data
      })
      .catch(err => {
        console.error("error in getting job count", err);
        return err;
      })
  },
  fetchJobTotalCount: ({ commit, getters }) => {
    let { getImpersonationStatus, getIsChildUser, getOrgIDFromAccessToken: org_id, getRoleScopes, getUser } = getters;
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    let user_org_id = getUser?.org_detail?.org_id ? getUser.org_detail.org_id : null;
    let orgId = null;
    if (getIsChildUser || getImpersonationStatus?.impersonation) orgId = org_id;
    else if (user_org_id) orgId = user_org_id;

    return customer
      .getJobTotalCount(orgId, role)
      .then(async (res) => {
        commit('SET_JOB_TOTAL_COUNT', res.data);
      })
      .catch((err) => {
        console.log('error fetching customer id', err);
        return err;
      });
  },
  fetchOrganizationId: (
    { state, commit, rootState, dispatch, getters },
    { customer_uid, organisation_id }
  ) => {
    let query = organisation_id ? `organisation_id=${organisation_id}` : "";
    return job
      .getOrganizationDetails({ customer_uid, query })
      .then(async (res) => {
        commit('SET_ORGANIZATION_ID', res.data);
        return res;
      })
      .catch((err) => {
        console.log('error fetching organization id', err);
        return err;
      });
  },
  fetchJobByIDWithInitialOptions: ({ commit, dispatch }, job_uuid) => {
    return job.getJobByID(job_uuid)
      .then(async (res) => {
        let jobData = res.data
        let appendFilterAction = [];
        // appendFilterAction = [...appendFilterAction, dispatch("fetchOrganisationRegions", jobData.organisation?.organisation_id)]
        // if (jobData?.qualification_region_ids) {
        //   appendFilterAction = [...appendFilterAction, dispatch("fetchQualificationCountriesByRegion", jobData.qualification_region_ids)];
        // }
        appendFilterAction = [...appendFilterAction, dispatch("fetchOrgContacts", { customer_id: jobData?.customer_uid, organisation_id: jobData.organisation_uid })];
        appendFilterAction = [...appendFilterAction, dispatch("fetchContactUserForSelectedJob", { customer_uid: jobData?.customer_uid, organisation_uid: jobData.organisation_uid })];
        return Promise.all(appendFilterAction).then(res => {
          commit('SET_JOB_BY_ID', jobData);
          commit('SET_JOB_ID', jobData.job_id);
          return res;
        });
      })
      .catch((err) => {
        console.log('error fetching Job by id', err);
        return err;
      });
  },
  fetchJobByID: ({ commit, dispatch, state }, job_uuid) => {
    return job.getJobByID(job_uuid)
      .then(async (res) => {
        commit('SET_JOB_BY_ID', res.data);
        commit('SET_JOB_ID', res.data.job_id);
        return res;
      })
      .catch((err) => {
        console.log('error fetching Job by id', err);
        return err;
      });
  },
  resetJobByID: ({ commit, dispatch }) => {
    commit('SET_JOB_BY_ID', []);
    commit('SET_JOB_ID', []);
  },
  getQualificationsPQR: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { candidate_type_id, level_id } = payload;
    return qualification
      .getQualificationsPQRType(candidate_type_id, level_id, 182, 7)
      .then(({ data }) => {
        commit('SET_QUALIFICATIONS_PQR', data);
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  fetchOrgJobBenefits: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { customer_uid, organisation_uid, candidate_type_id, benefit_types } = payload;
    return (
      job.benefits(customer_uid, organisation_uid, candidate_type_id, benefit_types)
        .then((res) => {
          commit('SET_BENEFITS', res.data);
          return res;
        })
        .catch((err) => {
          console.error(err);
          return err;
        })
    );
  },
  getJobLevelsById: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    if (payload) {
      const { job_id } = payload;
      return job
        .getJobLevels(job_id)
        .then(async (res) => {
          const { data } = res;
          commit('SET_JOB_LEVELS', data);
          return data;
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    }
  },
  fetchOrgJobDetails: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { organisation_id } = payload;
    return job
      .getOrganisationJobDetails(organisation_id)
      .then(async (res) => {
        const { data } = res;
        commit('SET_ORG_JOB_DETAILS', data);
        return data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  addJobs: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { organisation, ...rest } = payload;
    dispatch('showToast', { message: 'Creating job...' });
    return job.createJobs({ ...rest }, organisation)
      .then((res) => {
        const { job_uuid } = res.data
        router.push({ path: '/add-job', query: { id: job_uuid, step: 0 } });
        return res
      })
      .catch((err) => {
        console.log(err)
        let msg = 'Error while creating job';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        return err;
      });
  },
  createJobSpeciality: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { job_id, customer_uid, ...rest } = payload
    return job
      .createJobSpeciality(job_id, customer_uid, rest).then(res => {
        return res.data
      }).catch((err) => {
        let msg = 'Error while saving speciality';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        console.log(err)
      })
  },
  updateJobSpeciality: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { job_id, customer_uid, ...rest } = payload
    return job
      .updateJobSpeciality(job_id, customer_uid, rest).then(res => {
        return res.data
      }).catch((err) => {
        let msg = 'Error while saving speciality';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        console.log(err)
      })
  },
  createJobs: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { organisation, ...rest } = payload;
    dispatch('showToast', { message: 'Saving...' });
    return job
      .createJobs(rest, organisation)
      .then(async (res) => {
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Details saved successfully',
        });
        commit('SET_CREATE_JOBS', res.data);
        router.push('/jobs-list');
      })
      .catch((err) => {
        let msg = 'Error while saving';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        return err;
      });
  },

  updateJobs: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { organisation, customer_uid, ...rest } = payload;
    dispatch('showToast', { message: 'Saving...' });
    return job
      .updateJobs(rest, customer_uid, organisation)
      .then(async (res) => {
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Details updated successfully',
        });
        commit('SET_CREATE_JOBS', res.data);
        router.push(`/jobs-list?id=${res.data.job_id}`);
        return res;
      })
      .catch((err) => {
        let msg = 'Error while saving';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        return err;
      });
  },

  updateJobByDetailPage: ({ commit, dispatch, getters }, payload) => {
    const {
      customer_uid,
      organisation_id,
      ...rest
    } = payload
    return job.updateJobs(rest, customer_uid, organisation_id).then(res => {
      const { data } = res;
      dispatch("showToast", {
        class: 'bg-success text-white',
        message: rest.published ? 'Published successfully' : 'Updated successfully'
      });
      commit("SET_UPDATE_JOBS", data);
      commit("UPDATE_JOB_BOARD_LIST_BY_JOB_ID", data)
      return res
    })
      .catch(err => {
        console.log(err)
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: "Error while saving"
        })
        return err;
      });
  },

  updateJobSteps: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const {
      organisation,
      customer_uid,
      working_days,
      working_hours,
      call_on,
      ...rest
    } = payload;
    dispatch('showToast', { message: 'Updating...' });
    return job
      .updateJobs(rest, customer_uid, organisation)
      .then(async (res) => {
        const {
          data,
          data: { job_id },
        } = res;
        commit('SET_CREATE_JOBS', data);
        commit('SET_JOB_BY_ID', data);
        commit('SET_JOB_ID', job_id);
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Updated successfully',
        });
        return res;
      })
      .catch((err) => {
        let msg = 'Error while saving';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        return err;
      });
  },

  createBenefit: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { customer_uid, job_id, ...rest } = payload;
    return job
      .createJobBenefit(job_id, customer_uid, rest)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        let msg = 'Error while saving';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        return err;
      });
  },
  updateBenefit: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const {
      customer_uid,
      job_id,
      job_benefit_id,
      ...rest } = payload;
    return job
      .updateJobBenefit(job_id, customer_uid, job_benefit_id, rest).then((res) => {
        return res;
      })
      .catch((err) => {
        let msg = 'Error while saving';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        return err;
      });
  },
  deleteBenefitWithoutToast: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { customer_uid, job_id, job_benefit_id } = payload;
    return job
      .deleteJobBenefit(job_id, customer_uid, job_benefit_id).then((res) => {
        return res;
      })
      .catch((err) => {
        let msg = 'Error while deleting Benifit';
        return err;
      });
  },
  fetchAllQualificationCountries: ({ state, commit, rootState, dispatch, getters }) => {
    return qualification
      .getAllQualificationCountries()
      .then(async ({ data }) => {
        await commit("SET_QUALIFICATION_COUNTRIES", data);
        return data;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error in fetching Qualification Countries",
        });
        return err;
      });
  },
  fetchQualificationRegions: ({ state, commit, rootState, dispatch, getters }) => {
    return qualification
      .getQualificationRegions()
      .then(async ({ data }) => {
        await commit("SET_QUALIFICATION_REGIONS", data);
        return data;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error in fetching Qualification Regions",
        });
        return err;
      });
  },
  fetchOrganisationRegions: ({ state, commit, rootState, dispatch, getters }, organisation_id) => {
    return job
      .fetchOrganisationRegions(organisation_id)
      .then(async ({ data }) => {
        await commit("SET_ORGANISATION_REGIONS", data);
        return data;
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error in fetching Organisation Regions",
        });
        return err;
      });
  },
  fetchQualificationCountriesByRegion: ({ state, commit, rootState, dispatch, getters }, payload) => {
    if (payload && payload.length) {
      return qualification.getQualificationCountries(payload)
        .then(({ data }) => {
          commit("SET_QUALIFICATION_COUNTRIES_BY_REGION", data);
          return data;
        })
        .catch((err) => {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error in fetching Qualification Countries",
          });
          return err;
        });
    }
    else {
      commit("SET_QUALIFICATION_COUNTRIES_BY_REGION", []);
    }
  },
  createJobCountry: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { customer_uid, job_id, country } = payload;
    return job
      .createJobNationality(job_id, customer_uid, {
        include_country_id: country.code,
        nationality: true,
        additional_info: country.label,
        birthplace: false,
        current_location: false,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  deleteJobCountryById: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { job_id, customer_uid, job_country_include_id } = payload;
    return job
      .deleteJobCountry(job_id, customer_uid, job_country_include_id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  createJobLevel: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { fetchJobByID } = getters;
    const { job_id, level } = payload;
    return job
      .createJobLevel(job_id, { level_id: level.code, comments: level.label })
      .then(async (res) => {
        await dispatch('fetchJobByID', job_id).then((final_res) => {
          const { data } = final_res;
          return res;
        });
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  createAdditionalInfo: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { job_id, val } = payload;
    const {
      comments,
      description,
      detail_number,
      detail_text,
      organisation_job_detail_id,
    } = val;
    return job.createJobDetails(job_id, {
      organisation_job_detail_id: organisation_job_detail_id,
      detail_text,
      detail_number,
      description,
      comments,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });


  },
  updateAdditionalInfo: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { job_id, val } = payload;
    const {
      comments,
      description,
      detail_number,
      detail_text,
      job_detail_id,
      organisation_job_detail_id
    } = val;
    return job.updateJobAdditionalInfo(job_id, job_detail_id, {
      job_detail_id,
      organisation_job_detail_id,
      detail_text,
      detail_number,
      description,
      comments,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  deleteAdditionalInfo: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { job_id, job_detail_id } = payload;
    return job.deleteJobDetails(job_id, job_detail_id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },

  fetchSubStatusOptions({ state, commit, dispatch, getters }, payload) {
    const { action_id, filter_id, tab_id } = payload;
    let queryPayload = { user_action: 'update' };
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    let finalPayload = { tab_id, filter_id, action_id, queryString }
    commit("RESET_SUB_STATUS_OPTIONS");
    commit("SET_FETCHING", true);
    return job
      .subStatusOptions(finalPayload)
      .then(res => {
        let { data } = res;
        commit("SET_SUB_STATUS_OPTIONS", data);
        return res;
      })
      .catch(err => {
        console.log('Error fetch Substatus', err)
        return err
      })
      .finally(res => {
        commit("SET_FETCHING", false);
      })
  },
  fetchJobCandidateReasonCode({ state, commit, dispatch, getters }, payload) {
    commit("RESET_REASONS_OPTIONS");
    const { sub_status_id, filter_id, action_id, tab_id } = payload;
    let queryPayload = { user_action: 'update' };
    if (sub_status_id)
      queryPayload['sub_status_id'] = sub_status_id;
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    let finalPayload = { tab_id, filter_id, action_id, queryString }
    return job
      .reasonOptions(finalPayload)
      .then(res => {
        let { data } = res;
        commit("SET_REASONS_OPTIONS", data);
        return res;
      })
      .catch(err => {
        console.log('Error fetch reasons', err)
        return err;
      })
  },
  updateJobCandidateStatus({ state, commit, dispatch, getters }, payload) {
    const {
      payloadData: {
        job_id,
        candidate_uid,
        payload: {
          reason_id,
          comments,
          expiry_date,
          priority_level,
        }
      },
      updateStatusSubStatus
    } = payload;
    const { getCandidateListForJobID } = getters
    commit("RESET_CANDIDATE_DETAILS_FOR_JOB_ID");
    dispatch("clearAllToast");
    let queryPayload = {};
    if (reason_id)
      queryPayload['reason_id'] = reason_id;
    if (comments)
      queryPayload['comments'] = comments;
    if (expiry_date)
      queryPayload['expiry_date'] = expiry_date;
    if (priority_level)
      queryPayload['priority_level'] = priority_level;
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    return job
      .updateJobCandidateStatus({ job_id, candidate_uid, payload: updateStatusSubStatus, queryString })
      .then(res => {
        const { data } = res;
        dispatch("fetchCandidateListForJobIDNew", { pagination: false });
        let msg = `Candidate moved into ${data?.status?.display_text}`;
        if (data?.sub_status?.display_text)
          msg = `Candidate moved into  ${data?.status?.display_text} - ${data?.sub_status?.display_text}`;
        commit("SET_UPDATE_TOAST_VISIBLE", { isShow: true, msg, cssClass: 'bg-success text-white' });
        commit("SET_CANDIDATE_DETAILS_FOR_JOB_ID", res.data);
        return res.data;
      })
      .catch(err => {
        console.log(err);
        const msg = "Error while Updating Job Status"
        commit("SET_UPDATE_TOAST_VISIBLE", { isShow: true, msg, cssClass: "bg-danger text-white" });
        return err
      })
  },
  triggerJobCandidateMatch: async ({ state, commit, dispatch, getters }, { job_id, job_uuid }) => {
    const { getCustomerId } = getters;
    let customer_uid = getCustomerId;
    if (!customer_uid) {
      await dispatch("getCustomerIDByJobID", job_uuid);
      const { getSelectedJobCustomerID } = getters;
      customer_uid = getSelectedJobCustomerID;
    }
    dispatch('showToast', { message: "Refreshing..." });
    return job
      .jobCandidateMatch({ customer_uid, job_id })
      .then(res => {
        dispatch('showToast', { class: 'bg-success text-white', message: "Rrefreshed successfully" });
        return res;
      })
      .catch(err => {
        console.log('Error trigger JobCandidateMatch', err)
        dispatch('showToast', { class: 'bg-danger text-white', message: "Rrefresh failed" });
        return err
      })
  },
  initFetchConsultantInfoOptions({ state, commit, rootState, dispatch, getters }) {
    const {
      getCandidateLevel,
      getCandidateType,
      getSpeciality,
      getSubSpeciality,
      // getSpecialInterest
    } = getters;
    let appendAction = [];
    commit("SET_FETCHING", true);
    if (!getCandidateLevel.length) {
      appendAction = [...appendAction, dispatch("candidateLevel")];
    }
    if (!getCandidateType.length) {
      appendAction = [...appendAction, dispatch("candidateType")];
    }
    if (!getSpeciality.length) {
      appendAction = [...appendAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendAction = [...appendAction, dispatch("subSpeciality")];
    }
    // if (!getSpecialInterest.length) {
    //   appendAction = [...appendAction, dispatch("fetchSpecialInterestList")];
    // }
    Promise.all(appendAction).then(res => {
      commit("SET_FETCHING", false);
      return res
    });
  },
  initFetchPrefrencesOptions({ state, commit, rootState, dispatch, getters }, payload) {
    const { organisation_id, qualification_regions } = payload
    const { getLocationCountryInfo, getLocationLanguage, getQualificationRegions } = getters;
    let appendAction = [];
    commit("SET_FETCHING", true);
    // if (!getLocationCountryInfo.length) {
    //   appendAction = [...appendAction, dispatch("locationCountryInfo")];
    // }
    if (!getLocationLanguage.length) {
      appendAction = [...appendAction, dispatch("locationLanguage")];
    }
    // appendAction = [...appendAction, dispatch("fetchOrganisationRegions", organisation_id)];
    // appendAction = [...appendAction, dispatch("fetchQualificationCountriesByRegion", qualification_regions)];
    Promise.all(appendAction).then(res => {
      commit("SET_FETCHING", false);
      return res
    });
  },

  fetchCandidateListForJobIDNew({ state, commit, dispatch, getters }, payload) {
    const { pagination, isFavourite } = payload;
    const { getTabInfo: { tab_id }, getChildOrgWithCustomerQuery } = getters;
    dispatch("showLoader");
    if (!pagination) {
      commit("SET_JOB_CANDIDATE_PAGINATION", {
        skip: 0,
        noMoreCandidates: false
      });
      commit("RESET_CANDIDATE_LIST_FOR_JOB_ID");
      dispatch("initJobCandidateCount");
      if (tab_id) {
        if (isFavourite)
          dispatch("fetchFavouriteCount");
        else
          dispatch("fetchTabFilterCandidateCount");
      }
    }
    const { getJobDetailsById, getJobCandidatePagination: { limit, skip }, getCandidateListForJobID, getDisplayStatusSubStatus, getTabInfo: { filter_id } } = getters;
    if (getJobDetailsById !== null && getDisplayStatusSubStatus.length) {
      const { job_id } = getJobDetailsById;
      let queryPayload = { skip, limit };
      if ([1, 3].includes(filter_id)) {
        queryPayload['job_applied'] = filter_id === 1;
      }
      let queryString = getFilterQueryStringWithoutArray(queryPayload);
      queryString += `&${getChildOrgWithCustomerQuery}`
      return job
        .candidateListForJobID({ job_id, statusSubStatus: getDisplayStatusSubStatus, queryString })
        .then(res => {
          let { data } = res;
          if (!pagination) {
            commit('SET_CANDIDATE_LIST_FOR_JOB_ID', data);
            if (data.length) {
              let appendAction = dispatch("commitCandidateInfo", data[0].candidate);
              Promise.all([appendAction]).then(res => {
                dispatch("fetchspecialInterest");
                // dispatch("fetchLicense");
                dispatch("fetchCandidateLanguage");
                dispatch("fetchUploadedDocument");
              })
            }
          } else {
            commit('SET_CANDIDATE_LIST_FOR_JOB_ID', getCandidateListForJobID.concat(data));
          }
          if (data.length < limit) {
            commit("SET_JOB_CANDIDATE_PAGINATION", {
              noMoreCandidates: true
            });
          } else {
            commit("SET_JOB_CANDIDATE_PAGINATION", {
              skip: skip + limit,
              noMoreCandidates: false
            });
          }
          return data;
        })
        .catch(err => {
          console.log('Error while Job candidate Fetch', err);
          return err;
        })
        .finally(res => {
          dispatch('hideLoader')
        })
    } else {
      dispatch('hideLoader')
    }
    return
  },
  initUpdateCandidateStatus({ state, commit, dispatch, getters }, payloadData) {
    const {
      payload: {
        status_id,
        sub_status_id,
        filter_id,
        action_id,
        tab_id,
        current_status,
        current_sub_status,
      },
      job_id,
      candidate_uid,
    } = payloadData;
    let queryPayload = { user_action: 'update' };
    if (job_id)
      queryPayload['job_id'] = job_id;
    if (candidate_uid)
      queryPayload['candidate_uid'] = candidate_uid;
    if (sub_status_id)
      queryPayload['sub_status_id'] = sub_status_id;
    if (current_status)
      queryPayload['current_status'] = current_status;
    if (current_sub_status)
      queryPayload['current_sub_status'] = current_sub_status;
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    let finalPayload = { tab_id, filter_id, action_id, queryString }
    return job
      .getUpdateStatusSubStatus(finalPayload)
      .then(async res => {
        let { data } = res;
        await dispatch("updateJobCandidateStatus", { payloadData, updateStatusSubStatus: data[0] });
        return res;
      })
      .catch(err => {
        console.log('Error fetch reasons', err)
        return err;
      })
  },
  addJobCandidateComment({ getters, dispatch }, payload) {
    const { job_id, candidate_id, comments, contact_method, message_id } = payload;
    let queryPayload = { comments };
    if (contact_method)
      queryPayload['contact_method'] = contact_method;
    if (message_id)
      queryPayload['message_id'] = message_id;
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    dispatch('showToast', { message: "Adding..." });
    return job
      .addJobCandidateComment({ job_id, candidate_id, queryString })
      .then(res => {
        let { data } = res;
        dispatch('showToast', { class: 'bg-success text-white', message: "Added successfully" });
        return data;
      })
      .catch(err => {
        console.log("Error while adding Comment", err);
        dispatch('showToast', { class: 'bg-danger text-white', message: "Error while Adding" });
        return err;
      })
  },
  fetchContactMethods({ commit }) {
    return job.getContactMethods().then(res => {
      commit("SET_CONTACT_METHODS", res.data);
      return res;
    })
      .catch(err => {
        console.log('Error while fetch contact methods', err);
        return err;
      })
  },
  fetchFieldsInAdvert({ commit }) {
    return job.getFieldsInAdvert().then(res => {
      commit("SET_FIELDS_IN_ADVERT", res.data);
      return res;
    })
      .catch(err => {
        console.log('Error while fetching fields in advert', err);
        return err;
      })
  },
  fetchSectionDisplayNames({ commit }, payload) {
    commit("SET_SECTION_DISPLAY_NAMES", []);
    return job.fetchSectionDisplayNames(payload).then(res => {
      commit("SET_SECTION_DISPLAY_NAMES", res.data);
      return res;
    })
      .catch(err => {
        console.log('Error while fetching section template names', err);
        return err;
      })
  },
  fetchSectionByDisplayNames({ commit }, payload) {
    let { display_name, candidate_type_id, ...rest } = payload
    display_name = display_name.split(" ").join("%20")
    if (candidate_type_id == null) candidate_type_id = "null";
    let query = getFilterQueryStringWithoutArray({ candidate_type_id__in: candidate_type_id, display_name })
    return job.fetchSectionByDisplayNames({ query, ...rest }).then(res => {
      display_name = display_name.split("%20").join("_")
      commit("SET_SECTION_TEMPLATE_BY_DISPLAY_NAMES", ({ display_name: display_name, data: res.data }));
      return res;
    })
      .catch(err => {
        console.log('Error while fetching section template', err);
        return err;
      })
  },
  createJobDescSections({ dispatch }, payload) {
    let { customer_uid, job_id, sections } = payload
    return job.createJobDescSections(customer_uid, job_id, sections)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log('Error while creating job section', err);
        dispatch('showToast', { class: 'bg-danger text-white', message: "Error while Saving" });
        return err;
      })
  },
  deleteJobDescSectionByID({ dispatch }, payload) {
    return job.deleteJobDescSectionByID(payload)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log('Error while deleting job description section', err);
        dispatch('showToast', { class: 'bg-danger text-white', message: "Error while Saving" });
        return err;
      })
  },
  initShortListInprogessJobs({ getters, commit, dispatch }, candidate_uid) {
    commit("RESET_SHORTLIST_INPROGRESS_JOBS");
    commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", true);
    let appendAction = [tabFilters.getDisplayStatusSubStatusByTab({ tab_id: 3 })]
    appendAction = [...appendAction, tabFilters.getDisplayStatusSubStatusByTab({ tab_id: 4 })];
    return Promise.all(appendAction).then(res => {
      const display_status = _.reduce(res, function (result, { data }) {
        return result = [...result, ...data]
      }, [])
      commit("SET_SHORLIST_INPROGRESS_DISPLAY_STATUS_SUB_STATUS", display_status);
      dispatch("fetchShortListInprogessJobs", { pagination: false })
      return res
    }).finally(res => {
      return res
    })
  },
  fetchShortListInprogessJobs({ state, commit, dispatch, getters }, payload) {
    commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", true)
    const { pagination } = payload;
    if (!pagination) {
      commit("SET_SHORTLIST_INPROGRESS_JOB_PAGINATION", {
        skip: 0,
        noMoreJobs: false
      });
      commit("RESET_SHORTLIST_INPROGRESS_JOBS");
    }
    const {
      candidateProfile,
      getShortListInProgressJobPagination: { limit, skip },
      getShortListInProgressJobs,
      getDisplayStatusShortListInProgressJob: statusSubStatus,
      getChildOrgWithCustomerQuery
    } = getters;
    if (candidateProfile !== null && statusSubStatus.length) {
      const { candidate_uid } = candidateProfile;
      let queryPayload = { skip, limit };
      let queryString = getFilterQueryStringWithoutArray(queryPayload);
      queryString += `&${getChildOrgWithCustomerQuery}`
      return candidate
        .jobListForCandidateID({ candidate_uid, statusSubStatus, queryString })
        .then(res => {
          let { data } = res;
          if (!pagination) {
            commit('SET_SHORTLIST_INPROGRESS_JOBS', data);
          } else
            commit('SET_SHORTLIST_INPROGRESS_JOBS', [...getShortListInProgressJobs, ...data]);
          if (data.length < limit) {
            commit("SET_SHORTLIST_INPROGRESS_JOB_PAGINATION", {
              noMoreJobs: true
            });
          } else {
            commit("SET_SHORTLIST_INPROGRESS_JOB_PAGINATION", {
              skip: skip + limit,
              noMoreJobs: false
            });
          }
          return data;
        })
        .catch(err => {
          console.log('Error while Fetch ShortList Inprogress Jobs', err);
          return err;
        })
        .finally(res => {
          commit("IS_FETCHING_CANDIDATE_JOB_INPROCESS_LIST", false)
        })
    }
    return
  },
  fetchContactUserForSelectedJob: ({ commit }, payload) => {
    const { customer_uid, organisation_uid } = payload;
    return contactUser
      .fetchContactUser({ customer_uid, organisation_uid })
      .then(res => {
        const { data } = res;
        commit("SET_CONTACT_USERS_FOR_SELECTED_JOB", data);
        return res;
      })
      .catch(err => {
        console.log('Error while Fetch Contact User', err);
        return err;
      })
      .finally(res => {
        return res;
      })
  },
  onChangeActiveJobsStatus: ({ getters, dispatch, commit }, payload) => {
    const { getCustomerId } = getters;
    const { status, job_id, job_uuid } = payload;
    return job
      .onChangeJobStatus({ customer_uid: getCustomerId, job_uuid, status })
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Job updated successfully'
        });
        router.push("/jobs-list/status/active");
        commit("UPDATE_JOB_BOARD_BY_REMOVING_JOB", job_id);
        return res;
      })
      .catch(err => {
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Error while Saving'
        });
        console.log("Error While changing status job", err)
        return err;
      })
  },
  addPublicJobs: ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { customer_uid, organisation_uid, ...restPayload } = payload;
    return job.createPublicJobs(restPayload, customer_uid, organisation_uid)
      .then((res) => {
        const { job_id } = res.data
        return res
      })
      .catch((err) => {
        console.log(err)
        let msg = 'Error while creating job';
        dispatch('showToast', { class: 'bg-danger text-white', message: msg });
        return err;
      });
  },
  fetchPublicJobByID: ({ commit, dispatch, state }, payload) => {
    const { customer_uid } = payload;
    return job.getPublicJobByID(customer_uid, payload)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('error fetching Job by id', err);
        return err;
      });
  },
  fetchEnumLabels({ commit, getters }, enum_name) {
    const { getOrgIDFromAccessToken, getCustomerId } = getters;
    commit("SET_ENUM_LABELS_DATA", { enum_name, data: [] });
    let query = `customer_id=${getCustomerId}${getOrgIDFromAccessToken ? `&organisation_id=${getOrgIDFromAccessToken}` : ''}`;
    return job.fetchEnumLabels({ enum_name, query })
      .then(res => {
        let { data } = res
        commit("SET_ENUM_LABELS_DATA", { enum_name, data });
        return res;
      })
      .catch(err => {
        console.log('error while fetchEnumLabels', err);
        return err;
      })
  },
  fetchOrgContacts({ commit }, payload) {
    return job.fetchOrgContacts(payload).then(res => {
      const { data } = res
      commit("SET_ORGANISATION_CONTACTS", data)
      return res;
    }).catch(err => {
      console.log("Error while fetching organisation contacts", err)
      return err;
    })
  },
  fetchCandidateWithdrawJobs: ({ commit, getters }) => {
    const { getCandidateId: candidate_id, getCustomerId: customer_id } = getters;
    return job
      .getCandidateWithdrawJobs({ customer_id, candidate_id })
      .then((res) => {
        commit('SET_CANDIDATE_WITHDRAW_JOBS', res.data);
        return res.data
      })
      .catch((err) => {
        console.log('error fetching withdraw jobs', err);
        return err;
      });
  },
  fetchWithdrawJobCount: ({ commit, getters }) => {
    const { getCandidateId, getCustomerId } = getters;
    return jobBoard
      .candidateWithdrawJobsCount({ candidate_id: getCandidateId, customer_id: getCustomerId })
      .then(res => {
        commit("WITHDRAW_JOB_COUNT", res.data)
        return res.data
      })
      .catch((err) => {
        console.log('error fetching withdraw jobs count', err);
        return err;
      });
  },
};

export default { state, getters, mutations, actions };
