import axios from "axios";
import { BASE_URL } from "@/service_urls";
import { getCustomerId } from "@/helpers/helper";
import router from "@/router/index";
import m from "moment" ;

const jobBoard = {
  getJobBoard: ({ customer_id, skip, limit, page, role, query, candidate_id, organisation_id }) => {
    let url;
    let customURL;
    query = query ? `&${query}` : "";
    let jobQuery = "";
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      jobQuery = "&is_own_job__in=null,true"
    }
    if (page === "jobBoard" || page === "publicJobBoard") {
      const { params: { jobStatus } } = router?.currentRoute;
      if (
        customer_id &&
        [
          "system_recruiter",
          "customer_admin",
          "customer_recruiter"
        ].includes(role)
      ) {
        customURL = router.currentRoute.name == "JobListNew"
        ? `${BASE_URL}/customer/${customer_id}/job/cards?`
        : `${BASE_URL}/customer/${customer_id}/job/summary?`;
        // Active Jobs //Inactive Jobs
        if (jobStatus == "active") customURL += 'status_id__in=27,28,29&'
        else if (jobStatus == "inactive") customURL += 'status_id__in=30&'

        if (organisation_id) customURL += `organisation_uid=${organisation_id}&`;
        url = `${customURL}skip=${skip}&limit=${limit}${query}${jobQuery}`;
      } else if (role === "system_admin") {
        customURL = router.currentRoute.name == "JobListNew"
        ? `${BASE_URL}/job/cards?`
        : `${BASE_URL}/job/summary?`;
        // Active Jobs //Inactive Jobs
        if (jobStatus == "active") query += '&status_id__in=27,28,29'
        else if (jobStatus == "inactive") query += '&status_id__in=30'

        url = `${customURL}skip=${skip}&limit=${limit}${query}${jobQuery}`;
      } else if (role === "customer_candidate") {
        url = `${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/summary?skip=${skip}&limit=${limit}&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&order_by=-coalesce__last_modified_on__created_on${query}${jobQuery}`;
      } else {
        const { query: { id: qid }, params: { id: pid } } = router?.currentRoute;
        let customer_uid = qid || pid;
        url = `${BASE_URL}/job-board/public/summary?skip=${skip}&limit=${limit}&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&customer_uid=${customer_uid}${query}${jobQuery}`;
      }
    } else if (page === "jobList") {
      url = `${BASE_URL}/customer/${customer_id}/job/?include_marketplace=false&skip=${skip}&limit=${limit}${query}`;
    }
    return axios({
      method: 'GET',
      url: url
    });
  },
  fetchPublicAdvertDescForJobId: ({ job_uuid }) => {
    let url = `${BASE_URL}/job-board/public/advert_description?job_uuid=${job_uuid}`;
    return axios({
      method: 'GET',
      url: url
    });
  },
  fetchJobBenefitsAdvertDescByJobId: ({ customer_id, job_uuid }) => axios.get(`${BASE_URL}/customer/${customer_id}/job/${job_uuid}/benefits/details/advertdesc`),
  getJobDetail: ({ customer_id, job_uuid, path, role, candidate_id }) => {
    let url;
    if (path && path.includes("/public/job-board-detail")) {
      if (
        customer_id &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter"
        ].includes(role)
      ) {
        url = `${BASE_URL}/customer/${customer_id}/job/uuid/${job_uuid}/`;
      } else {
        url = `${BASE_URL}/job-board/public?job_uuid=${job_uuid}`;
      }
    } else {
      url = candidate_id ? `${BASE_URL}/customer/${customer_id}/job/uuid/${job_uuid}/?candidate_uid=${candidate_id}` :
        `${BASE_URL}/customer/${customer_id}/job/uuid/${job_uuid}/`
    }
    return axios.get(url);
  },
  fetchApplicationStatus: ({ candidate_uid, job_uuid }) => {
    let url = `${BASE_URL}/job/application/${job_uuid}/candidate/${candidate_uid}`;
    return axios.get(url);
  },
  applyJob: (candidate_id, job_id, payload) => {
    let url = `${BASE_URL}/job/application/${job_id}/candidate/${candidate_id}`;
    return axios.post(url, payload);
  },
  withdrawJob: (candidate_id, job_id) => {
    let url = `${BASE_URL}/job/application/${job_id}/candidate/${candidate_id}`;
    return axios.delete(url);
  },
  disapproveAppliedCandidate: (candidate_uid, job_id) => axios.put(`${BASE_URL}/job/application/disapproved?candidate_uid=${candidate_uid}&job_id=${job_id}`),
  getJobsCount: ({ query, role }) => {
    const { query: { id: qid }, params: { id: pid } } = router?.currentRoute;
    let customer_uid = qid || pid;
    let url = ""
    let subQuery = ""
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true"
    }
    if (["system_admin"].includes(role)) {
      const { params: { jobStatus } } = router?.currentRoute;
      // Active Jobs //Inactive Jobs
      if (jobStatus == "active") query += '&status_id__in=27,28,29'
      else if (jobStatus == "inactive") query += '&status_id__in=30'
      url = `${BASE_URL}/job/total_count?${query}${subQuery}`;
    } else {
      url = `${BASE_URL}/job-board/public/total_count?customer_uid=${customer_uid}&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&${query}${subQuery}`;
    }
    return axios.get(url);
  },
  getJobsCountFacility: (query, customer_id, org_id) => {
    const { params: { jobStatus } } = router?.currentRoute;
    let subQuery = ""
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true"
    }
    // Active Jobs //Inactive Jobs
    if (jobStatus == "active") query += '&status_id__in=27,28,29'
    else if (jobStatus == "inactive") query += '&status_id__in=30'

    let url = `${BASE_URL}/customer/${customer_id}/job/total_count`;
    if (org_id) {
      query = query + `&organisation_uid=${org_id}`;
    }
    if (query) {
      query = query + `&${subQuery}`
    } else {
      query = `${subQuery}`
    }
    return axios.get(`${url}?${query}`);
  },
  updateJob: (payload, id, customer_id) =>
    axios.put(
      `${BASE_URL}/customer/${customer_id}/job/organisation/${id}/`,
      payload
    ),
  addPreferredJob: ({ customer_id, candidate_id, payload }) => axios.post(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/preferred_job/`, payload),
  deletePreferredJob: ({ customer_id, candidate_id, preferred_id }) => axios.delete(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/preferred_job/candidate_preferred_job_id/${preferred_id}`),
  getPreferredJobCount: ({ customer_id, candidate_id, query }) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/preferred_job/count?${query}`),
  candidateAppliedJobsCount: ({ candidate_id }) => axios.get(`${BASE_URL}/job/application/candidate/${candidate_id}/total_count`),
  getMatchedJobCount: ({ candidate_id }) => axios.get(`${BASE_URL}/jobcandidate/match/candidate/${candidate_id}/count`),
  getJobsCountCandidate: ({ customer_id, candidate_id, query }) => {
    let subQuery = ""
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true"
    }
    if (query) {
      query = query + `&${subQuery}`
    } else {
      query = `${subQuery}`
    }
    return axios.get(`${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/total_count?date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&${query}`)
  },
  fetchAllJobsFilterList: ({ query }) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/job_id/job_title?status_id__in=28,29&${query}`),
  getJobAdvertisements: () => axios.get(`${BASE_URL}/job_advertisement/all_job_adverts`),
  uploadAdvert: ({ queryString, document }) => axios.post(`${BASE_URL}/job_advertisement/create_new_job_advertisement?${queryString}`, document),
  updateAdvert: ({ queryString, document, fileName, job_advertisement_id }) => {
    if (fileName)
      return axios.put(`${BASE_URL}/job_advertisement/update/job_advertisements/${job_advertisement_id}?${queryString}`, document)
    else
      return axios.put(`${BASE_URL}/job_advertisement/update/job_advertisements/${job_advertisement_id}?${queryString}`)
  },
  deleteJobAdvert: (job_advertisement_id) => axios.delete(`${BASE_URL}/job_advertisement/delete/job_advertisements/${job_advertisement_id}`),
  fetchAllJobsByShortlistProcess: ({ candidate_id, query }) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/candidate/${candidate_id}/job_title?status_id__in=28,29&${query}`),
  candidateWithdrawJobsCount: ({ candidate_id, customer_id }) => axios.get(`${BASE_URL}/job/application/withdrawal_jobs/total_count?customer_id=${customer_id}&candidate_uid=${candidate_id}`),
};

export default jobBoard;
