import login from "@/services/login";
import customer from "@/services/customer";
import router from "../router/index";

import {
  parseJwt,
  pendoInit,
  Role,
  CANDIDATE_URL,
  FACILITY_CANDIDATE_URL,
  LOGIN_URL,
} from "@/helpers/helper";

const state = {
  isLoading: false,
  userData: JSON.parse(localStorage.getItem("userData")) || "",
  error: "",
  user: [],
  autoGenPassword: null,
};

// create getters for all state variables
const getters = {
  isLoginFetching: (state, getters, rootState) => state.isLoading,
  getUserData: (state, getters, rootState) => state.userData || {},
  getUserFullName: (state, getters, rootState) => state.userFullName,
  getUserDetails: (state, getters, rootState) => {
    let { getUserData } = getters;
    if (getUserData && getUserData.access_token) {
      return parseJwt(getUserData.access_token);
    } else {
      return {};
    }
  },
  getUserId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails.user_id;
  },
  getCustomerId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails.customer_id;
  },
  getCandidateId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails.candidate_id;
  },
  getRoleScopes(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails && getUserDetails.scopes ? getUserDetails.scopes : [];
  },
  getRole(state, getters, rootState) {
    let { getRoleScopes } = getters;
    return getRoleScopes.length ? getRoleScopes[0] : "";
  },
  getUser: (state, getters, rootState) => state.user,
  getImpersonationStatus(state, getters, rootState) {
    const { getUserDetails } = getters;
    return {
      impersonation: getUserDetails?.impersonating || "",
      customer_name: getUserDetails?.customer_name || "",
      customer_id: getUserDetails?.customer_id || "",
    };
  },
  getCandidateListUrlBasedOnRole(state, getters, rootState) {
    const { customerAdmin } = Role;
    const { getRole } = getters;
    if (customerAdmin === getRole) {
      return FACILITY_CANDIDATE_URL;
    }
    return CANDIDATE_URL;
  },
  getOrgID(state, getters, rootstate) {
    let { getUserDetails } = getters;
    return getUserDetails.organisation_id;
  },
  getIsParentUser: (state) => state.user?.org_detail?.is_parent || null,
  getIsChildUser: (state) => state.user?.org_detail?.is_child || null,
  getIsJobAuthoriser: (state) =>
    state.user && state.user.users_roles && state.user.users_roles.length
      ? state.user.users_roles.includes("job_authoriser")
      : false,
  getIsCandidateReviewer: (state) =>
    state.user && state.user.users_roles && state.user.users_roles.length
      ? state.user.users_roles.includes("candidate_reviewer")
      : false,
  customerUserRoles: (state) =>
    state.user && state.user.users_roles && state.user.users_roles.length
      ? state.user.users_roles.map((val) => ({
        code: val?.id,
        label: val?.display_name,
      }))
      : [],
  getOrgName: (state) => state.user?.org_detail?.org_name || null,
  getChildOrgWithCustomerQuery(state, getters, rootState) {
    const { getCustomerId, getIsChildUser, getOrgIDFromAccessToken: org_id } = getters;
    if (getCustomerId && getIsChildUser) {
      return `customer_id=${getCustomerId}&organisation_id=${org_id}`;
    } else if (getCustomerId) {
      return `customer_id=${getCustomerId}`;
    }
    return "";
  },
  getChildOrgQuery(state, getters, rootState) {
    const { getIsChildUser, getOrgIDFromAccessToken: org_id } = getters;
    return getIsChildUser ? `organisation_id=${org_id}` : "";
  },
  autoGenPassword: (state) => state.autoGenPassword,
  getAccessToken: (state, getters, rootState) => {
    let { getUserData } = getters;
    if (getUserData && getUserData.access_token) {
      return getUserData.access_token;
    } else {
      return null;
    }
  },
  getOrgIDFromAccessToken: (state, getters) => {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.org_id || getUserDetails?.organisation_id || null;
  },
  accessibleOrgs: (state, getters) => {
    const { getUserDetails } = getters;
    return getUserDetails?.accessible_orgs || [];
  }

};

// actions
const actions = {
  login: async (
    { state, commit, rootState, dispatch, getters },
    { username, password }
  ) => {
    const { customerAdmin, customerRecruiter } = Role;
    localStorage.removeItem("userData");
    commit("LOGIN");
    let payload = new FormData();
    payload.append("username", username);
    payload.append("password", password);
    return login
      .loginAccessToken(payload)
      .then(async (res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Login successful",
        });
        let { data } = res;
        localStorage.setItem("userData", JSON.stringify(data));
        commit("LOGIN_SUCCESS", data);
        let { scopes, user_id, user_uuid, customer_uuid, email, accessible_orgs } = parseJwt(
          data.access_token
        );
        const user_role = scopes && scopes.length ? scopes[0] : "";
        const payload = { user_id, user_role, user_uuid, customer_uuid, email };
        // pendoInit(payload);
        await dispatch("fetchUser");
        if (accessible_orgs.length > 1 && user_role === "customer_recruiter") {
          return res;
        }
        if (
          user_role === "customer_candidate" ||
          user_role === "system_candidate"
        ) {
          router.push("/home");
        } else if (user_role === "customer_user") {
          router.push("/list-activity");
        } else {
          router.push(
            [customerAdmin, customerRecruiter].includes(user_role)
              ? "/recruiter-dashboard"
              : "/dashboard"
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: err.response.data.detail,
          });
        }
        console.error("error in login", err);
        commit("LOGINERROR", err);
        return err;
      });
  },

  forgot: async (
    { state, commit, rootState, dispatch, getters },
    { email }
  ) => {
    return login
      .forgotPassword(email)
      .then(() => { 
        dispatch("showToast", {
          message:
            "if you are registered in the system, you will get an email to reset your password.",
        });
        router.push(LOGIN_URL());
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: err?.response?.data?.detail});
        }
        router.push(LOGIN_URL());
        return err;
      })
  },
  reset: async ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { confirmPassword, ...rest } = payload;
    if (confirmPassword != rest.new_password) {
      dispatch("showToast", { message: "Passwords does not match!" });
      return;
    } else {
      dispatch("showToast", { message: "Resetting password" });
      return login
        .resetPassword(rest)
        .then(async (res) => {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Password Reset Successfully!",
          });
          router.push(LOGIN_URL());
        })
        .catch((err) => {
          console.error("error in reset", err);
          return err;
        });
    }
  },
  unsubscribe: async (
    { state, commit, rootState, dispatch, getters },
    { email }
  ) => {
    return login
      .unsubscribe(email)
      .then(() => { })
      .catch((err) => {
        console.error("error in unsubscribe", err);
        return err;
      })
      .finally(() => {
        dispatch("showToast", {
          message: "You are unsubscribed from the system",
        });
        router.push(LOGIN_URL());
      });
  },
  logout({ state, dispatch, commit, getters }) {
    const { sse } = getters;
    localStorage.removeItem("userData");
    commit("LOGOUT");
    dispatch("resetCandidate");
    dispatch("resetJobList");
    dispatch("resetCandidateList");
    dispatch("resetEmployeeList");
    dispatch("resetApplicationStatus");
    setTimeout(() => {
      router.push(LOGIN_URL());
    }, 0);
    if (sse) sse.close();
  },
  redirectToReset({ state, commit }, token) {
    router.push(`/auth/reset-password?token=${token}`);
  },
  refreshToken: async ({ state, commit, dispatch }) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    const { refresh_token } = userData;
    return login
      .refreshtoken(refresh_token)
      .then(async (res) => {
        let { data } = res;
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(data));
        commit("LOGIN_SUCCESS", data);
        return res;
      })
      .catch((err) => {
        console.log("error in refreshing token");
        commit("LOGINERROR", err);
        throw err;
      })
      .finally(() => {
        dispatch("hideLoader");
      });
  },
  startImpersonation({ state, commit, dispatch, getters }, payload) {
    let { organisation_id, ...rest } = payload;
    return customer
      .startImpersonation(organisation_id, rest)
      .then(async (res) => {
        if (res.status == 200) {
          let { data } = res;
          localStorage.removeItem("userData");
          localStorage.setItem("userData", JSON.stringify(data));
          commit("IMPERSONATION_SUCCESS", data);
          dispatch("fetchUser");
          dispatch("getTotalCandidates");
          dispatch("clearAllSelectedFilters");
          router.push({
            path: FACILITY_CANDIDATE_URL,
          });
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Role Switched successfully",
          });
        }
      })
      .catch((err) => {
        if (err.response.status == 500) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error while Switching Role",
          });
        }
        console.log("error in Switch Role", err);
        return err;
      });
  },
  stopImpersonation({ state, commit, dispatch, getters }) {
    let query = {
      active: "[true,null]",
      deleted: "[false]",
    };
    return customer
      .stopImpersonation()
      .then(async (res) => {
        if (res.status == 200) {
          let { data } = res;
          localStorage.removeItem("userData");
          localStorage.setItem("userData", JSON.stringify(data));
          commit("IMPERSONATION_SUCCESS", data);
          dispatch("fetchUser");
          dispatch("getTotalCandidates");
          dispatch("clearAllSelectedFilters");
          router
            .push({
              path: CANDIDATE_URL,
            })
            .then(() => {
              dispatch("updateSelectedFilterFromFilterCode", query);
            });

          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Role Switched successfully",
          });
        }
      })
      .catch((err) => {
        if (err.response.status == 500) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error while Switching Role",
          });
        }
        console.log("error in Switch Role", err);
        return err;
      });
  },
  fetchUser({ state, commit, rootState, dispatch, getters }) {
    return customer
      .getUser()
      .then((res) => {
        let { data } = res;
        commit("SET_USER", data);
        const { getUser } = getters;
        const { full_name } = getUser;
        const { getRoleScopes } = getters;
        let rolecheck = getRoleScopes.length ? getRoleScopes[0] : "";

        if (
          rolecheck === "customer_candidate" ||
          rolecheck === "system_candidate"
        ) {
          return;
        } else if (rolecheck === "customer_user") {
          const { users_roles } = data;
          const currentUserType =
            users_roles && users_roles.length ? users_roles[0] : null;
          commit(
            "SET_CURRENT_USER_TYPE",
            currentUserType
              ? {
                code: currentUserType.id,
                label: currentUserType.display_name,
              }
              : null
          );
          return;
        } else if (
          [Role.customerAdmin, Role.customerRecruiter].includes(rolecheck)
        ) {
          commit("SET_USER_FULL_NAME", full_name);
          dispatch("fetchNewNotificationEmail");
          dispatch("fetchUnreadEmailMessages");
        }
        dispatch("fetchUserUploadedDocument");
        return res;
      })
      .catch((err) => {
        console.error("error fetching user", err);
        return err;
      });
  },
  fetchAutoGenPassword({ commit }) {
    return login
      .getAutoGenPassword()
      .then((res) => {
        let password = res?.data?.password;
        commit("SET_AUTO_GEN_PASSWORD", password);
      })
      .catch((err) => {
        console.log("Failed to Fetch Auto Generated Password", err);
      });
  },
  fetchOrganisationAccessToken({ dispatch, getters, commit }, organisation_id) {
    return login
      .organisationAccessToken(organisation_id)
      .then(async res => {
        const { data } = res;
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(data));
        commit("IMPERSONATION_SUCCESS", data);
        await dispatch("fetchUser");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Successfully switched facility"
        });
        return res;
      })
      .catch(err => {
        const message = "Error while switch facility";
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        })
        console.log(message, err);
        return err;
      })
  }
};

// mutations
const mutations = {
  ["LOGINERROR"](state, error) {
    state.error = error;
    state.isLoading = false;
    state.userData = "";
  },
  ["LOGIN_SUCCESS"](state, payload) {
    state.error = false;
    state.isLoading = false;
    state.userData = payload;
  },
  ["LOGIN"](state, payload) {
    state.error = false;
    state.isLoading = true;
    state.userData = "";
  },
  ["LOGOUT"](state, payload) {
    state.error = false;
    state.isLoading = false;
    state.userData = "";
    state.user = [];
  },
  ["IMPERSONATION_SUCCESS"](state, payload) {
    state.userData = payload;
  },
  ["SET_USER"](state, payload) {
    state.user = payload;
  },
  ["SET_AUTO_GEN_PASSWORD"](state, password) {
    state.autoGenPassword = password;
  },
  ["SET_USER_FULL_NAME"](state, payload) {
    state.userFullName = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
