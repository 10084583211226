export default {
  // @WORKING ON GROUP FILTER - do not remove commented code

  // specialitySubSpeciality: {
  //   name: "specialitySubSpeciality",
  //   label: "Speciality/Sub-Speciality",
  //   component: "groupFilterSelect",
  //   schema: {
  //     SPASUBcountry: {
  //       name: "SPASUBcountry",
  //       component: "filterSelect",
  //       label: "Country",
  //       queryKey: "country_query_key",
  //       dependsOn: [],
  //       dependentFilter: [],
  //       taggable: false,
  //       multiple: true
  //     },
  //     SPASUBspecialityOrSubSpeciality: {
  //       name: "SPASUBspecialityOrSubSpeciality",
  //       component: "filterSelect",
  //       label: "Speciality/Sub-Speciality",
  //       queryKey: "hospitalexclude_query_key",
  //       dependsOn: [],
  //       dependentFilter: [],
  //       taggable: false,
  //       multiple: true,
  //       filterDynamicSearchAction: "test"
  //     }
  //   }
  // },
  organisation: {
    name: "organisation",
    component: "filterSelect",
    label: "Facility",
    placeholder: "All",
    queryKey: "organisation_id",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
  },
  candidateType: {
    name: "candidateType",
    component: "filterSelect",
    label: "Candidate Type",
    placeholder: "All",
    queryKey: "candidate_type_id__in",
    dependsOn: [],
    dependentFilter: ["candidateLevelCountryBased", "specialitySearch"],
    secondLevelDependentDisable: [],
    taggable: false,
    multiple: false,
    clearable: false
  },
  candidateLevelCountryBased: {
    name: "candidateLevelCountryBased",
    component: "filterSelect",
    label: "Candidate Level",
    queryKey: "level_ids__overlap",
    dependsOn: ["candidateType"],
    secondLevelDependentDisable: ["candidateType"],
    taggable: false,
    multiple: true
  },
  specialitySearch: {
    name: "specialitySearch",
    component: "accordionSelect",
    label: "Speciality/Sub-Speciality",
    queryKey: "speciality_references",
    dependsOn: [],
    dependentFilter: [],
    secondLevelDependentDisable: ['candidateType'],
    taggable: false,
    multiple: true,
    clearable: true,
    filterDynamicSearchAction: "genericSpecialitySearch",
    dependentQuerys: ["candidateType"],
    placeholder:"Please start typing Speciality/Sub-Speciality",
  },
  // ageLimit: {
  //   name: "ageLimit",
  //   component: "filterRange",
  //   label: "Age limit",
  //   queryKey: "maximum_age",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   selected: [20], //default value
  //   min: 20,
  //   max: 70,
  //   type: "range"
  // },
  // contractOptions: {
  //   name: "contractOptions",
  //   component: "filterSelect",
  //   label: "By Contract",
  //   queryKey: "contract_query_key",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  // },
  locations: {
    name: "locations",
    component: "filterSelect",
    label: "Location",
    queryKey: "location_id",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false
  },
    // hospitalNames: {
    //   name: "hospitalNames",
    //   component: "filterSelect",
    //   label: "By Hospital",
    //   queryKey: "organisation_uid",
    //   dependsOn: [],
    //   dependentFilter: [],
    //   taggable: false,
    //   multiple: false
    // },
  // postingDates: {
  //   name: "postingDates",
  //   component: "filterSelect",
  //   label: "By Posting Dates​",
  //   queryKey: "posting_dates_query_key",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  // },
  // voluntaryJobs: {
  //   name: "voluntaryJobs",
  //   component: "filterSelect",
  //   label: "By Voluntary jobs​",
  //   queryKey: "vj_query_key",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  // retiredJobs: {
  //   name: "retiredJobs",
  //   component: "filterSelect",
  //   label: "By Retired jobs​​",
  //   queryKey: "rj_query_key",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  // regionInclude: {
  //   name: "regionInclude",
  //   component: "filterSelect",
  //   label: "Region Include",
  //   queryKey: "include_country_id",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  // },
  // regionExclude: {
  //   name: "regionExclude",
  //   component: "filterSelect",
  //   label: "Region Exclude",
  //   queryKey: "exclude_country_id",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  // },
  // hospitalInclude: {
  //   name: "hospitalInclude",
  //   component: "filterSelect",
  //   label: "Hospital Include",
  //   queryKey: "hospitalInclude_query_key",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  // },
  // hospitalExclude: {
  //   name: "hospitalExclude",
  //   component: "filterSelect",
  //   label: "Hospital Exclude",
  //   queryKey: "hospitalexclude_query_key",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  // },
  // availabilityType: {
  //   name: "availabilityType",
  //   component: "filterSelect",
  //   label: "Availability (Full Time / Part Time) ",
  //   queryKey: "job_type",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: true,
  // },
  contractType: {
    name: "contractType",
    component: "filterSelect",
    label: "Contract Type",
    queryKey: "job_time",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  // jobStatus: {
  //   name: "jobStatus",
  //   component: "filterSelect",
  //   label: "Status (Published / Un-Published)",
  //   queryKey: "job_status",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  // salaryRange: {
  //   name: "salaryRange",
  //   component: "filterRange",
  //   label: "By Salary range​",
  //   queryKey: "salary__lte",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   selected: [20], //default value
  //   min: 20,
  //   max: 70,
  //   type: "range"
  // },
};
