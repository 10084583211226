import dashboard from "@/services/dashboard";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
const getDefaultState = () => {
  return {
    getCandidateByType: {},
    getCandidateByTypeId: {},
    recruitedCandidateCount: null,
    registeredUserCount: null,
    candidateCountList: [],
    jobApplicationCount: [],
    getFacilitiesCount: null,
    getApplicationCount: null,
    getShortlistCount: null,
    getDashboardAll: null,
  };
};

const state = getDefaultState();

const getters = {
  getCandidateByType: (state) => state.getCandidateByType,
  getCandidateByTypeId: (state) => state.getCandidateByTypeId,
  getRecruitedCandidateCount: (state) => state.recruitedCandidateCount,
  getRegisteredUserCount: (state) => state.registeredUserCount,
  getCandidateCountList: (state) => state.candidateCountList,
  getJobApplicationCount: (state) => state.jobApplicationCount,
  getFacilitiesCount: (state) => state.getFacilitiesCount,
  getApplicationCount: (state) => state.getApplicationCount,
  getShortlistCount: (state) => state.getShortlistCount,
  getDashboardAll: (state) => state.getDashboardAll,
};

const mutations = {
  ["SET_CANDIDATE_BY_TYPE"](state, payload) {
    state.getCandidateByType = payload;
  },
  ["SET_CANDIDATE_BY_TYPE_ID"](state, payload) {
    state.getCandidateByTypeId = payload;
  },
  ["SET_CONVERSATION"](state, data) {
    state.getConversation = data;
  },
  ["SET_RECRUITED_CANDIDATE_COUNT"](state, data) {
    state.recruitedCandidateCount = data;
  },
  ["SET_REGISTERED_USER_COUNT"](state, data) {
    state.registeredUserCount = data;
  },
  ["SET_CANDIDATE_COUNT"](state, data) {
    state.candidateCountList = data;
  },
  ["SET_JOB_APPLICATION_COUNT"](state, data) {
    state.jobApplicationCount = data;
  },
  ["SET_FACILITIES_COUNT"](state, data) {
    state.getFacilitiesCount = data;
  },
  ["SET_APPLICATION_COUNT"](state, data) {
    state.getApplicationCount = data;
  },
  ["SET_SHORTLISTED_COUNT"](state, data) {
    state.getShortlistCount = data;
  },
  ["SET_DASHBOARD_ALL"](state, data) {
    state.getDashboardAll = data;
  },
};

const actions = {
  FetchCandidateByType: ({ state, commit, rootState, dispatch, getters }) => {
    let { getCustomerId: customer_id } = getters;
    customer_id = customer_id || 2;
    return dashboard
      .getCandidateByType(customer_id)
      .then(async (res) => {
        commit("SET_CANDIDATE_BY_TYPE", res.data);
      })
      .catch((err) => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },

  fetchCandidateByTypeId: ({ state, commit, rootState, dispatch, getters }) => {
    const { getCustomerId, getIsChildUser, getOrgIDFromAccessToken } = getters;
    let query = getIsChildUser
      ? `organisation_id=${getOrgIDFromAccessToken}`
      : "";
    return dashboard
      .getCandidateByTypeReport(getCustomerId, query)
      .then(async (res) => {
        commit("SET_CANDIDATE_BY_TYPE_ID", res.data);
      })
      .catch((err) => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  fetchRecruitedCandidateCount: ({ state, commit, dispatch, getters }) => {
    const {
      getCustomerId,
      getIsChildUser,
      getOrgIDFromAccessToken: organisation_id,
    } = getters;
    const customer_id = getCustomerId || 2;
    let queryPayload = { status_id: 37 };
    if (customer_id) queryPayload = { ...queryPayload, customer_id };
    if (organisation_id) queryPayload = { ...queryPayload, organisation_id };
    const query = getFilterQueryStringWithoutArray(queryPayload);
    return dashboard
      .getRecruitedCandidateCount({ customer_id, query })
      .then((res) => {
        const { data } = res;
        commit("SET_RECRUITED_CANDIDATE_COUNT", data);
        return data;
      })
      .catch((err) => {
        console.log("error fetching recruited candidate", err);
        return err;
      });
  },
  fetchRegisteredUserCount: ({ state, commit, dispatch, getters }) => {
    const { getCustomerId: customer_id, getOrgIDFromAccessToken } = getters;
    let queryPayload = {};
    if (customer_id) queryPayload = { ...queryPayload, customer_id };
    else queryPayload = { ...queryPayload, customer_id: 2 };
    if (getOrgIDFromAccessToken)
      queryPayload = {
        ...queryPayload,
        organisation_id: getOrgIDFromAccessToken,
      };
    const query = getFilterQueryStringWithoutArray(queryPayload);
    return dashboard
      .getRegisteredUserCount({ query })
      .then((res) => {
        const { data } = res;
        commit("SET_REGISTERED_USER_COUNT", data);
        return data;
      })
      .catch((err) => {
        console.log("error fetching registered user count", err);
        return err;
      });
  },
  fetchCandidateCount: ({ state, commit, dispatch, getters }, payload) => {
    const { start_range, end_range } = payload;
    return dashboard
      .getCandidateCountList({ start_range, end_range })
      .then((res) => {
        const { data } = res;
        commit("SET_CANDIDATE_COUNT", data);
        return data;
      })
      .catch((err) => {
        console.log("error fetching registered user count", err);
        return err;
      });
  },
  fetchJobApplicationCount: ({ state, commit, dispatch, getters }, payload) => {
    const { start_range, end_range } = payload;
    return dashboard
      .getJobApplicationCount({ start_range, end_range })
      .then((res) => {
        const { data } = res;
        commit("SET_JOB_APPLICATION_COUNT", data);
        return data;
      })
      .catch((err) => {
        console.log("error fetching registered user count", err);
        return err;
      });
  },
  fetchRegisteredFacilityCount: (
    { state, commit, getters, dispatch },
    payload = {}
  ) => {
    let queryPayload = { all_rows: true, fetch_row_count: true };
    queryPayload = { ...queryPayload, ...payload };
    const query = getFilterQueryStringWithoutArray(queryPayload);
    return dashboard
      .getFacilitiesCount(query)
      .then((res) => {
        const { data } = res;
        commit("SET_FACILITIES_COUNT", data);
        return res;
      })
      .catch((err) => {
        const message = "Error while Fetch Facilities Count";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },
  fetchApplicationCount: (
    { state, commit, getters, dispatch },
    payload = {}
  ) => {
    let queryPayload = { all_rows: true, fetch_row_count: true };
    queryPayload = { ...queryPayload, ...payload };
    const query = getFilterQueryStringWithoutArray(queryPayload);
    return dashboard
      .getApplicationCount(query)
      .then((res) => {
        const { data } = res;
        commit("SET_APPLICATION_COUNT", data);
        return res;
      })
      .catch((err) => {
        const message = "Error while Fetch Applcation Count";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },
  fetchDashboardAll: ({ state, commit, getters, dispatch }, payload = {}) => {
    let queryPayload = { all_rows: true, fetch_row_count: true };
    queryPayload = { ...queryPayload, ...payload };
    const query = getFilterQueryStringWithoutArray(queryPayload);
    return dashboard
      .getDashboardAll(query)
      .then((res) => {
        const { data } = res;
        commit("SET_DASHBOARD_ALL", data);
        return res;
      })
      .catch((err) => {
        const message = "Error while Fetch Applcation Count";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },

  fetchShortListedCount: (
    { state, commit, getters, dispatch },
    payload = {}
  ) => {
    let { getCustomerId: customer_id } = getters;
    customer_id = customer_id || 2;
    let queryPayload = { all_rows: true, fetch_row_count: true, status_id: 34 };
    queryPayload = { ...queryPayload, ...payload, customer_id };
    const query = getFilterQueryStringWithoutArray(queryPayload);
    return dashboard
      .getShortlistCount({ customer_id, query })
      .then((res) => {
        const { data } = res;
        commit("SET_SHORTLISTED_COUNT", data);
        return res;
      })
      .catch((err) => {
        const message = "Error while Fetch Shortlist Count";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },
};

export default { state, getters, mutations, actions };
