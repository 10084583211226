import customer from '@/services/customer';
import job from '@/services/job'
import _ from 'lodash'

import { getFilterQueryStringWithoutArray, Role } from "@/helpers/helper.js";
// initial state

const getDefaultState = () => {
  return {
    isFetchingJobs: false,
    isFetchingJobsDetails: false,
    isFetchingApplications: false,
    jobs: [],
    error: {},
    jobDetails: {},
    applications: [],
    inProcessApplications: [],
    selectedIndex: 0,
    shortListedOneApplications: [],
    shortListedTwoApplications: [],
    shortListedThreeApplications: [],
    rejectedApplications: [],
    selectedApplication: {},
    jobListFilter: {},
    jobPagination: {
      limit: 10,
      skip: 0,
      noMoreDataFromJobList: false,
    },
    job_Details_By_Id: null,
    isFetchingJobDetails: false,
    fullPathJobListBackUrl: [],
    currentNavigationJobsPage: [],
    selectedJobCustomerID: null,
  }
}

const state = getDefaultState()


// create getters for all state variables
const getters = {
  isFetchingJobs: (state, getters, rootState) => state.isFetchingJobs,
  isFetchingJobsDetails: (state, getters, rootState) => state.isFetchingJobsDetails,
  isFetchingJobApplications: (state, getters, rootState) => state.isFetchingApplications,
  getJobs: (state, getters, rootState) => state.jobs,
  noMoreDataFromJobList: (state, getters, rootState) => state.jobPagination.noMoreDataFromJobList,
  jobListFilter: (state, getters, rootState) => state.jobListFilter,
  getJobPaginationSkip: (state, getters, rootState) => state.jobPagination.skip,
  getJobPaginationLimit: (state, getters, rootState) => state.jobPagination.limit,
  getJobDetails: (state, getters, rootState) => state.jobDetails,
  getApplications: (state, getters, rootState) => state.applications,
  getShortListedOneApplications: (state, getters, rootState) => state.shortListedOneApplications,
  getShortListedTwoApplications: (state, getters, rootState) => state.shortListedTwoApplications,
  getShortListedThreeApplications: (state, getters, rootState) => state.shortListedThreeApplications,
  getRejectedApplications: (state, getters, rootState) => state.rejectedApplications,
  getInProcessApplications: (state, getters, rootState) => state.inProcessApplications,
  getSelectedApplication: (state, getters, rootState) => state.selectedApplication,
  selectedApplicationId: (state, getters, rootState) => state.selectedApplication ? state.selectedApplication.application_id : 0,
  getSelectedCandidateByJob: (state, getters, rootState) => {
    if (getters.getSelectedApplication) {
      return getters.getSelectedApplication.candidate
    }
    return
  },
  getApplicationIndex: (state, getters, rootState) => state.selectedIndex,
  getJobDetailsById: (state, getters, rootState) => state.job_Details_By_Id,
  isFetchingJobDetails: (state, getters, rootState) => state.isFetchingJobDetails,
  jobListBackUrl: (state, getters, rootState) => {
    let { getRoleScopes } = getters;
    let jobListBackUrl = {
      admin: "/job-list",
      facility: "/job-list"
    }
    let backURL = state.fullPathJobListBackUrl;
    if (!backURL.length) {
      if (getRoleScopes[0] === Role.systemAdmin) {
        return jobListBackUrl.admin
      }
      else {
        return jobListBackUrl.facility
      }
    }
    else {
      return backURL[backURL.length - 1]
    }
  },
  currentNavigationJobsPage: (state, getters, rootState) => state.currentNavigationJobsPage,
  getPreviousJobId: (state, getters, rootState) => {
    let { getJobBoard, getJobDetailsById } = getters;
    let candidateIndex = getJobBoard.findIndex(e => {
      return e.job_id === getJobDetailsById?.job_id
    });
    return candidateIndex > 0 ? getJobBoard[candidateIndex - 1].job_uuid : 0;
  },
  getNextJobId: (state, getters, rootState) => {
    let { getJobBoard, getJobDetailsById, currentNavigationJobsPage } = getters;
    let candidateIndex = getJobBoard.findIndex(e => e.job_id === getJobDetailsById?.job_id);
    return candidateIndex + 1 == getJobBoard.length ? 0 : getJobBoard[candidateIndex + 1].job_uuid;
  },
  getSelectedJobCustomerID: state => state.selectedJobCustomerID?.customer_uid,
}

// actions
const actions = {
  updateJobApplication({ state, commit, rootState, dispatch, getters }, payload) {
    let { job_id: jobId, action, ...rest } = payload
    const { getApplications } = getters
    return customer.updateJobApplication(jobId, rest)
      .then((res) => {
        let { data, status } = res;
        if (status === 200 && !action) {
          commit('SET_APPLICATIONS_LIST', getApplications.filter(({ application_id }) => application_id !== rest.application_id))
        }
      }).catch((err) => {
        console.error("error in updateJobApplication", err);
        return err;
      })
  },

  resetJobList({ state, commit }, payload) {
    commit("RESET_JOB_LIST")
    return
  },

  getJobsList({ state, commit, rootState, dispatch, getters }, payload) {
    let { getJobPaginationLimit, getJobs } = getters
    commit("SET_JOB_LIST_FILTER", payload);
    commit('SET_IS_FETCHING_JOB', true);
    let queryString = getFilterQueryStringWithoutArray(payload);
    return customer.allJobs(queryString)
      .then((res) => {
        let { data } = res;
        if (data.length < getJobPaginationLimit) {
          commit("SET_JOB_LIST_PAGINATION", { noMoreDataFromJobList: true })
        }
        if (payload.skip) {
          commit('SET_JOBS_LIST', getJobs.concat(data));
        } else {
          commit('SET_JOBS_LIST', data);
        }
        commit('SET_IS_FETCHING_JOB', false);
        return res;
      }).catch((err) => {
        commit('SET_IS_FETCHING_JOB', false);
        console.log("error in candidateType", err);
        return err;
      })
  },
  fetchMoreJobList({ state, commit, rootState, dispatch, getters }) {
    let { jobListFilter, getJobPaginationSkip, getJobPaginationLimit, } = getters;
    jobListFilter = {
      ...jobListFilter,
      pagination: true
    }
    dispatch("jobBoardAction", jobListFilter)
  },
  fetchJobDetails({ state, commit, rootState, dispatch, getters }, payload) {
    commit('JOB_DETAILS')
    return job.getJobDetail(payload)
      .then(res => res.data).then(jobDetails => {
        commit('JOB_DETAILS_SUCCESS', jobDetails)
      }).catch((err) => {
        console.error("error in login", err);
        commit('JOB_DETAILS_ERROR', err)
        return err;
      })
  },

  jobListPagination({ state, commit, rootState, dispatch, getters }, payload) {
    let { getJobPaginationLimit } = getters
    commit("SET_JOB_LIST_PAGINATION", { skip: 0, noMoreDataFromJobList: false });
    dispatch("getJobsList", { ...payload, limit: getJobPaginationLimit, skip: 0 });
  },

  getApplicationsByJob({ state, commit, rootState, dispatch, getters }, payload) {
    // ToDo: Should look if we can call a action with payload without it.
    // let { getJobPaginationLimit, getApplications } = getters
    if (!payload.limit || !payload.skip) {
      payload = { ...payload, limit: 10, skip: 0 }
    }
    commit('SET_IS_FETCHING_APPLICATIONS_LIST', true)
    commit('SET_APPLICATIONS_LIST', [])
    return job.getCandidatesByJob(payload)
      .then((res) => {
        let { data } = res;
        commit('SET_APPLICATIONS_LIST', data)
        commit('SET_SELECTED_APPLICATION', data[0])
        // const newData = [...getApplications, ...data]
        // commit('SET_APPLICATIONS_LIST', data)
        // commit('SET_SELECTED_INDEX', 0)
        // if (data.length < getJobPaginationLimit) {
        //   commit("SET_JOB_LIST_PAGINATION", { noMoreDataFromJobList: true })
        // }
        // if (payload.skip) {
        //   commit('SET_APPLICATIONS_LIST', newData);
        // } else {
        //   commit('SET_APPLICATIONS_LIST', data);
        // }
        // commit('SET_IS_FETCHING_JOB', false);
        return res;
      }).catch((err) => {
        console.error("error in candidateDetailsByJob", err);
        return err;
      }).finally(() => {
        commit('SET_IS_FETCHING_APPLICATIONS_LIST', false);
      })
  },
  fetchMoreCandidatesByJob({ state, commit, rootGetters, dispatch, getters }) {
    let {
      candidateListFilter,
      getCandidatePaginationSkip,
      getCandidatePaginationLimit,
      getJobDetails
    } = rootGetters;
    dispatch("getApplicationsByJob", {
      ...candidateListFilter,
      skip: getCandidatePaginationSkip + getCandidatePaginationLimit,
      limit: getCandidatePaginationLimit,
      id: getJobDetails.job_id
    });
    commit("SET_CANDIDATE_LIST_PAGINATION", {
      skip: getCandidatePaginationSkip + getCandidatePaginationLimit
    }, { root: true });
  },

  setSelectedApplication({ state, commit, rootState, dispatch, getters }, index) {
    const { getApplications } = getters
    commit('SET_SELECTED_INDEX', index)
    commit('SET_SELECTED_APPLICATION', getApplications[index])
  },

  shortListCandidate({ state, commit, rootState, dispatch, getters }, index) {
    const { getApplications } = getters
    const { status, ...rest } = getApplications[index]
    const statusData = ['ShortListOne', 'ShortListTwo', 'ShortListThree', 'Rejected']
    commit('SET_APPLICATIONS_LIST', [
      ...state.applications.slice(0, index),
      { ...rest, status: { ...status, status: "Rejected" } },
      ...state.applications.slice(index1)])
    commit('SET_SHORTLISTED_APPLICATIONS_LIST', state.shortListedApplications.concat([{ ...rest, status: { ...status, status: "ShortListOne" } }]))
    commit('SET_APPLICATIONS_LIST', state.applications.filter(({ status: { status } }) => !statusData.includes(status)))
    commit('SET_SELECTED_APPLICATION', getApplications[index1] || getApplications[index - 1] || getApplications[index])
  },

  shortListTwoCandidate({ state, commit, rootState, dispatch, getters }, index) {
    const { getApplications } = getters
    const { status, ...rest } = getApplications[index]
    const statusData = ['ShortListOne', 'ShortListTwo', 'ShortListThree', 'Rejected']
    commit('SET_APPLICATIONS_LIST', [
      ...state.applications.slice(0, index),
      { ...rest, status: { ...status, status: "ShortListTwo" } },
      ...state.applications.slice(index1)])
    commit('SET_SHORTLISTED_TWO_APPLICATIONS_LIST', state.shortListedTwoApplications.concat([{ ...rest, status: { ...status, status: "ShortListTwo" } }]))
    commit('SET_APPLICATIONS_LIST', state.applications.filter(({ status: { status } }) => !statusData.includes(status)))
    commit('SET_SELECTED_APPLICATION', getApplications[index1] || getApplications[index - 1] || getApplications[index])
  },

  shortListThreeCandidate({ state, commit, rootState, dispatch, getters }, index) {
    const { getApplications } = getters
    const { status, ...rest } = getApplications[index]
    const statusData = ['ShortListOne', 'ShortListTwo', 'ShortListThree', 'Rejected']
    commit('SET_APPLICATIONS_LIST', [
      ...state.applications.slice(0, index),
      { ...rest, status: { ...status, status: "ShortListTwo" } },
      ...state.applications.slice(index1)])
    commit('SET_SHORTLISTED_THREE_APPLICATIONS_LIST', state.shortListedThreeApplications.concat([{ ...rest, status: { ...status, status: "ShortListThree" } }]))
    commit('SET_APPLICATIONS_LIST', state.applications.filter(({ status: { status } }) => !statusData.includes(status)))
    commit('SET_SELECTED_APPLICATION', getApplications[index1] || getApplications[index - 1] || getApplications[index])
  },


  rejectCandidate({ state, commit, rootState, dispatch, getters }, index) {
    const { getApplications } = getters
    const { status, ...rest } = getApplications[index]
    const statusData = ['ShortListOne', 'ShortListTwo', 'ShortListThree', 'Rejected']
    commit('SET_APPLICATIONS_LIST', [
      ...state.applications.slice(0, index),
      { ...rest, status: { ...status, status: "Rejected" } },
      ...state.applications.slice(index1)])
    commit('SET_REJECTED_APPLICATIONS_LIST', state.rejectedApplications.concat([{ ...rest, status: { ...status, status: "Rejected" } }]))
    commit('SET_APPLICATIONS_LIST', state.applications.filter(({ status: { status } }) => !statusData.includes(status)))
    commit('SET_SELECTED_APPLICATION', getApplications[index1] || getApplications[index - 1] || getApplications[index])
  },
  getJobById: async ({ state, commit, rootState, dispatch, getters }, job_uuid) => {
    if (!state.job_Details_By_Id) commit("SET_IS_FETCHING_JOB_DETAILS", false);
    const { getCustomerId } = getters;
    let customer_uid = getCustomerId;
    if (!customer_uid) {
      await dispatch("getCustomerIDByJobID", job_uuid);
      const { getSelectedJobCustomerID } = getters;
      customer_uid = getSelectedJobCustomerID;
    }
    return customer
      .getJobByIdSer({ job_uuid, customer_uid })
      .then((res) => {
        dispatch("commitJobInfo", res.data);
        commit("SELECTED_JOBBOARD_ADVERT", res.data);
        commit("UPDATE_JOB_BOARD_LIST_BY_JOB_ID", res.data)
        return res.data;
      })
      .catch((err) => {
        console.error("error in getJobByIDSer", err);
        return err;
      });
  },
  commitJobInfo({ commit, getters }, payload) {
    commit("SET_JOB_DETAILS_BY_ID", payload);
    commit("SET_IS_FETCHING_JOB_DETAILS", true);
    return payload;
  },
  initJobDetailsFetch({ commit, dispatch, getters }) {
    commit("SET_JOB_CANDIDATE_PAGINATION", {
      skip: 0,
      noMoreCandidates: false
    });
    commit("RESET_CANDIDATE_LIST_FOR_JOB_ID");
    commit("RESET_JOB_CANDIDATE_COUNT");
    let appendAction = []
    const {
      getJobDetailsById,
      getLocationLanguage,
      // getSpecialInterest,
      // getQualificationCountries,
      // getQualificationRegions,
      // getContactMethods,
      // getCurrency,
      // getRole,
    } = getters;
    if (!getLocationLanguage.length) {
      appendAction = [...appendAction, dispatch("fetchLocationLanguage")];
    }
    // if (!getSpecialInterest.length) {
    //   appendAction = [...appendAction, dispatch("fetchSpecialInterestList")];
    // }
    // if (!getQualificationCountries.length) {
    //   appendAction = [...appendAction, dispatch("fetchAllQualificationCountries")];
    // }
    // if (!getQualificationRegions.length) {
    //   appendAction = [...appendAction, dispatch("fetchQualificationRegions")];
    // }
    // if (!getContactMethods.length && getRole != 'customer_user') {
    //   appendAction = [...appendAction, dispatch("fetchContactMethods")];
    // }
    // if (!getCurrency.length) {
    //   appendAction = [...appendAction, dispatch("fetchCurrency")];
    // }
    appendAction = [...appendAction, dispatch("getJobLevelsById", getJobDetailsById)]
    return Promise.all(appendAction).then(res => {
      return res
    })
  },
  setSelectedjobById: async ({ state, commit, rootState, dispatch, getters }, job_uuid) => {
    let { getJobBoard } = getters;
    let jobDetailById;
    let jobDetails;
    if (getJobBoard.length &&
      (getJobBoard.findIndex(e => e.job_uuid == job_uuid)) >= 0 &&
      getJobBoard[getJobBoard.findIndex(e => e.job_uuid == job_uuid)]
    ) {
      jobDetailById = getJobBoard;
    }
    if (jobDetailById) {
      jobDetails = await dispatch("getJobById", job_uuid);
      dispatch("commitJobInfo", jobDetails);
    }
    else {
      jobDetails = await dispatch("getJobById", job_uuid);
    }
    return jobDetails;
  },
  getCustomerIDByJobID({ commit }, job_uuid) {
    return customer
      .getCustomerIDByJobID(job_uuid)
      .then(res => {
        const { data } = res;
        commit("SET_SELECTED_JOB_CUSTOMER_ID", data[0]);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching CustomerID", err)
        return err
      })
  },
}

// mutations
const mutations = {
  ['SET_APPLICATIONS_LIST'](state, data) {
    state.applications = data;
  },
  ['RESET_JOB_LIST'](state, data) {
    Object.assign(state, getDefaultState())
  },
  ['SET_SHORTLISTED_ONE_APPLICATIONS_LIST'](state, data) {
    state.shortListedOneApplications = data;
  },
  ['SET_SHORTLISTED_TWO_APPLICATIONS_LIST'](state, data) {
    state.shortListedTwoApplications = data;
  },
  ['SET_SHORTLISTED_THREE_APPLICATIONS_LIST'](state, data) {
    state.shortListedThreeApplications = data;
  },
  ['SET_IN_PROCESS_APPLICATIONS_LIST'](state, data) {
    state.inProcessApplications = data;
  },
  ['SET_REJECTED_APPLICATIONS_LIST'](state, data) {
    state.rejectedApplications = data;
  },
  ['SET_SELECTED_INDEX'](state, data) {
    state.selectedIndex = data;
  },
  ['SET_IS_FETCHING_APPLICATIONS_LIST'](state, data) {
    state.isFetchingApplications = data;
  },
  ['SET_SELECTED_APPLICATION'](state, data) {
    state.selectedApplication = data;
  },
  ['SET_JOBS_LIST'](state, data) {
    state.jobs = data;
  },
  ['SET_IS_FETCHING_JOB'](state, data) {
    state.isFetchingJobs = data;
  },
  ['SET_JOB_LIST_FILTER'](state, data) {
    state.jobListFilter = data;
  },
  ['SET_JOB_LIST_PAGINATION'](state, data) {
    state.jobPagination = Object.assign({}, { ...state.jobPagination, ...data });
  },
  ['JOB_DETAILS_ERROR'](state, error) {
    state.error = error;
    state.isFetchingJobsDetails = false;
    state.jobDetails = '';
  },
  ['JOB_DETAILS_SUCCESS'](state, payload) {
    state.error = false;
    state.isFetchingJobsDetails = false;
    state.jobDetails = payload;
  },
  ['JOB_DETAILS'](state, payload) {
    state.error = false;
    state.isFetchingJobsDetails = true;
    state.jobDetails = '';
  },
  ["SET_JOB_DETAILS_BY_ID"](state, data) {
    state.job_Details_By_Id = data;
  },
  ["SET_IS_FETCHING_JOB_DETAILS"](state, payload) {
    state.isFetchingJobDetails = payload
  },
  ["SET_JOB_LIST_BACK_URL"](state, url) {
    state.fullPathJobListBackUrl = [...state.fullPathJobListBackUrl, url];
  },
  ["RESET_JOB_LIST_BACK_URL"](state) {
    state.fullPathJobListBackUrl = [];
  },
  ["REMOVE_JOB_LIST_BACK_URL"](state, url) {
    let _index = _.findLastIndex(state.fullPathJobListBackUrl, e => e === url);
    if (_index > -1)
      state.fullPathJobListBackUrl.splice(_index, 1)
  },
  ["SET_SELECTED_JOB_CUSTOMER_ID"](state, payload) {
    state.selectedJobCustomerID = payload;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
