<template>
  <div>
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
      ref="profile"
      @mouseover.native="show()"
      @mouseleave.native="hide()"
    >
      <template #toggler>
        <CHeaderNavLink id="user-settings-dropdown">
          <div class="c-avatar text">
            <!-- <CIcon name="cil-user" /> -->
            <img
              class="avatar rounded-circle"
              alt="profile"
              :src="profilePic"
              onerror="this.src='/img/user_default_pic.png';"
            />
          </div>
        </CHeaderNavLink>
      </template>
      <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>Settings</strong>
      </CDropdownHeader>
      <a
        class="dropdown-item"
        @click="switchRole"
        v-if="
          currentUserRole === Role.systemAdmin ||
            getImpersonationStatus.impersonation
        "
      >
        <em class="fas fa-user mr-2"></em>Switch Role
      </a>
      <a
        class="dropdown-item"
        @click="switchUserType"
        v-if="currentUserRole === Role.customerUser"
      >
        <CIcon name="cil-user" />Switch User Type
      </a>
      <a class="dropdown-item" @click="changePassword">
        <em class="fas fa-key mr-2" style="margin-top: 1px"></em>Change Password
      </a>
      <a
        class="dropdown-item"
        v-if="
          [Role.customerAdmin, Role.customerRecruiter].includes(currentUserRole)
        "
        @click="updateOrgPictures()"
      >
        <em class="fas fa-user-circle mr-2" style="margin-top: 1px"></em>Update
        Picture/Logo
      </a>
      <a class="dropdown-item" @click="clearLocalStorage">
        <em class="fas fa-sign-out-alt mr-2" style="margin-top: 1px"></em>Logout
      </a>
    </CDropdown>
    <SwitchAccountModal
      :isShowPopup="isShowPopup"
      :modalCallBack="modalCallBack"
      :impersonation="getImpersonationStatus.impersonation"
    />
    <SwitchUserTypeModal
      v-if="customerUserModal.isShowPopup"
      :isShowPopup="customerUserModal.isShowPopup"
      :currentUserType="currentUserType"
      @modalCallBack="SwitchUserTypeModalCallBack"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SwitchAccountModal from "./SwitchAccountModal";
import SwitchUserTypeModal from "./SwitchUserTypeModal";
import { Role, appendAccessToken } from "@/helpers/helper";
import { BASE_URL } from "@/service_urls";

export default {
  name: "TheHeaderDropdownAccnt",
  components: {
    SwitchAccountModal,
    SwitchUserTypeModal,
  },
  data() {
    return {
      itemsCount: 42,
      isShowPopup: false,
      Role,
      customerUserModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getImpersonationStatus",
      "getRoleScopes",
      "getUploadedDocument",
      "currentUserType",
      "getUserUploadedDocuments",
      "getUser",
      "getCustomerId",
    ]),
    currentUserRole() {
      return this.getRoleScopes.length ? this.getRoleScopes[0] : "";
    },
    isCustomer() {
      return (
        [
          this.Role.customerAdmin,
          this.Role.customerRecruiter,
          this.Role.customerUser,
        ].includes(this.currentUserRole) || false
      );
    },
    profilePic() {
      if (
        [this.Role.customerCandidate, this.Role.systemCandidate].includes(
          this.currentUserRole
        )
      ) {
        let profileData = this.getUploadedDocument
          .filter(({ document_type_id }) => document_type_id === 2)
          .reduce((acc, cur) => {
            return cur;
          }, {});
        if (Object.keys(profileData).length) {
          _base_domain = `${window.location.origin}/api/v1`;
          return appendAccessToken(`${_base_domain}/customer/${this.getCustomerId}/file/${profileData.candidate_document_uuid}`);
        }
        return "/img/user_default_pic.png";
      } else if (this.isCustomer && this.getUser) {
        let profileData = this.getUserUploadedDocuments
          .filter((document) => document.document_type_id === 2)
          .reduce((acc, cur) => {
            return cur;
          }, {});
        if (
          Object.keys(profileData).length &&
          this.getUser?.id == profileData.user_id
        ) {
          let _baseURL = BASE_URL;
          var _base_domain = _baseURL.substring(0, _baseURL.indexOf("/api"));
          return `${_base_domain}/api/v1/user_documents/${profileData.user_document_id}/file?user_id=${profileData.user_id}`;
        }
        return "/img/user_default_pic.png";
      } else {
        return "/img/user_default_pic.png";
      }
    },
  },
  methods: {
    ...mapActions([
      "logout",
      "fetchFacilityList",
      "startImpersonation",
      "stopImpersonation",
    ]),
    clearLocalStorage() {
      this.logout();
    },
    switchRole() {
      if (!this.getImpersonationStatus.impersonation) {
        this.fetchFacilityList();
      }
      this.isShowPopup = true;
    },
    modalCallBack(action, value) {
      const _action = {
        customerAdmin: "CustomerAdmin",
        systemAdmin: "SystemAdmin",
        cancel: "Cancel",
      };
      if (action === _action.customerAdmin) {
        let payload = {
          customer_id: value?.customer_data?.customer_id,
          organisation_id: value?.customer_data?.organisation_id,
          role_id: 4,
        };
        this.startImpersonation(payload);
      } else if (action === _action.systemAdmin) {
        this.stopImpersonation();
      }
      this.isShowPopup = false;
    },
    changePassword() {
      this.$store.commit("SET_PREVIOUS_PAGE_URL", this.$route.fullPath);
      this.$router.push({ path: "/change-password" });
    },
    updateOrgPictures() {
      this.$store.commit("SET_PREVIOUS_PAGE_URL", this.$route.fullPath);
      this.$router.push({ path: "/upload-org-pictures" });
    },
    switchUserType() {
      this.customerUserModal.isShowPopup = true;
    },
    SwitchUserTypeModalCallBack(payload) {
      if (payload) this.$store.commit("SET_CURRENT_USER_TYPE", payload);
      this.customerUserModal.isShowPopup = false;
    },
    show() {
      this.$refs.profile._data.visible = true;
    },
    hide() {
      this.$refs.profile._data.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-icon {
  margin-right: 0.3rem;
}
.avatar {
  width: 36px;
  height: 36px;
}
.dropdown-item {
  cursor: pointer;
}
::marker {
  color: #085d6600 !important;
}
.c-header-nav-items {
  list-style: none;
}
</style>
