import axios from "axios";
import { BASE_URL } from "@/service_urls";

const communication = {
  getEmailConversation: ({payload, order}) =>
    axios.post(
      `${BASE_URL}/communication/email/conversation?order_by=${order}`,payload
    ),
  sendOutboundEmail: (base_url, formData) =>
    axios.post(`${base_url}/subapi/communication/email/outbound`, formData),
  downloadAttachment: (attachment_id) =>
    axios.get(
      `${BASE_URL}/communication/email/file?attachment_id=${attachment_id}`
    ),
  getMessageConversation: (sender, receiver, media) =>
    axios.get(
      `${BASE_URL}/communication/message/conversation?sender=${sender}&receiver=${receiver}&media=${media}`
    ),
  sendOutboundWhatsapp: (sender, whatsapp_to, message) =>
    axios.post(
      `${BASE_URL}/communication/whatsapp/outbound?sender_name=${sender}&whatsapp_to=${whatsapp_to}&message_content=${message}`
    ),
  sendOutboundMessage: (sender, message_to, message) =>
    axios.post(
      `${BASE_URL}/communication/message/outbound?sender_name=${sender}&message_to=${message_to}&message_content=${message}`
    ),
  getCandidateEmailMessages: ({payload, skip, limit, order}) =>
    axios.post(
      `${BASE_URL}/communication/email/conversation?skip=${skip}&limit=${limit}&order_by=${order}`, payload
    ),
  deleteEmailConversation: (deleteId) =>
    axios.delete(
      `${BASE_URL}/communication/email/delete?message_id=${deleteId}`
    ),
  getEmailMessagesAndCount: (payload) =>
    axios.post(`${BASE_URL}/communication/email/messages`,payload),
  getSearchTermEmailMessagesCount: (query, searchTerm) =>
    axios.get(
      `${BASE_URL}/communication/email/search?${query}&searchTerm=${searchTerm}`
    ),
  getRecruiterEmailMessages: ({payload, skip, limit}) =>
    axios.post(
      `${BASE_URL}/communication/email/conversation?skip=${skip}&limit=${limit}&order_by=-message_id`, payload
    ),
  getSearchTermEmailMessages: ({payload, skip, limit, searchTerm}) =>
    axios.post(
      `${BASE_URL}/communication/email/conversation?skip=${skip}&limit=${limit}&order_by=-message_id&searchTerm=${searchTerm}`, payload
    ),
  getAllUserEmailSeacrhTerm: (customer_id, org_id, skip, limit, searchTerms) =>
    axios.get(
      `${BASE_URL}/communication/email/${customer_id}/candidate?order_by=-created_on&skip=${skip}&limit=${limit}${searchTerms}&${org_id}`
    ),
  getAllCustomerContactUserEmail: (
    customer_id,
    org_id,
    current_role,
    role,
    contact
  ) => {
    let url;
    let query = "";
    if (contact || contact == false) query = `?contact=${contact}`;
    if (org_id) query = query.replace("?", "&");

    if (role.systemAdmin === current_role)
      url = `${BASE_URL}/communication/email/user${query}`;
    else if (org_id && customer_id)
      url = `${BASE_URL}/communication/email/${customer_id}/user?organisation_id=${org_id}${query}`;
    else if (customer_id)
      url = `${BASE_URL}/communication/email/${customer_id}/user${query}`;

    return axios({
      method: "GET",
      url: url,
    });
  },
  markAsReadUnRead: ({ payload, read }) => axios.put(`${BASE_URL}/communication/email/mark_as/read_unread?read=${read}`, payload),
};

export default communication;
