import axios from 'axios'
import { BASE_URL } from '@/service_urls'
import { getCustomerId } from "@/helpers/helper";
import router from "@/router/index";

const job = {
  jobStatus: () => axios.get(`${BASE_URL}/job/status/`),
  getJobDetail: (jobUUId) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/uuid/${jobUUId}/`), // TODO Make Customer Dynamic
  getCandidatesByJob: (job) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/${job.id}/application/candidates?limit=${job.limit}&skip=${job.skip}${job.queryString}`), // TODO Make Customer Dynamic
  createJobs: (payload, id) => axios.post(`${BASE_URL}/customer/${getCustomerId()}/job/organisation/${id}/`, payload),
  updateJobs: (payload, customer_uid, id) => axios.put(`${BASE_URL}/customer/${customer_uid}/job/organisation/${id}/`, payload),
  getOrganizationDetails: ({ customer_uid, query }) => axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/?${query}`),
  getJobByID: (job_uuid) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/uuid/${job_uuid}/`),
  getAllJobs: ({ skip, limit }) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/?include_marketplace=false?skip=${skip}&limit=${limit}`),
  getBenefits: (organisation_id, payload) => axios.get(`${BASE_URL}/organisation/7/organisation/33/job_benefit?candidate_type_id=1&level_id=1`),
  createJobSpeciality: (job_id, customer_uid, payload) => axios.post(`${BASE_URL}/customer/${customer_uid}/job/${job_id}/speciality/`, payload),
  updateJobSpeciality: (job_id, customer_uid, payload) => axios.put(`${BASE_URL}/customer/${customer_uid}/job/${job_id}/speciality/`, payload),
  createJobBenefit: (job_id, customer_uid, payload) => axios.post(`${BASE_URL}/customer/${customer_uid}/job/${job_id}/benefit/`, payload),
  updateJobBenefit: (job_id, customer_uid, job_benefit_id, payload) => axios.put(`${BASE_URL}/customer/${customer_uid}/job/${job_id}/benefit/${job_benefit_id}`, payload),
  deleteJobBenefit: (job_id, customer_uid, job_benefit_id) => axios.delete(`${BASE_URL}/customer/${customer_uid}/job/${job_id}/benefit/${job_benefit_id}`),
  createJobLevel: (job_id, payload) => axios.post(`${BASE_URL}/job/${job_id}/levels/`, payload),
  updateJobLevel: (job_id, payload) => axios.post(`${BASE_URL}/job/${job_id}/levels/`, payload),
  getJobLevels: (job_id) => axios.get(`${BASE_URL}/job/${job_id}/levels/`),
  createJobNationality: (job_id, customer_id, payload) => axios.post(`${BASE_URL}/customer/${customer_id}/job/${job_id}/include_country/`, payload),
  updateJobNationality: (job_id, customer_id, payload) => axios.put(`${BASE_URL}/customer/${customer_id}/job/${job_id}/include_country/`, payload),
  updateOrganisationDesc: (payload) => axios.put(`${BASE_URL}/organisation/`, payload),
  getJobCountries: (job_id, customer_uid) => axios.get(`${BASE_URL}/customer/${customer_uid}/job/${job_id}/include_country/`),
  deleteJobCountry: (job_id, customer_uid, job_country_include_id) => axios.delete(`${BASE_URL}/customer/${customer_uid}/job/${job_id}/include_country/${job_country_include_id}`),
  deleteJobLevel: (job_id, job_level_id) => axios.delete(`${BASE_URL}/job/${job_id}/levels/${job_level_id}`),
  getOrganisationJobDetails: (organisation_id) => axios.get(`${BASE_URL}/organisation/${organisation_id}/job_detail/?all_rows=true`),
  createJobDetails: (job_id, payload) => axios.post(`${BASE_URL}/job/${job_id}/details/`, payload),
  updateJobAdditionalInfo: (job_id, job_detail_id, payload) => axios.put(`${BASE_URL}/job/${job_id}/details/${job_detail_id}`, payload),
  deleteJobDetails: (job_id, job_detail_id) => axios.delete(`${BASE_URL}/job/${job_id}/details/${job_detail_id}`),
  createOrgJobDetail: (organisation_id, payload) => axios.post(`${BASE_URL}/organisation/${organisation_id}/job_detail/`, payload),
  jobCandidateMatch: ({ job_id, customer_uid }) => axios.post(`${BASE_URL}/jobcandidate/match/${customer_uid}/job/${job_id}`),
  candidateListForJobID: ({ job_id, queryString, statusSubStatus }) => axios.post(`${BASE_URL}/jobcandidate/match/job/${job_id}?${queryString}`, statusSubStatus),
  statusOptions: ({ tab_id, filter_id, queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/status?${queryString}`),
  subStatusOptions: ({ tab_id, filter_id, action_id, queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/action/${action_id}/sub_status?${queryString}`),
  reasonOptions: ({ tab_id, filter_id, action_id, queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/action/${action_id}/reasons?${queryString}`),
  getUpdateStatusSubStatus: ({ tab_id, filter_id, action_id, queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/action/${action_id}/get_update_status?${queryString}`),
  updateJobCandidateStatus: ({ job_id, candidate_uid, queryString, payload }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_uid}?${queryString}`, payload),
  getCandidatePreferredJobs: ({ customer_id, candidate_id, query }) => {
    let subQuery = ""
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true"
    }
    if (query) {
      query = query + `&${subQuery}`
    } else {
      query = `${subQuery}`
    }
    return axios.get(`${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/summary?${query}`)
  },
  getCandidateAppliedOrFavouriteJobs: ({ customer_id, candidate_id, query }) => axios.get(`${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/jobs/minimal?${query}`),
  addJobCandidateComment: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/comments?${queryString}`),
  getContactMethods: () => axios.get(`${BASE_URL}/jobcandidateprocess/contact_methods`),
  getFieldsInAdvert: () => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/fields_in_advert`),
  fetchSectionDisplayNames: ({ customer_uid, organisation_uid }) => axios.get(`${BASE_URL}/section_template/display_names/?customer_id=${customer_uid}&organisation_id=${organisation_uid}`),
  fetchSectionByDisplayNames: ({ customer_uid, organisation_uid, query }) => axios.get(`${BASE_URL}/section_template/by/display_name?customer_id__in=${customer_uid},null&organisation_id__in=${organisation_uid},null&${query}`),
  createJobDescSections: (customer_uid, job_id, payload) => axios.post(`${BASE_URL}/customer/${customer_uid}/job_desc_section/${job_id}`, payload),
  deleteJobDescSectionbyID: ({ customer_uid, job_desc_secion_id }) => axios.delete(`${BASE_URL}/customer/${customer_uid}/job_desc_section/job_description/${job_desc_secion_id}`),
  benefits: (customer_uid, organisation_uid, candidate_type_id, benefit_types) => axios.get(`${BASE_URL}/organisation/${customer_uid}/organisation/${organisation_uid}/job_benefit?candidate_type_id=${candidate_type_id}&benefit_types__in=${benefit_types}`),
  getJobComments: (job_uuid) => axios.get(`${BASE_URL}/job/${job_uuid}/job_candidate_comment/?all_rows=true`),
  createJobComment: (job_uuid, payload) => axios.post(`${BASE_URL}/job/${job_uuid}/job_candidate_comment/`, payload),
  deleteJobComment: (job_uuid, comment_id) => axios.delete(`${BASE_URL}/job/${job_uuid}/job_candidate_comment/${comment_id}`),
  getActionsForRecruiter: ({ user_id }) => axios.get(`${BASE_URL}/action_detail/?initiated_by=${user_id}&assigned_to__isnull=false`),
  fetchOrganisationRegions: (organisation_id) => axios.get(`${BASE_URL}/organisation/organisation_regions/?organisation_id=${organisation_id}`),
  onChangeJobStatus: ({ customer_uid, job_uuid, status }) => axios.put(`${BASE_URL}/customer/${customer_uid}/job/id/${job_uuid}?status=${status}`),
  createPublicJobs: (payload, customer_id, organisation_id) => axios.post(`${BASE_URL}/customer/${customer_id}/job/organisation/${organisation_id}/public`, payload),
  getPublicJobByID: (customer_id, payload) => {
    const URL = `${BASE_URL}/customer/${customer_id}/job/call/for/share/jobs`;
    return axios.post(URL, payload);
  },
  fetchEnumLabels: ({ enum_name, query }) => axios.get(`${BASE_URL}/get_enum_labels/${enum_name}?${query}`),
  fetchOrgContacts: ({ customer_id, organisation_id }) => axios.get(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact/`),
  getCandidateWithdrawJobs: ({ customer_id, candidate_id }) => axios.get(`${BASE_URL}/job/application/withdrawal_jobs/Summary?customer_id=${customer_id}&candidate_uid=${candidate_id}&order_by=-withdrawal_on`),
}

export default job