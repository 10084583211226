<template>
  <div class="loading-overlay is-marginless">
    <div class="background-image" ></div>
    <div class="overlay-content-wrap">
      <div class="overlay-content">
        <Loader/>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
export default {
  name: "LoadingOverlay",
  components: {
    Loader: Loader
  }
};
</script>
