import jobBoard from "@/services/jobBoard";
import tabFilters from "@/services/tabFilter";
import candidate from "@/services/candidate";
import customer from "@/services/customer";
import router from '@/router/index';
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
import { getCustomerId } from "@/helpers/helper";
import m from "moment";

const state = {
  isFetchingJobCandidateSts: false,
  isFetchingJobCandidateStsFilter: false,
  getAllJobsFilterList: [],
  getTabFilterOptions: [],
  getTabSubFilterOptions: [],
  displayStatusSubStatus: [],
  getJobCandidatesByStatusID: [],
  getJobCandidatesByStatusIDCount: 0,
  jobCandidateStatusPagination: {
    limit: 10,
    skip: 0,
    noMoreCandidates: false,
  },
  allJobsListByShortlistProcess: [],
  activeJobCount: 0,
  expiryJobCount: 0,
};

const getters = {
  isFetchingJobCandidateSts: (state) => state.isFetchingJobCandidateSts,
  isFetchingJobCandidateStsFilter: (state) => state.isFetchingJobCandidateStsFilter,
  getAllJobsFilterList: (state) =>
    state.getAllJobsFilterList?.length
      ? state.getAllJobsFilterList.map((val) => ({
        label: `${val.job_id} | ${val.job_title}`,
        code: val.job_id,
      }))
      : [],
  getAllJobsListByShortlistProcess: (state) =>
    state.allJobsListByShortlistProcess?.length
      ? state.allJobsListByShortlistProcess.map((val) => ({
        label: `${val.job_id} | ${val.job_title}`,
        code: val.job_id,
      }))
      : [],
  getTabFilterOptions: (state) =>
    state.getTabFilterOptions?.length
      ? state.getTabFilterOptions.map((val) => ({
        label: val.filter_?.filter_name,
        code: val.filter_?.id,
      }))
      : [],
  getTabSubFilterOptions: (state) =>
    state.getTabSubFilterOptions?.length
      ? state.getTabSubFilterOptions.map((val) => ({
        label: val.sub_filter_?.filter_name,
        code: val.sub_filter_?.id,
      }))
      : [],
  displayStatusSubStatus: (state) => state.displayStatusSubStatus,
  getJobCandidatesByStatusID: (state) => state.getJobCandidatesByStatusID,
  getJobCandidatesByStatusIDCount: (state) => state.getJobCandidatesByStatusIDCount,
  getJobCandidateStatusPagination: (state) => state.jobCandidateStatusPagination,
  getActiveJobCount: (state) => state.activeJobCount,
  getExpiryJobCount: (state) => state.expiryJobCount,
};

const actions = {
  initJobCandidatesStatusFetchOptions({ dispatch, getters }) {
    let { getJobCandidateTabs } = getters;
    let appendFilterAction = [];
    dispatch("showLoader");
    appendFilterAction = [...appendFilterAction, dispatch("fetchAllJobsFilterList")];
    if (!getJobCandidateTabs.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchJobCandidateTabs")];
    }
    return Promise.all(appendFilterAction).then((res) => {
      dispatch("hideLoader");
      return res;
    });
  },
  fetchAllJobsFilterList({ commit, getters }) {
    const { getOrgIDFromAccessToken } = getters;
    let query = getOrgIDFromAccessToken ? `organisation_id=${getOrgIDFromAccessToken}` : "";
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", true);
    return jobBoard
      .fetchAllJobsFilterList({ query: query })
      .then((res) => {
        const { data } = res;
        commit("SET_ALL_JOBS_FILTER_LIST", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching all jobs filter list", err);
        return err;
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", false);
        return res;
      });
  },
  fetchActiveAndExpiryJobsCount({ commit, getters }) {
    const { getOrgIDFromAccessToken } = getters;
    let query = "status_id__in=28,29";
    let customer_id = getCustomerId();
    let organisation_id = getOrgIDFromAccessToken;
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", true);
    return jobBoard
      .getJobsCountFacility(query, customer_id, organisation_id)
      .then((res) => {
        commit("SET_JOB_ACTIVE_JOB_COUNT", res.data);
        query += `&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&to_date=${m().add(1, 'days').format("YYYY-MM-DD")}`
        return jobBoard.getJobsCountFacility(query, customer_id, organisation_id).then(expiryRes => {
          commit("SET_JOB_24_HR_EXPIRY_JOB_COUNT", expiryRes.data);
          return expiryRes;
        }).catch(err => {
          console.log("Error while fetching expiry jobs count", err);
          return err;
        })
      })
      .catch((err) => {
        console.log("Error while active jobs count", err);
        return err;
      })
  },
  fetchAllJobsByShortlistProcess({ commit, getters, dispatch }, candidate_id) {
    const { getOrgIDFromAccessToken } = getters;
    let query = getOrgIDFromAccessToken ? `organisation_uid=${getOrgIDFromAccessToken}` : "";
    commit("SET_ALL_JOBS_LIST_BY_SHORTLIST_PROCESS", []);
    dispatch("showLoader")
    return jobBoard
      .fetchAllJobsByShortlistProcess({ candidate_id, query })
      .then((res) => {
        const { data } = res;
        commit("SET_ALL_JOBS_LIST_BY_SHORTLIST_PROCESS", data);
        dispatch("hideLoader");
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching all jobs by shortlist process", err);
        dispatch("hideLoader");
        return err;
      })
  },
  fetchJobTabFilterOptions({ commit }, { tab_id }) {
    if (!tab_id) {
      commit("RESET_TAB_FILTER_OPTIONS");
      commit("RESET_TAB_SUB_FILTER_OPTIONS");
      return
    }
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", true);
    return tabFilters
      .getTabFilters({ tab_id })
      .then((res) => {
        const { data } = res;
        commit("SET_TAB_FILTER_OPTIONS", data);
      })
      .catch((err) => {
        console.log("Error while fetching tab filter list", err);
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", false);
        return res;
      });
  },
  fetchJobTabSubFilterOptions({ commit }, payload) {
    if (!payload?.filter_id) {
      commit("RESET_TAB_SUB_FILTER_OPTIONS");
      return
    }
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", true);
    return tabFilters
      .getTabSubFilters(payload)
      .then((res) => {
        const { data } = res;
        commit("SET_TAB_SUB_FILTER_OPTIONS", data);
      })
      .catch((err) => {
        console.log("Error while fetching tab sub filter list", err);
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", false);
        return res;
      });
  },
  fetchDisplayStatusSubStatus({ commit, getters, dispatch }) {
    const { getJobCandidateTabs } = getters;
    const fields = {
      job: "job_id",
      tab: "tab_id",
      tabFilter: "filter_id",
      tabSubFilter: "sub_filter_id",
    };
    let payload = {};
    for (const [key, value] of Object.entries(router.currentRoute.query)) {
      payload[fields[key]] = value;
    }
    const { tab_id, filter_id, sub_filter_id } = payload;
    commit("RESET_FILTER_DISPLAY_STATUS_SUB_STATUS");
    if (tab_id == "allTab") {
      let appendAction = [];
      getJobCandidateTabs.forEach(ele => {
        appendAction = [...appendAction, dispatch("fetchDisplayStatusSubStatusByTab", ele.tab_id)];
      });
      return Promise.all(appendAction).then((res) => {
        return res;
      });
    }
    if (!(tab_id && filter_id)) return;
    let queryString = `${sub_filter_id ? `sub_filter_id=${sub_filter_id}&` : ""
      }user_action=display`;
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS", true);
    return tabFilters
      .getDisplayStatusSubStatus({ tab_id, filter_id, queryString })
      .then((res) => {
        const { data } = res;
        commit("SET_FILTER_DISPLAY_STATUS_SUB_STATUS", data);
      })
      .catch((err) => {
        console.log("Error while fetching display status sub status", err);
      })
      .finally((res) => {
        return res;
      });
  },
  fetchDisplayStatusSubStatusByTab({ state, commit }, tab_id) {
    return tabFilters
      .getDisplayStatusSubStatusByTab({ tab_id }).then(res => {
        const { data } = res;
        commit("SET_FILTER_DISPLAY_STATUS_SUB_STATUS", state.displayStatusSubStatus.concat(data));
        return res
      }).catch(err => {
        console.log("Error while fetching display status by tab", err)
        return err
      })
  },
  fetchJobCandidatesByStatusID({ commit, getters, dispatch }, payload) {
    const { displayStatusSubStatus, getJobCandidateStatusPagination, getJobCandidatesByStatusID, getChildOrgWithCustomerQuery } = getters;
    if (!displayStatusSubStatus?.length) {
      dispatch("resetJobCandidateStatus");
      commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
      commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
        noMoreCandidates: true
      });
      return;
    }
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS", true);
    if (!payload.pagination) {
      commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
        skip: 0,
        noMoreCandidates: false,
      });
      dispatch("fetchJobCandidatesByStatusIDCount")
    }
    let skip = payload.pagination ? getJobCandidateStatusPagination.skip : 0;
    let limit = getJobCandidateStatusPagination.limit;
    let queryString = getChildOrgWithCustomerQuery
    let query = router?.currentRoute?.query
    if (query?.job) queryString = `&job_id=${query?.job}`
    if (query?.tab == 1 && query?.tabFilter == 1) queryString += `&job_applied=true`
    return tabFilters
      .fetchJobCandidatesByStatusID({
        skip: skip,
        limit: limit,
        queryString: queryString,
        formPayload: displayStatusSubStatus,
      })
      .then((res) => {
        const { data } = res;
        if (data.length < limit) {
          commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
            noMoreCandidates: true
          });
        } else {
          commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
            skip: skip + limit,
            noMoreCandidates: false
          });
        }
        if (payload.pagination) {
          commit("SET_JOB_CANDIDATES_BY_STATUS_ID", getJobCandidatesByStatusID.concat(data));
        } else {
          commit("SET_JOB_CANDIDATES_BY_STATUS_ID", data);
        }
      })
      .catch((err) => {
        console.log("Error while fetching display status sub status", err);
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
        return res;
      });
  },
  fetchJobCandidatesByStatusIDCount({ commit, getters }) {
    const { getChildOrgWithCustomerQuery, getOrgIDFromAccessToken } = getters;
    const fields = {
      job: "job_id",
      tab: "tab_id",
      tabFilter: "filter_id",
      tabSubFilter: "sub_filter_id",
    };
    let payload = {};
    for (const [key, value] of Object.entries(router.currentRoute.query)) {
      payload[fields[key]] = value;
    }
    const { tab_id, filter_id, ...rest } = payload
    let queryPayload = { ...rest };
    if (getOrgIDFromAccessToken)
      queryPayload = { ...queryPayload, organisation_id: getOrgIDFromAccessToken };
    let queryString = getFilterQueryStringWithoutArray(queryPayload)
    queryString += `&${getChildOrgWithCustomerQuery}`;
    if (tab_id == "allTab") {
      return tabFilters.jobCandidateTabTotalCount(queryString).then(res => {
        const { data } = res;
        let totalCount = 0;
        data.forEach(ele => {
          totalCount += ele.count
        })
        commit("SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT", totalCount);
        return res;
      }).catch(err => {
        console.log("Error while fetching candidate status job count", err)
        return err;
      })
    }
    if (tab_id == 1 && filter_id == 1) queryString += `&job_applied=true`
    return tabFilters
      .tabFilterCandidateCount({ tab_id, filter_id, queryString: queryString }).then(res => {
        const { data } = res
        commit("SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT", data);
      }).catch((err) => {
        console.log("Error while fetching job candidate count", err);
      })
      .finally((res) => {
        return res;
      });
  },
  resetJobCandidateStatus({ commit }) {
    commit("RESET_JOB_CANDIDATES_BY_STATUS_ID");
    commit("RESET_JOB_CANDIDATES_BY_STATUS_ID_COUNT");
  }
};
const mutations = {
  ["SET_FETCHING_JOB_CANDIDATE_STATUS"](state, payload) {
    state.isFetchingJobCandidateSts = payload;
  },
  ["SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER"](state, payload) {
    state.isFetchingJobCandidateStsFilter = payload;
  },
  ["SET_ALL_JOBS_FILTER_LIST"](state, payload) {
    state.getAllJobsFilterList = payload;
  },
  ["SET_TAB_FILTER_OPTIONS"](state, payload) {
    state.getTabFilterOptions = payload;
  },
  ["RESET_TAB_FILTER_OPTIONS"](state) {
    state.getTabFilterOptions = [];
  },
  ["SET_TAB_SUB_FILTER_OPTIONS"](state, payload) {
    state.getTabSubFilterOptions = payload;
  },
  ["RESET_TAB_SUB_FILTER_OPTIONS"](state) {
    state.getTabSubFilterOptions = [];
  },
  ["SET_FILTER_DISPLAY_STATUS_SUB_STATUS"](state, payload) {
    state.displayStatusSubStatus = payload;
  },
  ["RESET_FILTER_DISPLAY_STATUS_SUB_STATUS"](state) {
    state.displayStatusSubStatus = [];
  },
  ["SET_JOB_CANDIDATES_BY_STATUS_ID"](state, payload) {
    state.getJobCandidatesByStatusID = payload;
  },
  ["RESET_JOB_CANDIDATES_BY_STATUS_ID"](state) {
    state.getJobCandidatesByStatusID = [];
  },
  ["SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT"](state, payload) {
    state.getJobCandidatesByStatusIDCount = payload;
  },
  ["RESET_JOB_CANDIDATES_BY_STATUS_ID_COUNT"](state) {
    state.getJobCandidatesByStatusIDCount = 0;
  },
  ["SET_JOB_CANDIDATE_STATUS_PAGINATION"](state, payload) {
    state.jobCandidateStatusPagination = {
      ...state.jobCandidateStatusPagination,
      ...payload,
    };
  },
  ["SET_ALL_JOBS_LIST_BY_SHORTLIST_PROCESS"](state, payload) {
    state.allJobsListByShortlistProcess = payload;
  },
  ["SET_JOB_ACTIVE_JOB_COUNT"](state, payload) {
    state.activeJobCount = payload;
  },
  ["SET_JOB_24_HR_EXPIRY_JOB_COUNT"](state, payload) {
    state.expiryJobCount = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
