import organisationDocuments from "@/services/organisationDocuments";
const getDefaultState = () => {
  return {
    getDocumentByOrganisationId: [],
    getOrganisationDocumentFile: "",
    lastFetchedDocumentByOrganisationID: "",
    getOrgLocation: []
  };
};

const state = getDefaultState();

const getters = {
  getDocumentByOrganisationId: (state) => state.getDocumentByOrganisationId,
  getOrgLocation: (state) => state.getOrgLocation,
};

const mutations = {
  ["SET_DOCUMENT_BY_ORGANISATION_ID"](state, payload) {
    state.getDocumentByOrganisationId = payload;
  },
  ["LAST_FETCHED_DOCUMENT_BY_ORGANISATION_ID"](state, payload) {
    state.lastFetchedDocumentByOrganisationID = payload;
  },
  ["SET_ORGANISATION_DOCUMENT_FILE"](state, payload) {
    state.getOrganisationDocumentFile = payload;
  },
  ["SET_ORG_LOCATION"](state, payload) {
    state.getOrgLocation = payload;
  }
};

const actions = {
  fetchDocumentByOrgId: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    let { getCustomerId: customer_id } = getters
    customer_id = customer_id || 2;
    const { organisation_id, skipLastFetch } = payload;
    if (organisation_id == state.lastFetchedDocumentByOrganisationID && !skipLastFetch) return;
    return organisationDocuments
      .getDocument(organisation_id, customer_id)
      .then(async (res) => {
        commit("LAST_FETCHED_DOCUMENT_BY_ORGANISATION_ID", organisation_id)
        commit("SET_DOCUMENT_BY_ORGANISATION_ID", res.data);
      })
      .catch((err) => {
        console.log("error fetching organisation document by org_id", err);
        return err;
      });
  },
  createDocumentForOrganisation: ({ state, commit, dispatch }, payload) => {
    let { getCustomerId: customer_id } = getters;
    customer_id = customer_id || 2;
    let { organisation_id, document_type_id, ...rest } = payload
    dispatch("showToast", { message: 'Uploading...' })
    const file = new FormData();
    file.append("file", rest.file, rest.fileName);
    file.append("document_type_id", document_type_id);
    return organisationDocuments
      .createDocument(organisation_id, customer_id, file)
      .then(res => {
        dispatch("fetchDocumentByOrgId", { organisation_id, skipLastFetch: true })
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "File Uploaded Successfully",
        })
        return res;
      }).catch(err => {
        console.log("Error while creating document", err);
        return err;
      })
  },
  deleteDocumentForOrganisation: ({ state, commit, dispatch }, payload) => {
    return organisationDocuments
      .deleteDocument(payload)
      .then(res => {
        dispatch("fetchDocumentByOrgId", { organisation_id: payload.organisation_id, skipLastFetch: true })
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Document Deleted Successfully",
        })
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while deleting",
        })
        console.log("Error while deleting document", err);
        return err;
      })
  },
  fetchOrgLocation: ({ state, commit, getters }) => {
    const { location_id } = getters.getUser.org_detail
    return organisationDocuments
      .getOrgLocation(location_id)
      .then(res => {
        const { data } = res;
        commit("SET_ORG_LOCATION", data)
        return data;
      })
      .catch(err => {
        console.log("Error organization location", err);
        return err;
      });
  }
};

export default { state, getters, mutations, actions };