export const employees = [
  {
    id: 1,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 2,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 3,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 4,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 5,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 6,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 7,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 8,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 9,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 10,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 11,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 12,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 13,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 14,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 15,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 16,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 17,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 18,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 19,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  },
  {
    id: 20,
    avatar: { url: "img/avatars/1.jpg", status: "success" },
    user: {
      name: "Yiorgos Avraamu",
      new: true,
      registered: "Jan 1, 2015"
    },
    country: { name: "USA", flag: "cif-us" },
    usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
    payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
    activity: "10 sec ago"
  }
]

export const tabDetails = {
  calculator: {
    name: "Calculator",
    about: `1. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                officia deserunt mollit anim id est laborum.`
  },
  shopping_cart: {
    name: "Shopping cart",
    about: `2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                officia deserunt mollit anim id est laborum.`
  },
  charts: {
    name: "Charts",
    about: `3. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                officia deserunt mollit anim id est laborum.`
  }
}

export const candidates = [
  {id:1, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:2, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:3, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:4, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:5, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:6, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:7, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:8, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:9, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:10, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
  {id:11, name: "Michael Phelps", mobile_no: "9840572930", mail_id:"micheal@gmail.com", description: "Doctors also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine", status:"Active", profile_percentage: 57, gender: "Male", occupation: "Ear Specialist" },
]

export const jobs = [
  {id:1, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:2, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:3, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:4, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:5, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:6, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:7, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:8, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:9, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:10, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:11, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:12, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]},
  {id:13, job_name: "Dentist", experience:8, company:"Dribble Sanfransisco", reference_id: "22220930", speciality:"Medical", sub_speciality: "Stroke Medicine", salary: "$20k - $30k USD", status:"Active", skills: ["ERCP", "Rhinoplasty", "MRI", "CVP"]}
]