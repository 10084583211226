import facility from "@/services/facility";
import router from "../router/index";
import moment from "moment";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";

const getDefaultState = () => {
    return {
        organisationTypeList: [],
        credentialDetails: {},
        facilityList: [],
        facilityLocations: [],
        customerDetails: {},
        facilityConfig: [],
        isFacilityConfigViolated: false,
        facilityUsers: [],
        facilityMaxCountConfig: {},
        defaultFacilityRegisterUser: {},
        customGroups: [],
    };
}

const state = getDefaultState()

const getters = {
    getOrganisationTypeList: state => state.organisationTypeList && state.organisationTypeList.length
        ? state.organisationTypeList.map(val => ({
            label: val.comments,
            code: val.organisation_type_id
        }))
        : [],
    credentialDetails: state => state.credentialDetails,
    getFacilityList: state => state.facilityList,
    getFacilityLocations: state => state.facilityLocations && state.facilityLocations.length
        ? state.facilityLocations.map(val => ({
            label: val.comments,
            code: val.location_id
        }))
        : [],
    getCustomerDetails: state => state.customerDetails,
    getCustomerDetailsById: state => state.customerDetails,
    getFacilityConfig: state => state.facilityConfig,
    isFacilityConfigViolated: state => state.isFacilityConfigViolated,
    getFacilityUsers: state => state.facilityUsers,
    getDefaultFacilityRegisterUser: state => state.defaultFacilityRegisterUser,
    getFacilityMaxCountConfig: state => state.facilityMaxCountConfig,
    getCustomGroups: state => state.customGroups,
};

const mutations = {
    ["SET_ORGANISATION_TYPE_LIST"](state, payload) {
        state.organisationTypeList = payload;
    },
    ["SET_CREDENTIAL_DETAILS"](state, payload) {
        state.credentialDetails = payload;
    },
    ["SET_FACILITY_LIST"](state, payload) {
        state.facilityList = payload;
    },
    ["SET_FACILITY_LOCATIONS"](state, payload) {
        state.facilityLocations = payload;
    },
    ["SET_CUSTOMER_DETAILS"](state, payload) {
        state.customerDetails = payload;
    },
    ["RESET_STATE"](state, payload) {
        Object.assign(state, getDefaultState());
    },
    ["SET_FACILITY_CONFIG"](state, payload) {
        state.facilityMaxCountConfig = {
            shortlist: payload[
                payload.findIndex((e) => e.key === "MAX_SHORTLISTS")
            ].value,
            interview: payload[
                payload.findIndex((e) => e.key === "MAX_INTERVIEWS")
            ].value,
            recruit: payload[
                payload.findIndex((e) => e.key === "MAX_RECRUITERS")
            ].value,
        }
        state.facilityConfig = payload;
    },
    ["RESET_FACILITY_CONFIG"](state, payload) {
        state.facilityMaxCountConfig = {}
        state.facilityConfig = [];
    },
    ["RESET_CUSTOMER_DETAILS"](state, payload) {
        state.customerDetails = {};
    },
    ['SET_CONFIG_VIOLATED'](state, payload) {
        state.isFacilityConfigViolated = payload;
    },
    ["SET_FACILITY_USERS"](state, payload) {
        state.facilityUsers = payload;
    },
    ["RESET_FACILITY_USERS"](state, payload) {
        state.facilityUsers = [];
    },
    ["SET_FACILITY_DETAILS"](state, payload) {
        state.defaultFacilityRegisterUser = payload;
    },
    ["SET_CUSTOM_GROUPS"](state, payload) {
        state.customGroups = payload;
    },
};

const actions = {
    fetchOrganisationTypeList: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .getOrganisationTypeList()
            .then(async res => {
                commit("SET_ORGANISATION_TYPE_LIST", res.data);
            })
            .catch(err => {
                console.log("error fetching organisation type list", err);
                return err;
            });
    },
    fetchCredentialDetails: ({ state, commit, rootState, dispatch, getters }) => {
        const { getCustomerId: customer_id } = getters;
        return facility
            .getNextCredentialDetail({ customer_id })
            .then(async res => {
                commit("SET_CREDENTIAL_DETAILS", res.data);
            })
            .catch(err => {
                console.log("error fetch next username, password", err);
            });
    },
    addFacility: ({ state, commit, rootState, dispatch, getters }, payloadData) => {
        dispatch("showToast", { message: 'Adding...' })
        const { customer_uid, payload } = payloadData;
        return facility
            .addOrganisation({ customer_uid, payload })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                if (res?.data?.msg) {
                    dispatch("showToast", { class: 'bg-danger text-white', message: res.data.msg })
                }
                else {
                    dispatch("showToast", { class: 'bg-success text-white', message: 'Facility Added Successfully' })
                    //router.push("/facility-list");
                    commit("SET_AUTO_GEN_PASSWORD", null)
                    return res;
                }

            })
            .catch(err => {
                let msg = "Error While Add Facility";
                console.log(msg, err);
                dispatch("clearAllToast");
                if (err.response.data.detail) {
                    msg = err.response.data.detail;
                }
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            })
    },
    fetchFacilityList: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .fetchOrganisationList({ customer_uid: 2 })
            .then(async res => {
                commit("SET_FACILITY_LIST", res.data);
                return res;
            })
            .catch(err => {
                console.log("error fetching facility list", err);
                return err;
            });
    },
    fetchFacilityLocations: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .getFacilityLocation()
            .then(async res => {
                commit("SET_FACILITY_LOCATIONS", res.data);
            })
            .catch(err => {
                console.log("error fetching facility location list", err);
                return err;
            });
    },
    fetchCustomerDetails: ({ state, commit, rootState, dispatch, getters }) => {
        return facility
            .getCustomerDetails()
            .then(async res => {
                commit("SET_CUSTOMER_DETAILS", res.data);
            })
            .catch(err => {
                console.log("error fetching customer details", err);
                return err;
            })
    },
    initFetchFacilityDetails: ({ state, commit, rootState, dispatch, getters }, facilityId) => {
        commit("RESET_STATE");
        if (facilityId) {
            dispatch("fetchCustomerDetailsById", facilityId);
        }
        else {
            dispatch("fetchCustomerDetails");
        }
        dispatch("getTitles");
        dispatch("getLocations");
        dispatch("locationCountryInfo");
        dispatch("fetchOrganisationTypeList");
    },
    addFacilityDetails: ({ state, commit, rootState, dispatch, getters }, payload) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId;
        dispatch("showToast", { message: 'Saving...' });
        return facility
            .addFacilityDetail(payload, customer_id)
            .then(async res => {
                dispatch("showToast", { class: 'bg-success text-white', message: 'Details saved successfully' })
                router.push("/candidate-list");
            })
            .catch(err => {
                let msg = "Error while Updating";
                console.log("error updating facility details", err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    fetchFacilityConfig: ({ state, commit, rootState, dispatch, getters }, payload) => {
        commit("RESET_FACILITY_CONFIG")
        const queryString = getFilterQueryStringWithoutArray(payload)
        return facility
            .getFacilityConfig({ queryString })
            .then(async res => {
                commit("SET_FACILITY_CONFIG", res.data);
                return res;
            })
            .catch(err => {
                console.log("error fetching facility config", err);
                return err;
            })
    },
    updateFacilityConfig: ({ state, commit, rootState, dispatch, getters }, payload) => {
        const { customer_id } = payload;
        return facility
            .updateFacilityConfig({ payload, customer_id })
            .then(async res => {
                await dispatch("clearAllToast");
                await dispatch("showToast", { class: 'bg-success text-white', message: 'Configuration saved successfully', autohide: 1000 })
            })
            .catch(err => {
                console.log("error while update facility config", err);
                return err;
            })
    },
    fetchCustomerDetailsById: ({ state, commit, rootState, dispatch, getters }, facilityId) => {
        return facility
            .getCustomerDetailsById(facilityId)
            .then(async res => {
                commit("SET_CUSTOMER_DETAILS", res.data);
            })
            .catch(err => {
                console.log("error fetching customer details", err);
                return err;
            })
    },
    initFetchFacilityEditDetails: ({ state, commit, rootState, dispatch, getters }, facilityId) => {
        commit("RESET_CUSTOMER_DETAILS");
        dispatch("fetchCustomerDetailsById", facilityId);
        dispatch("getTitles");
        dispatch("getLocations");
        dispatch("locationCountryInfo");
        dispatch("fetchOrganisationTypeList");
    },
    updateFacilityDetails: ({ state, commit, rootState, dispatch, getters }, payload) => {
        dispatch("showToast", { message: 'Saving...' });
        return facility
            .updateOrganisation(payload)
            .then(async res => {
                dispatch("showToast", { class: 'bg-success text-white', message: 'Details saved successfully' });
                return res;
            })
            .catch(err => {
                let msg = "Error while saving";
                console.log("error creating facility", err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    downloadCandidates(
        { state, commit, rootState, dispatch, getters },
        { organisation_id, facility_name }
    ) {
        dispatch("showFixedToast",
            { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
        return facility
            .downloadCandidatesDocument({ organisation_id })
            .then(res => {
                if (res.status === 200) {
                    let { data } = res;
                    let dateTime = moment.utc().utcOffset(+4).format('DD/MM/YYYY HH:mm:ss');
                    const fileName = facility_name.replace(/[^a-zA-Z0-9]/g, "") + "__" + dateTime + ".xlsx";
                    const link = document.createElement("a");
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(data);
                        link.setAttribute("href", url);
                        link.setAttribute("download", fileName);
                        link.style.visibility = "hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        dispatch("showToast", { class: 'bg-success text-white', message: 'Candidates downloaded successfully' });
                    }
                }
                dispatch("hideFixedToast")
            })
            .catch(err => {
                dispatch("showToast",
                    { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
                console.error("error in download Candidates", err);
                return err;
            });
    },
    addFacilityUser: ({ state, commit, rootState, dispatch, getters }, payload) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId;
        const data = payload.data;
        const facility_id = payload.facility_id;
        dispatch("showToast", { message: 'Saving...' })
        return facility
            .addFacilityUser({ data, customer_id, facility_id })
            .then(async res => {
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Admin/User created successfully' })
                router.push("/candidate-list");
                return res;
            })
            .catch(err => {
                dispatch("clearAllToast");
                let msg = "Error while saving";
                const { status, data } = err.response;
                if (status === 400 &&
                    data.detail.indexOf("email") > 0 &&
                    data.detail.indexOf("already exists") > 0) {
                    msg = "Email already exists";
                }
                console.log("error creating facility admin/user", err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    resetPassword: ({ state, commit, rootState, dispatch, getters }, payload) => {
        return facility
            .recoveryPassword(payload)
            .then(async res => {
                dispatch("showToast", { message: 'Reset Password email has been sent' })
            })
            .catch(err => {
                let msg = "Error while Password reset";
                console.log("error facility password reset", err);
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            });
    },
    checkFacilityUser: ({ state, commit, rootState, dispatch, getters }, payload) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId;
        commit("SET_CONFIG_VIOLATED", false);
        return facility
            .checkFacilityUser({ customer_id, payload })
            .then(async res => {
                commit("SET_CONFIG_VIOLATED", res.data);
                return res.data;
            })
            .catch(err => {
                console.log("error check Facility config violated", err);
                return err;
            });
    },
    fetchFacilityUserReport: ({ state, commit, rootState, dispatch, getters }, payload) => {
        commit("RESET_FACILITY_USERS");
        return facility
            .userReport(payload)
            .then(async res => {
                commit("SET_FACILITY_USERS", res.data);
                return res.data
            })
            .catch(err => {
                console.log("error while fetch user report", err);
            });
    },
    checkFacility: ({ state, commit, rootState, dispatch, getters }, payloadData) => {
        dispatch("showToast", { message: 'Checking...' })
        //const { customer_uid, payload } = payloadData;
        return facility
            .checkFacilityMail(payloadData)
            .then(res => {
                dispatch("clearAllToast");
                if (res?.data?.msg) {
                    dispatch("showToast", { class: 'bg-danger text-white', message: res.data.msg })
                    return res.data;
                }
                else {
                    dispatch("showToast", { class: 'bg-success text-white', message: 'Please Enter Facility Details' })
                    commit("SET_FACILITY_DETAILS", res.data)
                    router.push("/add-facility");
                    return res.data;
                }

            })
            .catch(err => {
                const msg = "Error While Checking Facility";
                console.log(msg, err);
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message: msg })
                return err;
            })
    },
    downloadAllFacilityDetails({ commit, dispatch, getters }, payload = {}) {
        dispatch("showFixedToast",
            { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
        const queryPayload = {
            ...payload,
        }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return facility
            .downloadAllFacilityDetails(queryString)
            .then(res => {
                let { data } = res;
                const document_name = `Facility Admin_Users`
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(data);
                    link.setAttribute("href", url);
                    link.setAttribute("download", document_name);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                return res;
            })
            .catch(err => {
                dispatch("showToast",
                    { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
                console.error("error in Download Document", err);
                return err;
            })
            .finally(() => {

                dispatch("hideFixedToast")
            })
    },
    downloadFacilityJobs({ commit, dispatch, getters }, payload = {}) {
        dispatch("showFixedToast",
            { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
        const queryPayload = {
            ...payload,
        }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return facility
            .downloadFacilityJobs(queryString)
            .then(res => {
                let { data } = res;
                const document_name = `Job Status Overview`
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(data);
                    link.setAttribute("href", url);
                    link.setAttribute("download", document_name);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                return res;
            })
            .catch(err => {
                dispatch("showToast",
                    { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
                console.error("error in Download Document", err);
                return err;
            })
            .finally(() => {

                dispatch("hideFixedToast")
            })
    },
    downloadCandidateStatus({ commit, dispatch, getters }, payload = {}) {
        dispatch("showFixedToast", {
            class: 'bg-secondary text-white',
            message: 'Preparing to Download...'
        })
        const queryPayload = {
            ...payload,
        }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return facility
            .downloadCandidateStatus(queryString)
            .then(res => {
                let { data } = res;
                const document_name = `Candidate Recruitment Status`
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(data);
                    link.setAttribute("href", url);
                    link.setAttribute("download", document_name);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: 'bg-danger text-white',
                    message: 'Download failed. Try again.'
                })
                console.error("error in Download Document", err);
                return err;
            })
            .finally(() => {
                dispatch("hideFixedToast")
            })
    },
    createCustomGroup: ({ dispatch, commit }, payload) => {
        return facility
            .createCustomGroup(payload)
            .then(res => {
                return res;
            })
            .catch(err => {
                const message = "Error While Create Group";
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                })
                console.log(message, err);
                return err;
            })
    },
    fetchCustomGroups: ({ dispatch, commit }) => {
        return facility
            .getCustomGroups()
            .then(res => {
                const { data } = res;
                commit("SET_CUSTOM_GROUPS", data);
                return res
            })
            .catch(err => {
                const message = "Error While Get Custom Groups";
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                })
                console.log(err)
                return err;
            })
    }
};

export default { state, getters, mutations, actions };