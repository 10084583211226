export function deepClone(obj) {
  return JSON.parse(JSON.stringify({ ...obj }));
}

export function isObject(a) {
  return !!a && a.constructor === Object;
}

export function isEmptyObjectCheck(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}


export function getFilterQueryStringWithoutArrayBasedOnSchema(pageName, filter, schema) {
  let filterKeys = Object.keys(filter);
  if (filterKeys.length) {
    let result = filterKeys.reduce((acc, item) => {
      if (filter[item].length) {
        let filteredIds
        const jobBoardNames = [
          "Public Job Board DOH",
          "JobList",
          "Public Job Board",
          "JobListNew",
          "JobBoardByStatus",
          "Job Board",
          "Demo DOH JOB BOARD"
        ];
        if (jobBoardNames.includes(pageName)) {
          if (['candidateLevel', 'candidateLevelCountryBased'].includes(item)) {
            filteredIds = `{${filter[item]}}`
          } else {
            filteredIds = filter[item].toString();
          }
        } else if (pageName == "Candidates") {
          if (item == 'nationalityInfo' || item == 'locationRegion' || item == 'preferredLocations' || item == 'locationLanguage') {
            filteredIds = `{${filter[item]}}`
          } else {
            filteredIds = filter[item].toString();
          }
        } else {
          filteredIds = filter[item].toString();
        }
        acc += `${schema[item] ? schema[item]["queryKey"] : item
          }=${filteredIds}&`;
      }
      return acc;
    }, "");
    return result.substring(0, result.length - 1);
  }
  return "";
}

export function getFilterQueryString(filter) {
  let filterKeys = Object.keys(filter);
  let result = filterKeys.reduce((acc, item) => {
    if (filter[item].length) {
      let filteredIds = filter[item].toString();
      acc += `${item}=[${filteredIds}]&`;
    }
    return acc;
  }, "");
  return result.substring(0, result.length - 1);
}

export function getFilterQueryStringWithoutArray(filter) {
  let filterKeys = Object.keys(filter);
  let result = filterKeys.reduce((acc, item) => {
    if (!(item == 'candidate_type_id__in' && filter[item] == 0)) {
      let filteredIds = filter[item]?.toString();
      acc += `${item}=${filteredIds}&`;
    }
    return acc;
  }, "");
  return result.substring(0, result.length - 1);
}

export function truncate(string, size) {
  return string.length > size ? string.slice(0, size - 1) + "…" : string;
}

export function queryAssignSelectedData(data, query) {
  for (let key in query) {
    if (query.hasOwnProperty(key) && data.hasOwnProperty(key)) {
      let arrayToStr = query[key].replace(/]|\[/g, "");
      let queryIdsResult = [...arrayToStr.split(/,\s*/)];
      let options = data[key]["options"];
      let selected = options.filter(val => queryIdsResult.includes(val.code.toString()));
      data[key]["selected"] = selected;
    }
  }
  if (query["ageLimit"]) {
    data["ageLimit"]["selected"] = JSON.parse(query["ageLimit"]);
  }
  return data;
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getScope = () => {
  let userData = JSON.parse(localStorage.getItem("userData")) || {};
  if (userData && userData.access_token) {
    let userDetails = parseJwt(userData.access_token || "")
    return userDetails && userDetails.scopes.length ? userDetails.scopes[0] : "";
  } else {
    return ""
  }
}

export const getCustomerId = () => {
  let userData = JSON.parse(localStorage.getItem("userData"))
  let userDetails = parseJwt(userData.access_token)
  return Number(userDetails.customer_id)
}

export function createUUID() {
  var s = [];
  var hexDigits = '0123456789abcdef';
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  var uuid = s.join('');
  return uuid;
}

export const CANDIDATE_URL = `/candidate-list`;
export const FACILITY_CANDIDATE_URL = `/candidate-list`;

export const Role = Object.freeze({
  systemAdmin: "system_admin",
  customerAdmin: "customer_admin", // Facility admin
  customerRecruiter: "customer_recruiter",
  customerCandidate: "customer_candidate",
  systemCandidate: "system_candidate",
  systemRecruiter: "system_recruiter",
  customerUser: "customer_user",
  jobAdvertiser: "job_advertiser",
})

export const getRoleType = () => {
  const currentUser = getScope();
  let id = null;
  switch (currentUser) {
    case 'system_admin':
      id = 4;
      break;
    case 'customer_admin':
      id = 5;
      break
    default:
      break
  }
  return id;
}

export const pendoInit = (payload) => {
  return pendo.initialize({
    visitor: {
      id: payload.user_uuid,   // Required if user is logged in
      email: payload.email, // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      role: payload.user_role     // Optional


      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: payload.customer_uuid // Highly recommended
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    }
  });
}

export function getDefaultBookmark(path) {
  let obj = {
    url: "",
    filter_name: "Default",
    isDefault: true
  };
  const currentUserRoleID = getRoleType();
  switch (path) {
    case "/candidate-list":
      if (currentUserRoleID === 4) {
        obj['url'] = CANDIDATE_URL;
      } else {
        obj['url'] = FACILITY_CANDIDATE_URL;
      }
      return obj
      break;
    default:
      return {}
  }
}

export const getLocalOrgDetail = () => {
  return Object(JSON.parse(localStorage.getItem("orgDetail")))
}

export function LOGIN_URL() {
  let orgDetail = JSON.parse(localStorage.getItem("orgDetail"));
  let path_acronym = orgDetail?.path_acronym;
  return String(path_acronym != undefined ? `/${path_acronym}` : "/");
}

export const IS_DOH_THEME = process.env.VUE_APP_THEME_NAME == "doh" ? true : false;

export const CONSTANT = Object.freeze({
  // Candidate type id
  PHYSICIAN: 1,
  NURSE: 2,
  AHP: 3,
  DENTIST: 4,
  NON_CLINIC_ADMIN: 6,

  // Document Type ID
  ORG_LOGO_DOC_TYPE: 17,
  JOB_PICTURE_DOC_TYPE: 19,

  // Advertisement Locations
  HOSPITAL_JOB_BOARD: "hospital_job_board",
  SOCIAL_MEDIA: "social_media",
  GROUP_JOB_BOARD: "group_job_board",

  // Template display Names
  ORGANISATION_DESCRIPTION: "Organisation Description"
})

export function appendAccessToken(url) {
  let userData = JSON.parse(localStorage.getItem("userData")) || {};
  if (url && userData?.access_token) {
    const token = encodeURIComponent(`Bearer ${userData?.access_token}`)
    return url.includes("?") ? `${url}&token=${token}` : `${url}?token=${token}`
  }
  return url;
}