<template>
  <div class="h-fixed">
    <CHeader
      class="bg-sidebar-color sticky-top c-fixed-height"
      fixed
      with-subheader
      light
      v-if="!isDemoPage"
    >
      <CToggler
        v-if="
          currentUserRole &&
          ![Role.systemCandidate, Role.customerCandidate].includes(
            currentUserRole
          )
        "
        in-header
        class="d-lg-none"
        style="margin-right: -28px; z-index: 99999"
        v-c-emit-root-event:toggle-sidebar-mobile
      >
        <span class="menu-toggle fa fa-bars" :class="getTextColor"></span>
      </CToggler>
      <div style="margin-top: auto; margin-bottom: auto">
        <div class="logo pr-1 mobile-pl-1 mx-2">
          <div>
            <img
              class="p-1 d-inline-block"
              :src="getHeaderLogo"
              :style="`${
                IS_DOH_THEME ? 'max-width: 100%;height: auto;' : 'height: 62px'
              }`"
              @error="$event.target.src = '/img/doh_logo_new.png'"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <span class="d-flex flex-column logo-title linespcad title">
        <span
          ><small
            >Kawader - Smart platform for Abu Dhabi's Healthcare
            Workforce</small
          >
        </span>
        <span
          ><small>كــــوادر- منصة ذكية لإدارة الكوادر الصحية في أبوظبي</small>
        </span>
      </span>
      <!-- <CButton  v-if="!isRegisterPage && getOrgAcronym"
                      name="register-now"
                      class="font-weight-bold active px-2 fs-15 text-white registration"
                      @click="redirectToFacalityRegistration"
                      >Facility Registration<span class="small-text mx-1"
                        ><sup>New</sup></span
                      ></CButton
                    > -->
      <span
        v-if="page == 'Direct Login' && isMobile"
        class="
          d-flex
          ml-auto
          align-items-center
          mr-1
          linespcad
          title
          pr-1
          text-nowrap
        "
      >
        <span
          ><small class="fs-10"
            ><a
              href="/public/job-board/2"
              class="c-header-nav-link text-white hover-decoration-none"
              >Job Board</a
            ></small
          >
        </span>
      </span>
      <div
        v-if="isAdminLogin && emailAlign"
        class="d-flex flex-row align-items-center text-primary ml-auto"
        :class="getTextColor"
      >
        <router-link
          to="/email-messages#notification"
          style="font-size: 1rem; cursor: pointer"
        >
          <div class="d-flex flex-column alignWidth pr-2 ml-2">
            <em
              class="fas fa-envelope fa-lg"
              :class="getTextColor"
              v-c-tooltip="'Email Messages'"
            >
            </em>
          </div>
        </router-link>
        <span class="badge badge-md badge-circle badge-count">{{
          getUnreadMessages
        }}</span>
      </div>
      <div v-if="currentUserRole" class="d-flex flex-column mt-auto mb-auto">
        <CHeaderNav
          v-if="
            currentUserRole !== Role.systemCandidate &&
            currentUserRole !== Role.customerCandidate &&
            currentUserRole !== Role.customerUser
          "
          :style="
            !isMobile && isAdminLogin ? 'min-height:auto;height:27px;' : ''
          "
          class="d-md-down-none mr-auto ml-3 d-flex align-items-center"
        >
          <CHeaderNavItem>
            <CDropdown
              v-if="
                [
                  Role.systemAdmin,
                  Role.customerAdmin,
                  Role.customerRecruiter,
                ].includes(currentUserRole)
              "
              class="nav-dropdown px-1"
              add-menu-classes="pt-0"
              ref="dashboard"
              :click="toggleClass('dashboard')"
              @mouseover.native="show('dashboard')"
              @mouseleave.native="hide('dashboard')"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <i class="fas fa-list mr-1"></i>
                  <span class="ml-2 header-nav">Dashboard</span>
                </div>
              </template>
              <CDropdownItem
                to="/dashboard"
                v-if="Role.systemAdmin === currentUserRole"
              >
                <i class="fas fa-chalkboard-teacher mr-2"></i>Admin Dashboard
              </CDropdownItem>
              <!-- <CDropdownItem
                :href="`${BI_REPORTS_URL}`"
                target="_blank"
                v-if="Role.systemAdmin === currentUserRole"
              >
                <i class="fas fa-chart-line mr-2"></i>BI Reports
              </CDropdownItem> -->
              <CDropdownItem to="/recruiter-dashboard" v-else>
                <i class="fas fa-chalkboard-teacher mr-2"></i>Recruiter
                Dashboard
              </CDropdownItem>
            </CDropdown>
          </CHeaderNavItem>
          <CHeaderNavItem>
            <CDropdown
              class="nav-dropdown px-1"
              add-menu-classes="pt-0"
              ref="candidates"
              @mouseover.native="show('candidates')"
              @mouseleave.native="hide('candidates')"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <i class="fas fa-briefcase-medical mr-1"></i>
                  <span class="ml-2 header-nav">Candidates</span>
                </div>
              </template>
              <CDropdownItem
                :to="getCandidateListUrlBasedOnRole"
                :click="toggleClass('candidates')"
              >
                <em class="fas fa-user-friends mr-2"></em>Talent Pool
              </CDropdownItem>
              <div
                v-if="
                  currentUserRole === Role.customerAdmin ||
                  currentUserRole === Role.customerRecruiter
                "
              >
                <CDropdownItem to="/candidates-status?status=applied">
                  <em class="fas fa-user-check mr-2"></em>Recruitment Status
                </CDropdownItem>
              </div>
            </CDropdown>
          </CHeaderNavItem>
          <CHeaderNavItem v-if="Role.systemAdmin === currentUserRole">
            <CDropdown
              class="nav-dropdown px-1"
              add-menu-classes="pt-0"
              ref="customers"
              @mouseover.native="show('customers')"
              @mouseleave.native="hide('customers')"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <CIcon name="cil-hospital" />
                  <span class="ml-2 header-nav">Facilities</span>
                </div>
              </template>
              <div>
                <CDropdownItem
                  to="/add-facility"
                  :click="toggleClass('customers')"
                >
                  <i class="fas fa-user-plus" style="margin-right: 10px" />Add
                  Facility
                </CDropdownItem>
                <CDropdownItem to="/facility-list">
                  <i class="fas fa-users" style="margin-right: 10px" />Facility
                  Config & Reports
                </CDropdownItem>
              </div>
            </CDropdown>
          </CHeaderNavItem>

          <!-- <CHeaderNavItem v-if="getIsParentUser">
            <CDropdown class="nav-dropdown px-1" add-menu-classes="pt-0">
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <CIcon name="cil-hospital" />
                  <span class="ml-2 header-nav">Organisation</span>
                </div>
              </template>
              <div>
                <CDropdownItem to="/add-organisation">
                  <CIcon name="cil-hospital" class="mr-1" />Add Organisation
                </CDropdownItem>
                <CDropdownItem to="/organisation-list">
                  <CIcon name="cil-hospital" class="mr-1" />Organisation List
                </CDropdownItem>
              </div>
            </CDropdown>
          </CHeaderNavItem> -->
          <!-- Temporarily Removed for R1 release -->
          <!-- <CHeaderNavItem
          v-if="( [Role.systemAdmin, Role.customerAdmin].includes(currentUserRole) )"
        >
          <CDropdown class="nav-dropdown px-1" add-menu-classes="pt-0">
            <template #toggler-content>
              <div class="d-flex align-items-center text">
                <CIcon name="cil-hospital" />
                <span class="ml-2 header-nav">Facilities</span>
              </div>
            </template>
            <div v-if="currentUserRole === Role.systemAdmin">
              <CDropdownItem to="/add-facility">
                <CIcon name="cil-user" style="margin-right:10px" />Add Facility
              </CDropdownItem>
              <CDropdownItem to="/facility-list">
                <CIcon name="cil-user" style="margin-right:10px" />Reports
              </CDropdownItem>
            </div>
            <div v-if="currentUserRole === Role.customerAdmin">
              <CDropdownItem to="/facility-detail">
                <CIcon name="cil-user" style="margin-right:10px" />Add Facility Details
              </CDropdownItem>
              <CDropdownItem to="/add-facilityuser">
                <CIcon name="cil-user" style="margin-right:10px" />Add Facility Admin / User
              </CDropdownItem>
            </div>
          </CDropdown>
        </CHeaderNavItem>  -->
          <CHeaderNavItem
            v-if="
              [Role.systemAdmin, Role.customerAdmin].includes(currentUserRole)
            "
          >
            <CDropdown
              class="nav-dropdown px-1"
              add-menu-classes="pt-0"
              ref="job"
              @mouseover.native="show('job')"
              @mouseleave.native="hide('job')"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <em class="fas fa-briefcase mr-1"></em>
                  <span class="ml-2 header-nav">Jobs</span>
                </div>
              </template>
              <CDropdownItem
                to="/add-job?step=0"
                v-if="[Role.customerAdmin].includes(currentUserRole)"
              >
                <em class="fas fa-laptop-medical mr-2"></em>Create Job
              </CDropdownItem>
              <CDropdown
                addTogglerClasses="sub-dropdown-item"
                placement="right-start"
                ref="jobBoard"
                @mouseover.native="show('jobBoard')"
                @mouseleave.native="hide('jobBoard')"
              >
                <template #toggler-content>
                  <div class="d-flex align-items-center">
                    <em class="fas fa-list-alt" style="margin-right: 10px"></em>
                    <span>View/Edit Job Board</span>
                  </div>
                </template>
                <CDropdownItem
                  to="/jobs-list/status/active"
                  :click="toggleClass('job')"
                >
                  <em class="fas fa-briefcase" style="margin-right: 10px"></em>
                  Active Jobs
                </CDropdownItem>
                <CDropdownItem
                  to="/jobs-list/status/inactive"
                  :click="toggleClass('job')"
                >
                  <em
                    class="fas fa-calendar-times"
                    style="margin-right: 10px"
                  ></em>
                  Inactive Jobs
                </CDropdownItem>
              </CDropdown>
              <CDropdownItem to="/job-list?published=%5B27,29%5D">
                <em class="fas fa-list mr-2"></em>Job List
              </CDropdownItem>
            </CDropdown>
          </CHeaderNavItem>

          <CHeaderNavItem v-if="Role.systemAdmin === currentUserRole">
            <CDropdown
              class="nav-dropdown px-1"
              add-menu-classes="pt-0"
              ref="config"
              @mouseover.native="show('config')"
              @mouseleave.native="hide('config')"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <em class="fas fa-cog mr-1"></em>
                  <span class="ml-2 header-nav">Config</span>
                </div>
              </template>
              <CDropdownItem
                to="/config-public-job-board"
                :click="toggleClass('config')"
              >
                <i class="fas fa-briefcase mr-2"></i>
                Public Job Board
              </CDropdownItem>
            </CDropdown>
          </CHeaderNavItem>

          <CHeaderNavItem v-if="Role.customerAdmin === currentUserRole">
            <CDropdown
              class="nav-dropdown px-1"
              add-menu-classes="pt-0"
              ref="settings"
              @mouseover.native="show('settings')"
              @mouseleave.native="hide('settings')"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <i class="fas fa-users-cog mr-1"></i>
                  <span class="ml-2 header-nav">Settings</span>
                </div>
              </template>
              <div v-if="getIsParentUser">
                <CDropdown
                  addTogglerClasses="sub-dropdown-item"
                  placement="right-start"
                  ref="organization"
                  @mouseover.native="show('organization')"
                  @mouseleave.native="hide('organization')"
                >
                  <template #toggler-content>
                    <div class="d-flex align-items-center">
                      <i class="fas fa-hospital" style="margin-right: 10px"></i>
                      <span>Organisation</span>
                    </div>
                  </template>
                  <CDropdownItem to="/add-organisation">
                    <i class="fas fa-hospital" style="margin-right: 10px"></i>
                    Add Organisation
                  </CDropdownItem>
                  <CDropdownItem to="/organisation-list">
                    <i class="fas fa-hospital" style="margin-right: 10px"></i>
                    List Organisation
                  </CDropdownItem>
                </CDropdown>
              </div>
              <CDropdown
                addTogglerClasses="sub-dropdown-item"
                placement="right-start"
                ref="users"
                @mouseover.native="show('users')"
                @mouseleave.native="hide('users')"
              >
                <template #toggler-content>
                  <div class="d-flex align-items-center">
                    <i
                      class="fas fa-user-friends"
                      style="margin-right: 10px"
                    ></i>
                    <span>Users</span>
                  </div>
                </template>
                <CDropdownItem
                  to="/add-contactuser"
                  :click="toggleClass('settings')"
                >
                  <i class="fas fa-user-plus" style="margin-right: 10px"></i>
                  Add User
                </CDropdownItem>
                <CDropdownItem to="/list-contactuser">
                  <i class="fas fa-users" style="margin-right: 10px"></i>
                  User List
                </CDropdownItem>
              </CDropdown>
              <CDropdownItem
                v-if="
                  [
                    Role.systemRecruiter,
                    Role.systemAdmin,
                    Role.systemCandidate,
                  ].includes(currentUserRole)
                "
                to="/configurable-fields"
              >
                <i class="fas fa-user-cog" style="margin-right: 10px"></i>
                Config Fields
              </CDropdownItem>
            </CDropdown>
          </CHeaderNavItem>
          <CHeaderNavItem
            v-if="
              [Role.systemRecruiter, Role.customerRecruiter].includes(
                currentUserRole
              )
            "
          >
            <CDropdown
              class="nav-dropdown px-1"
              add-menu-classes="pt-0"
              ref="recruiterJob"
              @mouseover.native="show('recruiterJob')"
              @mouseleave.native="hide('recruiterJob')"
            >
              <template #toggler-content>
                <div class="d-flex align-items-center text">
                  <em class="fas fa-briefcase mr-1"></em>
                  <span class="ml-2 header-nav">Jobs</span>
                </div>
              </template>
              <CDropdownItem
                to="/add-job?step=0"
                :click="toggleClass('recruiterJob')"
              >
                <em class="fas fa-laptop-medical mr-2"></em>Create Job
              </CDropdownItem>
              <CDropdown
                addTogglerClasses="sub-dropdown-item"
                placement="right-start"
                ref="recruiterJobBoard"
                @mouseover.native="show('recruiterJobBoard')"
                @mouseleave.native="hide('recruiterJobBoard')"
              >
                <template #toggler-content>
                  <div class="d-flex align-items-center">
                    <em class="fas fa-list-alt" style="margin-right: 10px"></em>
                    <span>View/Edit Job Board</span>
                  </div>
                </template>
                <CDropdownItem
                  to="/jobs-list/status/active"
                  :click="toggleClass('job')"
                >
                  <em class="fas fa-briefcase" style="margin-right: 10px"></em>
                  Active Jobs
                </CDropdownItem>
                <CDropdownItem
                  to="/jobs-list/status/inactive"
                  :click="toggleClass('job')"
                >
                  <em
                    class="fas fa-calendar-times"
                    style="margin-right: 10px"
                  ></em>
                  Inactive Jobs
                </CDropdownItem>
              </CDropdown>
              <CDropdownItem to="/job-list?published=%5B27,29%5D">
                <em class="fas fa-list mr-2"></em>Job List
              </CDropdownItem>
            </CDropdown>
          </CHeaderNavItem>

          <!-- Hiding this, Job Board to be launched later - by venki -->
          <!-- <CHeaderNav
        v-if="(currentUserRole === Role.systemCandidate || currentUserRole === Role.customerCandidate)"
        class="d-md-down-none mr-auto ml-5 d-flex align-items-center"
      >
        <CHeaderNavItem>
          <CDropdown class="nav-dropdown px-1" add-menu-classes="pt-0">
            <template #toggler-content>
              <div class="d-flex align-items-center text">
                <CIcon name="cil-briefcase" />
                <span class="ml-2 header-nav">Jobs</span>
              </div>
            </template>
            <CDropdownItem to="/public/job-board">
              <CIcon name="cil-user" style="margin-right:10px" />Job-board
            </CDropdownItem>
        </CDropdown>
      </CHeaderNavItem>-->
        </CHeaderNav>
        <small
          v-if="!isMobile && isAdminLogin && pageRoute"
          class="text-white ml-3 mb-0 d-flex"
          style="font-size: 12px; cursor: pointer"
          >{{ pageRoute }}
        </small>
      </div>
      <div class="d-flex ml-auto mr-1">
        <div
          v-if="isAdminLogin && !emailAlign"
          class="d-flex flex-row align-items-center text-primary ml-auto"
        >
          <router-link to="/email-messages#notification" class="mail-hover">
            <div class="d-flex flex-column alignWidth pr-2">
              <em
                class="fas fa-envelope fa-lg"
                :class="getTextColor"
                v-c-tooltip="'Email Messages'"
              >
              </em>
            </div>
          </router-link>
          <span class="badge badge-md badge-circle badge-count">{{
            getUnreadMessages
          }}</span>
        </div>
        <div
          id="headerIconHolder"
          class="d-flex flex-row align-items-center responsive ml-auto"
          :class="`${
            isAdminLogin &&
            [Role.customerAdmin, Role.customerRecruiter].includes(
              this.currentUserRole
            )
              ? ''
              : 'list-unstyled'
          }`"
          v-if="getImpersonationStatus"
        >
          <div
            id="loginUsername"
            ref="loginUsername"
            class="flex-column alignWidth mr-1"
            v-if="!getImpersonationStatus.impersonation && getUserName"
          >
            <div v-if="getUserName">
              <span class="pr-2" :class="getTextPrimaryColor"
                >Hello {{ getUserName }}</span
              >
              <span
                class="d-block w-80 fs-9"
                :class="getTextColor"
                v-if="getOrgName"
              >
                <small>{{ getOrgName }}</small></span
              >
            </div>
          </div>
          <div
            id="impersonation"
            class="d-flex flex-column linespcad title text alignWidth mx-2"
            v-if="getImpersonationStatus.impersonation"
          >
            <div>
              <span>You are now</span>
            </div>
            <div>
              <span>Facility Admin for</span>
            </div>
            <div>
              <span>{{ getImpersonationStatus.customer_name }}</span>
            </div>
          </div>
          <div
            v-if="isCustomerUser"
            class="d-flex flex-column linespcad title text alignWidth pl-2"
          >
            <div>
              <span>You are now</span>
            </div>
            <div>
              <span>{{ customerType }}</span>
            </div>
          </div>
          <div
            class="d-flex flex-row align-items-center"
            v-if="currentUserRole"
          >
            <TheHeaderDropdownAccnt v-if="currentUserRole" />
          </div>
          <div
            v-if="page == 'Direct Login' && !isMobile"
            class="d-flex flex-row align-items-center alignItems mr-4"
          >
            <a
              href="/public/job-board/2"
              class="c-header-nav-link text-white hover-decoration-none"
              >Job Board</a
            >
          </div>
          <div
            v-if="!currentUserRole"
            class="d-flex flex-row align-items-center alignItems"
          >
            <span
              class="signup-link align-right text-nowrap"
              :class="getTextPrimaryColor"
              v-if="!isRegisterPage && getOrgAcronym"
              ><span v-if="!isMobile">Not Registered?</span>
              <CButton
                name="register-now"
                color="link"
                class="font-weight-bold active px-0 fs-15 ml-1 mr-2"
                :class="getTextColor"
                @click="userRegister"
                >Sign up now</CButton
              >
            </span>
            <span
              class="signup-link align-right"
              :class="getTextPrimaryColor"
              v-if="isRegisterPage"
              ><span v-if="!isMobile">Already Registered?</span>
              <CButton
                name="register-now"
                color="link"
                class="font-weight-bold active px-0 fs-15 ml-1 mr-2"
                :class="getTextColor"
                @click="userSigin"
                >Sign In</CButton
              >
            </span>
          </div>
          <router-link
            to="/faq"
            class="mr-1 ml-1 faq"
            v-if="
              currentUserRole ||
              isRegisterPage ||
              (!isRegisterPage && getOrgAcronym)
            "
          >
            <i
              aria-hidden="true"
              title="Help"
              class="fa fa-question-circle"
            ></i>
          </router-link>
        </div>
      </div>
      <router-link to="/faq" class="mr-1 faq" v-if="!getImpersonationStatus">
        <i aria-hidden="true" title="Help" class="fa fa-question-circle"></i>
      </router-link>
      <div
        id="loginUsernameMobile"
        v-if="!getImpersonationStatus.impersonation && getUserName"
        ref="loginUsernameMobile"
        class="mr-2 ml-auto"
      >
        <div v-if="getUserName">
          <span class="d-block" :class="getTextColor" v-if="getOrgName">
            <span :class="getTextPrimaryColor">Hello {{ getUserName }} | </span>
            <small>{{ getOrgName }}</small>
          </span>
        </div>
      </div>
      <div id="impersonationMobile" class="mr-2 ml-auto" v-if="getImpersonationStatus.impersonation">
        <span
          >You are now Facility Admin for
          {{ getImpersonationStatus.customer_name }}</span
        >
      </div>
    </CHeader>
    <!-- <div class="additional-space"></div> -->
  </div>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import { mapGetters, mapActions } from "vuex";
import {
  Role,
  LOGIN_URL,
  getLocalOrgDetail,
  IS_DOH_THEME,
} from "@/helpers/helper";
import { BI_REPORTS_URL } from "@/service_urls";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      Role,
      page: "",
      pagePath: "",
      getOrgAcronym: "",
      isMobile: false,
      isSmall: false,
      emailAlign: false,
      IS_DOH_THEME,
      BI_REPORTS_URL,
    };
  },
  computed: {
    ...mapGetters([
      "getRoleScopes",
      "getUser",
      "getUserId",
      "getImpersonationStatus",
      "getCandidateListUrlBasedOnRole",
      "getIsParentUser",
      "getNotificationMessagesCount",
      "getImages",
      "currentUserType",
      "customerUserRoles",
      "getOrgName",
    ]),
    pageRoute() {
      let text = "";
      if (this.page === "Candidate") {
        text = "Candidates > Talent Pool > Candidate Detailed View";
        return text;
      } else if (this.page === "Job List Preview") {
        text = "Jobs > Job List > Job Detailed View";
        return text;
      } else if (this.pagePath === "/add-contactuser") {
        text = "Settings > Users > Add User";
        return text;
      } else if (this.pagePath === "/list-contactuser") {
        text = "Settings > Users > List User";
        return text;
      } else if (this.pagePath === "/jobs-list/status/active") {
        text = "Jobs > Job Board > Active Jobs";
        return text;
      } else if (this.pagePath === "/jobs-list/status/inactive") {
        text = "Jobs > Job Board > Inactive Jobs";
        return text;
      } else if (this.pagePath === "/add-organisation") {
        text = "Settings > Organisation > Add Organisation";
        return text;
      } else if (this.pagePath === "/organisation-list") {
        text = "Settings > Organisation > List Organisation";
        return text;
      }
    },
    currentUserRole() {
      return this.getRoleScopes.length ? this.getRoleScopes[0] : "";
    },
    isAdminLogin() {
      if (
        [
          this.Role.customerAdmin,
          this.Role.systemAdmin,
          this.Role.customerRecruiter,
          this.Role.systemRecruiter,
        ].includes(this.currentUserRole)
      )
        return true;
      return false;
    },
    getUserName() {
      let username = this.getUser.first_name;
      let systemAdmin = 1;
      if (this.getUserId === systemAdmin) {
        username = this.getUser.user_name;
      }
      if (username) {
        return username.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
      return false;
    },
    getHeaderLogo() {
      return this.getImages?.header_logo;
    },
    isDemoPage() {
      return [
        "Demo TalentFind",
        "Demo DOH",
        "Demo DOH JOB BOARD",
        "Demo DOH PQR CHECK",
        "Public Job Board DOH",
        "Public Job Board Detail",
      ].includes(this.page);
    },
    isRegisterPage() {
      return [
        "Register",
        "Organisation Register",
        "Organisation with Location Register",
      ].includes(this.page);
    },
    getUniqConversation() {
      let array = [];
      array = _.uniqBy(this.getMessages, (v) =>
        [v.email_id, v.sender, v.subject].join()
      );
      return this.getMessages.length ? array : [];
    },
    getUnreadMessages() {
      return this.getNotificationMessagesCount;
    },
    isCustomerUser() {
      return this.currentUserRole === "customer_user" && this.currentUserType;
    },
    isCandidate() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    customerType() {
      return this.currentUserType?.label || "";
    },
    getTextColor() {
      return IS_DOH_THEME ? "text-white" : "text-primary";
    },
    getTextPrimaryColor() {
      return IS_DOH_THEME ? "text-white" : "text-primary-color";
    },
  },
  created() {
    if (this.currentUserRole) {
      this.fetchUser();
    }
  },
  updated() {
    if (
      window.matchMedia("only screen and (max-width: 359px)").matches &&
      this.$router.currentRoute.path !== "/"
    )
      this.isSmall = true;
    if (
      window.matchMedia("(min-width: 992px) and (max-width: 1250px)").matches &&
      this.$router.currentRoute.path !== "/"
    )
      this.isSmall = true;
    let isMobile =
      (window.matchMedia("only screen and (max-width: 540px)").matches &&
        this.$router.currentRoute.path !== "/") ||
      (window.matchMedia("only screen and (max-width: 1250px)").matches &&
        this.$router.currentRoute.path !== "/") ||
      (window.matchMedia("only screen and (max-width: 540px)").matches &&
        this.page == "Direct Login") ||
      (window.matchMedia("only screen and (max-width: 1250px)").matches &&
        this.page == "Direct Login");
    this.isMobile = isMobile;
    if (window.matchMedia("(min-width: 290px) and (max-width: 530px)").matches)
      this.emailAlign = true;
  },
  methods: {
    ...mapActions(["fetchUser", "resetCandidate", "fetchOrgDetailsByAcronym"]),
    userRegister() {
      this.resetCandidate();
      this.$router.push({
        // path: `/${getLocalOrgDetail()?.path_acronym}/register/${
        //   getLocalOrgDetail()?.customer_uid
        // }${
        //   getLocalOrgDetail()?.organisation_id
        //     ? `/organisation/${getLocalOrgDetail()?.organisation_id}/location/${
        //         getLocalOrgDetail()?.location_id
        //       }`
        //     : ""
        // }`,

        // for DOH: Mail communication needs organisation id, so hardcoded
        path: `/${getLocalOrgDetail()?.path_acronym}/register/${
          getLocalOrgDetail()?.customer_uid
        }/organisation/1/location/1`,
      });
    },
    userSigin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    toggleClass(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].hide(true);
      }
    },
    show(ref) {
      if (this.$refs[ref]) this.$refs[ref]._data.visible = true;
    },
    hide(ref) {
      if (this.$refs[ref]) this.$refs[ref]._data.visible = false;
    },
    redirectToFacalityRegistration() {
      this.$router.push({
        path: `/check-facility`,
      });
    },
  },
  watch: {
    "$route.name": function () {
      this.page = this.$router.currentRoute.name;
      const {
        params: { org_name: pid },
      } = this.$router.currentRoute;
      this.getOrgAcronym = pid || "";
      this.fetchOrgDetailsByAcronym();
    },
    "$route.path": function () {
      this.pagePath = this.$router.currentRoute.path;
    },
  },
};
</script>

<style lang="scss">
.logo {
  width: 9rem;
}
#loginUsernameMobile {
  display: none;
  margin-top: -10px;
  width: 100%;
  text-align: right;
  font-size: 10px;
  margin-right: 5px;
  z-index: 999;
}
#loginUsername {
  margin-left: auto;
}

#headerIconHolder {
  position: relative;
  float: right;
  margin-left: auto !important;
}
.fa-question-circle {
  color: #e2e2e2;
  font-size: 36px;
}

.text {
  font-size: 1.25em;
  color: $text-base-color;
  font-weight: 400;
}

.menu-toggle {
  font-size: 24px;
}

.list-unstyled {
  margin-left: auto;
}

@media (max-width: 1024px) {
  .list-unstyled {
    margin-left: auto;
  }
  .additional-space {
    height: 30px;
  }
  #loginUsername {
    display: none;
  }
  #loginUsernameMobile {
    display: block;
  }
  .avatar {
    width: 28px !important;
    height: auto !important;
  }
  .fa-question-circle {
    margin-top: 7px;
    font-size: 29px;
  }
}

.responsive {
  @media (max-width: 1250px) and (min-width: 1024px) {
    float: right;
    margin-left: 700px;
  }
  @media (max-width: 620px) and (min-width: 490px) {
    float: right;
    margin-left: 230px;
  }
  @media (max-width: 610px) {
    float: right;
  }
}

@media (max-width: 480px) {
  .flex-d {
    display: flex;
    align-items: center;
  }
  .alignItems {
    width: 100%;
  }
  .icon-align {
    justify-content: flex-end !important;
    width: 20%;
  }
  .align {
    width: 50%;
  }
  .alignWidth {
    margin-left: 10px !important;
    width: 60%;
  }
  .signup-link,
  .signup-link button {
    font-size: 12px !important;
    margin-left: auto;
    // margin-right: 1rem;
  }
  #loginUsername {
    display: none;
  }
  #loginUsernameMobile {
    display: block;
  }
  .avatar {
    width: 28px !important;
    height: auto !important;
  }
  .fa-question-circle {
    font-size: 29px;
  }
}
.title {
  line-height: 11px;
  font-size: 10px;
}
.faq-icon {
  position: absolute;
  right: 2%;
}
.faq-clickable-area {
  height: 24px;
  width: 24px;
  padding: 2px 5px;
}
.text-primary-color {
  color: $text-primary-color;
}
.faq {
  margin-bottom: 6px;
}
.fs-15 {
  font-size: 15px !important;
}
.badge-count {
  position: relative;
  top: -8px;
  left: -15px;
  background-color: #eee;
  border: 0.2px solid #0a5e67;
  border-radius: 15px !important;
  padding: 0.4em !important;
  justify-content: center;
}
.badge-circle {
  width: auto;
  height: 1.2rem;
  min-width: 1.5rem;
}
.bg-sidebar-color {
  background-color: $sidebar-color !important;
}
.mail-hover {
  font-size: 1rem;
  cursor: pointer;
}
.mail-hover:hover {
  text-decoration: none;
}
.h-fixed {
  padding-bottom: 60px !important;
}
.hover-decoration-none:hover {
  text-decoration: none;
}
@media (max-width: 480px) {
  .registration {
    font-size: 12px !important;
  }
  .mobile-pl-1 {
    padding-left: 0rem !important;
  }
}
.w-80 {
  width: 80%;
}
.fs-9 {
  font-size: 9.6px;
}
.fs-10 {
  font-size: 10px !important;
}
@media (max-width: 480px) {
  #impersonation {
    display: none !important;
  }
  #impersonationMobile {
    display: block !important;
    font-size: 10px;
    margin-top:5px !important;
  }
}
@media (min-width: 480px) {
  #impersonationMobile {
    display: none !important;
  }
}
</style>
