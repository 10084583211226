import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const dashboard = {
    getCandidateByType: (customer_id) => axios.get(`${BASE_URL}/candidate/report/by_candidate_type/?customer_id=${customer_id}`),
    getCandidateByTypeReport: (customerId, query) => axios.get(`${BASE_URL}/candidate/report/by_candidate_type_report/?customer_id=${customerId}&${query}`),
     getRecruitedCandidateCount: ({ customer_id, query }) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/shortlist/total_count?${query}`),
    getRegisteredUserCount: ({ query }) => axios.get(`${BASE_URL}/users/total_count?role_type_id__notin=6&${query}`),
    getCandidateCountList: ({ start_range, end_range }) => axios.get(`${BASE_URL}/candidate/report/candidate_count?start_range=${start_range}&end_range=${end_range}`),
    getJobApplicationCount: ({ start_range, end_range }) => axios.get(`${BASE_URL}/candidate/report/job_application_count?start_range=${start_range}&end_range=${end_range}`),
    getFacilitiesCount: (query) => axios.get(`${BASE_URL}/organisation/?${query}`),
    getApplicationCount: (query) => axios.get(`${BASE_URL}/job/application/get_count_of_all_applied_candidates?${query}`),
    getShortlistCount: ({customer_id,query}) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/shortlist/total_count?${query}`),
    getDashboardAll:(query)=> axios.get(`${BASE_URL}/admin_dashboard/count?${query}`),
}

export default dashboard;
