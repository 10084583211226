<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowModal"
      :closeOnBackdrop="closeOnBackdrop"
    >
      <template #header>
        <h5>{{ modalTitle }}</h5>
        <button aria-label="Close" class="close" @click="modalCallBack(false)">
          x
        </button>
      </template>
      {{ modalContent }}
      <template #footer>
        <div v-if="stepperModal">
          <span v-for="button in buttons" :key="button">
            <CButton
              class="ml-2"
              :color="button == 'Continue Anyway' ? 'secondary' : modalColor"
              @click="modalCallBack(button)"
              >{{ button }}</CButton
            >
          </span>
        </div>
        <div v-else>
          <span v-for="button in buttons" :key="button">
            <CButton
              class="ml-2"
              :variant="
                button == 'Cancel' || button == 'No' ? `outline` : undefined
              "
              :color="modalColor"
              @click="modalCallBack(button)"
              >{{ button }}</CButton
            >
          </span>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
export default {
  name: "Modal",
  props: [
    "modalTitle",
    "modalColor",
    "modalContent",
    "isShowPopup",
    "buttons",
    "modalCallBack",
    "closeOnBackdrop",
    "stepperModal",
  ],
  data() {
    return {
      isShow: this.isShowPopup,
    };
  },
  watch: {
    isShowPopup(newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    isShowModal: {
      get() {
        return this.isShow;
      },
      set(isShowPopup) {
        this.isShow = isShowPopup;
      },
    },
  },
};
</script>
