import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const auditHistory = {
    getStatusChange: ({ query }) => axios.get(`${BASE_URL}/action_detail/status_change?order_by=-created_on&${query}`),
    getComments: ({ query }) => axios.get(`${BASE_URL}/action_detail/comments?order_by=-created_on&${query}`),
    getCommunications: ({ query }) => axios.get(`${BASE_URL}/action_detail/communications?order_by=-created_on&${query}`),
    getCandidateTransactions: ({ customer_uid, candidate_uid, query }) =>
        axios.get(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/audit_trails/?order_by=-transaction_date_time&date_field=transaction_date_time&${query}`),
    getCandidateEmailCommunication: ({ candidate_uid, query }) =>
        axios.get(`${BASE_URL}/communication/email/conversation?candidate_uid=${candidate_uid}&order_by=-message_id&date_field=load_datetime&${query}`),
    getPrivateJobComments: ({ job_uuid, query }) => axios.get(`${BASE_URL}/job/${job_uuid}/job_candidate_comment/?${query}`),
    getPrivateCandidateComments: ({ candidate_uuid, query }) => axios.get(`${BASE_URL}/candidate/${candidate_uuid}/job_candidate_comment/?${query}`),
}

export default auditHistory;