export default {
  candidateType: {
    name: "candidateType",
    component: "filterSelect",
    label: "Candidate Type",
    queryKey: "candidate_type_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  recruitingCandidateLevel: {
    name: "recruitingCandidateLevel",
    component: "filterSelect",
    label: "Candidate Level",
    queryKey: "level_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  speciality: {
    name: "speciality",
    component: "filterSelect",
    label: "Speciality",
    queryKey: "speciality_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    dependentFilter: ["subSpeciality", "specialInterest", "skill"]
  },
  subSpeciality: {
    name: "subSpeciality",
    component: "filterSelect",
    label: "Sub Speciality",
    queryKey: "sub_speciality_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  specialInterest: {
    name: "specialInterest",
    component: "filterSelect",
    label: "Special Interest",
    queryKey: "special_interest_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  skill: {
    name: "skill",
    component: "filterSelect",
    label: "Skill",
    queryKey: "skill_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  qualification: {
    name: "qualification",
    component: "filterSelect",
    label: "Qualification",
    queryKey: "qualification_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },

  jobStatus: {
    name: "jobStatus",
    component: "filterSelect",
    label: "Job Status",
    queryKey: "job_status_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  locationLanguage: {
    name: "locationLanguage",
    component: "filterSelect",
    label: "Location Language",
    queryKey: "location_language_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  locationCountryInfo: {
    name: "locationCountryInfo",
    component: "filterSelect",
    label: "Job Country",
    queryKey: "country_info_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  countryIncluded: {
    name: "countryIncluded",
    component: "filterSelect",
    label: "Preferred Nationality",
    queryKey: "country_included_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  locationRegion: {
    name: "locationRegion",
    component: "filterSelect",
    label: "Region",
    queryKey: "location_region_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    clearable: false
  }
};
