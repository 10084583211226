import { getFilterQueryStringWithoutArray, Role } from "@/helpers/helper.js";
import customer from "@/services/customer";
import m from "moment";
import _ from 'lodash'
import Vue from 'vue'
import router from '../router/index';
import candidate from "@/services/candidate";
import jobBoard from "@/services/jobBoard";

const ageingDates = {
  'ageing_less_then_7_days': {
    gt: -1,
    lte: 7
  },
  'ageing_less_then_15_days': {
    gt: 7,
    lte: 15
  },
  'ageing_less_then_30_days': {
    gt: 15,
    lte: 30
  },
  'ageing_less_then_90_days': {
    gt: 30,
    lte: 90
  },
  'ageing_less_then_180_days': {
    gt: 90,
    lte: 180
  },
  'ageing_less_then_365_days': {
    gt: 180,
    lte: 365
  }
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}
// initial state

const getDefaultState = () => {
  return {
    fullPathCandidateListBackUrl: [],
    isFetchingCandidates: false,
    isFetchingCandidatesDetails: false,
    candidates: [],
    profiles: [],
    selectedCandidate: {},
    candidateDetails: {},
    candidateListFilter: {},
    candidatePagination: {
      limit: 10,
      skip: 0,
      noMoreDataFromCandidateList: false
    },
    candidateTotal: 0,
    getTotalCount: 0,
    backcount: 0,
    shortlistCandidates: [],
    shortlistedCount: 0,
    recruitlistCandidates: [],
    shortliststatus: {},
    interviewstatus: {},
    releasestatus: {},
    recruitstatus: {},
    reasons: [],
    jobs: [],
    currentNavigationCandidatesPage: [],
    dashboardMetrics: {},
    candidateExpiryCount: {},
    getAllCandidateJobStatus: null,
    getAllJobs: null,
    selectedCandidateCustomerID: null,
    filteredStatusCandidates: {},
    isFetchingStatusCandidates: false,
    jobCandidatesPagePagination: {
      limit: 10,
      skip: 0,
      noMoreCandidates: false,
    },
    getShortlistStatusCount: {
      applied: 0,
      shortlist: 0,
      interview: 0,
      recruit: 0,
      release: 0,
      withdrawn: 0
    },
    filteredStatusCandidatesCount: 0
  }
}
const state = getDefaultState();

// create getters for all state variables
const getters = {
  getAllCandidateJobStatus: (state) => state.getAllCandidateJobStatus,
  getShortlistStatusCount: (state) => state.getShortlistStatusCount,
  getAllJobsByExpiryDate: (state) => {
    const jobsByExpiryDate = {}
    _.keys(ageingDates).forEach(key => {
      jobsByExpiryDate[key] = state.getAllJobs?.filter(job => {
        const today = m()
        const dateDiff = m(job.expiry_review_date).diff(today.format('YYYY-MM-DD'), 'days')
        return dateDiff > ageingDates[key].gt && dateDiff <= ageingDates[key].lte
      })
    });
    return jobsByExpiryDate
  },
  candidateListBackUrl: (state, getters, rootState) => {
    let { getRoleScopes } = getters;
    let candidateListBackUrl = {
      admin: "/candidate-list",
      facility: "/candidate-list"
    }
    let backURL = state.fullPathCandidateListBackUrl;
    if (!backURL.length) {
      if (getRoleScopes[0] === Role.systemAdmin) {
        return candidateListBackUrl.admin
      }
      else {
        return candidateListBackUrl.facility
      }
    }
    else {
      return backURL[backURL.length - 1]
    }
  },
  isFetchingCandidates: (state, getters, rootState) =>
    state.isFetchingCandidates,
  isFetchingCandidatesDetails: (state, getters, rootState) =>
    state.isFetchingCandidatesDetails,
  getCandidates: (state, getters, rootState) => state.candidates,
  getCandidateCount: (state, getters, rootState) => state.candidateTotal,
  noMoreDataFromCandidateList: (state, getters, rootState) =>
    state.candidatePagination.noMoreDataFromCandidateList,
  candidateListFilter: (state, getters, rootState) => state.candidateListFilter,
  getCandidatePaginationSkip: (state, getters, rootState) =>
    state.candidatePagination.skip,
  getCandidatePaginationLimit: (state, getters, rootState) =>
    state.candidatePagination.limit,
  getCandidateDetail: (state, getters, rootState) => state.candidateDetails,
  getInProcessProfile: (state, getters, rootState) => state.profiles,
  getSelectedCandidate: (state, getters, rootState) => state.selectedCandidate,
  selectedJobId: (state, getters, rootState) => {
    let { getSelectedCandidate } = getters;
    return getSelectedCandidate.job_id;
  },
  currentNavigationCandidatesPage: (state, getters, rootState) => state.currentNavigationCandidatesPage,
  getPreviousCandidateId: (state, getters, rootState) => {
    let { getCandidates, candidateProfile, getFilteredStatusCandidates, getJobCandidatesByStatusID, currentNavigationCandidatesPage } = getters;
    let _currentPage = {
      candidateStatus: "Candidates Status",
      jobCandidateStatus: "Job Candidates Status"
    }
    let candidateIndex;
    if (currentNavigationCandidatesPage.currentPage === _currentPage.jobCandidateStatus) {
      candidateIndex = getJobCandidatesByStatusID.findIndex(e => e.candidate.candidate_uid === candidateProfile?.candidate_uid);
      return candidateIndex > 0 ? getJobCandidatesByStatusID[candidateIndex - 1].candidate?.candidate_uuid : 0;
    }
    if (currentNavigationCandidatesPage.currentPage === _currentPage.candidateStatus) {
      getCandidates = getFilteredStatusCandidates;
    }
    candidateIndex = getCandidates.findIndex(e => e.candidate_uid === candidateProfile.candidate_uid);
    if (candidateIndex < 0 && currentNavigationCandidatesPage.lastSlicedCandidateIndex) {
      candidateIndex = currentNavigationCandidatesPage.lastSlicedCandidateIndex
    }
    return candidateIndex > 0 ? getCandidates[candidateIndex - 1].candidate_uuid : 0;
  },
  getNextCandidateId: (state, getters, rootState) => {
    let { getCandidates, candidateProfile, getFilteredStatusCandidates, getJobCandidatesByStatusID, currentNavigationCandidatesPage } = getters;
    let _currentPage = {
      candidateStatus: "Candidates Status",
      jobCandidateStatus: "Job Candidates Status"
    }
    let candidateIndex;
    if (currentNavigationCandidatesPage.currentPage === _currentPage.jobCandidateStatus) {
      candidateIndex = getJobCandidatesByStatusID.findIndex(e => e.candidate.candidate_uid === candidateProfile?.candidate_uid);
      return candidateIndex + 1 == getJobCandidatesByStatusID.length ? 0 : getJobCandidatesByStatusID[candidateIndex + 1].candidate?.candidate_uuid;
    }
    if (currentNavigationCandidatesPage.currentPage === _currentPage.candidateStatus) {
      getCandidates = getFilteredStatusCandidates;
    }
    candidateIndex = getCandidates.findIndex(e => e.candidate_uid === candidateProfile?.candidate_uid);
    if (candidateIndex < 0 && currentNavigationCandidatesPage.lastSlicedCandidateIndex) {
      candidateIndex = currentNavigationCandidatesPage.lastSlicedCandidateIndex - 1
    }
    return candidateIndex + 1 == getCandidates.length ? 0 : getCandidates[candidateIndex + 1].candidate_uuid;
  },
  getBackCount: (state, getters, rootState) => state.backcount,
  getTotalCount: (state, getters, rootState) => state.getTotalCount,
  reasonsFilterByReasonType: (state, getters, rootState) => ({ reasonType }) => {
    return state.reasons && state.reasons.length
      ? state.reasons
        .filter(reasonTypeFilter => {
          return reasonTypeFilter.reason_type.reason_type === reasonType
        })
        .map(val => ({
          label: val.reason,
          code: val.reason_id
        }))
      : []
  },
  getCandidateJobs: (state, getters, rootState) =>
    state?.jobs?.map(val => ({
      label: val.job_title + ' | ' + val.customer_uid + '-' + val.job_id,
      code: val.job_id,
    })),

  //selected Candidate getters
  getshortlistcandidates: (state, getters, rootState) => state.shortlistCandidates,
  getShortliststatus: (state, getters, rootstate) => state.shortliststatus,
  getInterviewstatus: (state, getters, rootstate) => state.interviewstatus,
  getReleasestatus: (state, getters, rootstate) => state.releasestatus,
  getRecruitstatus: (state, getters, rootstate) => state.recruitstatus,
  getrecruitedlistcandidates: (state, getters, rootstate) => state.recruitlistCandidates,
  getDashboardMetrics: (state, getters, rootState) => state.dashboardMetrics,
  getCandidateExpiryCount: (state, getters, rootState) => state.candidateExpiryCount,
  getReasons: (state, getters, rootState) => state.reasons && state.reasons.length
    ? state.reasons
      .map(val => ({
        label: val.reason,
        code: val.reason_id,
        reason_type: val?.reason_type?.reason_type
      }))
    : [],
  getSelectedCandidateCustomerID: state => state.selectedCandidateCustomerID?.customer_uid,
  getFilteredStatusCandidates: (state) => state.filteredStatusCandidates || [],
  isFetchingStatusCandidates: (state) => state.isFetchingStatusCandidates,
  jobCandidatesPagePagination: (state) => state.jobCandidatesPagePagination,
  filteredStatusCandidatesCount: (state) => state.filteredStatusCandidatesCount,
};

// actions
const actions = {
  setCandidateListBackUrl({ state, commit }, url) {
    commit("SET_CANDIDATE_LIST_BACK_URL", url);
  },
  getDocumentsAction({ state, commit, rootState, dispatch, getters }, { candidate_uuid, document_name }) {
    return customer
      .getCandidateDocuments(candidate_uuid)
      .then(res => {
        let { data } = res;
        return data
      }).then((data) => {
        const link_to_doc = data[data.length - 1].link_to_doc.replace('/api/v1/', '')
        dispatch('downloadDocument', { url: link_to_doc, document_name })
      })
  },
  downloadDocument(
    { state, commit, rootState, dispatch, getters },
    { url, document_name }
  ) {
    dispatch("showFixedToast",
      { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
    return customer
      .downloadDocumentFile(url)
      .then(res => {
        let { data } = res;
        const fileName = document_name;
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(data);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        dispatch("hideFixedToast")
      })
      .catch(err => {
        dispatch("hideFixedToast")
        dispatch("showToast",
          { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
        console.error("error in Candidate Details", err);
        return err;
      });
  },
  resetCandidateList({ state, commit }, payload) {
    commit("RESET_CANDIDATE_LIST")
  },
  getCandidateDetails: async (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { getCustomerId } = getters;
    let customer_uid = getCustomerId;
    if (!customer_uid) {
      await dispatch("getCustomerIDByCandidateID", payload);
      const { getSelectedCandidateCustomerID } = getters;
      customer_uid = getSelectedCandidateCustomerID;
    }
    commit("SET_IS_FETCHING_CANDIDATE_DETAILS", false);
    return customer
      .getCandidateDetail({ candidate_uuid: payload, customer_uid })
      .then(res => {
        let { data } = res;
        dispatch("commitCandidateInfo", data);
        return data;
      })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      })
      .finally(res => {
        commit("SET_IS_FETCHING_CANDIDATE_DETAILS", true);
      })
  },
  getJobByCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    return customer.getJobByCandidate(payload).then(res => {
      let { data } = res;
      if (data.length) {
        dispatch("setSelectedCandidate", data[0]);
      }
      commit("SET_CANDIDATE_DETAIL_PROFILE", data);
    });
  },
  createshortlist({ commit, dispatch, getters }, payload) {
    let { getOrgIDFromAccessToken, getFilteredStatusCandidates } = getters;
    payload = {
      ...payload,
      organisation_id: getOrgIDFromAccessToken
    }
    return customer.saveShortlistCandidates(payload).then(res => {
      dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate shortlisted successfully' });
      if (getFilteredStatusCandidates.length && payload?.application_id) {
        for (var i = 0; i < getFilteredStatusCandidates.length; i++) {
          if (getFilteredStatusCandidates[i].application_id == payload?.application_id) {
            getFilteredStatusCandidates.splice(i, 1);
            let data = { lastSlicedCandidateIndex: i }
            dispatch("setCurrentNavigationCandidatesPage", data)
            i = i - 1;
          }
        }
        commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates);
        dispatch("getFilteredCandidatesStatusCount");
        dispatch("fetchAppliedCandidatesForJobsCount");
      }
      return res;
    }).catch(err => {
      const { status, data } = err.response;
      if (err && status == 500 && data.detail == "MAX SHORTLIST COUNT REACHED") {
        dispatch("showToast", { class: 'bg-danger text-white', message: 'You have reached maximum shortlist count.' });
      }
      console.log("error in ShortlistCandidates", err);
      return err;
    })
  },
  createInterviewlist({ state, commit, rootState, dispatch, getters }, payload) {
    let { getFilteredStatusCandidates, currentNavigationCandidatesPage, getShortliststatus } = getters;
    return customer.updateShortlistStatus(payload).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate has been selected for Interview successfully' });
        if (getFilteredStatusCandidates.length > 0) {
          for (var i = 0; i < getFilteredStatusCandidates.length; i++) {
            if (getFilteredStatusCandidates[i].candidate_status_id == payload.candidate_status_id) {
              getFilteredStatusCandidates.splice(i, 1);
              let data = { lastSlicedCandidateIndex: i }
              dispatch("setCurrentNavigationCandidatesPage", data)
              i = i - 1;
            }
          }
          commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates);
          dispatch("getTotalCandidates");
          if (currentNavigationCandidatesPage.currentPage == "Candidates Status") {
            dispatch("getFilteredCandidatesStatusCount")
            dispatch("getTotalSelectedCandidatesCount", getShortliststatus.status_id) //To get shortlist count
          }
        }
        return res;
      }
    })
      .catch(err => {
        const { status, data } = err.response;
        if (err && status == 500 && data.detail == "MAX INTERVIEW COUNT REACHED") {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'You have reached maximum interview count.' });
        }
        console.log("error in updating Interviewstatus", err);
        return err;
      });
  },
  createRecruitList({ state, commit, rootState, dispatch, getters }, payload) {
    let { getFilteredStatusCandidates, currentNavigationCandidatesPage, getInterviewstatus } = getters;
    return customer.updateShortlistStatus(payload).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate has been selected to Recruit successfully' });
        if (getFilteredStatusCandidates.length > 0) {
          for (var i = 0; i < getFilteredStatusCandidates.length; i++) {
            if (getFilteredStatusCandidates[i].candidate_status_id == payload.candidate_status_id) {
              getFilteredStatusCandidates.splice(i, 1);
              let data = { lastSlicedCandidateIndex: i }
              dispatch("setCurrentNavigationCandidatesPage", data)
              i = i - 1;
            }
          }
          commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates);
          if (currentNavigationCandidatesPage.currentPage == "Candidates Status") {
            dispatch("getFilteredCandidatesStatusCount")
            dispatch("getTotalSelectedCandidatesCount", getInterviewstatus.status_id) //To get interview list count
          }
        }
        return res;
      }
    })
      .catch(err => {
        const { status, data } = err.response;
        if (err && status == 500 && data.detail == "MAX RECRUIT COUNT REACHED") {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'You have reached maximum recruit count.' });
        }
        console.log("error in updating Recruitstatus", err);
        return err;
      });
  },
  releaseCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { getFilteredStatusCandidates, getShortliststatus, getInterviewstatus, getRecruitstatus, currentNavigationCandidatesPage } = getters;
    let releasefrom = payload.releasefrom;
    delete payload.releasefrom;
    return customer.updateShortlistStatus(payload).then(async res => {
      if (res.status == 200) {
        if (releasefrom === getShortliststatus.status_id) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Shortlisted candidate released successfully' });
        }
        if (releasefrom === getInterviewstatus.status_id) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate selected for Interview has been released successfully' });
        }
        if (releasefrom === getRecruitstatus.status_id) {
          dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate selected for Recruit has been released successfully' });
        }
        if (getFilteredStatusCandidates.length > 0) {
          for (var i = 0; i < getFilteredStatusCandidates.length; i++) {
            if (getFilteredStatusCandidates[i].candidate_status_id == payload.candidate_status_id) {
              getFilteredStatusCandidates.splice(i, 1);
              let data = { lastSlicedCandidateIndex: i }
              dispatch("setCurrentNavigationCandidatesPage", data)
              i = i - 1;
            }
          }
          commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates);
          if (currentNavigationCandidatesPage.currentPage == "Candidates Status") {
            dispatch("getFilteredCandidatesStatusCount")
            dispatch("getTotalSelectedCandidatesCount", releasefrom) //To get shortlist count
          }
        }
        return res;
      }
    })
      .catch(err => {
        console.log("error in release candidates", err);
        return err;
      });
  },

  //For setting getNextCandidateId and getPreviousCandidateId function according to current page
  setCurrentNavigationCandidatesPage({ state, commit, rootState, dispatch, getters }, payload) {
    let { currentNavigationCandidatesPage } = getters
    payload = {
      ...currentNavigationCandidatesPage,
      ...payload
    }
    commit("SET_CURRENT_NAVIGATION_PAGE", payload)
  },

  deleteCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_uuid, candidate_uid, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.deleteCandidates({ candidate_uuid, customer_uid, payload }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-danger text-white', message: 'Candidate deleted successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_uid)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting' });
        }
        console.log("error in Delete Candidate", err);
        return err;
      });
  },
  restoreCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_id, candidate_uuid, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.restoreCandidates({ candidate_uuid, payload, customer_uid }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-primary text-white', message: 'Candidate restored successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_id)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while restoring' });
        }
        console.log("error in Restore Candidate", err);
        return err;
      });
  },
  deactivateCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_id, candidate_uuid, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.deactivateCandidates({ candidate_uuid, payload, customer_uid }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-danger text-white', message: 'Candidate deactivated successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_id)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deactivating candidate' });
        }
        console.log("error in Deactivate Candidate", err);
        return err;
      });
  },
  reactivateCandidate({ state, commit, rootState, dispatch, getters }, payload) {
    let { candidate_id, candidate_uuid, reason_id, comments, customer_uid } = payload;
    payload = { reason_id: reason_id, comments: comments }
    return customer.reactivateCandidates({ candidate_uuid, payload, customer_uid }).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-primary text-white', message: 'Candidate reactivated successfully' });
        dispatch("sliceCandidateInGetCandidates", candidate_id)
        return res;
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while reactivating candidate' });
        }
        console.log("error in Reactivate Candidate", err);
        return err;
      });
  },

  sliceCandidateInGetCandidates({ state, commit, rootState, dispatch, getters }, candidate_uid) {
    let { getCandidates } = getters;
    if (getCandidates.length >= 0) {
      for (var i = 0; i < getCandidates.length; i++) {
        if (getCandidates[i].candidate_uid == candidate_uid) {
          getCandidates.splice(i, 1);
          let data = { lastSlicedCandidateIndex: i }
          dispatch("setCurrentNavigationCandidatesPage", data)
          i = i - 1;
        }
      }
      commit("SET_CANDIDATE_LIST", getCandidates);
    }
  },

  fetchReasons({ state, commit, rootState, dispatch, getters }) {
    return customer.fetchReasons().then(res => {
      let { data } = res;
      commit("SET_REASONS", data);
      return res;
    })
      .catch(err => {
        console.log("error fetching reasons", err);
        return err;
      });
  },
  disapproveAppliedCandidate({ dispatch, getters, commit }, payload) {
    let { candidate_uid, job_id } = payload;
    let { getFilteredStatusCandidates } = getters;
    return jobBoard.disapproveAppliedCandidate(candidate_uid, job_id).then(res => {
      dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate has been disapproved successfully' });
      if (getFilteredStatusCandidates.length > 0) {
        for (var i = 0; i < getFilteredStatusCandidates.length; i++) {
          if (getFilteredStatusCandidates[i].application_id == payload.application_id) {
            getFilteredStatusCandidates.splice(i, 1);
            let data = { lastSlicedCandidateIndex: i }
            dispatch("setCurrentNavigationCandidatesPage", data)
            i = i - 1;
          }
        }
        commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates);
        dispatch("getFilteredCandidatesStatusCount");
        dispatch("fetchAppliedCandidatesForJobsCount");
      }
      return res;
    }).catch(err => {
      console.log("error while disapproving the candidate", err)
      return err;
    })
  },
  fetchFilteredStatusCandidates({ state, commit, rootstate, dispatch, getters }, payload) {
    const { getFilteredStatusCandidates, getCustomerId, getOrgIDFromAccessToken, jobCandidatesPagePagination } = getters;
    commit("SET_FETCHING_CANDIDATES_STATUS", true);
    let skip = payload?.pagination ? jobCandidatesPagePagination.skip : 0;
    let limit = jobCandidatesPagePagination.limit;
    const fields = {
      job: "job_id",
      candidateType: "candidate_type_id",
      status: "status_id",
      expiryBy: "expiryBy"
    };
    let queryPayload = {};
    let dataPayload = {}
    for (const [key, value] of Object.entries(router.currentRoute.query)) {
      dataPayload[fields[key]] = value;
    }
    if (dataPayload?.expiryBy) {
      const expiry_payload = {
        date_field: "jobs.expiry_review_date",
        from_date: m().format("YYYY-MM-DD"),
        to_date: m().add(dataPayload?.expiryBy, "days").format("YYYY-MM-DD")
      }
      queryPayload = { ...queryPayload, ...expiry_payload }
    }

    let { pagination } = payload
    if (!pagination) {
      commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
        skip: 0,
        noMoreCandidates: false,
      });
    }
    queryPayload = {
      ...queryPayload,
      skip, limit
    }
    if (dataPayload?.candidate_type_id)
      queryPayload = { ...queryPayload, candidates___candidate_type_id: dataPayload.candidate_type_id };
    if (dataPayload?.job_id)
      queryPayload = { ...queryPayload, job_id: dataPayload?.job_id }

    let queryString = getFilterQueryStringWithoutArray(queryPayload);

    dispatch("getFilteredCandidatesStatusCount")
    if (dataPayload?.status_id == "applied") {
      if (!pagination) dispatch("fetchAppliedCandidatesForJobsCount");
      return candidate.fetchAppliedCandidatesForJobs({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString })
        .then((res) => {
          let { data } = res;
          if (data.length < limit) {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              noMoreCandidates: true
            });
          } else {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              skip: skip + limit,
              noMoreCandidates: false
            });
          }
          if (pagination) {
            if (getFilteredStatusCandidates?.length) data = getFilteredStatusCandidates.concat(data)
            commit("SET_FILTERED_CANDIDATES_STATUS", data);
          } else {
            commit("SET_FILTERED_CANDIDATES_STATUS", data);
          }
          return data;
        })
        .catch(err => {
          console.log("Error While fetching Applied candidates", err);
          return err
        })
        .finally(res => {
          commit("SET_FETCHING_CANDIDATES_STATUS", false);
          return res
        })
    } else if (dataPayload?.status_id == "withdrawn") {
      if (!pagination) dispatch("fetchWithdrawnCandidatesForJobsCount");
      return candidate.fetchWithdrawnCandidatesForJobs({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString })
        .then((res) => {
          let { data } = res;
          if (data.length < limit) {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              noMoreCandidates: true
            });
          } else {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              skip: skip + limit,
              noMoreCandidates: false
            });
          }
          if (pagination) {
            commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates.concat(data));
          } else {
            commit("SET_FILTERED_CANDIDATES_STATUS", data);
          }
          return data;
        })
        .catch(err => {
          console.log("Error While fetching Applied candidates", err)
          return err;
        })
        .finally(res => {
          commit("SET_FETCHING_CANDIDATES_STATUS", false);
          return res
        })
    } else {
      if (!pagination) dispatch("getTotalSelectedCandidatesCount", dataPayload?.status_id)
      if (dataPayload?.status_id)
        queryPayload = { ...queryPayload, status_id: dataPayload?.status_id };
      queryPayload = {
        ...queryPayload, customer_id: getCustomerId,
        organisation_id: getOrgIDFromAccessToken,
      }
      queryString = getFilterQueryStringWithoutArray(queryPayload);
      return customer.shortlistedCandidates(queryString)
        .then((res) => {
          let { data } = res;
          data = data?.map(val => {
            val.candidate_uid = val.candidate_id
            return val
          })
          if (data.length < limit) {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              noMoreCandidates: true
            });
          } else {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              skip: skip + limit,
              noMoreCandidates: false
            });
          }
          if (pagination) {
            commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates.concat(data));
          } else {
            commit("SET_FILTERED_CANDIDATES_STATUS", data);
          }
          return data;
        })
        .catch(err => {
          console.log("Error While fetching shortlist candidates", err)
          return err
        })
        .finally(res => {
          commit("SET_FETCHING_CANDIDATES_STATUS", false);
          return res
        })
    }
  },

  fetchFilteredStatusCandidates1({ state, commit, rootstate, dispatch, getters }, payload) {
    const { getFilteredStatusCandidates, getCustomerId, getOrgIDFromAccessToken, jobCandidatesPagePagination } = getters;
    commit("SET_FETCHING_CANDIDATES_STATUS", true);
    let skip = pagination ? jobCandidatesPagePagination.skip : 0;
    let limit = jobCandidatesPagePagination.limit;
    const fields = {
      job: "job_id",
      candidateType: "candidate_type_id",
      status: "status_id",
      expiryBy: "expiryBy"
    };
    payload = {
      customer_id: getCustomerId,
      organisation_id: getOrgIDFromAccessToken,
    };
    let queryPayload = {}
    for (const [key, value] of Object.entries(router.currentRoute.query)) {
      payload[fields[key]] = value;
    }
    if (payload?.expiryBy) {
      const expiry_payload = {
        date_field: "jobs.expiry_review_date",
        from_date: m().format("YYYY-MM-DD"),
        to_date: m().add(payload?.expiryBy, "days").format("YYYY-MM-DD")
      }
      payload = {
        ...payload,
        ...expiry_payload
      }
      delete payload.expiryBy
    }
    let { pagination } = payload
    if (!pagination) {
      commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
        skip: 0,
        noMoreCandidates: false,
      });
      if (payload?.status_id == "applied") {
        dispatch("fetchAppliedCandidatesForJobsCount");
      } else if (payload?.status_id == "withdrawn") {
        dispatch("fetchWithdrawnCandidatesForJobsCount");
      } else {
        dispatch("getTotalSelectedCandidatesCount", payload?.status_id)
      }
    }
    payload = {
      ...payload,
      skip, limit
    }
    let queryString = getFilterQueryStringWithoutArray(payload)
    dispatch("getFilteredCandidatesStatusCount", queryPayload)
    if (payload?.status_id != "applied" && payload?.status_id != "withdrawn") {
      return customer.shortlistedCandidates(queryString)
        .then((res) => {
          let { data } = res;
          data = data?.map(val => {
            val.candidate_uid = val.candidate_id
            return val
          })
          if (data.length < limit) {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              noMoreCandidates: true
            });
          } else {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              skip: skip + limit,
              noMoreCandidates: false
            });
          }
          if (pagination) {
            commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates.concat(data));
          } else {
            commit("SET_FILTERED_CANDIDATES_STATUS", data);
          }
        })
        .catch(err => {
          console.log("Error While fetching shortlist candidates", err)
        })
        .finally(res => {
          commit("SET_FETCHING_CANDIDATES_STATUS", false);
          return res
        })
    } else if (payload?.status_id == "applied") {
      queryPayload = {
        ...queryPayload,
        skip, limit,
      }
      if (payload?.candidate_type_id)
        queryPayload = { ...queryPayload, candidates___candidate_type_id: payload?.candidate_type_id };
      if (payload?.job_id)
        queryPayload = { ...queryPayload, job_id: payload?.job_id }

      queryString = getFilterQueryStringWithoutArray(queryPayload);

      return candidate.fetchAppliedCandidatesForJobs({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString })
        .then((res) => {
          let { data } = res;
          if (data.length < limit) {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              noMoreCandidates: true
            });
          } else {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              skip: skip + limit,
              noMoreCandidates: false
            });
          }
          if (pagination) {
            commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates.concat(data));
          } else {
            commit("SET_FILTERED_CANDIDATES_STATUS", data);
          }
        })
        .catch(err => {
          console.log("Error While fetching Applied candidates", err)
        })
        .finally(res => {
          commit("SET_FETCHING_CANDIDATES_STATUS", false);
          return res
        })
    } else if (payload?.status_id == "withdrawn") {
      queryPayload = {
        ...queryPayload,
        skip, limit,
      }

      if (payload?.candidate_type_id)
        queryPayload = { ...queryPayload, candidates___candidate_type_id: candidate_type_id };
      if (payload?.job_id)
        queryPayload = { ...queryPayload, job_id }

      queryString = getFilterQueryStringWithoutArray(queryPayload);

      return candidate.fetchWithdrawnCandidatesForJobs({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString })
        .then((res) => {
          let { data } = res;
          if (data.length < limit) {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              noMoreCandidates: true
            });
          } else {
            commit("SET_JOB_CANDIDATES_PAGE_PAGINATION", {
              skip: skip + limit,
              noMoreCandidates: false
            });
          }
          if (pagination) {
            commit("SET_FILTERED_CANDIDATES_STATUS", getFilteredStatusCandidates.concat(data));
          } else {
            commit("SET_FILTERED_CANDIDATES_STATUS", data);
          }
        })
        .catch(err => {
          console.log("Error While fetching Applied candidates", err)
        })
        .finally(res => {
          commit("SET_FETCHING_CANDIDATES_STATUS", false);
          return res
        })
    }
  },
  fetchAppliedCandidatesForJobsCount({ commit, getters }, payload) {
    const { getCustomerId, getOrgIDFromAccessToken } = getters;
    let queryPayload = {}
    if (payload?.date_field) {
      const { date_field, from_date, to_date } = payload
      queryPayload = { ...queryPayload, date_field, from_date, to_date };
    }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return candidate.fetchAppliedCandidatesForJobsCount({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString })
      .then(res => {
        let { data } = res;
        commit("SET_SHORTLIST STATUS_COUNT", { applied: data });
        return res;
      })
      .catch(err => {
        console.log("Error while fetching applied candidates count", err);
        return err;
      })
  },
  fetchWithdrawnCandidatesForJobsCount({ commit, getters }) {
    const { getCustomerId, getOrgIDFromAccessToken } = getters
    return candidate.fetchWithdrawnCandidatesForJobsCount({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString: "" })
      .then(res => {
        let { data } = res;
        commit("SET_SHORTLIST STATUS_COUNT", { withdrawn: data });
        return res;
      })
      .catch(err => {
        console.log("Error while fetching withdrawn candidates count", err);
        return err;
      })
  },
  candidatesListAction({ commit, getters, dispatch }, payload) {
    commit("SET_IS_FETCHING_CANDIDATE", true);
    if (!payload.pagination) {
      commit("SET_CANDIDATE_LIST_PAGINATION", {
        skip: 0,
        noMoreDataFromCandidatesList: false
      });
    }
    let { query } = payload;
    const {
      getCandidatePaginationSkip,
      getCandidatePaginationLimit,
      getCandidates,
      getRoleScopes,
      getTotalCount,
      getCustomerId,
      getIsChildUser,
      getOrgIDFromAccessToken: org_id,
    } = getters;

    commit("SET_CANDIDATE_LIST_FILTER", query);
    dispatch("getFilteredTotalCandidates", query);
    if (!getTotalCount) {
      dispatch("getTotalCandidates");
    }
    if (getRoleScopes[0] !== Role.systemAdmin) {
      dispatch("getallshortliststatus");
      dispatch("fetchFacilityConfig", { customer_id: getCustomerId, organisation_id: org_id });
    }
    return customer
      .getCandidatesList({
        role: getRoleScopes.length ? getRoleScopes[0] : '',
        skip: getCandidatePaginationSkip,
        limit: getCandidatePaginationLimit,
        org_id: getIsChildUser ? org_id : null,
        query
      })
      .then(res => {
        let { data } = res;
        if (data.length < getCandidatePaginationLimit) {
          commit("SET_CANDIDATE_LIST_PAGINATION", {
            noMoreDataFromCandidateList: true
          });
        } else {
          commit("SET_CANDIDATE_LIST_PAGINATION", {
            skip: getCandidatePaginationSkip + getCandidatePaginationLimit,
            noMoreDataFromCandidateList: false
          });
        }
        if (payload.pagination) {
          commit("SET_CANDIDATE_LIST", getCandidates.concat(data));
        } else {
          commit("SET_CANDIDATE_LIST", data);
          commit("SET_SELECTED_CANDIDATE", data[0]);
        }
        commit("SET_IS_FETCHING_CANDIDATE", false);
      })
      .catch(err => {
        commit("SET_IS_FETCHING_CANDIDATE", false);
        console.log("error in fetching candidate data", err);
        return err;
      });
  },

  getCandidateList({ state, commit, rootState, dispatch, getters }, payload) {
    let {
      getCandidatePaginationLimit,
      getCandidates,
      candidateLevel,
      getTotalCount,
      getCustomerId,
      getOrgIDFromAccessToken: org_id
    } = getters;
    // need to remove this section ==== start
    let licensingLevelId;
    if (payload && payload.licensing_level_id__in) {
      licensingLevelId = JSON.parse(`[${payload.licensing_level_id__in}]`)[0];
    }
    //======= end
    let { getRoleScopes } = getters;
    commit("SET_CANDIDATE_LIST_FILTER", payload);
    commit("SET_IS_FETCHING_CANDIDATE", true);

    let queryString = getFilterQueryStringWithoutArray(payload);
    dispatch("getFilteredTotalCandidates", queryString);
    if (!getTotalCount) {
      dispatch("getTotalCandidates");
    }
    if (getRoleScopes[0] === Role.systemAdmin) {
      return customer
        .allCandidates(queryString)
        .then(res => {
          let { data } = res;
          commit("SET_CANDIDATE_LIST", data);
          // need to remove this section ==== start
          if (licensingLevelId) {
            let candidateLevelName = candidateLevel.filter(
              val => val.code == licensingLevelId
            )[0].label;
            data = data.map(val => {
              let { level } = val;
              level["candidate_level"] = candidateLevelName;
              return { ...val, level };
            });
          } else {
            data = data.map(val => {
              let { level } = val;
              if (level) {
                level["candidate_level"] = "";
                return { ...val, level };
              }
              return val;
            });
          }
          // ======= end
          if (data.length < getCandidatePaginationLimit) {
            commit("SET_CANDIDATE_LIST_PAGINATION", {
              noMoreDataFromCandidateList: true
            });
          }
          if (payload.skip) {
            commit("SET_CANDIDATE_LIST", getCandidates.concat(data));
          } else {
            commit("SET_CANDIDATE_LIST", data);
            commit("SET_SELECTED_CANDIDATE", data[0]);
          }
          commit("SET_IS_FETCHING_CANDIDATE", false);
          return res;
        })
        .catch(err => {
          commit("SET_IS_FETCHING_CANDIDATE", false);
          console.log("error in candidateType", err);
          return err;
        });
    }
    else {
      dispatch("getallshortliststatus");
      dispatch("fetchFacilityConfig", { customer_id: getCustomerId, organisation_id: org_id });
      return customer
        .allunshortlistCandidates(queryString)
        .then(res => {
          let { data } = res;
          commit("SET_CANDIDATE_LIST", data);
          // need to remove this section ==== start
          if (licensingLevelId) {
            let candidateLevelName = candidateLevel.filter(
              val => val.code == licensingLevelId
            )[0].label;
            data = data.map(val => {
              let { level } = val;
              level["candidate_level"] = candidateLevelName;
              return { ...val, level };
            });
          } else {
            data = data.map(val => {
              let { level } = val;
              if (level) {
                level["candidate_level"] = "";
                return { ...val, level };
              }
              return val;
            });
          }
          // ======= end
          if (data.length < getCandidatePaginationLimit) {
            commit("SET_CANDIDATE_LIST_PAGINATION", {
              noMoreDataFromCandidateList: true
            });
          }
          if (payload.skip) {
            commit("SET_CANDIDATE_LIST", getCandidates.concat(data));
          } else {
            commit("SET_CANDIDATE_LIST", data);
            commit("SET_SELECTED_CANDIDATE", data[0]);
          }
          commit("SET_IS_FETCHING_CANDIDATE", false);
          return res;
        })
        .catch(err => {
          commit("SET_IS_FETCHING_CANDIDATE", false);
          console.log("error in candidateType", err);
          return err;
        });
    }
  },

  fetchMoreCandidateList({ state, commit, rootState, dispatch, getters }) {
    let {
      candidateListFilter,
      noMoreDataFromCandidateList,
      currentNavigationCandidatesPage,
      getCandidates,
      getJobCandidatesByStatusID,
      getJobCandidateStatusPagination,
    } = getters;
    let _currentPage = {
      jobCandidateStatus: "Job Candidates Status"
    }
    if (!(getCandidates?.length || getJobCandidatesByStatusID?.length)) return;
    if (currentNavigationCandidatesPage.currentPage === _currentPage.jobCandidateStatus) {
      if (!getJobCandidateStatusPagination.noMoreCandidates) {
        dispatch("fetchJobCandidatesByStatusID", { pagination: true })
      }
    }
    else if (!noMoreDataFromCandidateList) {
      dispatch("candidatesListAction", {
        pagination: true,
        query: candidateListFilter,
      });
    }
  },

  getTotalCandidates({ state, commit, rootState, dispatch, getters }) {
    const { getIsChildUser, getOrgIDFromAccessToken: org_id, getRoleScopes } = getters;
    let payload = ''
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    return customer.totalCandidates(payload, getIsChildUser ? org_id : null, role).then(res => {
      let { data } = res;
      commit("SET_TOTAL_COUNT", data);
    })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      });
  },

  getFilteredTotalCandidates({ state, commit, rootState, dispatch, getters }, payload) {
    let { getRoleScopes, getIsChildUser, getOrgIDFromAccessToken: org_id } = getters;
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    return customer.totalCandidates(payload ? payload : '', getIsChildUser ? org_id : null, role).then(res => {
      let { data } = res;
      commit("SET_CANDIDATE_TOTAL", data);
    })
      .catch(err => {
        console.error("error in filtered Candidate Details", err);
        return err;
      });
  },

  getAllCandidateJobStatus({ commit }) {
    customer.getAllCandidateJobStatus().then(res => {
      let {
        data
      } = res;
      commit("SET_ALL_CANDIDATE_JOB_STATUS", data)
    })
  },

  getAllJobs({ commit, getters }, status) {
    const { getRoleScopes } = getters;
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    return customer
      .getAllJobs(role).then(res => {
        let { data } = res;
        commit("SET_ALL_JOBS", data)
        return res;
      })
      .catch(err => {
        console.log('Error While Fetch All Jobs', err);
        return err;
      })
  },

  getCandidateExpiryCountsByStatus({ state, commit, getters }, params) {
    let { getCustomerId } = getters;
    let payload = null
    let queryString = null

    payload = {
      doh_candidate_status___customer_id: getCustomerId,
      status_id: params.status.status_id,
      days_to_expire__gt: params.expiry_gt,
      days_to_expire__lte: params.expiry_lte
    }
    queryString = getFilterQueryStringWithoutArray(payload);
    customer.allCandidates(queryString).then(async res => {
      let { data } = res;
      commit("SET_CANDIDATE_EXPIRY_COUNT", {
        expire: params.expiry_lte,
        length: data.length,
        status: params.status.status
      })
    });
  },

  getTotalSelectedCandidatesCount({ state, commit, getters }, statusId) {
    let { getCustomerId, getallshortliststatus, getRoleScopes, getOrgIDFromAccessToken } = getters;
    let stats_id = statusId ? statusId : state.shortliststatus.status_id;
    let payload = {
      customer_id: getCustomerId,
      organisation_id: getOrgIDFromAccessToken,
      status_id: stats_id
    };
    if (payload.status_id === undefined) {
      payload.status_id = getallshortliststatus.status_id
    }
    if (payload.status_id != 37) {
      payload.expired = false;
    }
    let queryString = getFilterQueryStringWithoutArray(payload);
    let role = getRoleScopes.length ? getRoleScopes[0] : '';

    return customer.shortlistedCandidatesTotalCount(queryString, null, role).then(res => {
      let { data } = res;

      if (statusId == 34) {
        commit("SET_SHORTLIST STATUS_COUNT", { shortlist: data });
      } else if (statusId == 35) {
        commit("SET_SHORTLIST STATUS_COUNT", { release: data });
      } else if (statusId == 36) {
        commit("SET_SHORTLIST STATUS_COUNT", { interview: data });
      } else if (statusId == 37) {
        commit("SET_SHORTLIST STATUS_COUNT", { recruit: data });
      } else {
        commit("SET_SELECTED_CANDIDATE_TOTAL", data);
      }
    })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      });
  },

  getFilteredCandidatesStatusCount({ dispatch, commit, getters }) {
    let { getCustomerId, getOrgIDFromAccessToken } = getters;
    const fields = {
      job: "job_id",
      candidateType: "candidate_type_id",
      status: "status_id",
      expiryBy: "expiryBy"
    };
    let payload = {};
    for (const [key, value] of Object.entries(router.currentRoute.query)) {
      payload[fields[key]] = value;
    }
    if (payload?.expiryBy) {
      let { expiryBy, ...rest } = payload
      rest = {
        ...rest,
        date_field: "jobs.expiry_review_date",
        from_date: m().format("YYYY-MM-DD"),
        to_date: m().add(expiryBy, "days").format("YYYY-MM-DD")
      }
      payload = rest;
    }
    if (payload?.candidate_type_id) {
      let { candidate_type_id, ...rest } = payload
      rest = {
        ...rest,
        candidates___candidate_type_id: candidate_type_id
      }
      payload = rest;
    }
    let { status_id, ...rest } = payload
    let queryString = getFilterQueryStringWithoutArray(rest)
    if (payload?.status_id != "applied" && payload?.status_id != "withdrawn") {
      payload = {
        ...payload,
        customer_id: getCustomerId,
        organisation_id: getOrgIDFromAccessToken,
      }
      queryString = getFilterQueryStringWithoutArray(payload)
      return customer.shortlistedCandidatesTotalCount(queryString).then(res => {
        let { data } = res;
        commit("SET_FILTERED_CANDIDATES_STATUS_COUNT", data);
      })
        .catch(err => {
          console.error("error in Candidate Details", err);
          return err;
        });
    } else if (payload?.status_id == "applied") {
      return candidate.fetchAppliedCandidatesForJobsCount({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString })
        .then(res => {
          let { data } = res;
          commit("SET_FILTERED_CANDIDATES_STATUS_COUNT", data);
        })
        .catch(err => {
          console.error("error in Candidate Details", err);
          return err;
        });
    } else if (payload?.status_id == "withdrawn") {
      return candidate.fetchWithdrawnCandidatesForJobsCount({ customer_id: getCustomerId, organisation_id: getOrgIDFromAccessToken, queryString })
        .then(res => {
          let { data } = res;
          commit("SET_FILTERED_CANDIDATES_STATUS_COUNT", data);
        })
        .catch(err => {
          console.error("error in Candidate Details", err);
          return err;
        });
    }
  },

  candidateListPagination(
    { state, commit, rootState, dispatch, getters },
    payload
  ) {
    let { getCandidatePaginationLimit } = getters;
    commit("SET_CANDIDATE_LIST_PAGINATION", {
      skip: 0,
      noMoreDataFromCandidateList: false
    });

    dispatch("getCandidateList", {
      ...payload,
      limit: getCandidatePaginationLimit,
      skip: 0
    });
  },
  setSelectedCandidate({ state, commit, rootState, dispatch, getters }, data) {
    let { selectedJobId } = getters;
    if (selectedJobId !== data.job_id) {
      commit("SET_SELECTED_CANDIDATE", data);
    }
  },
  setSelectedcandidateById: async ({ state, commit, rootState, dispatch, getters }, candidate_uuid) => {
    let { getCandidates, getFilteredStatusCandidates } = getters;
    let candidateDetailById;
    let candidateDetails;

    if (getCandidates.length && (getCandidates.findIndex(e => e.candidate_uuid == candidate_uuid)) >= 0 && getCandidates[getCandidates.findIndex(e => e.candidate_uuid == candidate_uuid)]) {
      candidateDetailById = getCandidates;
    }
    else if (getFilteredStatusCandidates.length && (getFilteredStatusCandidates.findIndex(e => e.candidate_uuid == candidate_uuid)) >= 0 && getFilteredStatusCandidates[getFilteredStatusCandidates.findIndex(e => e.candidate_uuid == candidate_uuid)]) {
      candidateDetailById = getFilteredStatusCandidates?.map(val => val?.candidate);
    }
    if (candidateDetailById) {
      candidateDetails = await dispatch("getCandidateDetails", candidate_uuid);
      dispatch("commitCandidateInfo", candidateDetails);
    }
    else {
      candidateDetails = await dispatch("getCandidateDetails", candidate_uuid);
    }
    return candidateDetails;
  },
  resetBackCount({ state, commit, rootState, dispatch, getters }) {
    commit("SET_BACK_COUNT", 0);
  },
  resetCandidateDetail({ commit, getters }) {
    commit("SET_CANDIDATE_DETAILS", {});
  },
  getallshortliststatus({ state, commit, rootState, dispatch, getters }) {
    return customer.allShortlistStatus().then(async res => {
      let { data } = res;
      for (var i = 0; i < data.length; i++) {
        if (data[i].status == "SHORTLIST")
          commit("SET_SHORTLIST_STATUS", data[i]);
        else if (data[i].status == "RELEASE")
          commit("SET_RELEASE_STATUS", data[i]);
        else if (data[i].status == "INTERVIEW")
          commit("SET_INTERVIEW_STATUS", data[i]);
        else if (data[i].status == "RECRUIT")
          commit("SET_RECRUIT_STATUS", data[i]);
      }
      return data;
    })
      .catch(err => {
        console.log("error in shortlist status", err);
        return err;
      });
  },
  fetchJobs({ state, commit, rootState, dispatch, getters }) {
    const { getRoleScopes } = getters;
    let role = getRoleScopes.length ? getRoleScopes[0] : '';
    return customer.getAllJobs(role).then(res => {
      let { data } = res;
      commit("SET_JOBS", data);
      return res;
    })
      .catch(err => {
        console.error("error fetching jobs", err);
        return err;
      });
  },
  setSelectedCandidateUsingId({ state, commit, rootState, dispatch, getters }, payload) {
    let id = payload;
    let { getCandidateListForJobsList } = getters;
    let selectedData = getCandidateListForJobsList.filter(item => item.candidate_uid == id)
    commit('SET_SELECTED_CANDIDATE_FOR_JOBS_LIST', selectedData[0])
  },
  updateCandidateInfoByID: async ({ commit, getters, dispatch }, candidate_uuid) => {
    const { getCandidates, candidateProfile, getCustomerId } = getters;
    let customer_uid = getCustomerId;
    if (!customer_uid) {
      await dispatch("getCustomerIDByCandidateID", candidate_uuid);
      const { getSelectedCandidateCustomerID } = getters;
      customer_uid = getSelectedCandidateCustomerID;
    }
    return customer
      .getCandidateDetail({ candidate_uuid, customer_uid })
      .then(res => {
        if (getCandidates.length) {
          const CandidateIndex = getCandidates.findIndex(e => e.candidate_uuid === candidate_uuid);
          const CandidateInfo = res.data
          if (CandidateIndex >= 0) {
            commit("REPLACE_CANDIDATE_BY_INDEX", { CandidateIndex, CandidateInfo })
          }
        }
        if (candidate_uuid === candidateProfile.candidate_uuid)
          dispatch("commitCandidateInfo", res.data);
        return res.data;
      })
      .catch(err => {
        console.error("error in Candidate Details", err);
        return err;
      });
  },
  getCustomerIDByCandidateID: ({ getters, dispatch, commit }, candidate_uuid) => {
    return customer
      .getCustomerIDByCandidateID(candidate_uuid)
      .then(res => {
        const { data } = res;
        commit("SET_SELECTED_CANDIDATE_CUSTOMER_ID", data[0]);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching CustomerID", err)
        return err
      })
  },
  downloadCandidateList: ({ getters, dispatch, commit }, payload = {}) => {
    const {
      getRoleScopes,
      getCustomerId,
      getIsChildUser,
      getOrgIDFromAccessToken: org_id,
    } = getters;
    const { query } = payload;
    dispatch("showLoader")
    return customer
      .downloadCandidateList({
        query,
        org_id: getIsChildUser ? org_id : null,
        role: getRoleScopes.length ? getRoleScopes[0] : '',
        customer_id: getCustomerId
      })
      .then(res => {
        let { data } = res;
        const timeStamp = m().format("yyyyMMddHHmmss")
        const document_name = `CandidateList_${timeStamp}`
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(data);
          link.setAttribute("href", url);
          link.setAttribute("download", document_name);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return res;
      })
      .catch(err => {
        const message = "Error While Download Result";
        dispatch("showToast", {
          class: "bg-danger text-white",
          message
        })
        return err;
      })
      .finally(()=>{
        dispatch("hideLoader")
      })
  }
}

// mutations
const mutations = {
  ["SET_ALL_JOBS"](state, payload) {
    state.getAllJobs = payload
  },
  ["SET_ALL_CANDIDATE_JOB_STATUS"](state, payload) {
    state.getAllCandidateJobStatus = payload
  },
  ["SET_DASHBOARD_METRICS"](state, payload) {
    Vue.set(state.dashboardMetrics, payload.status, payload.value)
  },
  ["SET_CANDIDATE_EXPIRY_COUNT"](state, payload) {
    if (payload.status) {
      if (!state.candidateExpiryCount[payload.status]) {
        Vue.set(state.candidateExpiryCount, payload.status, {})
      }
      Vue.set(state.candidateExpiryCount[payload.status], `ageing_less_then_${payload.expire}_days`, payload.length)
    }
  },
  ["SET_SHORTLIST_CANDIDATES"](state, payload) {
    state.shortlistCandidates = payload;
  },
  ["SET_SHORTLIST_STATUS"](state, payload) {
    state.shortliststatus = payload;
  },
  ["SET_RELEASE_STATUS"](state, payload) {
    state.releasestatus = payload;
  },
  ["SET_INTERVIEW_STATUS"](state, payload) {
    state.interviewstatus = payload;
  },
  ["SET_RECRUIT_STATUS"](state, payload) {
    state.recruitstatus = payload;
  },
  ["SET_RECRUITLIST_CANDIDATES"](state, payload) {
    state.recruitlistCandidates = payload;
  },
  ["SET_CANDIDATE_TOTAL"](state, payload) {
    state.candidateTotal = payload;
  },
  ["SET_CANDIDATE_DETAILS"](state, data) {
    state.candidateDetails = Object.assign(data, {
      title: data.title || {},
      speciality: data.speciality || {},
      sub_speciality: data.sub_speciality || {},
      candidate_level: data.candidate_level || {},
      nationality: data.nationality || {},
      doh_candidate_status: data.doh_candidate_status || null,
      main_qualification: data.main_qualification || {},
      gender: data.gender || { gender: "" },
      location: data.location || {},
      candidate_uid: data.candidate_uid || null,
      whatsapp: data.whatsapp || "",
      available: data.available || null,
      profile_picture: data.profile_picture || null,
      local_license:
        data.local_license == null
          ? "--"
          : data.local_license
            ? "Yes"
            : "No",
      dataflow_complete:
        data.dataflow_complete == null
          ? "--"
          : data.dataflow_complete
            ? "Yes"
            : "No",
      flexible:
        data.flexible == null ? "--" : data.flexible ? "Yes" : "No",
      extra_hours_available: data.extra_hours_available || "--",
      retired_clinician:
        data.retired_clinician == null
          ? "--"
          : data.retired_clinician == "Yes" || data.retired_clinician == true
            ? "Yes"
            : "No",
      volunteer:
        data.volunteer == null
          ? "--"
          : data.volunteer == "Yes" || data.volunteer == true
            ? "Yes"
            : "No"
    });
  },
  ["SET_SELECTED_CANDIDATE"](state, payload) {
    state.selectedCandidate = payload;
  },
  ["SET_CANDIDATE_LIST"](state, payload) {
    state.candidates = payload;
  },
  ["SET_IS_FETCHING_CANDIDATE"](state, data) {
    state.isFetchingCandidates = data;
  },
  ["SET_CANDIDATE_LIST_FILTER"](state, data) {
    state.candidateListFilter = data;
  },
  ["SET_CURRENT_NAVIGATION_PAGE"](state, data) {
    state.currentNavigationCandidatesPage = data
  },
  ["SET_CANDIDATE_DETAIL_PROFILE"](state, data) {
    state.profiles = data;
  },
  ["SET_CANDIDATE_LIST_PAGINATION"](state, data) {
    state.candidatePagination = { ...state.candidatePagination, ...data }
  },
  ['RESET_CANDIDATE_LIST'](state, data) {
    Object.assign(state, getDefaultState())
  },
  ["SET_BACK_COUNT"](state, data) {
    state.backcount = data;
  },
  ["SET_TOTAL_COUNT"](state, payload) {
    state.getTotalCount = payload;
  },
  ["SET_CANDIDATE_LIST_BACK_URL"](state, url) {
    state.fullPathCandidateListBackUrl = [...state.fullPathCandidateListBackUrl, url];
  },
  ["RESET_CANDIDATE_LIST_BACK_URL"](state) {
    state.fullPathCandidateListBackUrl = [];
  },
  ["REMOVE_CANDIDATE_LIST_BACK_URL"](state, url) {
    let _index = _.findLastIndex(state.fullPathCandidateListBackUrl, e => e === url);
    if (_index > -1)
      state.fullPathCandidateListBackUrl.splice(_index, 1)
  },
  ["SET_REASONS"](state, payload) {
    state.reasons = payload;
  },
  ["SET_JOBS"](state, payload) {
    state.jobs = payload;
  },
  ["SET_IS_FETCHING_CANDIDATE_DETAILS"](state, payload) {
    state.isFetchingCandidatesDetails = payload
  },
  ['REPLACE_CANDIDATE_BY_INDEX'](state, payload) {
    if (payload) {
      const { CandidateIndex, CandidateInfo } = payload;
      state.candidates[CandidateIndex] = CandidateInfo;
    }
  },
  ['SET_SELECTED_CANDIDATE_CUSTOMER_ID'](state, payload) {
    state.selectedCandidateCustomerID = payload;
  },
  ["SET_FILTERED_CANDIDATES_STATUS"](state, payload) {
    state.filteredStatusCandidates = payload
  },
  ["SET_JOB_CANDIDATES_PAGE_PAGINATION"](state, payload) {
    state.jobCandidatesPagePagination = {
      ...state.jobCandidatesPagePagination,
      ...payload,
    };
  },
  ["SET_FETCHING_CANDIDATES_STATUS"](state, payload) {
    state.isFetchingStatusCandidates = payload;
  },
  ['SET_SHORTLIST STATUS_COUNT'](state, data) {
    state.getShortlistStatusCount = { ...state.getShortlistStatusCount, ...data };
  },
  ["SET_FILTERED_CANDIDATES_STATUS_COUNT"](state, payload) {
    state.filteredStatusCandidatesCount = payload
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
