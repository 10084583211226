import axios from 'axios'
import { BASE_URL } from '@/service_urls'


const facility = {
  getCustomerType: () => axios.get(`${BASE_URL}/customer_type/`),
  addCustomer: (payload) => axios.post(`${BASE_URL}/customer/register/`, payload),
  getCustomerList: () => axios.get(`${BASE_URL}/customer/`),
  updateCustomer: ({ customerId, payload }) => axios.put(`${BASE_URL}/customer/${customerId}`, payload),
  addOrganisation: ({ customer_uid, payload }) => axios.post(`${BASE_URL}/organisation/customer/${customer_uid}/register/`, payload),
  fetchOrganisationList: ({ customer_uid, queryString }) => axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/?${queryString}`),
  updateOrganisation: (payload) => axios.put(`${BASE_URL}/organisation/`, payload),
  getDistrictsByLocationID: (location_id) => axios.get(`${BASE_URL}/location/location/${location_id}/districts`),
  getDistricts: () => axios.get(`${BASE_URL}/location/location/district/all_districts`),
}

export default facility;