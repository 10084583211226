import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Pages
const CandidateList = () => import("@/pages/CandidateList");
const Register = () => import("@/pages/Register");
const Home = () => import("@/pages/Home");
const NewAddCandidate = () => import("@/pages/NewAddCandidate");
const CandidateProfile = () => import("@/pages/CandidateProfileTalentfind");
const AddHospitalAdmin = () => import("@/pages/AddHospitalAdmin");
const AddHospital = () => import("@/pages/AddHospital");
const HospitalList = () => import("@/pages/HospitalList");
const HospitalAdminList = () => import("@/pages/HospitalAdminList");
const Dashboard = () => import("@/pages/Dashboard");
const CandidateDetails = () => import("@/pages/CandidateDetailsTalentfind");
const JobBoard = () => import("@/pages/JobBoard");
const JobBoardDetail = () => import("@/pages/JobBoardDetail");
const JobListDetail = () => import("@/pages/Jobs/JobListDetail");
const faq = () => import("@/views/pages/Faq");
const AddHospitalRecruiters = () => import("@/pages/AddHospitalRecruiters");
const HospitalRecruitersList = () => import("@/pages/HospitalRecruitersList");
const AddFacility = () => import("@/pages/AddFacility");
const AddFacilityDetail = () => import("@/pages/AddFacilityDetails");
const FacilityList = () => import("@/pages/FacilityList");
const JobCandidatesStatus = () => import("@/pages/JobCandidatesStatus")
const ListJob = () => import("@/pages/Jobs/ListJob");
const ListJobNew = () => import("@/pages/Jobs/ListJobNew");
const AddFacilityUser = () => import("@/pages/AddFacilityUser");
const NewCandidateList = () => import("@/pages/NewCandidateList");
const AddJobs = () => import("@/pages/Jobs/AddJob");
const EditJob = () => import("@/pages/Jobs/EditJob");
const CandidatesStatus = () => import("@/pages/DohCandidatesStatus");
const UploadOrganisationPictures = () => import("@/pages/UploadOrganisationPictures");

const AddCustomer = () => import("@/pages/AddCustomer");
const CustomerList = () => import("@/pages/CustomerList");
const RecruiterDashBoard = () => import("@/pages/RecruiterDashBoard");
const JobListPreview = () => import("@/pages/Jobs/JobDetailsTalentFindNew");
const AddOrganisation = () => import("@/pages/AddOrganisation");
const OrganisationList = () => import("@/pages/OrganisationList");
const AddContactUser = () => import("@/pages/AddContactUser");
const ContactUserList = () => import("@/pages/ContactUserList");
const ActivityList = () => import("@/pages/ActivityList/ActivityList");
const CandidateReviewerReviewActivity = () => import("@/pages/ActivityList/CandidateReviewer/ReviewActivity");
const JobAuthoriserReviewActivity = () => import("@/pages/ActivityList/JobAuthoriser/ReviewActivity");
const RecruiterEmailMessages = () => import("@/pages/RecruiterEmailMessages");
const CheckFacility = () => import("@/pages/checkFacility");
// Views

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Forgot = () => import("@/views/pages/Forgot");
const Reset = () => import("@/views/pages/Reset");
const UnSubscribe = () => import("@/views/pages/UnSubscribe");
// const Register = () => import("@/views/pages/Register");
const demo = () => import("@/views/pages/DemoTalentFind");
const DemoDOH = () => import("@/views/pages/DemoDOH/JobBoard");
const DemoDOHJobDetails = () => import("@/views/pages/DemoDOH/JobDetail");
const DemoDOHPQRCheck = () => import("@/views/pages/DemoDOH/PQRCheck");
const ChangePassword = () => import("@/pages/ChangePassword");
const ConfigurableFields = () => import("@/pages/ConfigurableFields/ConfigurableFields");
const ConfigPublicJobBoard = () => import("@/pages/ConfigPublicJobBoard");
const AddPublicJobExternal = () => import("@/pages/JobIssuer/AddPublicJobExternal");
const JobIssuerHome = () => import("@/pages/JobIssuer/JobIssuerHome");
const EditPublicJobExternal = () => import("@/pages/JobIssuer/AddPublicJobExternal");


// Routers
import { Role, getScope, LOGIN_URL } from "@/helpers/helper";

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
  routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  if (window.location.hostname === "adhwf.doh.gov.ae") {
    window.location.href = "https://adhkawader.doh.gov.ae/dohae";
  }
  if (
    !localStorage.getItem("userData") &&
    !to.path.includes("/auth") &&
    !to.path.includes("/faq") &&
    !to.path.includes("/public/job-board") &&
    !to.path.includes("/public/job-board-detail/:uuid") &&
    !to.path.includes("/doh/pqr-check") &&
    !to.path.includes("/register") &&
    !to.path.includes("/forgot") &&
    !to.path.includes("/unsubscribe") &&
    !to.path.includes("/add-facility") &&
    !to.path.includes("/check-facility") && 
    !to.path
  ) {
    next(LOGIN_URL());
  }
  if (localStorage.getItem("userData") && to.path.includes("/auth")) {
    if (
      getScope() === "customer_candidate" ||
      getScope() === "system_candidate"
    ) {
      next("/home");
    } else if (getScope() === "customer_user") {
      next("/list-activity");
    } else {
      next("/candidate-list");
    }
  }
  if (authorize && authorize.length) {
    const currentUserRole = getScope();
    if (!authorize.includes(currentUserRole)) {
      return next(LOGIN_URL());
    } else {
      return next();
    }
  }
  next();
});

export default router;

function configRoutes() {
  return [
    {
      path: "/",
      redirect: () => {
        if (localStorage.getItem("userData")) {
          if (
            getScope() === "customer_candidate" ||
            getScope() === "system_candidate"
          ) {
            return "/home";
          } else {
            return "/candidate-list";
          }
        } else {
          return "/";
        }
      },
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "candidate-list",
          name: "Candidates",
          component: NewCandidateList,
          beforeEnter(from, to, next) {
            if (
              getScope() === "customer_candidate" ||
              getScope() === "system_candidate"
            ) {
              next("/home");
            } else {
              next();
            }
          },
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "job-candidates-status",
          name: "Job Candidates Status",
          component: JobCandidatesStatus,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "candidates-status",
          name: "Candidates Status",
          component: CandidatesStatus,
          meta: {
            authorize: [Role.customerAdmin, Role.customerRecruiter],
          },
        },
        {
          path: "add-candidate",
          name: "AddCandidate",
          component: NewAddCandidate,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "profile",
          name: "CandidateProfile",
          component: CandidateProfile,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
              Role.systemCandidate,
              Role.customerCandidate,
            ],
          },
        },
        {
          path: "add-hospital",
          name: "AddHospital",
          component: AddHospital,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "hospital-admin-list",
          name: "HospitalAdminList",
          component: HospitalAdminList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "hospital-list",
          name: "HospitalList",
          component: HospitalList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "candidate-list-old",
          name: "OldCandidateList",
          component: CandidateList,
        },
        {
          path: "add-hospital-admin",
          name: "AddHospitalAdmin",
          component: AddHospitalAdmin,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "add-hospital-recruiters",
          name: "AddHospitalRecruiters",
          component: AddHospitalRecruiters,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "hospital-recruiters-list",
          name: "HospitalRecruitersList",
          component: HospitalRecruitersList,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "job-board",
          name: "Job Board",
          component: JobBoard,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "job-board-detail/:uuid",
          name: "Job board detail",
          component: JobBoardDetail,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "dashboard",
          component: Dashboard,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "home",
          name: "Candidate Home",
          component: Home,
          meta: {
            authorize: [Role.systemCandidate, Role.customerCandidate],
          },
        },
        {
          path: "candidate/:uuid",
          name: "Candidate",
          component: CandidateDetails,
          meta: {
            authorize: [
              Role.systemCandidate,
              Role.customerCandidate,
              Role.systemAdmin,
              Role.customerAdmin,
              Role.customerRecruiter,
              Role.customerUser,
            ],
          },
        },
        {
          path: "check-facility",
          name: "CheckFacility",
          component: CheckFacility,
        },
        {
          path: "add-facility",
          name: "AddFacility",
          component: AddFacility,
        },
        {
          path: "facility-list",
          name: "FacilityList",
          component: FacilityList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "facility-detail",
          name: "FacilityDetail",
          component: AddFacilityDetail,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "add-facilityuser",
          name: "AddFacilityUser",
          component: AddFacilityUser,
          meta: {
            authorize: [Role.customerAdmin],
          },
        },
        {
          path: "add-customer",
          name: "AddCustomer",
          component: AddCustomer,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "customer-list",
          name: "CustomerList",
          component: CustomerList,
          meta: {
            authorize: [Role.systemAdmin],
          },
        },
        {
          path: "jobs-list",
          name: "JobList",
          component: ListJob,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
              Role.customerCandidate,
              Role.systemCandidate
            ],
          },
          children: [
            {
              path: "status/:jobStatus",
              name: "JobBoardByStatus",
              component: ListJob,
              meta: {
                authorize: [
                  Role.systemAdmin,
                  Role.systemRecruiter,
                  Role.customerAdmin,
                  Role.customerRecruiter,
                ],
              },
            }
          ]
        },
        {
          path: "job-list",
          name: "JobListNew",
          component: ListJobNew,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "job-list-detail/:uuid",
          name: "JobListDetail",
          component: JobListDetail,
          authorize: [
            Role.systemAdmin,
            Role.systemRecruiter,
            Role.customerAdmin,
            Role.customerRecruiter,
          ],
        },

        {
          path: "add-job",
          name: "AddJobs",
          component: AddJobs,
          meta: {
            authorize: [
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "edit-job/:uuid",
          name: "EditJobs",
          component: EditJob,
          authorize: [
            Role.systemRecruiter,
            Role.customerAdmin,
            Role.customerRecruiter,
          ],
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "/public/job-board-detail/:uuid",
          name: "Public Job Board Detail",
          component: JobBoardDetail,
        },
        {
          path: "recruiter-dashboard",
          name: "Recruiter DashBoard",
          component: RecruiterDashBoard,
          authorize: [
            Role.systemAdmin,
            Role.systemRecruiter,
            Role.customerAdmin,
            Role.customerRecruiter,
          ],
        },
        {
          path: "job-list-preview/:uuid",
          name: "Job List Preview",
          component: JobListPreview,
          authorize: [
            Role.systemAdmin,
            Role.systemRecruiter,
            Role.customerAdmin,
            Role.customerRecruiter,
            Role.customerUser,
          ],
        },
        {
          path: "change-password",
          name: "Change Password",
          component: ChangePassword,
        },
        {
          path: "add-organisation",
          name: "Add Organisation",
          component: AddOrganisation,
          meta: {
            authorize: [
              Role.customerAdmin,
            ]
          }
        },
        {
          path: "organisation-list",
          name: "List Organisation",
          component: OrganisationList,
          meta: {
            authorize: [
              Role.customerAdmin,
            ]
          }
        },
        {
          path: "add-contactuser",
          name: "Add Contact User",
          component: AddContactUser,
          meta: {
            authorize: [
              Role.customerAdmin,
            ]
          }
        },
        {
          path: "list-contactuser",
          name: "List Contact User",
          component: ContactUserList,
          meta: {
            authorize: [
              Role.customerAdmin,
            ]
          }
        },
        {
          path: "list-activity",
          name: "List Activity",
          component: ActivityList,
          meta: {
            authorize: [
              Role.customerUser,
            ]
          }
        },
        {
          path: "candidate-reviewer-activity-review/:id",
          name: "Candidate Review Activity",
          component: CandidateReviewerReviewActivity,
          meta: {
            authorize: [
              Role.customerUser,
            ]
          }
        },
        {
          path: "job-authoriser-activity-review/:id",
          name: "Job Review Activity",
          component: JobAuthoriserReviewActivity,
          meta: {
            authorize: [
              Role.customerUser,
            ]
          }
        },
        {
          path: "email-messages",
          name: "Recruiter Email Messages",
          component: RecruiterEmailMessages,
          meta: {
            authorize: [
              Role.systemAdmin,
              Role.systemRecruiter,
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "/configurable-fields",
          name: "Configurable Fields",
          component: ConfigurableFields,
        },
        {
          path: "config-public-job-board",
          name: "Config Public Job Board",
          component: ConfigPublicJobBoard,
          meta: {
            authorize: [
              Role.systemAdmin,
            ],
          },
        },
        {
          path: "upload-org-pictures",
          name: "Upload Organisation Pictures",
          component: UploadOrganisationPictures,
          meta: {
            authorize: [
              Role.customerAdmin,
              Role.customerRecruiter,
            ],
          },
        },
        {
          path: "",
          name: "Direct Login",
          component: Login,
          beforeEnter: (to, from, next) => {
            if (
              getScope() === "customer_candidate" ||
              getScope() === "system_candidate"
            ) {
              next({ name: 'Candidate Home' })
            } else if (getScope() === "customer_user") {
              next("/list-activity");
            } else if (getScope()) {
              next({ name: 'Candidates' }) // path /candidate-list
            }
            next()
          }
        },
        {
          path: "/faq",
          name: "Frequently Asked Questions",
          component: faq,
        },
        {
          path: "/:org_name",
          name: "Org Based Login",
          component: Login,
          beforeEnter: (to, from, next) => {
            let pre_defined_paths = {
              "/faq": "Frequently Asked Questions",
              "/forgot": "Direct Forgot"
            } // need to be entered manually if new page added outside the getScope() to avoid routing to login page 
            if (to.path.includes("/faq")) {
              next({ name: pre_defined_paths[to.path] })
            } else if (
              getScope() === "customer_candidate" ||
              getScope() === "system_candidate"
            ) {
              next({ name: 'Candidate Home' })
            } else if (getScope() === "customer_user") {
              next("/list-activity");
            } else if (getScope()) {
              next({ name: 'Candidates' }) // path /candidate-list
            } else if (to.path.includes("/forgot")) {
              next({ name: pre_defined_paths[to.path] })
            }
            next()
          }
        }
      ],
    },
    {
      path: "/:org_name/register/:id",
      component: Register,
      props: true,
      children: [
        {
          path: '',
          component: Register,
          name: 'Register'
        },
        {
          path: 'organisation/:org_id',
          component: Register,
          name: 'Organisation Register'
        },
        {
          path: 'organisation/:org_id/location/:loc_id',
          component: Register,
          name: 'Organisation with Location Register'
        },
      ]
    },
    {
      path: "/:org_name/unsubscribe",
      name: "UnSubscribe",
      component: UnSubscribe,
    },
    {
      path: "/forgot",
      name: "Direct Forgot",
      component: Forgot,
    },
    {
      path: "/:org_name/forgot",
      name: "Organisation Based Forgot",
      component: Forgot,
    },
    {
      path: "/auth",
      redirect: "/auth/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "AuthPage404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "reset-password",
          name: "Reset",
          component: Reset,
        },
      ],
    },
    {
      path: "/demo",
      name: "Demo TalentFind",
      component: demo,
    },
    {
      path: "/public/job-board/:id",
      name: "Public Job Board DOH",
      component: DemoDOH,
    },
    {
      path: "/doh/public/job-board-detail/:uuid",
      name: "Demo DOH JOB BOARD",
      component: DemoDOHJobDetails,
    },
    {
      path: "/doh/pqr-check",
      name: "Demo DOH PQR CHECK",
      component: DemoDOHPQRCheck,
    },
    {
      path: "/add/public/job",
      name: "Add Public Jobs to Job Board",
      component: AddPublicJobExternal,
      meta: {
        authorize: [Role.customerUser],
      },
    },
    {
      path: "/public/job/home",
      name: "Job Issuer Home",
      component: JobIssuerHome,
      meta: {
        authorize: [Role.customerUser],
      },
    },
    {
      path: "/edit/public/job/:id",
      name: "Edit Public Job by Job Issuer",
      component: EditPublicJobExternal,
      meta: {
        authorize: [Role.customerUser],
      },
    },
  ];
}
