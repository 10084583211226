import axios from 'axios'
import { BASE_URL } from '@/service_urls'


const facility = {
  getOrganisationTypeList: () => axios.get(`${BASE_URL}/organisation/type/`),
  getNextCredentialDetail: ({ customer_id }) => axios.get(`${BASE_URL}/customer/${customer_id}/nextUser`),
  addFacility: (payload) => axios.post(`${BASE_URL}/customer_registration/`, payload),
  getFacilityList: () => axios.get(`${BASE_URL}/customer/?customer_uid__gte=3`),
  getFacilityLocation: () => axios.get(`${BASE_URL}/location/location/?location_id__notin=4,5,6,7`),
  getCustomerDetails: () => axios.get(`${BASE_URL}/user/customer/`),
  addFacilityDetail: (payload, customer_id) => axios.put(`${BASE_URL}/customer_registration/?customer_id=${customer_id}`, payload),
  getFacilityConfig: ({ queryString }) => axios.get(`${BASE_URL}/customer_application_configuration/?${queryString}`),
  updateFacilityConfig: ({ payload, customer_id }) => axios.put(`${BASE_URL}/customer_application_configuration/?customer_id=${customer_id}`, payload),
  getCustomerDetailsById: (facilityId) => axios.get(`${BASE_URL}/customer/${facilityId}`),
  downloadCandidatesDocument: ({ organisation_id }) => {
    const endUrl = `${BASE_URL}/job_report/organisation/candidates/export/excel?organisation_id=${organisation_id}`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    });
  },
  recoveryPassword: (email) => axios.post(`${BASE_URL}/password-recovery/${email}`),
  addFacilityUser: ({ data, customer_id, facility_id }) => axios.post(`${BASE_URL}/customer/${customer_id}/organisation/${facility_id}/user/`, data),
  checkFacilityUser: ({ customer_id, payload }) => axios.get(`${BASE_URL}/customer/${customer_id}/config_violated/?config_key=${payload}`),
  userReport: ({ customer_uid, organisation_id }) => axios.get(`${BASE_URL}/customer/${customer_uid}/user/recruiter/contact_user?organisation_id=${organisation_id}`),
  //addOrganisation: ({ customer_uid, payload }) => axios.post(`${BASE_URL}/organisation/customer/${customer_uid}/register/`, payload),
  addOrganisation: ({ customer_uid, payload }) => axios.post(`${BASE_URL}/organisation/facility/register/`, payload),
  fetchOrganisationList: ({ customer_uid }) => axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/`),
  updateOrganisation: (payload) => axios.put(`${BASE_URL}/organisation/`, payload),
  checkFacilityMail: (payload) => axios.post(`${BASE_URL}/organisation/facility/register/validation?email=${payload.email}&unique_id=${payload.unique_id}`),
  downloadAllFacilityDetails: () => {
    const endUrl = `${BASE_URL}/organisation/export/excel`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    });
  },
  downloadFacilityJobs: () => {
    const endUrl = `${BASE_URL}/job_report/facility_jobs/export/excel`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    });
  },
  downloadCandidateStatus: () => {
    const endUrl = `${BASE_URL}/job_report/job_candidate_status/export/excel`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    });
  },
  createCustomGroup: (payload) => axios.post(`${BASE_URL}/custom_groups/`, payload),
  getCustomGroups:() => axios.get(`${BASE_URL}/custom_groups/`),
}

export default facility;