import axios from 'axios'
import { BASE_URL } from '@/service_urls'


const hospital = {
  addHospital: (payload) => axios.post(`${BASE_URL}/new_customer_registration/`, payload),
  getHospitalList: () => axios.get(`${BASE_URL}/customer/?customer_uid__gte=3`),
  getHospitalAdminList: () => axios.get(`${BASE_URL}/users/?role_type_id=4&limit=1000`),
  addHospitalAdmin: (customer_id, payload) => axios.post(`${BASE_URL}/users/${customer_id}/`, payload),
  getHospitalRecruitersList: (customer_id) => axios.get(`${BASE_URL}/users/?role_type_id=5&customer_id=${customer_id}`),
  getOrganisationTypeList: () => axios.get(`${BASE_URL}/organisation/type/`)
}

export default hospital;