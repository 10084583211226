import contactUser from "@/services/contactUser";
import { getFilterQueryStringWithoutArray, isObject, isEmptyObjectCheck } from "@/helpers/helper.js";
import router from "../router/index";

const getDefaultState = () => {
    return {
        contactList: [],
        userTypes: [],
        contactType: [],
        contactUsers: [],
        groupedCustomerOrganisations: [],
    };
}
const state = getDefaultState();

const getters = {
    contactList: state => state.contactList.length ?
        state.contactList.map(val => ({
            code: val?.contact_id,
            label: val?.contact?.name
        })) : [],
    userTypes: state => state.userTypes.length ?
        state.userTypes.map(val => ({
            code: val.id,
            label: val?.display_name
        })) : [],
    contactType: state => state.contactType.length ?
        state.contactType.map(val => ({
            code: val?.contact_type_id,
            label: val?.contact_type
        })) : [],
    contactUsers: state => state.contactUsers,
    groupedCustomerOrganisations: state => state.groupedCustomerOrganisations || [],
}

const mutations = {
    ['SET_CONTACT_LIST'](state, payload) {
        state.contactList = payload;
    },
    ['SET_USER_TYPES'](state, payload) {
        state.userTypes = payload;
    },
    ['SET_CONTACT_TYPE'](state, payload) {
        state.contactType = payload;
    },
    ['SET_CONTACT_USERS'](state, payload) {
        state.contactUsers = payload;
    },
    ['SET_GROUPED_CUSTOMER_ORGANISATIONS'](state, payload) {
        state.groupedCustomerOrganisations = payload;
    },
    ['RESET_GROUPED_CUSTOMER_ORGANISATIONS'](state) {
        state.groupedCustomerOrganisations = [];
    },
}

const actions = {
    initContactFetch: ({ state, getters, commit, dispatch }) => {
        const { getUser, getLocationCountryInfo, autoGenPassword } = getters;
        commit("SET_FETCHING", true);
        let appendActions = [];
        if (!(isObject(getUser) && !isEmptyObjectCheck(getUser))) {
            appendActions = [...appendActions, dispatch("fetchUser")];
        }
        if (!getLocationCountryInfo.length) {
            appendActions = [...appendActions, dispatch("locationCountryInfo")];
        }
        appendActions = [...appendActions, dispatch("fetchAutoGenPassword")];
        Promise.all(appendActions).then(async res => {
            commit("SET_FETCHING", false);
        })
    },
    fetchContactList: ({ state, getters, commit, dispatch }) => {
        const { getCustomerId: customer_uid, getOrgIDFromAccessToken: organisation_uid } = getters;
        return contactUser
            .fetchContact({ customer_uid, organisation_uid })
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_LIST", data);
                return res;
            })
            .catch(err => {
                console.log("Error While Fetch Contact List", err);
                return err;
            })
    },
    fetchUserType: ({ getters, commit, dispatch }) => {
        return contactUser
            .fetchUserType()
            .then(res => {
                const { data } = res;
                commit("SET_USER_TYPES", data);
                return res;
            })
            .catch(err => {
                console.log('Error While Fetch UserType', err);
                return err;
            })
    },
    fetchContactType: ({ commit }) => {
        return contactUser
            .fetchContactType()
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_TYPE", data);
                return res;
            })
            .catch(err => {
                console.log('Error While Fetch Contact Type', err);
                return err;
            })
    },
    createUserExisting: ({ getters, commit, dispatch }, data) => {
        const { contact_id, payload: { customer_user_type_id } } = data;
        const { getCustomerId: customer_id, getOrgIDFromAccessToken: organisation_id } = getters;
        dispatch("showToast", { message: 'Adding...' });
        let queryPayload = {};
        if (contact_id)
            queryPayload = { ...queryPayload, contact_id };
        if (customer_user_type_id)
            queryPayload = { ...queryPayload, customer_user_type_id }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return contactUser
            .createUserExisting({ contact_id, organisation_id, customer_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'User Added Successfully' })
                router.push("/organisation-list");
                return res
            })
            .catch(err => {
                const message = "Error While Create User";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    createUserNew: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken: organisation_id } = getters;
        dispatch("showToast", { message: 'Adding...' });
        return contactUser
            .createCustomerContactUser({ organisation_id, customer_id, payload })
            .then(res => {
                const { data } = res;
                const { user_id, contact_id } = data;
                let updatePayload = {
                    user_id,
                    contact_id,
                    customer_recruiters: [{
                        user_id,
                        recruitment_type_id: 1,
                        created_by: 1,
                    }]
                }
                return contactUser.updateCustomerContactUser({ customer_id, payload: updatePayload }).then(res => {
                    dispatch("clearAllToast");
                    dispatch("showToast", { class: 'bg-success text-white', message: 'User Added Successfully' })
                    router.push("/list-contactuser");
                    commit("SET_AUTO_GEN_PASSWORD", null)
                    return res
                }).catch(err => {
                    const message = "Error While Create User";
                    dispatch("clearAllToast");
                    dispatch("showToast", { class: 'bg-danger text-white', message })
                    console.log(message, err);
                    return err;
                })

            })
            .catch(err => {
                const message = "Error While Create User";
                dispatch("clearAllToast");
                if (err.response.status == 422) {
                    dispatch("showToast", { class: 'bg-danger text-white', message: 'Email already exists! Please choose another email id!' });
                }
                else {
                    dispatch("showToast", { class: 'bg-danger text-white', message })
                }
                console.log(message, err);
                return err;
            })
    },
    fetchContactUser: ({ commit, getters }) => {
        const { getCustomerId: customer_uid, getOrgIDFromAccessToken: organisation_uid } = getters;
        commit("SET_IS_CUSTOMER_FETCH", true);
        return contactUser
            .fetchContactUser({ customer_uid, organisation_uid })
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_USERS", data);
                return res;
            })
            .catch(err => {
                console.log('Error while Fetch Contact User', err);
                return err;
            })
            .finally(res => {
                commit("SET_IS_CUSTOMER_FETCH", false);
            })
    },
    updateCustomerUser: ({ dispatch, getters }, payload = {}) => {
        const { getCustomerId } = getters;
        dispatch("showToast", { message: 'Updating...' });
        const { customer_id: cus_id, organisation_id, ...rest } = payload;
        const customer_id = getCustomerId || cus_id;
        let queryPayload = {};
        if (organisation_id)
            queryPayload = { ...queryPayload, organisation_id };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return contactUser
            .updateCustomerContactUser({ customer_id, payload:rest, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'User Updated Successfully' })
                return res;
            })
            .catch(err => {
                const message = "Error While Update User"
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    fetchCustomerContactUser({ commit, getters }) {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken: organisation_id } = getters;
        commit("SET_IS_CUSTOMER_FETCH", true);
        return contactUser
            .getCustomerContactUser({ customer_id, organisation_id })
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_USERS", data);
                return res;
            })
            .catch(err => {
                console.log('Error while Fetch Contact User', err);
                return err;
            })
            .finally(res => {
                commit("SET_IS_CUSTOMER_FETCH", false);
            })
    },
    fetchAllGroupedCustomerOrganisation: ({ getters, commit, dispatch }, contact_id) => {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken: organisation_id } = getters;
        commit("RESET_GROUPED_CUSTOMER_ORGANISATIONS");
        return contactUser
            .getAllGroupedCustomerOrganisation({ contact_id, organisation_id, customer_id })
            .then(res => {
                const { data } = res;
                commit("SET_GROUPED_CUSTOMER_ORGANISATIONS", data);
                return res;
            })
            .catch(err => {
                const message = "Error While Fetch Grouped Organisation";
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                })
                console.log(message, err)
                return err;
            })
    },
    createCustomerOrganisationContact: ({ getters, commit, dispatch }, payload = {}) => {
        const { organisation_id, contact_id } = payload;
        const { getCustomerId: customer_id } = getters;
        return contactUser
            .createCustomerOrganisationContact({ customer_id, organisation_id, contact_id })
            .then(res => {
                return res;
            })
            .catch(err => {
                const message = "Error while add customer organisation";
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                })
                console.log(message, err)
                return err;
            })
    },
    deleteCustomerOrganisationContact: ({ getters, commit, dispatch }, payload = {}) => {
        const { getCustomerId: customer_id } = getters;
        const { contact_id, organisation_id } = payload;
        return contactUser
            .deleteCustomerOrganisationContact({ customer_id, organisation_id, contact_id })
            .then(res => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Deleted Successfully",
                })
                return res;
            })
            .catch(err => {
                let message = "Error while Delete User";
                if (err?.response?.status === 400) {
                    message = "Please Contact Admin to Delete";
                }
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                })
                console.log("Error while Delete User");
                return err;
            })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
};