import candidate from "@/services/candidate";
import job from "@/services/job";
import location from "@/services/location";
import speciality from "@/services/speciality";
import qualification from "@/services/qualification";
import gender from "@/services/gender";
import filter from "@/services/filter";
import hospital from "@/services/hospital";
import { Role, getScope } from "@/helpers/helper";

import { getFilterQueryString } from "@/helpers/helper.js";
import router from "@/router";

// schema
import jobFilter from "@/schema/jobFilter";
import candidateFilter from "@/schema/candidateFilter";

function convertQueryKeyData(data = {}, filter) {
  let result = {};
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      let queryIdsResult;
      if (Array.isArray(data[key])) {
        queryIdsResult = data[key]
      } else {
        let arrayToStr = data[key].replace(/]|\[/g, "");
        queryIdsResult = [...arrayToStr.split(/,\s*/)];
      }
      if (filter[key]) {
        result[filter[key]["queryKey"]] = queryIdsResult.join(",");
      }
    }
  }
  return result;
}
// initial state
const state = {
  benefits: [],
  isFetchingFilter: false,
  recruitingCandidateLevel: [],
  candidateLevel: [],
  candidateType: [],
  //jobStatus: [],
  languageProficiency: [],
  locationLanguage: [],
  locationCountryInfo: [],
  nationalityCountryInfo: [],
  locationRegion: [],
  speciality: [],
  subSpeciality: [],
  skill: [],
  qualification: [],
  filteredQualification: [],
  filteredIssueAuthority: [],
  filteredNonMedicalScore: [],
  issueAuthority: [],
  gender: [],
  specialInterest: [],
  licensingAuthority: [],
  jobFilterData: jobFilter,
  candidateFilterData: candidateFilter,
  titles: [],
  locations: [],
  insideUAELocation: [],
  hospitalNames: [],
  displayData: [],
  regionCountry: [],
  currency: [],
  conditionalOptions: [
    { label: "Yes", code: true },
    { label: "No", code: false },
    { label: "Not Selected", code: "null" },
  ],
  availabilityOptions: [
    { label: "Full Time", code: "Full Time" },
    { label: "Part Time", code: "Part Time" },
  ],
  contractOptions: [
    { label: "Permanent", code: 'Permanent' },
    { label: "Temporary", code: 'Temporary' },
  ],
  activeOptions: [
    { label: "Active", code: true },
    { label: "Deactivated", code: false },
    { label: "Not Selected", code: "null" },
  ],
  deletedOptions: [
    { label: "Yes", code: true },
    { label: "No", code: false },
  ],
  jobStatus: [
    { label: "Draft Jobs", code: "un-published" },
    { label: "Published Jobs", code: "published" }
  ],
  departments: [],
  filteredFirstQualification: [],
  filteredHigherQualification: [],
  nurseRegistrationType: [],
};

function recruitingCandidateLevelFilter(
  recruitingCandidateLevel,
  { candidateType }
) {
  if (candidateType && candidateType.length) {
    let filterCandidateLevalBasedOnTypeId = recruitingCandidateLevel.filter(
      val => {
        return candidateType.includes(val.typeId);
      }
    );
    return filterCandidateLevalBasedOnTypeId;
  }
  return recruitingCandidateLevel;
}

function dependentFilterComputation(dependentFilterData, filterData) {
  if (filterData && filterData.length) {
    let filterCandidateLevalBasedOnTypeId = dependentFilterData.filter(val => {
      return filterData.includes(val.typeId);
    });
    return filterCandidateLevalBasedOnTypeId;
  }
  return dependentFilterData;
}

function specialInterestFilter(specialInterest, { speciality }) {
  if (speciality && speciality.length) {
    let filterCandidateLevalBasedOnTypeId = specialInterest.filter(val => {
      return speciality.includes(val.typeId);
    });
    return filterCandidateLevalBasedOnTypeId;
  }
  return specialInterest;
}

function skillFilter(skill, { speciality }) {
  if (speciality && speciality.length) {
    let filterCandidateLevalBasedOnTypeId = skill.filter(val => {
      return speciality.includes(val.typeId);
    });
    return filterCandidateLevalBasedOnTypeId;
  }
  return skill;
}

function subSpecialityFilter(subSpeciality, { speciality }) {
  if (speciality && speciality.length) {
    let filterCandidateLevalBasedOnTypeId = subSpeciality.filter(val => {
      return speciality.includes(val.typeId);
    });
    return filterCandidateLevalBasedOnTypeId;
  }
  return subSpeciality;
}

function qualificationFilter(qualification, { locationCountryInfo }) {
  if (locationCountryInfo && locationCountryInfo.length) {
    let filterqualificationBasedOnCountry = qualification.filter(val => {
      return locationCountryInfo.includes(val.code);
    });
    return filterqualificationBasedOnCountry;
  }
  return qualification;
}

function issueAuthorityFilter(qualification, { locationCountryInfo }) {
  if (locationCountryInfo && locationCountryInfo.length) {
    let filterissueAuthorityBasedOnCountry = issueAuthority.filter(val => {
      return locationCountryInfo.includes(val.code);
    });
    return filterissueAuthorityBasedOnCountry;
  }
  return issueAuthority;
}

function licensingAuthorityFilter(licensingAuthority, { locationCountryInfo }) {
  if (locationCountryInfo && locationCountryInfo.length) {
    let filterCandidateLevalBasedOnTypeId = licensingAuthority.filter(val => {
      return locationCountryInfo.includes(val.typeId);
    });
    return filterCandidateLevalBasedOnTypeId;
  }
  return licensingAuthority;
}
function remove_duplicates_es6(arr) {
  let s = new Set(arr);
  let it = s.values();
  return Array.from(it);
}
// create getters for all state variables
const getters = {
  benefits: state =>
    state.benefits && state.benefits.length
      ? state.benefits.map(val => ({
        label: val.benefit_type.benefit_type,
        code: val.benefit_type.benefit_type_id,
      }))
      : [],
  conditionalOptions: state => state.conditionalOptions,
  availabilityOptions: state => state.availabilityOptions,
  contractOptions: state => state.contractOptions,
  activeOptions: state => state.activeOptions,
  deletedOptions: state => state.deletedOptions,
  titles: state =>
    state.titles && state.titles.length
      ? state.titles.map(val => ({ label: val.title, code: val.title_id }))
      : [],
  locations: state =>
    state.locations && state.locations.length
      ? state.locations.map(val => ({
        label: val.location,
        code: val.location_id
      }))
      : [],
  insideUAELocation: (state, getters) => {
    let { getInsideUAELocation } = getters;
    return getInsideUAELocation.map(val => ({
      label: val.comments,
      code: val.location_id
    }))
  },
  hospitalNames: state => state.hospitalNames && state.hospitalNames.length
    ? state.hospitalNames.map(val => ({
      label: val.name,
      code: val.customer_uid
    }))
    : [],
  isFetchingFilter: (state, getters, rootState) => state.isFetchingFilter,
  getInsideUAELocation: () => state.insideUAELocation,
  getCandidateLevel: (state, getters, rootState) => state.candidateLevel,
  getRecruitingCandidateLevel: (state, getters, rootState) =>
    state.recruitingCandidateLevel,
  getCandidateType: (state, getters, rootState) => state.candidateType,
  getJobStatus: (state, getters, rootState) => state.jobStatus,
  getLanguageProficiency: (state, getters, rootState) => state.languageProficiency,
  getLocationLanguage: (state, getters, rootState) => state.locationLanguage,
  getLocationCountryInfo: (state, getters, rootState) =>
    state.locationCountryInfo,
  getNationalityCountryInfo: (state, getters, rootState) =>
    state.nationalityCountryInfo,
  getSpeciality: (state, getters, rootState) => state.speciality,
  getSubSpeciality: (state, getters, rootState) => state.subSpeciality,
  getSkill: (state, getters, rootState) => state.skill,
  getSpecialInterest: (state, getters, rootState) => state.specialInterest,
  getQualification: (state, getters, rootState) => state.qualification,
  getIssueAuthority: (state, getters, rootState) => state.issueAuthority,
  getFilteredIssueAuthority: (state, getters, rootState) => state.filteredIssueAuthority?.map(val => ({
    label: val.issue_authority.authority_name,
    code: val.issue_authority.issue_authority_id
  })),
  getGender: (state, getters, rootState) => state.gender,
  getLocationRegion: (state, getters, rootState) => state.locationRegion,
  getLicensingAuthority: (state, getters, rootState) =>
    state.licensingAuthority,
  gender: (state, getters, rootState) => {
    let { getGender } = getters;
    return (
      getGender.length &&
      getGender.map(val => ({ label: val.gender, code: val.gender_id }))
    );
  },
  licensingAuthority: (state, getters, rootState) => {
    let { getLicensingAuthority } = getters;
    return getLicensingAuthority.map(val => ({
      label: val.licensing_authority,
      code: val.license_auth_id,
      typeId: val.country.country_id
    }));
  },
  locationRegion: (state, getters, rootState) => {
    let { getLocationRegion } = getters;
    return getLocationRegion.map(val => ({
      label: val.region_name,
      code: val.region_id,
      job_only: val.job_only
    }));
  },
  qualification: (state, getters, rootState) => {
    let { getQualification } = getters;
    return (
      getQualification.length &&
      getQualification.map(val => ({
        label: val.qualification_with_acronym,
        code: val.qualification_id
      }))
    );
  },
  qualificationIssueAuthority: (state, getters, rootState) => {
    let { getIssueAuthority } = getters;
    return (
      getIssueAuthority.length &&
      getIssueAuthority.filter(val => {
        return val.qualification == true
      }).map(val => ({
        label: val.authority_name,
        code: val.issue_authority_id
      }))
    );
  },
  licensingIssueAuthority: (state, getters, rootState) => {
    let { getIssueAuthority } = getters;
    return (
      getIssueAuthority.length &&
      getIssueAuthority.filter(val => {
        return val.licensing == true
      }).map(val => ({
        label: val.authority_name,
        code: val.issue_authority_id
      }))
    );
  },
  specialInterest: (state, getters, rootState) => {
    let { getSpecialInterest } = getters;
    return getSpecialInterest.map(val => ({
      label: val.special_interest,
      code: val.special_interest_id,
      typeId: val.speciality_id
    }));
  },
  candidateBasedSpecialInterest: (state, getters, rootState) => {
    let { getSpecialInterest } = getters;
    return getSpecialInterest.map(val => ({
      label: val.special_interest,
      code: val.special_interest_id,
      candidateTypeId: val.candidate_type_id,
      typeId: val.speciality_id
    }));
  },
  skill: (state, getters, rootState) => {
    let { getSkill } = getters;
    return getSkill.map(val => ({
      label: val.skill,
      code: val.skill_id,
      typeId: val.speciality_id
    }));
  },
  speciality: (state, getters, rootState) => {
    let { getSpeciality } = getters;
    return getSpeciality.map(val => ({
      label: val.speciality,
      code: val.speciality_id,
      typeId: val.candidate_type_id
    }));
  },
  specialityFilterByType: (state, getters, rootState) => ({
    candidateType
  }) => {
    return state.speciality && candidateType
      ? state.speciality
        .filter(specialityFilter => {
          return (
            specialityFilter.candidate_type_id == candidateType && specialityFilter.country_id == null
          );
        })
        .map(val => ({
          label: val.speciality,
          code: val.speciality_id
        }))
      : null;
  },
  specialityByTypeAndCountry: (state, getters, rootState, dispatch) => ({
    candidateType, country_id
  }) => {
    let data = []
    if (state.speciality && candidateType && !country_id) {
      data = state.speciality
        .filter(specialityFilter => {
          return specialityFilter.candidate_type_id == candidateType && specialityFilter.country_id == null;
        })
    }
    if (state.speciality && candidateType && country_id) {
      data = state.speciality
        .filter(specialityFilter => {
          return specialityFilter.candidate_type_id == candidateType && (specialityFilter.country_id == country_id);
        })
      if (!data.length) {
        data = state.speciality
          .filter(specialityFilter => {
            return specialityFilter.candidate_type_id == candidateType && specialityFilter.country_id == null;
          })
      } else {
        let other_data = state.speciality
          .filter(specialityFilter => {
            return specialityFilter.candidate_type_id == candidateType && (specialityFilter.speciality === "Other - not listed");
          })
        data = [...data, ...other_data]
      }
    }
    data = data.sort(({ display_order: a }, { display_order: b }) => (a === null) - (b === null) || +(a > b) || -(a < b));
    return data?.map(val => ({
      label: val.speciality,
      code: val.speciality_id
    })) || []
  },
  subSpeciality: (state, getters, rootState) => {
    let { getSubSpeciality } = getters;
    return getSubSpeciality.map(val => ({
      label: val.sub_speciality,
      code: val.sub_speciality_id,
      typeId: val.speciality_id
    }));
  },
  candidateBasedSubSpeciality: (state, getters, rootState) => {
    let { getSubSpeciality } = getters;
    return getSubSpeciality.map(val => ({
      label: val.sub_speciality,
      code: val.sub_speciality_id,
      candidateTypeId: val.candidate_type_id,
      typeId: val.speciality_id
    }));
  },
  subSpecialityFilterBySpeciality: (state, getters, rootState) => ({
    speciality
  }) => {
    return state.subSpeciality && speciality
      ? state.subSpeciality
        .filter(subSpecialityFilter => {
          return subSpecialityFilter.speciality_id === speciality;
        })
        .map(val => ({
          label: val.sub_speciality,
          code: val.sub_speciality_id
        }))
      : null;
  },
  specialInterestFilterBySpeciality: (state, getters, rootState) => ({
    speciality
  }) => {
    return state.specialInterest && speciality
      ? state.specialInterest
        .filter(specialInterestFilter => {
          return specialInterestFilter.speciality_id === speciality || specialInterestFilter.special_interest === "Other - not listed";
        })
        .map(val => ({
          label: val.special_interest,
          code: val.special_interest_id,
        }))
      : null;
  },
  qualificationByCountryAndCandidateType: (state, getters, rootState) => {
    return state.filteredQualification ? state.filteredQualification
      .map(val => ({
        label: val.qualification_with_acronym,
        code: val.qualification_id,
        by_exam: val.by_exam,
        specialist_registration_reqd: val.specialist_registration_reqd,
        course_duration_reqd: val.course_duration_reqd,
        allow_subject_input: val?.allow_subject_input,
        qualification_classification_sort_level: val?.qualification_classification_sort_level,
        qualification_classification_name: val?.qualification_classification_name,
      }))
      : [];
  },
  issueAuthorityByQualification: (state, getters, rootState) => ({
    country_id, qualification_id
  }) => {
    let data = []
    data = state.filteredIssueAuthority.map(val => ({
      label: val.issue_authority.authority_name,
      code: val.issue_authority.issue_authority_id
    }));
    if (state.issueAuthority && qualification_id && country_id) {
      if (!data.length) data = state.issueAuthority.filter(val => {
        return val.issue_country_id === country_id && val.qualification;
      }).map(val => ({
        label: val.authority_name,
        code: val.issue_authority_id
      })) || []
    }
    qualification_id ? data.push({ label: "Others - not listed", code: "Others" }) : null;
    return data;
  },
  scoreFilteredByNonMedicalQualName: (state) => {
    return state.filteredNonMedicalScore ? state.filteredNonMedicalScore
      .map(val => ({
        label: val.score,
        code: val.id,
        qualification_id: val.non_medical_qual_type_id,
        name: val.name,
        skill_level: val.skill_level
      })).sort((a, b) => {
        if (a.name == 'TOEFL - Test of English as a Foreign Language') {
          return
        }
        let regExp = /[a-zA-Z]/g;
        if (regExp.test(a.label)) {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        } else {
          if (a.label > b.label) { return -1; }
          if (a.label < b.label) { return 1; }
          return 0;
        }
      })
      : []
  },
  qualificationIssueAuthorityByCountry: (state, getters, rootState) => ({
    country_id
  }) => {
    return state.issueAuthority && country_id
      ? state.issueAuthority
        .filter(val => {
          return val.issue_country_id === country_id && val.qualification;
        })
        .map(val => ({
          label: val.authority_name,
          code: val.issue_authority_id
        }))
      : null;
  },
  licenseIssuedByCountry: (state, getters, rootState) => ({
    country_id
  }) => {
    return state.issueAuthority && country_id
      ? state.issueAuthority
        .filter(val => {
          return val.issue_country_id === country_id && val.licensing == true;
        })
        .map(val => ({
          label: val.authority_name,
          code: val.issue_authority_id
        }))
      : null;
  },
  candidateLevelFilterByType: (state, getters, rootState) => ({
    candidateType
  }) => {
    return state.candidateLevel && candidateType
      ? state.candidateLevel
        .filter(candidateLevelFilter => {
          return candidateLevelFilter.candidate_type_id === candidateType;
        })
        .map(val => ({
          label: val.candidate_level,
          code: val.level_id,
          title: val?.description,
        }))
      : null;
  },
  candidateLevelByTypeAndCountry: (state, getters, rootState) => ({
    candidateType, country_id
  }) => {
    let data = []
    if (state.candidateLevel && candidateType) {
      // if (state.candidateLevel && candidateType && country_id) {
      // data = state.candidateLevel
      //   .filter(candidateLevelFilter => {
      //     return candidateLevelFilter.candidate_type_id == candidateType && candidateLevelFilter.country_id == country_id;
      //   })
      if (!data.length) data = state.candidateLevel
        .filter(candidateLevelFilter => {
          return candidateLevelFilter.candidate_type_id == candidateType;
        })
    }
    data = data.filter(candidateLevelFilter => candidateLevelFilter.country_id === country_id)
    return data?.map(val => ({
      label: val.candidate_level,
      code: val.level_id
    })) || []
  },
  locationCountryInfo: (state, getters, rootState) => {
    let { getLocationCountryInfo } = getters;
    return getLocationCountryInfo.map(val => ({
      dialing_code: val.dialing_code,
      nationality: val.nationality,
      label: val.country_name,
      code: val.country_id,
      region_id: val.region_id
    }));
  },
  nationalityCountryInfo: (state, getters, rootState) => {
    let { getNationalityCountryInfo } = getters;
    return getNationalityCountryInfo.map(val => ({
      dialing_code: val.dialing_code,
      nationality: val.nationality,
      label: val.country_name,
      code: val.country_id,
      region_id: val.region_id
    }));
  },
  dialingCodeWithCountryCode: (state, getters, rootState) => {
    let { getLocationCountryInfo } = getters;
    return getLocationCountryInfo.length ? getLocationCountryInfo.filter(val => {
      return val.dialing_code && val.country_code
    }).map(val => ({
      label: val.country_code + " " + val.dialing_code,
      dialing_code: val.dialing_code
    })) : null;
  },
  nationalityInfo: (state, getters, rootState) => {
    let { getNationalityCountryInfo } = getters;
    return getNationalityCountryInfo.map(val => ({
      label: val.nationality,
      code: val.country_id
    }));
  },
  locationLanguage: (state, getters, rootState) => {
    let { getLocationLanguage } = getters;
    return getLocationLanguage.map(val => ({
      label: val.language,
      code: val.language_id
    }));
  },
  languageProficiency: (state, getters, rootState) => {
    let { getLanguageProficiency } = getters;
    return getLanguageProficiency.map(val => ({
      label: val.proficiency,
      code: val.lang_prof_id
    }))
  },
  jobStatus: (state, getters, rootState) => {
    let { getJobStatus } = getters;
    return getJobStatus.map(val => ({
      label: val.job_status,
      code: val.job_status_id
    }));
  },
  candidateType: (state, getters, rootState) => {
    let { getCandidateType } = getters;
    return getCandidateType.map(val => ({
      label: val.candidate_type,
      code: val.candidate_type_id
    }));
  },
  candidateTypewithAll: (state, getters, rootState) => {
    let { getCandidateType } = getters;
    let candidateTypeAll = getCandidateType.map(val => ({
      label: val.candidate_type,
      code: val.candidate_type_id
    }));
    if (candidateTypeAll.length)
      candidateTypeAll.push({ code: 0, label: 'All' });
    return candidateTypeAll;
  },
  candidateLevel: (state, getters, rootState) => {
    let { getCandidateLevel } = getters;
    return getCandidateLevel.map(val => ({
      label: val.candidate_level,
      code: val.level_id,
      typeId: val.candidate_type_id
    }));
  },
  recruitingCandidateLevel: (state, getters, rootState) => {
    let { getRecruitingCandidateLevel } = getters;
    return getRecruitingCandidateLevel.map(val => ({
      label: val.candidate_level,
      code: val.level_id,
      typeId: val.candidate_type_id
    }));
  },
  getDisplayData: (state, getters, routState) => {
    return state.displayData ? state.displayData.map(val => ({
      label: val.display_data,
      code: val.display_data_id
    })) : [];
  },
  getRegionCountry: (state) => {
    return state.regionCountry ? state.regionCountry : [];
  },
  getCurrency: (state) => {
    return state.currency && state.currency.length ? state.currency.filter(val => val.currency_code).map(val => ({
      label: val.currency_code,
      code: val.currency_id,
      symbol: `${val.currency_symbol ? val.currency_symbol : val.currency_code}`
    })) : []
  },
  getFilterData: (state, getters, rootState) => {
    let {
      recruitingCandidateLevel,
      candidateType,
      jobStatus,
      locationLanguage,
      locationCountryInfo,
      speciality,
      subSpeciality,
      skill,
      specialInterest,
      qualification,
      locationRegion
    } = getters;
    let { jobFilterData } = state;
    jobFilterData["recruitingCandidateLevel"][
      "options"
    ] = recruitingCandidateLevel;
    jobFilterData["candidateType"]["options"] = candidateType;
    jobFilterData["jobStatus"]["options"] = jobStatus;
    jobFilterData["locationLanguage"]["options"] = locationLanguage;
    jobFilterData["locationCountryInfo"]["options"] = locationCountryInfo;
    jobFilterData["locationRegion"]["options"] = locationRegion;
    jobFilterData["countryIncluded"]["options"] = locationCountryInfo;
    jobFilterData["speciality"]["options"] = speciality;
    jobFilterData["subSpeciality"]["options"] = subSpeciality;
    jobFilterData["skill"]["options"] = skill;
    jobFilterData["specialInterest"]["options"] = specialInterest;
    jobFilterData["qualification"]["options"] = qualification;
    return jobFilterData;
  },
  getCandidateFilterData: (state, getters, rootState) => {
    let {
      candidateLevel,
      candidateTypewithAll,
      jobStatus,
      locationLanguage,
      locationCountryInfo,
      speciality,
      subSpeciality,
      skill,
      specialInterest,
      qualification,
      locations,
      gender,
      licensingAuthority,
      nationalityInfo,
      conditionalOptions,
      availabilityOptions,
      contractOptions,
      activeOptions,
      deletedOptions
    } = getters;
    let { candidateFilterData } = state;
    candidateFilterData["locationCountryInfo"]["options"] = locationCountryInfo;
    candidateFilterData["countryIncluded"]["options"] = nationalityInfo;
    candidateFilterData["speciality"]["options"] = speciality;
    candidateFilterData["subSpeciality"]["options"] = subSpeciality;
    candidateFilterData["locations"]["options"] = locations;
    candidateFilterData["retiredClinician"]["options"] = conditionalOptions;
    candidateFilterData["available"]["options"] = conditionalOptions;
    candidateFilterData["volunteer"]["options"] = conditionalOptions;
    candidateFilterData["licence"]["options"] = conditionalOptions;
    candidateFilterData["active"]["options"] = activeOptions;
    candidateFilterData["deleted"]["options"] = deletedOptions;
    candidateFilterData["availabilityType"]["options"] = availabilityOptions;
    candidateFilterData["contractType"]["options"] = contractOptions;
    candidateFilterData["preferredLocations"]["options"] = locations;
    candidateFilterData["skill"]["options"] = skill;
    candidateFilterData["specialInterest"]["options"] = specialInterest;
    candidateFilterData["gender"]["options"] = gender;
    candidateFilterData["licensingAuthority"]["options"] = licensingAuthority;
    candidateFilterData["licensingLevel"]["options"] = candidateLevel;
    candidateFilterData["recruitingCandidateLevel"]["options"] = candidateLevel;
    candidateFilterData["candidateType"]["options"] = candidateTypewithAll;
    candidateFilterData["qualification"]["options"] = qualification;
    return candidateFilterData;
  },
  getLocationByCountryID: (state, getters, rootState) => ({
    country_id
  }) => {
    return state.locations && state.locations.length
      ? state.locations.filter(val => val?.country_id === country_id).map(val => ({
        label: val.location,
        code: val.location_id
      })) : []
  },
  getDepartmentsByOrganisationId: (state) => {
    return state.departments && state.departments.length
      ? state.departments.map(val => ({
        label: val.department_name,
        code: val.department_id
      })) : []
  },
  getSkillsByCandidateType: (state) => ({ candidate_type_id }) => {
    return state.skill && state.skill.length
      ? state.skill.filter(val => val?.candidate_type_id === candidate_type_id).map(val => ({
        label: val.skill,
        code: val.skill_id
      })) : []
  },
  getLocationByCountrysID: (state, getters, rootState) => ({
    country_id
  }) => {
    return state.locations && state.locations.length
      ? state.locations.filter(val => Array.isArray(country_id) ? country_id.includes(val?.country_id) : val?.country_id === country_id).map(val => ({
        label: val.location,
        code: val.location_id,
        country_id: val.country_id,
      })) : []
  },
  licenseIssuedByCountrys: (state, getters, rootState) => ({
    country_id
  }) => {
    return state.issueAuthority && country_id
      ? state.issueAuthority
        .filter(val => {
          return Array.isArray(country_id) ? country_id.includes(val?.issue_country_id) : val.issue_country_id === country_id && val.licensing == true;
        })
        .map(val => ({
          label: val.authority_name,
          code: val.issue_authority_id
        }))
      : null;
  },
  specialInterestFilterAHP: (state, getters, rootState) => ({ candidate_type_id, speciality }) => {
    return state.specialInterest && candidate_type_id
      ? state.specialInterest
        .filter(specialInterestFilter => {
          return specialInterestFilter.speciality_id === speciality
            && specialInterestFilter.candidate_type_id === candidate_type_id;
        })
        .map(val => ({
          label: val.special_interest,
          code: val.special_interest_id,
        }))
      : [];
  },
  firstQualificationByCountryAndCandidateType: (state, getters, rootState) => {
    return state.filteredFirstQualification ? state.filteredFirstQualification
      .map(val => ({
        label: val.qualification_with_acronym,
        code: val.qualification_id,
        by_exam: val.by_exam,
        specialist_registration_reqd: val.specialist_registration_reqd,
        course_duration_reqd: val.course_duration_reqd,
        allow_subject_input: val?.allow_subject_input
      }))
      : [];
  },
  higherQualificationByCountryAndCandidateType: (state, getters, rootState) => {
    return state.filteredHigherQualification ? state.filteredHigherQualification
      .map(val => ({
        label: val.qualification_with_acronym,
        code: val.qualification_id,
        by_exam: val.by_exam,
        specialist_registration_reqd: val.specialist_registration_reqd,
        course_duration_reqd: val.course_duration_reqd,
        allow_subject_input: val?.allow_subject_input
      }))
      : [];
  },
  nurseRegistrationType: state => state.nurseRegistrationType,
  candidateLevelFilterByTypeCountry: (state, getters, rootState) => ({
    candidateType, country_id
  }) => {
    return state.candidateLevel && candidateType
      ? state.candidateLevel
        .filter(candidateLevelFilter => {
          return candidateLevelFilter.candidate_type_id === candidateType && country_id === candidateLevelFilter.country_id;
        })
        .map(val => ({
          label: val.candidate_level,
          code: val.level_id,
          title: val?.description,
        }))
      : null;
  },
};

// actions
const actions = {
  initJobListFilters: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    dispatch("initFilter", payload).then(res => {
      dispatch("onChangeJobListFilter", { payload });
    });
  },
  initalStateJobListFilter: ({
    state,
    commit,
    rootState,
    dispatch,
    getters
  }) => {
    commit("INIT_JOB_FILTER");
  },
  initalStateCandidateListFilter: ({
    state,
    commit,
    rootState,
    dispatch,
    getters
  }) => {
    commit("INIT_CANDIDATE_FILTER");
  },
  initCandidateListFilters: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    dispatch("initFilter", payload).then(res => {
      dispatch("onChangeCandidateListFilter", { payload });
    });
  },

  initAddHospitalAdminFetchOptions({ state, commit, rootState, dispatch, getters }, isAdmin) {
    let {
      getLocationCountryInfo, hospitalNames
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("locationCountryInfo")
      ];
    }
    if (!hospitalNames.length && isAdmin) {
      appendFilterAction = [...appendFilterAction, dispatch("getHospitalName")];
    }
  },
  initAddHospitalFetchOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      locations
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
  },
  initJobsFetchOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      locations,
      getCandidateLevel,
      getCandidateType,
      getGender,
      getSpeciality,
      getSubSpeciality,
      hospitalNames,
      // getQualificationRegions,
      // getLocationCountryInfo,
      getLocationLanguage,
      // getSpecialInterest,
      // getCurrency,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
    if (!getGender.length) {
      appendFilterAction = [...appendFilterAction, dispatch("gender")];
    }
    if (!getCandidateType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateType")];
    }
    if (!getCandidateLevel.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    }
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    if (!hospitalNames.length && getScope() != Role.customerAdmin) {
      appendFilterAction = [...appendFilterAction, dispatch("getHospitalName")];
    }
    // if (!getQualificationRegions.length) {
    //   appendFilterAction = [...appendFilterAction, dispatch("fetchQualificationRegions")]
    // }
    // if (!getLocationCountryInfo.length) {
    //   appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")]
    // }
    if (!getLocationLanguage.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchLocationLanguage")];
    }
    // if (!getSpecialInterest.length) {
    //   appendFilterAction = [...appendFilterAction, dispatch("fetchSpecialInterestList")];
    // }
    // if (!getCurrency.length) {
    //   appendFilterAction = [...appendFilterAction, dispatch("fetchCurrency")];
    // }
    return Promise.all(appendFilterAction).then(res => {
      return res;
    });
  },
  initQuickRegisterFetchOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      titles,
      getGender,
      getLocationCountryInfo,
      getNationalityCountryInfo,
      getCandidateType,
      getSpeciality,
      getSubSpeciality,
      documentType,
      getSkill,
      getCandidateLevel,
      getSpecialInterest,
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!titles.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getTitles")];
    }
    if (!getGender.length) {
      appendFilterAction = [...appendFilterAction, dispatch("gender")];
    }
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getNationalityCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("nationalityCountryInfo")];
    }
    if (!getCandidateType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateType")];
    }
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    if (!documentType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchDocumentType")];
    }
    if (!getSkill.length) {
      appendFilterAction = [...appendFilterAction, dispatch("skill")];
    }
    if (!getCandidateLevel.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    }
    if (!getSpecialInterest.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchSpecialInterestList")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initRegisterFetchOptions({ state, commit, rootState, dispatch, getters }) {
    let {
      getCandidateLevel,
      getCandidateType,
      getLocationLanguage,
      getLanguageProficiency,
      getLocationCountryInfo,
      getNationalityCountryInfo,
      getSpeciality,
      getSubSpeciality,
      getIssueAuthority,
      getSpecialInterest,
      getSkill,
      getGender,
      titles,
      locations,
      getDisplayData,
      getRegionCountry,
      getCurrency
    } = getters;
    let { departments } = state;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!titles.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("getTitles", null, "candidate-profile")
      ];
    }
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
    if (!getGender.length) {
      appendFilterAction = [...appendFilterAction, dispatch("gender")];
    }
    if (!getSkill.length) {
      appendFilterAction = [...appendFilterAction, dispatch("skill")];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    if (!getLocationLanguage.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationLanguage")];
    }
    if (!getLanguageProficiency.length) {
      appendFilterAction = [...appendFilterAction, dispatch("languageProficiency")];
    }
    if (!getSpecialInterest.length) {
      appendFilterAction = [...appendFilterAction, dispatch("specialInterest")];
    }
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("locationCountryInfo")
      ];
    }
    if (!getNationalityCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("nationalityCountryInfo")];
    }
    if (!getCandidateType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateType")];
    }
    if (!getCandidateLevel.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    }
    if (!getDisplayData.length) {
      appendFilterAction = [...appendFilterAction, dispatch("displayData")];
    }
    if (!getRegionCountry.length) {
      appendFilterAction = [...appendFilterAction, dispatch("regionCountry")];
    }
    if (!getCurrency.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCurrency")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  initFilter: ({ state, commit, rootState, dispatch, getters }) => {
    let {
      getCandidateLevel,
      getRecruitingCandidateLevel,
      getCandidateType,
      getJobStatus,
      getLocationLanguage,
      getLocationCountryInfo,
      getNationalityCountryInfo,
      getSpeciality,
      getSkill,
      getSubSpeciality,
      getSpecialInterest,
      getQualification,
      getLocationRegion,
      getGender,
      getLicensingAuthority,
      locations
    } = getters;
    let appendFilterAction = [];
    commit("SET_FETCHING", true);
    if (!getCandidateLevel.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateLevel")];
    }
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
    if (!getRecruitingCandidateLevel.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("recruitingCandidateLevel")
      ];
    }
    if (!getCandidateType.length) {
      appendFilterAction = [...appendFilterAction, dispatch("candidateType")];
    }
    if (!getJobStatus.length) {
      appendFilterAction = [...appendFilterAction, dispatch("jobStatus")];
    }
    if (!getLocationLanguage.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("locationLanguage")
      ];
    }
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("locationCountryInfo")
      ];
    }
    if (!getNationalityCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("nationalityCountryInfo")];
    }
    if (!getSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("speciality")];
    }
    if (!getSubSpeciality.length) {
      appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
    }
    if (!getSkill.length) {
      appendFilterAction = [...appendFilterAction, dispatch("skill")];
    }
    if (!getSpecialInterest.length) {
      appendFilterAction = [...appendFilterAction, dispatch("specialInterest")];
    }
    if (!getQualification.length) {
      appendFilterAction = [...appendFilterAction, dispatch("qualification")];
    }
    if (!getLocationRegion.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationRegion")];
    }
    if (!getGender.length) {
      appendFilterAction = [...appendFilterAction, dispatch("gender")];
    }
    if (!getLicensingAuthority.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("licensingAuthority")
      ];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  clearDependentCandidateFilter: (
    { state, commit, rootState, getters, dispatch },
    { payload, changed_name }
  ) => {
    let { getCandidateFilterData } = getters;
    let dependentFilter =
      getCandidateFilterData[changed_name]["dependentFilter"];
    if (dependentFilter && dependentFilter.length) {
      dependentFilter.forEach(filterName => {
        getCandidateFilterData[filterName]["selected"] = [];
        commit("CLEAR_SELECTED_CANDIDATE_FILTER_BASEDON_NAME", filterName);
        delete payload[filterName];
      });
    }
    return payload;
  },
  clearDependentJobFilter: (
    { state, commit, rootState, getters, dispatch },
    { payload, changed_name }
  ) => {
    let { getFilterData } = getters;
    let dependentFilter = getFilterData[changed_name]["dependentFilter"];
    if (dependentFilter && dependentFilter.length) {
      dependentFilter.forEach(filterName => {
        getFilterData[filterName]["selected"] = [];
        commit("CLEAR_SELECTED_JOB_FILTER_BASEDON_NAME", filterName);
        delete payload[filterName];
      });
    }
    return payload;
  },
  onChangeJobListFilterWithRouterChange: async (
    { state, commit, rootState, getters, dispatch },
    { payload, changed_name }
  ) => {
    if (changed_name) {
      payload = await dispatch("clearDependentJobFilter", {
        payload,
        changed_name
      });
    }
    dispatch("onChangeJobListFilter", { payload, changed_name });
    let result = getFilterQueryString(payload);
    router.push({ path: `/ job - list ? ${result}` });
  },
  onChangeCandidateListFilterWithRouterChange: async (
    { state, commit, rootState, getters, dispatch },
    { payload, changed_name }
  ) => {
    if (changed_name) {
      payload = await dispatch("clearDependentCandidateFilter", {
        payload,
        changed_name
      });
    }
    dispatch("onChangeCandidateListFilter", { payload, changed_name });
    let result = getFilterQueryString(payload);
    router.push({ path: `/ candidate - list ? ${result} ` });
  },
  dependentFilterQueryBasedDataOptimize: (
    { state, commit, rootState, getters, dispatch },
    { payload, type }
  ) => {
    let {
      recruitingCandidateLevel,
      candidateLevel,
      speciality,
      subSpeciality,
      candidateBasedSubSpeciality,
      candidateBasedSpecialInterest,
      licensingAuthority,
      specialInterest,
      skill
    } = getters;

    if (type === "jobList") {
      // dependent candidateLevel filter
      let recruitingCandidateLevelResult = recruitingCandidateLevelFilter(
        recruitingCandidateLevel,
        payload
      );
      commit(
        "SET_RECRUITING_CANDIDATE_LEVEL_JOB_FILTER",
        recruitingCandidateLevelResult
      );

      // dependent subSpeciality filter
      let subSpecialityResult = subSpecialityFilter(subSpeciality, payload);
      commit("SET_SUB_SPECIALITY_JOB_FILTER", subSpecialityResult);

      // dependent Special Interest filter
      let specialInterestResult = specialInterestFilter(
        specialInterest,
        payload
      );
      commit("SET_SPECIAL_INTEREST_JOB_FILTER", specialInterestResult);

      // dependent skill filter
      let skillResult = skillFilter(skill, payload);
      commit("SET_SKILL_JOB_FILTER", skillResult);
    }

    if (type === "candidateList") {
      let recruitingCandidateLevelResult = dependentFilterComputation(
        candidateLevel,
        payload.candidateType
      );
      commit(
        "SET_RECRUITING_CANDIDATE_LEVEL_CANDIDATE_FILTER",
        recruitingCandidateLevelResult
      );

      let recruitingSpecialityResult = dependentFilterComputation(
        speciality,
        payload.candidateType
      );
      commit(
        "SET_RECRUITING_SPECIALITY_CANDIDATE_FILTER",
        recruitingSpecialityResult
      );

      let subSpecialityResult = dependentFilterComputation(
        subSpeciality,
        payload.speciality
      );

      commit(
        "SET_SUB_SPECIALITY_CANDIDATE_FILTER",
        subSpecialityResult
      );

      let specialInterestResult = specialInterestFilter(
        specialInterest,
        payload
      );
      commit("SET_SPECIAL_INTEREST_CANDIDATE_FILTER", specialInterestResult);

      // dependent skill filter
      let skillResult = skillFilter(skill, payload);
      commit("SET_SKILL_CANDIDATE_FILTER", skillResult);
    }

    // dependent licensingAuthority filter
    let licensingAuthorityResult = licensingAuthorityFilter(
      licensingAuthority,
      payload
    );
    commit(
      "SET_LICENSING_AUTHORITY_CANDIDATE_FILTER",
      licensingAuthorityResult
    );
    if (
      licensingAuthorityResult.length &&
      licensingAuthorityResult.length === 1 &&
      !payload["licensingAuthority"]
    ) {
      payload[
        "licensingAuthority"
      ] = `[${licensingAuthorityResult[0]["code"]}]`;

      // Licensing Level computation based on licensing Authority
      dispatch("licensingAuthorityCandidateLevelComputation", {
        licenseAuthIds: payload["licensingAuthority"]
      });
      commit("SET_LICENSING_AUTHORITY_CANDIDATE_FILTER_SELECTED", [
        licensingAuthorityResult[0]
      ]);
    }
    return payload;
  },
  licensingAuthorityCandidateLevelComputation: async (
    { state, commit, rootState, getters, dispatch },
    { licenseAuthIds }
  ) => {
    let { candidateLevel } = getters;
    licenseAuthIds =
      typeof licenseAuthIds === "string"
        ? JSON.parse(licenseAuthIds)
        : licenseAuthIds;
    let data = await dispatch(
      "licenseAuthPqr",
      `license_auth_id__in = ${licenseAuthIds.join(",")} `
    );
    let licenseAuthPqrData = data.map(val => val.licensing_level_id);
    licenseAuthPqrData = candidateLevel.filter(val =>
      licenseAuthPqrData.includes(val.code)
    );
    commit("SET_LICENSING_LEVEL_CANDIDATE_FILTER", licenseAuthPqrData);
  },
  onChangeJobListFilter: (
    { state, commit, rootState, getters, dispatch },
    { payload }
  ) => {
    dispatch("dependentFilterQueryBasedDataOptimize", {
      payload: payload,
      type: "jobList"
    });
    let queryResult = convertQueryKeyData(payload, jobFilter);
    dispatch("jobListPagination", queryResult);
  },
  onChangeCandidateListFilter: async (
    { state, commit, rootState, getters, dispatch },
    { payload }
  ) => {
    let { candidateLevel } = getters;
    let { licensingAuthority } = payload;
    if (licensingAuthority && licensingAuthority.length) {
      // Licensing Level computation based on licensing Authority
      dispatch("licensingAuthorityCandidateLevelComputation", {
        licenseAuthIds: licensingAuthority
      });
    } else {
      commit("SET_LICENSING_LEVEL_CANDIDATE_FILTER", candidateLevel);
    }
    payload = await dispatch("dependentFilterQueryBasedDataOptimize", {
      payload: payload,
      type: "candidateList"
    });
    let queryResult = convertQueryKeyData(payload, candidateFilter);
    dispatch("candidateListPagination", queryResult);

  },

  licenseAuthPqr: ({ state, commit, rootState }, payload) => {
    return filter
      .licenseAuthPqr(payload)
      .then(res => {
        let { data } = res;
        return data;
      })
      .catch(err => {
        console.log("error in licenseAuthPqr", err);
        return err;
      });
  },
  getTitles: ({ commit }) => {
    return candidate
      .getTitles()
      .then(res => {
        let { data } = res;
        commit("SET_TITLES", data);
        return res;
      })
      .catch(err => {
        console.log("error fetching titles", err);
        return err;
      });
  },
  getLocations: ({ commit }) => {
    return candidate
      .getLocations()
      .then(res => {
        let { data } = res;
        commit("SET_LOCATIONS", data);
        return res;
      })
      .catch(err => {
        console.log("error fetching locations", err);
        return err;
      });
  },
  getUAELocations: ({ commit }) => {
    return candidate
      .getUAELocations()
      .then(res => {
        let { data } = res;
        commit("SET_UAE_LOCATIONS", data);
        return res;
      })
      .catch(err => {
        console.log("error fetching getUAELocations", err);
        return err;
      });
  },
  getHospitalName: ({ commit }) => {
    return hospital
      .getHospitalList()
      .then(res => {
        let { data } = res;
        commit("SET_HOSPITAL_LIST", data);
        return res;
      })
      .catch(err => {
        console.log("error fetching locations", err);
        return err;
      });
  },
  gender: ({ state, commit, rootState }) => {
    return gender
      .gender()
      .then(res => {
        let { data } = res;
        commit("SET_GENDER", data);
        return res;
      })
      .catch(err => {
        console.log("error in gender", err);
        return err;
      });
  },
  licensingAuthority: ({ state, commit, rootState }) => {
    return filter
      .licensingAuthority()
      .then(res => {
        let { data } = res;
        commit("SET_LICENSING_AUTHORITY", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  candidateLevel: ({ state, commit, rootState }) => {
    return candidate
      .candidateLevel()
      .then(res => {
        let { data } = res;
        commit("SET_CANDIDATE_LEVEL", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  recruitingCandidateLevel: ({ state, commit, rootState }) => {
    return candidate
      .recruitingCandidateLevel()
      .then(res => {
        let { data } = res;
        commit("SET_RECRUITING_CANDIDATE_LEVEL", data);
        return res;
      })
      .catch(err => {
        console.log("error in recruitingCandidateLevel", err);
        return err;
      });
  },
  candidateType: ({ state, commit, rootState }) => {
    return candidate
      .candidateType()
      .then(res => {
        let { data } = res;
        commit("SET_CANDIDATE_TYPE", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  getBenefits: ({ commit }) => {
    return job
      .getBenefits()
      .then(res => {
        let { data } = res;
        commit("SET_BENEFITS", data);
        return res;
      })
      .catch(err => {
        console.log("error in benefits", err);
        return err;
      });
  },
  jobStatus: ({ state, commit, rootState }) => {
    return job
      .jobStatus()
      .then(res => {
        let { data } = res;
        commit("SET_JOB_STATUS", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  languageProficiency: ({ state, commit, rootState }) => {
    return location
      .languageProficiency()
      .then(res => {
        let { data } = res;
        commit("SET_LANGUAGE_PROFICIENCY", data);
        return res;
      })
      .catch(err => {
        console.log("error in languageProficiency", err);
        return err;
      });
  },
  locationLanguage: ({ state, commit, rootState }) => {
    return location
      .locationLanguage()
      .then(res => {
        let { data } = res;
        commit("SET_LOCATION_LANGUAGE", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  fetchLocationLanguage: ({ state, commit, rootState }) => {
    return location
      .locationLanguage()
      .then(res => {
        let { data } = res;
        commit("SET_LOCATION_LANGUAGE", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  locationRegion: ({ state, commit, rootState }) => {
    return location
      .locationRegion()
      .then(res => {
        let { data } = res;
        commit("SET_LOCATION_REGION", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  locationCountryInfo: ({ state, commit, rootState }) => {
    return location
      .locationCountryInfo()
      .then(res => {
        let { data } = res;
        commit("SET_LOCATION_COUNTRY_INFO", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  nationalityCountryInfo: ({ state, commit, rootState }) => {
    return location
      .nationalityCountryInfo()
      .then(res => {
        let { data } = res;
        commit("SET_NATIONALITY_COUNTRY_INFO", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  speciality: ({ state, commit, rootState }) => {
    return speciality
      .speciality()
      .then(res => {
        let { data } = res;
        commit("SET_SPECIALITY", data);
        return res;
      })
      .catch(err => {
        console.log("error in candidateType", err);
        return err;
      });
  },
  skill: ({ state, commit, rootState }) => {
    return speciality
      .skill()
      .then(res => {
        let { data } = res;
        commit("SET_SKILL", data);
        return res;
      })
      .catch(err => {
        console.log("error in skill", err);
        return err;
      });
  },
  specialInterest: ({ state, commit, rootState }) => {
    return speciality
      .specialInterest()
      .then(res => {
        let { data } = res;
        commit("SET_SPECIAL_INTEREST", data);
        return res;
      })
      .catch(err => {
        console.log("error in skill", err);
        return err;
      });
  },
  fetchSpecialInterestList: ({ state, commit, rootState }) => {
    return speciality
      .specialInterest()
      .then(res => {
        let { data } = res;
        commit("SET_SPECIAL_INTEREST", data);
        return res;
      })
      .catch(err => {
        console.log("error in skill", err);
        return err;
      });
  },
  subSpeciality: ({ state, commit, rootState }) => {
    return speciality
      .subSpeciality()
      .then(res => {
        let { data } = res;
        commit("SET_SUB_SPECIALITY", data);
        return res;
      })
      .catch(err => {
        console.log("error in skill", err);
        return err;
      });
  },
  qualification: ({ state, commit, rootState }) => {
    return qualification
      .qualification()
      .then(res => {
        let { data } = res;
        commit("SET_QUALIFICATION", data);
        return res;
      })
      .catch(err => {
        console.log("error in skill", err);
        return err;
      });
  },
  issueAuthority: ({ state, commit, rootState }) => {
    return qualification
      .issueAuthority()
      .then(res => {
        let { data } = res;
        commit("SET_ISSUE_AUTHORITY", data);
        return res;
      })
      .catch(err => {
        console.log("error in issue Authority", err);
        return err;
      });
  },
  displayData: ({ commit }) => {
    return candidate
      .getDisplayData()
      .then(res => {
        let { data } = res;
        commit("SET_DISPLAY_DATA", data);
        return res;
      })
      .catch(err => {
        console.log("error fetching display data", err);
        return err;
      });
  },
  regionCountry: ({ commit }) => {
    return location
      .regionCountry()
      .then(res => {
        let { data } = res;
        commit("SET_REGION_COUNTRY_DATA", data);
        return res;
      })
      .catch(err => {
        console.log("error fetching region country data", err);
        return err;
      });
  },
  fetchScoreByNonMedicalQual: ({ commit, getters }, qualification_name) => {
    if (!qualification_name) {
      commit("SET_FILTERED_SCORE", [])
      return []
    }
    const { candidateProfile: { customer_uid } } = getters;
    const qua_name=encodeURIComponent(qualification_name)
    return candidate.getScoreByNonMedicalQualName(qua_name, customer_uid)
      .then(res => {
        commit("SET_FILTERED_SCORE", res.data);
        return res.data;
      })
      .catch(err => {
        console.log("error in qualification", err);
        return err;
      });
  },
  fetchQualificationByCountryAndCandidateType: ({ state, commit, rootState, dispatch }, payload) => {
    if (!payload) {
      commit("SET_FILTERED_QUALIFICATION", []);
      return []
    }
    const { country_id, candidate_type_id } = payload
    if (!(country_id && candidate_type_id)) {
      commit("SET_FILTERED_QUALIFICATION", []);
      return []
    }
    return qualification.filteredQualification({ country_id, candidate_type_id })
      .then(res => {
        let { data } = res;
        commit("SET_FILTERED_QUALIFICATION", data);
        return data;
      })
      .catch(err => {
        console.log("error in qualification", err);
        return err;
      });
  },
  fetchIssueAuthorityByQualification: ({ state, commit, rootState }, payload) => {
    if (!payload?.qualification_id) {
      commit("SET_FILTERED_ISSUE_AUTHORITY", []);
      return []
    }
    const { qualification_id } = payload
    return qualification.filteredIssueAuthority({ qualification_id })
      .then(res => {
        let { data } = res;
        commit("SET_FILTERED_ISSUE_AUTHORITY", data);
        return data;
      })
      .catch(err => {
        console.log("error in getting Issue Authority by Qualification", err);
        return err;
      });
  },
  fetchCurrency: ({ state, commit, rootState }) => {
    return location
      .currency()
      .then(res => {
        let { data } = res;
        commit("SET_CURRENCY", data);
        return res;
      })
      .catch(err => {
        console.log("error in fetching currency", err);
        return err;
      });
  },
  fetchDepartments: ({ state, commit }, payload) => {
    const { organisation_id } = payload
    return candidate.department(organisation_id)
      .then((res) => {
        let { data } = res;
        commit("SET_DEPARTMENTS", data);
        return res;
      })
      .catch((err) => {
        console.log('Error while fetching departments', err);
        return err;
      });
  },
  fetchQualificationByCountryAndCandidateTypeWithoutGetter: ({ state, commit, rootState }, payload) => {
    if (!payload) {
      return []
    }
    const { country_id, candidate_type_id } = payload
    if (!(country_id && candidate_type_id)) {
      return []
    }
    return qualification.filteredQualification({ country_id, candidate_type_id })
      .then(res => {
        let { data } = res;
        return data;
      })
      .catch(err => {
        console.log("error in qualification", err);
        return err;
      });
  },
  fetchFirstQualificationByCountryAndCandidateType: ({ state, commit, rootState }, payload) => {
    if (!payload) {
      commit("SET_FILTERED_FIRST_QUALIFICATION", []);
      return []
    }
    const { country_id, candidate_type_id } = payload
    if (!(country_id && candidate_type_id)) {
      commit("SET_FILTERED_FIRST_QUALIFICATION", []);
      return []
    }
    let query = "ug=true";
    return qualification.filteredQualification({ country_id, candidate_type_id, query })
      .then(res => {
        let { data } = res;
        commit("SET_FILTERED_FIRST_QUALIFICATION", data);
        return data;
      })
      .catch(err => {
        console.log("error in qualification", err);
        return err;
      });
  },
  fetchHigherQualificationByCountryAndCandidateType: ({ state, commit, rootState }, payload) => {
    if (!payload) {
      commit("SET_FILTERED_HIGHER_QUALIFICATION", []);
      return []
    }
    const { country_id, candidate_type_id } = payload
    if (!(country_id && candidate_type_id)) {
      commit("SET_FILTERED_HIGHER_QUALIFICATION", []);
      return []
    }
    let query = "pg=true";
    return qualification.filteredQualification({ country_id, candidate_type_id, query })
      .then(res => {
        let { data } = res;
        commit("SET_FILTERED_HIGHER_QUALIFICATION", data);
        return data;
      })
      .catch(err => {
        console.log("error in qualification", err);
        return err;
      });
  },
  fetchNurseRegistrationType({ getters, commit }, { customer_id }) {
    return candidate.getNurseRegistrationType({ customer_id })
      .then(res => {
        const { data } = res;
        commit("SET_NURSE_REGISTRATION_TYPE", data);
        return data;
      })
      .catch(err => {
        console.log("Error WHile Fetching Nurse Registration Type", err);
        return err;
      })
  }
};

// mutations
const mutations = {
  ["SET_TITLES"](state, payload) {
    state.titles = payload;
  },
  ["SET_LOCATIONS"](state, payload) {
    state.locations = payload;
  },
  ["SET_UAE_LOCATIONS"](state, payload) {
    state.insideUAELocation = payload;
  },
  ["SET_HOSPITAL_LIST"](state, payload) {
    state.hospitalNames = payload;
  },
  ["SET_CANDIDATE_LEVEL"](state, data) {
    state.candidateLevel = data;
  },
  ["SET_RECRUITING_CANDIDATE_LEVEL"](state, data) {
    state.recruitingCandidateLevel = data;
  },
  ["SET_CANDIDATE_TYPE"](state, data) {
    state.candidateType = data;
  },
  ["SET_BENEFITS"](state, data) {
    state.benefits = data;
  },
  ["SET_JOB_STATUS"](state, data) {
    state.jobStatus = data;
  },
  ["SET_LOCATION_LANGUAGE"](state, data) {
    state.locationLanguage = data;
  },
  ["SET_LANGUAGE_PROFICIENCY"](state, data) {
    state.languageProficiency = data;
  },
  ["SET_LOCATION_COUNTRY_INFO"](state, data) {
    state.locationCountryInfo = data;
  },
  ['SET_NATIONALITY_COUNTRY_INFO'](state, data) {
    state.nationalityCountryInfo = data;
  },
  ["SET_LOCATION_REGION"](state, data) {
    state.locationRegion = data;
  },
  ["SET_SPECIALITY"](state, data) {
    state.speciality = data;
  },
  ["SET_SKILL"](state, data) {
    state.skill = data;
  },
  ["SET_SUB_SPECIALITY"](state, data) {
    state.subSpeciality = data;
  },
  ["SET_SPECIAL_INTEREST"](state, data) {
    state.specialInterest = data;
  },
  ["SET_QUALIFICATION"](state, data) {
    state.qualification = data;
  },
  ["SET_FILTERED_SCORE"](state, data) {
    state.filteredNonMedicalScore = data;
  },
  ["SET_FILTERED_QUALIFICATION"](state, data) {
    state.filteredQualification = data;
  },
  ["SET_FILTERED_ISSUE_AUTHORITY"](state, data) {
    state.filteredIssueAuthority = data;
  },
  ["SET_ISSUE_AUTHORITY"](state, data) {
    state.issueAuthority = data;
  },
  ["SET_GENDER"](state, data) {
    state.gender = data;
  },
  ["SET_LICENSING_AUTHORITY"](state, data) {
    state.licensingAuthority = data;
  },
  ["SET_FETCHING"](state, data) {
    state.isFetchingFilter = data;
  },
  ["SET_CANDIDATE_LEVEL_JOB_FILTER"](state, data) {
    state.jobFilterData["candidateLevel"]["options"] = data;
  },
  ["SET_RECRUITING_CANDIDATE_LEVEL_JOB_FILTER"](state, data) {
    state.jobFilterData["recruitingCandidateLevel"]["options"] = data;
  },
  ["SET_SUB_SPECIALITY_JOB_FILTER"](state, data) {
    state.jobFilterData["subSpeciality"]["options"] = data;
  },
  ["SET_SPECIAL_INTEREST_JOB_FILTER"](state, data) {
    state.jobFilterData["specialInterest"]["options"] = data;
  },
  ["SET_SKILL_JOB_FILTER"](state, data) {
    state.jobFilterData["skill"]["options"] = data;
  },
  ["SET_RECRUITING_CANDIDATE_LEVEL_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData["recruitingCandidateLevel"]["options"] = data;
  },
  ["SET_RECRUITING_SPECIALITY_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData["speciality"]["options"] = data;
  },
  ["SET_SUB_SPECIALITY_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData["subSpeciality"]["options"] = data;
  },
  ["SET_SPECIAL_INTEREST_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData["specialInterest"]["options"] = data;
  },
  ["SET_SKILL_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData["skill"]["options"] = data;
  },
  ["SET_LICENSING_AUTHORITY_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData["licensingAuthority"]["options"] = data;
  },
  ["SET_LICENSING_AUTHORITY_CANDIDATE_FILTER_SELECTED"](state, data) {
    state.candidateFilterData["licensingAuthority"]["selected"] = data;
  },
  ["SET_LICENSING_LEVEL_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData["licensingLevel"]["options"] = data;
  },
  ["UPDATE_CANDIDATE_FILTER"](state, data) {
    state.candidateFilterData = data;
  },
  ["CLEAR_SELECTED_CANDIDATE_FILTER_BASEDON_NAME"](state, name) {
    state.candidateFilterData[name]["selected"] = [];
  },
  ["CLEAR_SELECTED_JOB_FILTER_BASEDON_NAME"](state, name) {
    state.jobFilterData[name]["selected"] = [];
  },
  ["INIT_JOB_FILTER"](state) {
    let keys = Object.keys(jobFilter);
    keys.forEach(key => {
      if (jobFilter[key]["selected"].length) {
        jobFilter[key]["selected"] = [];
      }
    });
    state.jobFilterData = jobFilter;
  },
  ["INIT_CANDIDATE_FILTER"](state) {
    let keys = Object.keys(candidateFilter);
    keys.forEach(key => {
      if (candidateFilter[key]["selected"].length) {
        candidateFilter[key]["selected"] = [];
      }
      if (key === 'subSpeciality') {
        candidateFilter[key]['disabled'] = true;
      }
    });
    state.candidateFilterData = candidateFilter;
  },
  ["SET_DISPLAY_DATA"](state, payload) {
    state.displayData = payload;
  },
  ['SET_REGION_COUNTRY_DATA'](state, payload) {
    state.regionCountry = payload;
  },
  ["SET_CURRENCY"](state, payload) {
    state.currency = payload;
  },
  ["SET_DEPARTMENTS"](state, payload) {
    state.departments = payload;
  },
  ['SET_FILTERED_FIRST_QUALIFICATION'](state, payload) {
    state.filteredFirstQualification = payload
  },
  ['SET_FILTERED_HIGHER_QUALIFICATION'](state, payload) {
    state.filteredHigherQualification = payload
  },
  ['SET_NURSE_REGISTRATION_TYPE'](state, payload) {
    state.nurseRegistrationType = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
