import axios from 'axios'
import { BASE_URL } from '@/service_urls'
import { getCustomerId } from "@/helpers/helper";

const candidate = {
  candidateLevel: (query) => axios.get(`${BASE_URL}/candidate/level/?order_by=seniority&${query}`),
  recruitingCandidateLevel: () => axios.get(`${BASE_URL}/candidate/level/jobs/`),
  candidateType: () => axios.get(`${BASE_URL}/candidate/type/?order_by=candidate_type_id&is_doh=true`),
  create: (customer, payload) => axios.post(`${BASE_URL}/customer/${customer}/candidate/`, payload),
  update: (customer, payload) => axios.put(`${BASE_URL}/customer/${customer}/candidate/`, payload),
  document: (customerId, candidate_uuid, userId, payload) => axios.post(`${BASE_URL}/customer/${customerId}/candidate/${candidate_uuid}/document?user_id=${userId}`, payload),
  getCandidate: (customer, id) => axios.get(`${BASE_URL}/customer/${customer}/candidate/id/${id}/`),
  getTitles: () => axios.get(`${BASE_URL}/title/`),
  getLocations: () => axios.get(`${BASE_URL}/location/location/?order_by=location`),
  getUAELocations: () => axios.get(`${BASE_URL}/location/location/?location_id__notin=7`),
  getUserMe: (customer) => axios.get(`${BASE_URL}/customer/${customer}/candidate/my`),
  getDocumentType: () => axios.get(`${BASE_URL}/documents/types?order_by=document_type&entity_id=1`),
  getUploadedDocument: (customerId, candidate_uuid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidate_uuid}/document`),
  updateDocument: (customerId, candidate_uuid, candidate_document_id, payload) => axios.put(`${BASE_URL}/customer/${customerId}/candidate/${candidate_uuid}/document/${candidate_document_id}`, `comments=${payload}`),
  deleteDocument: (customerId, candidate_uuid, candidate_document_id) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidate_uuid}/document/${candidate_document_id}/`),
  getSkills: (customerId, candidateUid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/skill/`),
  createSkill: (customer, candidateUid, payload) => axios.post(`${BASE_URL}/customer/${customer}/candidate/${candidateUid}/skill/`, payload),
  getCandidateLanguage: (customerId, candidateUid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/language/`),
  createCandidateLanguage: (customer, candidateUid, payload) => axios.post(`${BASE_URL}/customer/${customer}/candidate/${candidateUid}/language/`, payload),
  deleteLanguage: (customerId, candidateUid, candidate_language_id) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/language/${candidate_language_id}`),
  getCandidateQualification: (customerId, candidate_uuid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidate_uuid}/qualification/`),
  createQualification: (customer, candidate_uuid, payload) => axios.post(`${BASE_URL}/customer/${customer}/candidate/${candidate_uuid}/qualification/`, payload),
  deleteSkills: (customerId, candidateUid, candidate_skill_id) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/skill/${candidate_skill_id}`),
  deleteQualification: (customerId, candidate_uuid, candidate_qualification_uuid) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidate_uuid}/qualification/${candidate_qualification_uuid}`),
  createSpecialInterest: (customer, candidateUid, payload) => axios.post(`${BASE_URL}/customer/${customer}/candidate/${candidateUid}/speciality/`, payload),
  deleteSpecialInterest: (customerId, candidateUid, candidate_speciality_id) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/speciality/${candidate_speciality_id}`),
  getCandidateSpecialInterest: (customerId, candidateUid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/speciality/`),
  getCandidatePQRLevels: (customerId, candidateUid) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/pqr_levels/`),
  checkPQRLevel: ({ customerId, candidateUid }) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/pqr_levels/check_pqr_levels`),
  updateLanguagesSpoken: (customerId, candidateUid, candidate_language_id, payload) => axios.put(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/language/${candidate_language_id}`, payload),
  updateCandidateSplInterest: (customerId, candidateUid, payload) => axios.put(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/speciality/`, payload),
  updateCandidateSkill: (customerId, candidateUid, payload) => axios.put(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/skill/`, payload),
  updateCandidateQualification: (customerId, candidate_uuid, candidate_qualification_id, payload) => axios.put(`${BASE_URL}/customer/${customerId}/candidate/${candidate_uuid}/qualification/${candidate_qualification_id}`, payload),
  getDisplayData: () => axios.get(`${BASE_URL}/display_data/`),
  createCandidatePreferredRegions: ({ customerId, candidateUid, payload }) => axios.post(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/region/`, payload),
  createCandidatePreferredCountry: ({ customerId, candidateUid, payload }) => axios.post(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/preferred_country/`, payload),
  getCandidatePreferredRegionList: ({ customerId, candidateUid }) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/region/`),
  getCandidatePreferredCountryList: ({ customerId, candidateUid }) => axios.get(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/preferred_country/`),
  deleteCandidatePreferredRegion: ({ customerId, candidateUid, preferred_region_id }) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/region/${preferred_region_id}`),
  deleteCandidatePreferredCountry: ({ customerId, candidateUid, preferred_country_id }) => axios.delete(`${BASE_URL}/customer/${customerId}/candidate/${candidateUid}/preferred_country/${preferred_country_id}`),
  jobListForCandidateID: ({ candidate_uid, statusSubStatus, queryString }) => { return axios.post(`${BASE_URL}/jobcandidate/match/candidate/${candidate_uid}?${queryString}`, statusSubStatus) },
  candidateJobMatch: ({ customer_uid, candidate_uid }) => axios.post(`${BASE_URL}/jobcandidate/match/${customer_uid}/candidate/${candidate_uid}`),
  jobListForCandidateIDCount: ({ candidate_uid, status_id }) => { return axios.get(`${BASE_URL}/jobcandidate/match/count/?candidate_uid=${candidate_uid}&status_id=${status_id}`) },
  getCandidateIDByMail: (email) => { return axios.get(`${BASE_URL}/candidate/candidate_uid/?email=${email}`) },
  getNonMedicalQualNames: (customer_id) => axios.get(`${BASE_URL}/non_medical_qualification/?customer_id=${customer_id}`),
  getScoreByNonMedicalQualName: (qualification_name, customer_id) => axios.get(`${BASE_URL}/non_medical_qualification/?customer_id=${customer_id}&name=${qualification_name}`),
  getCandidateNonMedicalQual: (customer_id, candidate_id) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/non_medical_qualification`),
  createCandidateNonMedicalInfo: (customer_id, payload) => axios.post(`${BASE_URL}/customer/${customer_id}/candidate/non_medical_qualification/`, payload),
  updateNonMedicalInfo: (customer_id, candidate_non_medical_qual_id, payload) => axios.put(`${BASE_URL}/customer/${customer_id}/candidate/non_medical_qualification/${candidate_non_medical_qual_id}/`, payload),
  deleteNonMedicalInfo: (customer_id, candidate_id, candidate_info_id) => axios.delete(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/non_medical_qualification/${candidate_info_id}`),
  getUpdateStatusSubStatus: ({ tab_id, filter_id, action_id, queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/action/${action_id}/get_update_status?${queryString}`),
  updateJobCandidateStatus: ({ job_id, candidate_uid, queryString, payload }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_uid}?${queryString}`, payload),
  createCandidateComment: (candidate_uuid, payload) => axios.post(`${BASE_URL}/candidate/${candidate_uuid}/job_candidate_comment/`, payload),
  department: (organisation_id) => axios.get(`${BASE_URL}/department/${organisation_id}`),
  getPreferredDepartments: (customer_uid, candidate_uid) => axios.get(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/by/candidate`),
  addPreferredDepartment: (customer_uid, candidate_uid, payload) => axios.post(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/`, payload),
  updatePreferredDepartment: ({ customer_uid, candidate_uid, candidate_preferred_departments_id, payload }) => axios.put(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/${candidate_preferred_departments_id}`, payload),
  deletePreferredDepartment: ({ customer_uid, candidate_uid, candidate_preferred_departments_id }) => axios.delete(`${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/preferred/department/${candidate_preferred_departments_id}`),
  getNurseRegistrationType: ({ customer_id }) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/nurse/registration/type`),
  fetchAppliedCandidatesForJobs: ({ customer_id, organisation_id, queryString }) => axios.get(`${BASE_URL}/job/application/get_applied_candidates?order_by=-created_on&is_accepted__in=null&customer_id=${customer_id}&jobs___organisation_uid=${organisation_id}&jobs___status_id__in=28,29&${queryString}`),
  fetchAppliedCandidatesForJobsCount: ({ customer_id, organisation_id, queryString }) => axios.get(`${BASE_URL}/job/application/get_count_of_all_applied_candidates?&is_accepted__in=null&customer_id=${customer_id}&jobs___organisation_uid=${organisation_id}&jobs___status_id__in=28,29&${queryString}`),
  fetchWithdrawnCandidatesForJobs: ({ customer_id, organisation_id, queryString }) => axios.get(`${BASE_URL}/job/application/withdrawal_jobs/Summary?customer_id=${customer_id}&organisation_uid__in=${organisation_id}&jobs___status_id__in=28,29&${queryString}`),
  fetchWithdrawnCandidatesForJobsCount: ({ customer_id, organisation_id, queryString }) => axios.get(`${BASE_URL}/job/application/withdrawal_jobs/total_count?customer_id=${customer_id}&organisation_uid__in=${organisation_id}&jobs___status_id__in=28,29&${queryString}`),
  checkLicenseValidation: (payload) => axios.post(`${BASE_URL}/hpldetail/for/candidate`, payload),
}

export default candidate;
