import hospital from "@/services/hospital";
import router from "../router/index";

const getDefaultState = () => {
    return {
        hospitalProfile: {},
        hospitalAdminProfile: {},
        getHospitalList: [],
        getHospitalAdminList: [],
        getHospitalRecruitersList: []
    };
}

const state = getDefaultState()


const getters = {
    hospitalProfile: state => state.hospitalProfile,
    hospitalAdminProfile: state => state.hospitalAdminProfile,
    getHospitalList : state => state.getHospitalList,
    getHospitalAdminList : state => state.getHospitalAdminList,
    getHospitalRecruitersList : state => state.getHospitalRecruitersList
};

const mutations = {
    ["SET_HOSPITAL"](state, payload) {
        state.hospitalProfile = payload;
    },
    ["SET_HOSPITAL_ADMIN"](state, payload) {
        state.hospitalAdminProfile = payload;
    },
    ["SET_HOSPITAL_LIST"](state, payload) {
        state.getHospitalList = payload;
    },
    ["SET_HOSPITAL_ADMIN_LIST"](state, payload) {
        state.getHospitalAdminList = payload;
    },
    ["SET_HOSPITAL_RECRUITERS_LIST"](state, payload) {
        state.getHospitalRecruitersList = payload;
    }
};

const actions = {
    addHospital: ( { state, commit, rootState, dispatch, getters }, payload) => {
        dispatch("showToast",  {message: 'Saving...'})
        return hospital
            .addHospital(payload)
            .then(async res => {
                dispatch("showToast", {class: 'bg-success text-white', message: 'Details saved successfully'})
                commit("SET_HOSPITAL", res.data);
                router.push("/hospital-list");
            })
            .catch(err => {
                let msg = "Error while saving";
                if (err.message.indexOf("409") > 0) {
                    msg = "Hospital already exists";
                }
                console.log("error creating hospital", err);
                dispatch("showToast",  {class: 'bg-danger text-white', message: msg})
                return err;
            });
    },
    addHospitalAdmin: ({ state, commit, rootState, dispatch, getters }, payload) => {
        dispatch("showToast",  {message: 'Saving...'})
        const { getCustomerId } = getters;
        const customer_id = payload.hospital_name || getCustomerId
        const role = payload.role_type_id
        return hospital
            .addHospitalAdmin(customer_id, payload)
            .then(async res => {
                dispatch("showToast", {class:'bg-success text-white' , message: 'Details saved successfully'})
                commit("SET_HOSPITAL_ADMIN", res.data);
                router.push(role === 4 ? "/hospital-admin-list" : "/hospital-recruiters-list")
            })
            .catch(err => {
                let msg = "Error while saving";
                if (err.message.indexOf("400") > 0) {
                    msg = "User already exists";
                }
                console.log("error creating hospital", err);
                dispatch("showToast", {class: 'bg-danger text-white', message: msg})
                return err;
            });
    },
    FetchHospitalList: ({ state, commit, rootState, dispatch, getters }) => {
        return hospital
            .getHospitalList()
            .then(async res => {
                commit("SET_HOSPITAL_LIST", res.data);
            })
            .catch(err => {
                console.log("error fetching hospital list", err);
                return err;
            });
    },
    FetchHospitalAdminList: ({ state, commit, rootState, dispatch, getters }) => {
        return hospital
            .getHospitalAdminList()
            .then(async res => {
                commit("SET_HOSPITAL_ADMIN_LIST", res.data);
            })
            .catch(err => {
                console.log("error fetching hospital admin list", err);
                return err;
            });
    },
    FetchHospitalRecruitersList: ({ state, commit, rootState, dispatch, getters }) => {
        const { getCustomerId } = getters;
        const customer_id = getCustomerId
        return hospital
            .getHospitalRecruitersList(customer_id)
            .then(async res => {
                commit("SET_HOSPITAL_RECRUITERS_LIST", res.data);
            })
            .catch(err => {
                console.log("error fetching recruiters list", err);
                return err;
            });
    },
};

export default { state, getters, mutations, actions };
