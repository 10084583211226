import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const organisationDocuments = {
  getDocument: (organisation_id, customer_id) => axios.get(`${BASE_URL}/organisation/${organisation_id}/document?only_latest_versions=true&customer_id=${customer_id}`),
  createDocument: (organisation_id, customer_id, payload) => axios.post(`${BASE_URL}/organisation/${organisation_id}/document?customer_id=${customer_id}`, payload),
  downloadDocumentById: (organisation_id, organisation_document_uuid, query) => axios.get(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_uuid}/file?${query}`),
  deleteDocument: ({ organisation_id, organisation_document_uuid }) => axios.delete(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_uuid}/`),
  getOrgLocation: (location_id) => axios.get(`${BASE_URL}/location/location/id/${location_id}`),
}

export default organisationDocuments