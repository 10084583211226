<template>
  <CFooter
    class="page-footer f-bg font-small white text-white"
    v-if="page != 'Demo TalentFind'"
  >
    <div class="ml-auto d-flex flex-column">
      <img class="logo-adjustment" src="/img/powered_by_kawader.jpeg" />
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      page: "",
    };
  },
  watch: {
    "$route.name": function () {
      this.page = this.$router.currentRoute.name;
    },
  },
};
</script>
<style scoped>
@media all and (max-width: 480px) {
  .copy-right {
    width: auto;
    font-size: 9px;
  }
  .logo-adjustment {
    height: 30px !important;
    margin-right: 0px !important;
  }
}
.copy-right {
  color: #645c5c;
}
.logo-adjustment {
  height: 36px;
  margin-right: 10px;
}
</style>
