import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const location = {
  locationLanguage: () => axios.get(`${BASE_URL}/location/language/`),
  locationCountryInfo: () => axios.get(`${BASE_URL}/location/country_info/distinct_country`),
  locationRegion: () => axios.get(`${BASE_URL}/location/region/`),
  languageProficiency: () => axios.get(`${BASE_URL}/language_proficiency/`),
  regionCountry: () => axios.get(`${BASE_URL}/location/region_country/`),
  currency: () => axios.get(`${BASE_URL}/location/currency/`),
  nationalityCountryInfo: () => axios.get(`${BASE_URL}/location/country_info/?order_by=sort_order`),
}

export default location