import tabFilter from '@/services/tabFilter';
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";

const getDefaultState = () => {
    return {
        jobCandidateTabs: [],
        jobCandidateCount: {},
        jobFilterOptions: [],
        jobSubFilterOptions: [],
        tabStatusSubStatus: [],
        tab_info: {
            tab_id: null,
            filter_id: null,
            sub_filter_id: null,
            page: null,
        },
        tabFilterCandidateCount: null,
        updateToastVisiable: {
            isShow: false,
            msg: null,
            cssClass: null,
            autohide: 5000,
        },
    }
}
const state = getDefaultState();

const getters = {
    jobCandidateTabs: (state) => state.jobCandidateTabs,
    getJobCandidateCount: (state) => state.jobCandidateCount,
    getJobCandidateTabs: (state) => state.jobCandidateTabs.length ? state.jobCandidateTabs.map(({ tab_ }) => { return { tab_id: tab_?.id, tab_name: tab_?.tab_name } }) : [],
    getJobFilterOptions: (state) => state.jobFilterOptions.length ? state.jobFilterOptions.map(({ filter_ }) => { return { code: filter_?.id, label: filter_?.filter_name } }) : [],
    getJobSubFilterOptions: (state) => state.jobSubFilterOptions.length ? state.jobSubFilterOptions.map(({ sub_filter_ }) => { return { code: sub_filter_?.id, label: sub_filter_?.filter_name } }) : [],
    getDisplayStatusSubStatus: (state) => state.tabStatusSubStatus,
    getTabInfo: (state) => state.tab_info,
    getTabFilterCandidateCount: (state) => state.tabFilterCandidateCount,
    getUpdateToast: (state) => state.updateToastVisiable,
}

const mutations = {
    ['SET_JOB_CANDIDATE_TABS'](state, data) {
        state.jobCandidateTabs = data;
    },
    ['RESET_JOB_CANDIDATE_TABS'](state) {
        state.jobCandidateTabs = [];
    },
    ['SET_JOB_CANDIDATE_COUNT'](state, data) {
        state.jobCandidateCount = { ...state.jobCandidateCount, ...data };
    },
    ['RESET_JOB_CANDIDATE_COUNT'](state) {
        state.jobCandidateCount = {}
    },
    ['SET_JOB_FILTER_OPTIONS'](state, data) {
        state.jobFilterOptions = data;
    },
    ['SET_JOB_SUB_FILTER_OPTIONS'](state, data) {
        state.jobSubFilterOptions = data;
    },
    ['RESET_JOB_FILTER_OPTIONS'](state) {
        state.jobFilterOptions = [];
    },
    ['RESET_JOB_SUB_FILTER_OPTIONS'](state) {
        state.jobSubFilterOptions = [];
    },
    ['SET_DISPLAY_STATUS_SUB_STATUS'](state, payload) {
        state.tabStatusSubStatus = payload;
    },
    ['RESET_DISPLAY_STATUS_SUB_STATUS'](state) {
        state.tabStatusSubStatus = [];
    },
    ['SET_TAB_INFO'](state, data) {
        state.tab_info = { ...state.tab_info, ...data };
    },
    ['SET_TAB_CANDIDATE_COUNT'](state, payload) {
        state.tabFilterCandidateCount = payload;
    },
    ['RESET_TAB_CANDIDATE_COUNT'](state) {
        state.tabFilterCandidateCount = null;
    },
    ['SET_UPDATE_TOAST_VISIBLE'](state, payload) {
        state.updateToastVisiable = { ...state.updateToastVisiable, ...payload };
        setTimeout(() => {
            state.updateToastVisiable = { ...state.updateToastVisiable, isShow: false, msg: null, cssClass: null };
        }, 2000);
    }
}

const actions = {
    fetchJobCandidateTabs({ state, commit }) {
        commit("RESET_JOB_CANDIDATE_TABS");
        return tabFilter
            .getJobCandidateTabs()
            .then(res => {
                let { data } = res;
                commit("SET_JOB_CANDIDATE_TABS", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching jobcandidate Tabs", err);
                return err;
            })
    },
    initJobCandidateCount({ state, commit, dispatch, getters }) {
        let { getJobCandidateTabs, getTabInfo: { page }, candidateProfile, getJobDetailsById } = getters;
        let payload = {};
        if (page === "CandidateDetails") {
            const { candidate_uid } = candidateProfile
            payload = { ...payload, candidate_uid };
        }
        if (page === "JobDetails") {
            const { job_id } = getJobDetailsById;
            payload = { ...payload, job_id };
        }
        commit("RESET_JOB_CANDIDATE_COUNT");
        getJobCandidateTabs.forEach(async ({ tab_id }) => {
            await dispatch("fetchJobCandidateCount", { ...payload, tab_id });
        })
    },
    fetchJobCandidateCount({ state, commit, dispatch, getters }, payload) {
        const { getChildOrgWithCustomerQuery } = getters;
        const { tab_id, job_id, candidate_uid } = payload;
        let payloadData = {}
        if (job_id)
            payloadData = { ...payloadData, job_id };
        if (candidate_uid)
            payloadData = { ...payloadData, candidate_uid };
        let queryString = getFilterQueryStringWithoutArray(payloadData);
        queryString += `&${getChildOrgWithCustomerQuery}`
        return tabFilter
            .jobCandidateCount({ tab_id, queryString })
            .then(res => {
                const { data } = res;
                let candidateCount = {};
                candidateCount[tab_id] = data;
                commit("SET_JOB_CANDIDATE_COUNT", candidateCount);
                return res.data;
            })
            .catch(err => {
                console.log("Error while fetch job candidate count", err)
                return err;
            })
    },
    fetchJobFilterOptions({ state, commit, getters }) {
        commit("RESET_JOB_FILTER_OPTIONS");
        commit("RESET_CANDIDATE_LIST_FOR_JOB_ID");
        commit("RESET_DISPLAY_STATUS_SUB_STATUS");
        commit("RESET_JOB_LIST_FOR_CANDIDATE_ID");
        commit("RESET_TAB_CANDIDATE_COUNT");
        const { getTabInfo } = getters;
        return tabFilter
            .getTabFilters(getTabInfo)
            .then(res => {
                let { data } = res;
                commit("SET_JOB_FILTER_OPTIONS", data)
                return data;
            })
            .catch(err => {
                console.log("Error while fetch jobcandidate Filter", err)
                return err;
            })
    },
    fetchJobSubFilterOptions({ state, commit, getters }) {
        commit("RESET_JOB_SUB_FILTER_OPTIONS");
        commit("RESET_SUB_STATUS_OPTIONS");
        const { getTabInfo } = getters;
        if (getTabInfo['tab_id'] && getTabInfo['filter_id'])
            return tabFilter
                .getTabSubFilters(getTabInfo)
                .then(res => {
                    let { data } = res;
                    commit("SET_JOB_SUB_FILTER_OPTIONS", data)
                    return data;
                })
                .catch(err => {
                    console.log("Error while fetch jobcandidate Filter", err)
                    return err;
                })
    },
    fetchCandidatesStatusSubStatus({ state, commit, dispatch, getters }) {
        const { getTabInfo } = getters;
        const { tab_id, filter_id, sub_filter_id } = getTabInfo;
        let queryPayload = { user_action: 'display' };
        if (sub_filter_id)
            queryPayload['sub_filter_id'] = sub_filter_id;
        let queryString = getFilterQueryStringWithoutArray(queryPayload);
        const finalPayload = { tab_id, filter_id, queryString };
        if (tab_id && filter_id) {
            return tabFilter
                .getDisplayStatusSubStatus(finalPayload)
                .then(res => {
                    let { data } = res;
                    commit("SET_DISPLAY_STATUS_SUB_STATUS", data);
                    return data;
                })
                .catch(err => {
                    console.log('Error while fetch status sub status', err);
                    return err;
                })
        }
        return
    },
    setTabInfo({ state, commit, dispatch, getters }, payload) {
        commit("SET_TAB_INFO", payload);
        return
    },
    fetchTabFilterCandidateCount({ state, commit, dispatch, getters }) {
        const { getChildOrgWithCustomerQuery, getJobDetailsById, getTabInfo: { tab_id, filter_id, sub_filter_id, page }, candidateProfile } = getters;
        let queryPayload = {}
        if (sub_filter_id)
            queryPayload = { ...queryPayload, sub_filter_id };
        if (page === "CandidateDetails") {
            const { candidate_uid } = candidateProfile
            queryPayload = { ...queryPayload, candidate_uid };
        } else if (page === "JobDetails") {
            const { job_id } = getJobDetailsById;
            queryPayload = { ...queryPayload, job_id };
        }
        if ([1, 3].includes(filter_id)) {
            queryPayload['job_applied'] = filter_id === 1;
        }
        let queryString = getFilterQueryStringWithoutArray(queryPayload);
        queryString += `&${getChildOrgWithCustomerQuery}`;
        return tabFilter
            .tabFilterCandidateCount({ tab_id, filter_id, queryString })
            .then(res => {
                let { data } = res;
                commit("SET_TAB_CANDIDATE_COUNT", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching TabFilter Candidate Count", err);
                return err;
            })
    },
    fetchFavouriteCount({ state, commit, dispatch, getters }) {
        const { getJobDetailsById, getTabInfo: { page }, candidateProfile } = getters;
        let queryPayload = {};
        if (page === "CandidateDetails") {
            const { candidate_uid } = candidateProfile
            queryPayload = { candidate_uid };
        } else if (page === "JobDetails") {
            const { job_id } = getJobDetailsById;
            queryPayload = { job_id };
        }
        let queryString = getFilterQueryStringWithoutArray(queryPayload);
        let payload = [
            {
                "display_status": 42,
                "display_sub_status": 70
            }
        ];
        return tabFilter
            .favouriteCount({ queryString, payload })
            .then(res => {
                let { data } = res;
                commit("SET_TAB_CANDIDATE_COUNT", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching Favourite Count", err);
                return err;
            })
    }
};

export default { state, getters, mutations, actions };