import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const activityList = {
    activityList: ({ user_id }) => axios.get(`${BASE_URL}/action_detail/?assigned_to__contains={${user_id}}`),
    fetchActivity: (action_detail_id) => axios.get(`${BASE_URL}/action_detail/?action_detail_id=${action_detail_id}`),
    interviewReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/interview?${queryString}`),
    holdReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/hold?${queryString}`),
    disapproveReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/disapprove?${queryString}`),
    queryReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/query?${queryString}`),
    approveAuthoriser: ({ job_id, customer_id, organisation_uid, action_detail_id, comment }) => axios.put(`${BASE_URL}/customer/${customer_id}/job/organisation/${organisation_uid}/${job_id}/${action_detail_id}`, comment),
}
export default activityList;