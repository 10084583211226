import axios from "axios";
import { BASE_URL } from "@/service_urls";
import { getCustomerId } from "@/helpers/helper";

const customer = {
  getCandidatesList: ({ skip, limit, page, query, role, org_id }) => {
    let url;
    if (role === "system_admin") {
      url = `${BASE_URL}/candidate/card/detail/?skip=${skip}&limit=${limit}&order_by=-created_on&${query}`
    } else if (org_id) {
      url = `${BASE_URL}/customer/${getCustomerId()}/candidate/card/detail/?organisation_id=${org_id}&skip=${skip}&limit=${limit}&order_by=-created_on&${query}`
    } else {
      url = `${BASE_URL}/customer/${getCustomerId()}/candidate/card/detail/?skip=${skip}&limit=${limit}&order_by=-created_on&${query}`
    }
    return axios({
      method: 'GET',
      url: url
    });
  },
  allJobs: payload => {
    return axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/?${payload}`);
  },
  allCandidates: payload => {
    return axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/?order_by=-created_on&${payload}`
    );
  },
  allunshortlistCandidates: payload => {
    return axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/?order_by=-created_on&${payload}`
    );
  },
  allShortlistStatus: () => {
    return axios.get(
      `${BASE_URL}/status/?status_type=DOH_CANDIDATE_PROCESS`);
  },
  saveShortlistCandidates: payload => {
    return axios.post(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/shortlist/`, payload);
  },
  updateShortlistStatus: payload => {
    return axios.put(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/shortlist/`, payload);
  },
  totalCandidates: (payload, org_id, role) => {
    let url
    if (role === "system_admin") {
      url = `${BASE_URL}/candidate/total_count`;
    }
    else if (org_id) {
      url = `${BASE_URL}/customer/${getCustomerId()}/organisation/${org_id}/candidate/total_count`
    } else {
      url = `${BASE_URL}/customer/${getCustomerId()}/candidate/total_count`;
    }
    return axios.get(
      `${url}?${payload}`
    );
  },
  deleteCandidates: ({ candidate_uuid, customer_uid, payload }) => {
    return axios.delete(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uuid}/`, { data: payload });
  },
  restoreCandidates: ({ candidate_uuid, payload, customer_uid }) => {
    return axios.put(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uuid}/restore/`, payload);
  },
  deactivateCandidates: ({ candidate_uuid, payload, customer_uid }) => {
    return axios.put(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uuid}/deactivate`, payload);
  },
  reactivateCandidates: ({ candidate_uuid, payload, customer_uid }) => {
    return axios.put(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uuid}/reactivate`, payload);
  },
  fetchReasons: payload => {
    return axios.get(
      `${BASE_URL}/reasons/reason/`);
  },
  downloadDocumentFile: url => {
    const endUrl = `${BASE_URL}/${url}`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    });
  },
  getCandidateDocuments: (candidate_uuid) => {
    let url = `${BASE_URL}/customer/${getCustomerId()}/candidate/${candidate_uuid}/document`;
    return axios.get(url);
  },
  getCandidateDetail: ({ candidate_uuid, customer_uid }) => {
    return axios.get(`${BASE_URL}/customer/${customer_uid}/candidate/uuid/${candidate_uuid}/`);
  },
  getJobByCandidate: id =>
    axios.get(`${BASE_URL}/customer/${getCustomerId()}/candidate/${id}/applied/jobs`),
  updateJobApplication: (job_id, payload) =>
    axios.put(`${BASE_URL}/customer/${getCustomerId()}/job/${job_id}/application/`, payload),
  candidateDetails: userId =>
    axios.get(`${BASE_URL}/customer/${getCustomerId()}/candidate/?user_id=${userId}`),
  getAllJobs: (role) => {
    let url;
    if (role === "system_admin") {
      url = `${BASE_URL}/job/summary?include_marketplace=false`
    } else {
      url = `${BASE_URL}/customer/${getCustomerId()}/job/summary?include_marketplace=false`
    }
    return axios.get(`${url}`);
  },
  startImpersonation: (organisation_id, payload) => {
    return axios.post(`${BASE_URL}/impersonation/start?organisation_id=${organisation_id}`, payload)
  },
  stopImpersonation: () => {
    return axios.post(`${BASE_URL}/impersonation/stop`)
  },
  getUser: () => {
    return axios.get(`${BASE_URL}/users/me`)
  },
  fetchCandidateList: ({ skip, limit, query }) => {
    return axios.get(`${BASE_URL}/customer/${getCustomerId()}/candidate/?skip=${skip}&limit=${limit}&order_by=-created_on&${query}`)
  },
  getJobByIdSer: ({ customer_uid, job_uuid }) => {
    return axios.get(
      `${BASE_URL}/customer/${customer_uid}/job/uuid/${job_uuid}/`
    );
  },
  getJobTotalCount: (org_id, role) => {
    let url;
    if (role === "system_admin") {
      url = `${BASE_URL}/job/total_count?status_id__in=27,28,29`;
    }
    else if (org_id) {
      url = `${BASE_URL}/customer/${getCustomerId()}/job/total_count?organisation_uid=${org_id}&status_id__in=27,28,29`;
    } else {
      url = `${BASE_URL}/customer/${getCustomerId()}/job/total_count?status_id__in=27,28,29`;
    }
    return axios.get(`${url}`);
  },
  getScratchPadItems: (customer_id, user_id) => {
    return axios.get(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/items`
    )
  },
  postScratchPad: (customer_id, user_id, payload) => {
    return axios.post(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/item/`, payload
    )
  },
  putScratchPad: (customer_id, user_id, payload, id) => {
    return axios.put(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/item/${payload.id}`, payload
    )
  },
  deleteScratchpad: (customer_id, user_id, payload) => {
    return axios.delete(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/item/${payload.id}`, payload
    )
  },
  getAllCandidateJobStatus: async () => {
    return axios.get(`${BASE_URL}/status/?status_type=Candidate%20Job%20Status`)
  },
  getCandidateCountByStatusByJob: async (status_id, job_id) => {
    return axios.get(`${BASE_URL}/jobcandidate/match/count/?status_id=${status_id}&job_id=${job_id}`)
  },
  getCandidateCountByStatus: async (status) => {
    const candidateJobStatus = await axios.get(`${BASE_URL}/status/?status_type=Candidate%20Job%20Status`)
    const result = candidateJobStatus?.data?.find((item) => item.status.toUpperCase() === status)
    return result
      ? axios.get(
        `${BASE_URL}/jobcandidate/match/count/?status_id=${result.status_id}`
      )
      : { data: 0 }
  },
  getCustomerIDByCandidateID: candidate_uuid => axios.get(`${BASE_URL}/customer/candidate/${candidate_uuid}`),
  getCustomerIDByJobID: job_uuid => axios.get(`${BASE_URL}/customer/job/${job_uuid}`),
  shortlistedCandidates: payload => {
    return axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/shortlist/?order_by=-created_on&${payload}`);
  },
  shortlistedCandidatesTotalCount: payload => {
    return axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/shortlist/total_count?${payload}`);
  },
  uploadUserPhoto: (query, payload) => axios.post(`${BASE_URL}/user_documents/user_document?${query}`, payload),
  deleteUserPhoto: ({ user_id, user_document_id }) => axios.delete(`${BASE_URL}/user_documents/user_document_id/${user_document_id}?user_id=${user_id}`),
  getUsersUploadedDocument: (user_id) => axios.get(`${BASE_URL}/user_documents/get_by_user_id/${user_id}`),
  getCustomerVariant: (customer_id) => axios.get(`${BASE_URL}/customer_application_configuration/?customer_id=${customer_id}`),
  downloadCandidateList: ({ role, query, customer_id, org_id }) => {
    let api = ""
    let queryString = query;
    if (role === "system_admin") {
      api = `${BASE_URL}/candidate/card/detail/excel`
    } else {
      api = `${BASE_URL}/customer/${customer_id}/candidate/card/detail/excel`
    }
    if (org_id) {
      queryString = `organisation_id=${org_id}&${queryString}`
    }

    const endUrl = `${api}?${queryString}`
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    })
  }
};

export default customer;
