import {employees, tabDetails} from '../mock/employee'

// initial state

const getDefaultState = () => {
  return {
    isFetchingList: false,
    isFetchingEmployeeDetails: false,
    employeeDetails: {},
    tab_details: tabDetails,
    employees: employees
  }
}

const state = getDefaultState()

// create getters for all state variables
const getters = {
  isFetchingList: (state, getters, rootState) =>  state.isFetchingList,
  isFetchingEmployeeDetails: (state, getters, rootState) =>  state.isFetchingEmployeeDetails,
  getEmployees: (state, getters, rootState) =>  state.employees,
  getTabDetails: (state, getters, rootState) =>  state.tab_details,
  getEmployeeDetails: (state, getters, rootState) =>  state.employeeDetails,
}

// actions
const actions = {
  getEmployeeList({ state, commit, rootState }, payload) {
   // commit('SET_EMPLOYEE_LIST', payload);
  },
  resetEmployeeList({state, commit}, payload) {
    commit("RESET_EMPLOYEE")
  },
  getMoreEmployeeList({ state, commit, rootState, dispatch, getters}, payload) {
    let { getEmployees } = getters
    let data = [];
    for(let i = 1; i<=10; i++ ){
      data.push(
        {
          id: (getEmployees.length + i),
          avatar: { url: "img/avatars/1.jpg", status: "success" },
          user: {
            name: "Yiorgos Avraamu",
            new: true,
            registered: "Jan 1, 2015"
          },
          country: { name: "USA", flag: "cif-us" },
          usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
          activity: "10 sec ago"
        }
      )
    }
    setTimeout(()=>{
     commit('SET_EMPLOYEE_LIST_PAGINATION', getEmployees.concat(data));
    },2000)
   },
  setEmployeeDetails({ state, commit, rootState }, payload) {
     commit('SET_EMPLOYEE_DETAILS', payload);
   },
}

// mutations
const mutations = {
  ['SET_EMPLOYEE_LIST'] (state, payload) {
    state.employees = payload;
  },
  ['SET_EMPLOYEE_DETAILS'] (state, payload) {
    state.employeeDetails = payload;
  },
  ['SET_EMPLOYEE_LIST_PAGINATION'] (state, payload) {
    state.employees = payload;
  },
  ['RESET_EMPLOYEE'](state, data) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
