import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const contactUser = {
    fetchContact: ({ customer_uid, organisation_uid }) => axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/${organisation_uid}/contact/`),
    fetchUserType: () => axios.get(`${BASE_URL}/customer_user_type/`),
    fetchContactType: () => axios.get(`${BASE_URL}/contact/type/`),
    createUserExisting: ({ customer_id, organisation_id, queryString }) => axios.post(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact_user/existing_contact?${queryString}`),
    createCustomerContactUser: ({ customer_id, organisation_id, payload }) => axios.post(`${BASE_URL}/customer/${customer_id}/user/recruiter/contact_user?organisation_id=${organisation_id}`, payload),
    fetchContactUser: ({ customer_uid, organisation_uid }) => axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/${organisation_uid}/contact_user/`),
    updateCustomerContactUser: ({ customer_id, payload, queryString }) => axios.put(`${BASE_URL}/customer/${customer_id}/user/recruiter/contact_user?${queryString}`, payload),
    getCustomerContactUser: ({ customer_id, organisation_id }) => axios.get(`${BASE_URL}/customer/${customer_id}/user/recruiter/contact_user?organisation_id=${organisation_id}`),
    getAllGroupedCustomerOrganisation: ({contact_id,organisation_id,customer_id}) => axios.get(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact/${contact_id}/group/`),
    createCustomerOrganisationContact: ({customer_id, organisation_id, contact_id}) => axios.post(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact/${contact_id}`),
    deleteCustomerOrganisationContact: ({customer_id,organisation_id,contact_id}) => axios.delete(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact/${contact_id}/`),
}
export default contactUser;