import agencyCustomer from "@/services/agencyCustomer";
import customer from "@/services/customer";
import router from "../router/index";
import { getFilterQueryStringWithoutArray, isObject, isEmptyObjectCheck } from "@/helpers/helper.js";

const getDefaultState = () => {
  return {
    customerTypeList: [],
    customerList: [],
    isCustomerFetch: false,
    selectedCustomer: {},
    organisationList: [],
    isOrganisationFetch: false,
    districts: [],
    getUserUploadedDocuments: [],
  };
}

const state = getDefaultState()

const getters = {
  getCustomerTypeList: state => state.customerTypeList && state.customerTypeList.length
    ? state.customerTypeList.map(val => ({
      label: val.comments,
      code: val.customer_type_id
    })) : [],
  customerList: state => state.customerList,
  isCustomerFetch: state => state.isCustomerFetch,
  selectedCustomer: state => state.selectedCustomer,
  organisationList: state => state.organisationList,
  isOrganisationFetch: state => state.isOrganisationFetch,
  getSelectedCustomerVariant: state => state.selectedCustomerVariant,
  districts: state => state.districts,
  getDistrictsByLocationsID: (state, getters, rootState) => ({
    location_id
  }) => {
    return state.districts && state.districts.length
      ? state.districts.filter(val => Array.isArray(location_id) ? location_id.includes(val?.location_id) : val?.location_id === location_id).map(val => (
        { code: val?.id, label: val?.district, location_id: val?.location_id }
      )) : []
  },
  getUserUploadedDocuments: state => state.getUserUploadedDocuments,
};

const mutations = {
  ["SET_CUSTOMER_TYPE_LIST"](state, payload) {
    state.customerTypeList = payload;
  },
  ["SET_CUSTOMER_LIST"](state, payload) {
    state.customerList = payload;
  },
  ["SET_IS_CUSTOMER_FETCH"](state, payload) {
    state.isCustomerFetch = payload;
  },
  ["SET_SELECTED_CUSTOMER"](state, payload) {
    state.selectedCustomer = payload;
  },
  ['SET_ORGANISATION_LIST'](state, payload) {
    state.organisationList = payload;
  },
  ['RESET_ORGANISATION_LIST'](state) {
    state.organisationList = [];
  },
  ["SET_IS_ORGANISATION_FETCH"](state, payload) {
    state.isOrganisationFetch = payload;
  },
  ['SET_DISTRICTS'](state, payload) {
    state.districts = payload;
  },
  ["SET_USER_UPLOADED_DOCUMENT"](state, data) {
    state.getUserUploadedDocuments = data;
  },
};

const actions = {
  initUserFetchOptions: ({ state, commit, rootState, dispatch, getters }) => {
    let {
      getLocationCountryInfo,
      getCustomerTypeList,
      locations,
      getOrganisationTypeList,
      getIssueAuthority,
      districts,
    } = getters;
    commit("SET_FETCHING", true);
    let appendFilterAction = [];
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!getCustomerTypeList.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchCustomerTypeList")];
    }
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
    if (!getOrganisationTypeList.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchOrganisationTypeList")];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    if (!districts.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchAllDistricts")];
    }
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  fetchCustomerTypeList: ({ state, commit, rootState, dispatch, getters }) => {
    return agencyCustomer
      .getCustomerType()
      .then(res => {
        commit("SET_CUSTOMER_TYPE_LIST", res.data);
        return res.data;
      })
      .catch(err => {
        console.log("error fetching customer type list", err);
        return err;
      });
  },
  addCustomer: ({ state, commit, dispatch, getters }, payload) => {
    dispatch("showToast", { message: 'Saving...' })
    return agencyCustomer
      .addCustomer(payload)
      .then(res => {
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-success text-white', message: 'Customer saved successfully' })
        router.push("/customer-list");
        return res
      })
      .catch(err => {
        let msg = "Error while Register";
        console.log("error while register customer", err);
        const { status, data } = err.response;
        if (status === 409 &&
          data.detail.indexOf("email") > 0 &&
          data.detail.indexOf("already exists") > 0) {
          msg = "Email already exists";
        }
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      });
  },
  fetchCustomerList: ({ state, commit, dispatch, getters }) => {
    commit("SET_IS_CUSTOMER_FETCH", true);
    return agencyCustomer
      .getCustomerList()
      .then(res => {
        commit("SET_CUSTOMER_LIST", res.data);
        commit("SET_IS_CUSTOMER_FETCH", false);
        return res
      })
      .catch(err => {
        console.log("error fetching customer list", err);
        commit("SET_IS_CUSTOMER_FETCH", false);
        return err;
      })
  },
  updateCustomer: ({ state, commit, dispatch, getters }, customerPayload) => {
    const { customerId, payload } = customerPayload;
    dispatch("showToast", { class: 'bg-success text-white', message: "Updating..." })
    return agencyCustomer
      .updateCustomer({ customerId, payload })
      .then(res => {
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-success text-white', message: "Updated successfully" })
        return res
      })
      .catch(err => {
        let msg = "Error while Customer Update";
        console.log("error while Customer Update", err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      })
  },
  initOrganisationFetchOptions: ({ state, commit, rootState, dispatch, getters }) => {
    let {
      getLocationCountryInfo,
      locations,
      getOrganisationTypeList,
      getIssueAuthority,
      districts,
      autoGenPassword
    } = getters;
    commit("SET_FETCHING", true);
    let appendFilterAction = [];
    if (!getLocationCountryInfo.length) {
      appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
    }
    if (!locations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("getLocations")];
    }
    if (!getOrganisationTypeList.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchOrganisationTypeList")];
    }
    if (!getIssueAuthority.length) {
      appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
    }
    if (!districts.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchAllDistricts")];
    }
    appendFilterAction = [...appendFilterAction, dispatch("fetchAutoGenPassword")];
    return Promise.all(appendFilterAction).then(res => {
      commit("SET_FETCHING", false);
      return res;
    });
  },
  addOrganisation: ({ state, commit, dispatch, getters }, payload) => {
    dispatch("showToast", { message: 'Adding...' })
    const { getOrgIDFromAccessToken: parent_org_id, getCustomerId: customer_uid } = getters;
    payload = { ...payload, parent_org_id };
    return agencyCustomer
      .addOrganisation({ customer_uid, payload })
      .then(res => {
        const { data } = res;
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-success text-white', message: 'Organisation Added Successfully' })
        router.push("/organisation-list");
        return res;
      })
      .catch(err => {
        const msg = "Error While Add Organisation";
        console.log(msg, err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      })
  },
  fetchOrganisationList: ({ getters, dispatch, commit }) => {
    const { getCustomerId: customer_uid, getOrgIDFromAccessToken: parent_org_id, getUser } = getters;
    commit("SET_IS_CUSTOMER_FETCH", true);
    let queryPayload = {};
    if (parent_org_id)
      queryPayload = { ...queryPayload, parent_org_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return agencyCustomer
      .fetchOrganisationList({ customer_uid, queryString })
      .then(res => {
        const { data } = res;
        commit("SET_ORGANISATION_LIST", data);
        return data;
      })
      .catch(err => {
        console.log("Error while Fetch Organisation List", err);
        return err;
      })
      .finally(res => {
        commit("SET_IS_CUSTOMER_FETCH", false);
      })
  },
  fetchOrganisationListForCustomer: ({ getters, dispatch, commit }, payload) => {
    commit("SET_IS_ORGANISATION_FETCH", true);
    commit("RESET_ORGANISATION_LIST");
    const { customer_uid, parent_org_id } = payload;
    let queryPayload = {}
    if (parent_org_id)
      queryPayload = { ...queryPayload, parent_org_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return agencyCustomer
      .fetchOrganisationList({ customer_uid, queryString })
      .then(res => {
        const { data } = res;
        commit("SET_ORGANISATION_LIST", data);
        return data;
      })
      .catch(err => {
        console.log("Error while Fetch Organisation List", err);
        return err;
      })
      .finally(res => {
        commit("SET_IS_ORGANISATION_FETCH", false);
      })
  },
  updateOrganisation({ state, getters, dispatch, commit }, payload) {
    dispatch("showToast", { class: 'bg-success text-white', message: "Updating..." })
    return agencyCustomer
      .updateOrganisation(payload)
      .then(res => {
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-success text-white', message: "Updated successfully" })
        return res
      })
      .catch(err => {
        let msg = "Error while Organisation Update";
        console.log(msg, err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: 'bg-danger text-white', message: msg })
        return err;
      })
  },
  getDistrictsByLocationID({ state, getters, commit }, location_id) {
    return agencyCustomer
      .getDistrictsByLocationID(location_id)
      .then(res => {
        const { data } = res;
        commit('SET_DISTRICTS', data);
        return data;
      })
      .catch(err => {
        console.log('error while fetch district', err);
        return err;
      })
  },
  fetchAllDistricts({ commit }) {
    return agencyCustomer
      .getDistricts()
      .then(res => {
        const { data } = res;
        commit('SET_DISTRICTS', data);
        return data;
      })
      .catch(err => {
        console.log('error while fetch district', err);
        return err;
      })
  },
  uploadUserPhoto({ commit, dispatch }, payload) {
    const { customer_uid, user_id, file, file: { src }, document_type, fileName } = payload
    const type = src.split(';')[0].split(':')[1]
    dispatch("showToast", { message: 'Uploading...' })
    if (user_id && customer_uid && file) {
      let query = `customer_id=${customer_uid}&user_id=${user_id}`
      file.getCroppedCanvas().toBlob(function (blob) {
        let formData = new FormData();
        formData.append("file", blob, fileName);
        formData.append("document_type_id", document_type);
        customer.uploadUserPhoto(query, formData)
          .then(res => {
            const { data } = res;
            commit("SET_UPLOADED", true);
            commit("SET_LAST_UPLOADED_DOCS", data)
            dispatch("fetchUserUploadedDocument", data.user_id)
            dispatch("showToast", { class: 'bg-success text-white', message: 'Uploaded successfully' })
            return res;
          })
          .catch(err => {
            commit("SET_UPLOADED", true);
            let msg = "Error while uploading photo";
            dispatch("showToast", { class: 'bg-danger text-white', message: msg })
            return err;
          })
      }, type);
    }
  },
  deleteUserPhoto({ commit, dispatch }, payload) {
    customer.deleteUserPhoto(payload).then(res => {
      dispatch("fetchUserUploadedDocument", payload.user_id)
      dispatch("showToast", { class: 'bg-success text-white', message: 'Deleted successfully' })
      return res;
    }).catch(err => {
      dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while deleting' })
      console.log("Error while deleting", err)
      return err;
    })
  },
  fetchUserUploadedDocument({ commit, getters }, user_id) {
    const { getUser } = getters;
    let userId = getUser.id
    if (!userId) userId = user_id
    return customer
      .getUsersUploadedDocument(userId)
      .then(res => {
        commit("SET_USER_UPLOADED_DOCUMENT", res.data);
        return res;
      })
      .catch(err => {
        console.log("error fetching document type", err);
        return err;
      })
  },
  getCustomerVariant({ commit }, customer_id) {
    return customer
      .getCustomerVariant(customer_id).then((res) => {
        let { data } = res;
        // commit("SELECTED_CUSTOMER_VARIANT", data);
        return data;
      }).catch((err) => {
        console.log('Error while fetching customer variant', err);
        return err;
      });
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
};