<template>
  <div ref="app" id="app">
    <div class="c-app" :class="`${getLoggedAcronym}`">
      <TheHeader class="header-bar" />
      <!-- <div ref="cbody" id="c-body-app" class="c-body"> -->
      <LoadingOverlay v-if="isLoading" />
      <Toaster />
      <FixedToaster />
      <router-view></router-view>
      <!-- </div> -->
      <TheFooter></TheFooter>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Toaster from "@/components/reusable/Toaster";
import FixedToaster from "@/components/reusable/FixedToaster";
import TheHeader from "@/containers/TheHeader";
import TheFooter from "@/containers/TheFooter";
export default {
  name: "App",
  components: {
    Toaster,
    FixedToaster,
    TheHeader,
    TheFooter,
  },
  computed: {
    ...mapGetters(["scrollTop", "isLoading", "getLoggedAcronym"]),
  },
  watch: {
    scrollTop() {
      if (this.scrollTop) {
        const app = this.$refs.app;
        if (app) app.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
