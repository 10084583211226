import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const speciality = {
  speciality: () => axios.get(`${BASE_URL}/speciality/?order_by=speciality`),
  subSpeciality: () => axios.get(`${BASE_URL}/sub_speciality/?order_by=sub_speciality`),
  skill: () => axios.get(`${BASE_URL}/skill/`),
  specialInterest: () => axios.get(`${BASE_URL}/special_interest/?order_by=special_interest`)
}

export default speciality