import publicJobBoard from "@/services/publicJobBoard";
import jobBoard from "@/services/jobBoard";
import router from "@/router/index";
import { isObject, Role } from "@/helpers/helper";


const state = {
  publicJobIssuerBoard: {},
  isLoading: false,
  selectedPublicJobIssuerBoard: {},
  publicJobIssuerBoardPagination: {
    limit: 10,
    skip: 0,
    noMoreData: false,
  },
  selectedPublicJobIssuerBoardAdvert: [],
  selectedPublicJobIssuerBenefitsAndAdvertDesc: [],
  lastFetchedPublicJobAdvert: 0
};

const getters = {
  isPublicJobIssuerBoardFetching: () => state.isLoading,
  getPublicJobIssuerBoard: (state, getters, rootState) => {
    return Object.keys(state.publicJobIssuerBoard).map(
      (val) => state.publicJobIssuerBoard[val]
    );
  },
  getSelectedPublicJobIssuerBoard: (state, getters, rootState) =>
    state.selectedPublicJobIssuerBoard,
  getSelectedPublicJobIssuerBoardAdvert: (state, getters, rootState) =>
    state.selectedPublicJobIssuerBoardAdvert,
  getSelectedPublicJobIssuerBenefitsAndAdvertDesc: (state) =>
    state.selectedPublicJobIssuerBenefitsAndAdvertDesc,
  getPublicJobIssuerBoardPaginationSkip: (state, getters, rootState) =>
    state.publicJobIssuerBoardPagination.skip,
  getPublicJobIssuerBoardPaginationLimit: (state, getters, rootState) =>
    state.publicJobIssuerBoardPagination.limit,
  noMoreDataFromPublicJobIssuerBoard: (state, getters, rootState) =>
    state.publicJobIssuerBoardPagination.noMoreData,
};

const actions = {
  publicJobIssuerBoardAction: (
    { state, commit, rootState, dispatch, getters },
    payload = {}
  ) => {
    commit("PUBLIC_JOB_ISSUER_BOARD");
    if (!payload.pagination) {
      commit("SET_PUBLIC_JOB_ISSUER_BOARD_PAGINATION", {
        skip: 0,
        noMoreData: false,
      });
    }
    const {
      getPublicJobIssuerBoardPaginationSkip,
      getPublicJobIssuerBoardPaginationLimit,
    } = getters;
    let payloadVar = payload;

    return publicJobBoard
      .getPublicJobs({
        skip: getPublicJobIssuerBoardPaginationSkip,
        limit: getPublicJobIssuerBoardPaginationLimit,
      })
      .then((res) => {
        try {
          let { data } = res;
          if (data.length < getPublicJobIssuerBoardPaginationLimit) {
            commit("SET_PUBLIC_JOB_ISSUER_BOARD_PAGINATION", {
              noMoreData: true,
            });
          } else {
            commit("SET_PUBLIC_JOB_ISSUER_BOARD_PAGINATION", {
              skip:
                getPublicJobIssuerBoardPaginationSkip +
                getPublicJobIssuerBoardPaginationLimit,
            });
          }
          if (payloadVar && payloadVar.pagination) {
            commit("PUBLIC_JOB_ISSUER_BOARD_SUCCESS", {
              payload: data,
              pagination: payloadVar.pagination,
            });
          } else {
            commit("PUBLIC_JOB_ISSUER_BOARD_SUCCESS", { payload: data });
            let queryJobId = parseInt(router.history.current.query.job_id)
              ? parseInt(router.history.current.query.job_id)
              : data.length
              ? data[0].job_id
              : null;
            let selectedJobDetails = data.find((e) => e.job_id === queryJobId);
            if (!selectedJobDetails) {
              selectedJobDetails = data[0];
            }
            if (selectedJobDetails) {
              dispatch("fetchPublicJobBenefitsAdvertDescByJobId", {
                job_id: selectedJobDetails?.job_id,
                customer_id: selectedJobDetails?.customer_uid,
              });
            }
            commit("SELECTED_PUBLIC_JOB_ISSUER_BOARD", selectedJobDetails);
          }
        } catch (error) {
            console.log('Public job issuer error');
            console.log(error);
            commit("PUBLIC_JOB_ISSUER_BOARD_ERROR", error);
        }
      })
      .catch((err) => {
        console.log('Public job issuer error');
        console.log(err);
        commit("PUBLIC_JOB_ISSUER_BOARD_ERROR", err);
        return err;
      });
  },
  selectedPublicJobIssuerBoard: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    try {
      const { getCandidateId, getPublicJobIssuerBoard } = getters;
      if (getPublicJobIssuerBoard && getPublicJobIssuerBoard.length) {
        const selectedJobBoard = getPublicJobIssuerBoard.filter(
          e => e.job_id === payload
        )[0];
        if (selectedJobBoard) {
          dispatch("fetchPublicJobBenefitsAdvertDescByJobId", {
            job_id: selectedJobBoard?.job_id,
            customer_id: selectedJobBoard?.customer_uid,
          });
        }
        commit("SELECTED_PUBLIC_JOB_ISSUER_BOARD", selectedJobBoard);
      }
    } catch (e) {
      console.error("error occurred in action selectedPublicJobIssuerBoard ", e)
    }
  },
  fetchPublicJobBenefitsAdvertDescByJobId: ({ commit, dispatch, getters }, payload) => {
    const { getRoleScopes } = getters
    if (state.lastFetchedPublicJobAdvert == payload.job_uuid) return
    commit("LAST_FETCHED_PUBLIC_JOB_ADVERT", payload.job_uuid)
    commit("SELECTED_PUBLIC_JOB_ISSUER_BOARD_ADVERT", []);
    dispatch("showLoader");
    let role = getRoleScopes[0] || null;

    return jobBoard
      .fetchJobBenefitsAdvertDescByJobId(payload).then(res => {
        let { data } = res;
        commit("SELECTED_PUBLIC_JOB_ISSUER_BOARD_ADVERT", data.advert_description);
        commit("SELECTED_PUBLIC_JOB_ISSUER_BENEFITS_AND_ADVERT", data)
        return res
      })
      .catch(err => {
        console.error("error in fetching candidate page job details", err);
        return err;
      })
      .finally(res => dispatch("hideLoader"))
  },
  softDeletePublicSharedJob: ({ getters, dispatch, commit }, job_uuid) => {
    return publicJobBoard
      .softDeletePublicSharedJob({ job_uuid })
      .then(res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Job Deleted successfully'
        });
        commit("UPDATE_PUBLIC_JOB_ISSUER_BOARD_BY_REMOVING_JOB", job_id);
        return res;
      })
      .catch(err => {
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Error while Deleting'
        });
        console.log("Error While deleting job", err)
        return err;
      })
  },
  updatePublicSharedJob: ({ getters, dispatch, commit }, payload) => {
    let { job_id } = payload;
    return publicJobBoard
      .updatePublicSharedJob(job_id, payload)
      .then(res => {
        let { data } = res;
        commit("SELECTED_PUBLIC_JOB_ISSUER_BOARD", data);
        dispatch("fetchPublicJobBenefitsAdvertDescByJobId", {
          job_id: data?.job_id,
          customer_id: data?.customer_uid,
        });
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Job updated successfully'
        });
        return res;
      })
      .catch(err => {
        dispatch("showToast", {
          class: 'bg-danger text-white',
          message: 'Error while updating job'
        });
        console.log("Error while updating job", err)
        return err;
      })
  },
};

const mutations = {
  ["PUBLIC_JOB_ISSUER_BOARD_ERROR"](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  ["SET_PUBLIC_JOB_ISSUER_BOARD_PAGINATION"](state, data) {
    state.publicJobIssuerBoardPagination = {
      ...state.publicJobIssuerBoardPagination,
      ...data,
    };
  },
  ["PUBLIC_JOB_ISSUER_BOARD_SUCCESS"](state, { payload, pagination }) {
    let result = {};
    if (isObject(payload)) {
      result = payload;
    } else {
      payload.forEach((item) => {
        result[`job_${item.job_id}`] = item;
      });
    }
    if (pagination) {
      state.publicJobIssuerBoard = { ...state.publicJobIssuerBoard, ...result };
    } else {
      state.publicJobIssuerBoard = { ...result };
    }
    state.isLoading = false;
  },
  ["PUBLIC_JOB_ISSUER_BOARD"](state) {
    state.isLoading = true;
  },
  ["SELECTED_PUBLIC_JOB_ISSUER_BOARD"](state, payload) {
    state.selectedPublicJobIssuerBoard = payload;
  },
  ["SELECTED_PUBLIC_JOB_ISSUER_BOARD_ADVERT"](state, payload) {
    state.selectedPublicJobIssuerBoardAdvert = payload;
  },
  ["SELECTED_PUBLIC_JOB_ISSUER_BENEFITS_AND_ADVERT"](state, payload) {
    state.selectedPublicJobIssuerBenefitsAndAdvertDesc = payload;
  },
  ["LAST_FETCHED_PUBLIC_JOB_ADVERT"](state, payload) {
    state.lastFetchedPublicJobAdvert = payload;
  },
  ['UPDATE_PUBLIC_JOB_ISSUER_BOARD_BY_REMOVING_JOB'](state, job_id) {
    job_id = `job_${[job_id]}`
    let job_board = state.publicJobIssuerBoard;
    state.publicJobIssuerBoard = [];
    delete job_board[job_id];
    state.publicJobIssuerBoard = job_board;
    state.selectedPublicJobIssuerBoard = Object.keys(state.publicJobIssuerBoard).length ? state.publicJobIssuerBoard[Object.keys(state.publicJobIssuerBoard)[0]] : [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
