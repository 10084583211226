import activityList from "@/services/activityList";
import candidate from "@/services/candidate";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
import router from "../router/index";

const getDefaultState = () => {
    return {
        activityList: [],
        isFetchingActivityList: false,
        selectedActivity: null,
        currentUserType: null,
        isShowUserTypeModal: true,
    };
}
const state = getDefaultState();

const getters = {
    activityList: state => state.activityList,
    isFetchingActivityList: state => state.isFetchingActivityList,
    selectedActivity: state => state.selectedActivity,
    currentUserType: state => state.currentUserType,
    isShowUserTypeModal: stat => state.isShowUserTypeModal,
}

const mutations = {
    ['SET_ACTIVITY_LIST'](state, payload) {
        state.activityList = payload;
    },
    ['SET_IS_FETCHING_ACTIVITY_LIST'](state, data) {
        state.isFetchingActivityList = data;
    },
    ['SET_SELECTED_ACTIVITY'](state, payload) {
        state.selectedActivity = payload;
    },
    ['RESET_SELECTED_ACTIVITY'](state) {
        state.selectedActivity = null;
    },
    ['SET_CURRENT_USER_TYPE'](state, data) {
        state.currentUserType = data;
    },
    ['SET_IS_SHOW_USER_TYPE_MODAL'](state, data) {
        state.isShowUserTypeModal = data;
    }
}

const actions = {
    fetchActivityList: ({ commit, dispatch, getters }) => {
        const { getUserId: user_id } = getters
        commit("SET_IS_FETCHING_ACTIVITY_LIST", true);
        return activityList
            .activityList({ user_id })
            .then(res => {
                const { data } = res;
                commit("SET_ACTIVITY_LIST", data)
                return data
            })
            .catch(err => {
                console.log('Error While fetch activity list', err);
                return err;
            })
            .finally(res => {
                commit("SET_IS_FETCHING_ACTIVITY_LIST", false);
            })
    },
    fetchDocumentByCandidateID: ({ getters, commit, dispatch }, payload) => {
        commit("RESET_UPLOADED_DOCUMENT")
        const { customer_uid, candidate_uid, candidate_uuid } = payload;
        return candidate
            .getUploadedDocument(customer_uid, candidate_uuid)
            .then(async res => {
                commit("SET_UPLOADED_DOCUMENT", res.data);
            })
            .catch(err => {
                console.log("error fetching document type", err);
                return err;
            })
    },
    fetchActivity: ({ dispatch, commit }, payload) => {
        commit("RESET_SELECTED_ACTIVITY");
        dispatch("showLoader")
        return activityList
            .fetchActivity(payload)
            .then(res => {
                const { data } = res;
                const details = data && data.length && data[0] || null;
                commit("SET_SELECTED_ACTIVITY", details)
                return data;
            })
            .catch(err => {
                console.log("Error while Fetch Activity", err);
                return err;
            })
            .finally(res => {
                dispatch("hideLoader");
            })
    },
    fetchSelectedActivity: async ({ dispatch, getters, commit }, activity_id) => {
        const { activityList } = getters;
        let activityIndex;
        if (activityList && activityList.length) {
            activityIndex = activityList.findIndex(({ action_detail_id }) =>
                activity_id === action_detail_id
            );
        }
        if (activityIndex > -1) {
            const details = activityList[activityIndex];
            commit("SET_SELECTED_ACTIVITY", details)
            return details
        } else {
            return await dispatch("fetchActivity", activity_id);
        }
    },
    interviewReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityList
            .interviewReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully approved for Interview!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    holdReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityList
            .holdReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Candidate updated with Hold Status!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    disapproveReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityList
            .disapproveReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Disapproved the candidate!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    queryReviewer({ dispatch }, payload) {
        const { job_id, candidate_uid: candidate_id, action_detail_id, comments } = payload;
        let queryPayload = {};
        if (action_detail_id)
            queryPayload = { ...queryPayload, action_detail_id };
        if (comments)
            queryPayload = { ...queryPayload, comments };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        dispatch("showToast", { message: 'Updating...' });
        return activityList
            .queryReviewer({ job_id, candidate_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Query sent to the initiator!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    approveAuthoriser({ dispatch }, payload) {
        const { job_id, customer_id, organisation_uid, action_detail_id, comment } = payload;
        dispatch("showToast", { message: 'Updating...' });
        return activityList
            .approveAuthoriser({ job_id, customer_id, organisation_uid, action_detail_id, comment })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'Approved the Job!' })
                router.push("/list-activity");
                return data;
            })
            .catch(err => {
                const message = "Error While Updating Activity";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
};