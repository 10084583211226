export default {
  candidateType: {
    name: "candidateType",
    component: "filterSelect",
    placeholder: "All",
    label: "Candidate Type",
    queryKey: "candidate_type_id__in",
    dependsOn: [],
    // dependentFilter: ["candidateLevel", "specialitySearch", 'pqrLevel','pqrCountries'],
    dependentFilter: ["candidateLevel", "specialitySearch"],
    secondLevelDependentDisable: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  // pqrCountries: {
  //   name: "pqrCountries",
  //   component: "filterSelect",
  //   label: "Licensing Country",
  //   queryKey: "pqr_country_id",
  //   groupFilterName: "PQR",
  //   dependsOn: [],
  //   secondLevelDependentDisable: ["candidateType"],
  //   selectDependentDisable:["candidateLevel"],
  //   dependentFilter: [],
  //   apiDependentFilter: ["pqrLicensingAuthority","pqrLevel"], //pqrLicensingAuthority is dependent on pqrCountries
  //   taggable: false,
  //   multiple: true
  // }, 
  // pqrLicensingAuthority: {
  //   name: "pqrLicensingAuthority",
  //   component: "filterSelect",
  //   label: "Licensing Authority",
  //   queryKey: "pqr_license_auth_id",
  //   groupFilterName: "PQR",
  //   dependsOn: [],
  //   secondLevelDependentClear: ['pqrLevel'],
  //   secondLevelDependentDisable: ["pqrCountries"],
  //   selectDependentDisable:["candidateLevel"],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true
  // }, 
  // pqrLevel: {
  //   name: "pqrLevel",
  //   component: "filterSelect",
  //   label: "Licensing Level",
  //   queryKey: "pqr_level_id",
  //   groupFilterName: "PQR",
  //   dependsOn: ['candidateType'],
  //   secondLevelDependentDisable: ["pqrCountries"],
  //   selectDependentDisable:["candidateLevel"],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false
  // }, 
  candidateLevel: {
    name: "candidateLevel",
    component: "filterSelect",
    label: "Level",
    queryKey: "level_id__in",
    dependsOn: ["candidateType"],
    secondLevelDependentDisable: ["candidateType"],
    selectDependentDisable: ["pqrCountries", "pqrLicensingAuthority", "pqrLevel"],
    taggable: false,
    multiple: true,
  },
  specialitySearch: {
    name: "specialitySearch",
    component: "accordionSelect",
    label: "Speciality/Sub-Speciality",
    queryKey: "speciality_references",
    dependsOn: [],
    dependentFilter: [],
    secondLevelDependentDisable: ['candidateType'],
    taggable: false,
    multiple: true,
    clearable: true,
    filterDynamicSearchAction: "genericSpecialitySearch",
    dependentQuerys: ["candidateType"],
    placeholder: "Please start typing Speciality/Sub-Speciality",
  },
  // speciality: {
  //   name: "speciality",
  //   component: "filterSelect",
  //   label: "Speciality",
  //   queryKey: "speciality_id__in",
  //   dependsOn: ["candidateType"],
  //   secondLevelDependentDisable: ["candidateType"],
  //   dependentFilter: ["subSpeciality"],
  //   taggable: false,
  //   multiple: true,
  // },
  // subSpeciality: {
  //   name: "subSpeciality",
  //   component: "filterSelect",
  //   label: "Sub Speciality",
  //   queryKey: "sub_speciality_id__in",
  //   dependsOn: ["candidateType"],
  //   secondLevelDependentDisable: ["speciality"],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  // },
  // retiredClinician: {
  //   name: "retiredClinician",
  //   component: "filterSelect",
  //   label: "Retired Clinician​​",
  //   queryKey: "retired_clinician__in",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  // volunteer: {
  //   name: "volunteer",
  //   component: "filterSelect",
  //   label: "Volunteer (Unpaid)",
  //   queryKey: "volunteer__in",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  licence: {
    name: "licence",
    component: "filterSelect",
    label: "Regional License",
    queryKey: "local_license__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  // active: {
  //   name: "active",
  //   component: "filterSelect",
  //   label: "Active",
  //   queryKey: "available_during_covid__in__and",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: true,
  // },
  // deleted: {
  //   name: "deleted",
  //   component: "filterSelect",
  //   label: "Deleted",
  //   queryKey: "deleted",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  availabilityType: {
    name: "availabilityType",
    component: "filterSelect",
    label: "Contract",
    queryKey: "job_type",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  // contractType: {
  //   name: "contractType",
  //   component: "filterSelect",
  //   label: "Contract",
  //   queryKey: "job_time",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  currentCountry: {
    name: "currentCountry",
    component: "filterSelect",
    label: "Current Home Location",
    queryKey: "country_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  // preferredLocations: {
  //   name: "preferredLocations",
  //   component: "filterSelect",
  //   label: "Preferred Location",
  //   queryKey: "preferred_location_id__in",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  gender: {
    name: "gender",
    component: "filterSelect",
    label: "Gender",
    queryKey: "gender_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  birthPlaces: {
    name: "birthPlaces",
    component: "filterSelect",
    label: "Birth Place",
    queryKey: "birth_place_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  nationalityInfo: {
    name: "nationalityInfo",
    component: "filterSelect",
    label: "Nationality",
    queryKey: "nationality_ids__overlap",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  qualificationCountries: {
    name: "qualificationCountries",
    component: "filterSelect",
    label: "Qualification Country",
    queryKey: "candidate_qualifications___main=true&candidate_qualifications___country_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  // locationRegion: {
  //   name: "locationRegion",
  //   component: "filterSelect",
  //   label: "Preferred Region",
  //   queryKey: "region_ids__overlap",
  //   dependsOn: [],
  //   dependentFilter: ['preferredLocations'],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  // preferredLocations: {
  //   name: "preferredLocations",
  //   component: "filterSelect",
  //   label: "Preferred Country",
  //   queryKey: "preferred_country_ids__overlap",
  //   dependsOn: ['locationRegion'],
  //   dependentFilter: [],
  //   secondLevelDependentDisable: ["locationRegion"],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  locationLanguage: {
    name: "locationLanguage",
    component: "filterSelect",
    label: "Language",
    queryKey: "language_ids__overlap",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  ageLimit: {
    name: "ageLimit",
    component: "filterRange",
    label: "Age limit",
    queryKey: "age__lte",
    dependsOn: [],
    dependentFilter: [],
    selected: [20], //default value
    min: 20,
    max: 70,
    type: "range",
  },
  available: {
    name: "available",
    component: "filterSelect",
    label: "Actively Seeking Employment",
    queryKey: "available__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
};
